import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { TextInput, Button } from 'components';
import { formTypes } from 'consts';
import { formUtils } from 'utils';

export const ResetPasswordField = ({ input, meta, disableSubmit = false, ...rest }) => {
  const isSubmitBtnDisabled = () => {
    const isEmailInvalid = !formUtils.isEmpty(input.value) && !formUtils.validateEmailAddress(input.value);
    return disableSubmit || formUtils.isEmpty(input.value) || isEmailInvalid;
  };

  return (
    <Fragment>
      <TextInput {...rest} input={input} meta={meta} label="Email" maxLength={formUtils.FIELD_MAX_LENGTH} errorOnBlur />
      <Button block disabled={isSubmitBtnDisabled()} type="submit" size="lg">
        Reset password
      </Button>
    </Fragment>
  );
};

ResetPasswordField.propTypes = {
  input: PropTypes.shape(formTypes.fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(formTypes.fieldMetaPropTypes).isRequired,
  disableSubmit: PropTypes.bool,
};
