import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Map, OrderedMap } from 'immutable';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { FlexibleWidthXYPlot, Hint, HorizontalGridLines, VerticalBarSeries, XAxis, YAxis } from 'react-vis';
import styled from 'styled-components';
import 'react-vis/dist/style.css';

import { Flex, Box, tokens, Typography } from '@unitoio/mosaic';
import { ErrorBoundary } from '@unitoio/sherlock';

import { containerActions, organizationActions, activityLogsActions } from 'actions';
import { featureTypes } from 'consts';
import { AppError } from 'containers';
import { capitalize, isFeatureUnlimited } from 'utils';
import { useLogger } from 'hooks';
import { Button, FeatureFlag, FeatureFlagVariant, InlineLoading, ProgressBar, Section, Title } from 'components';
import {
  getActiveContainers,
  getCountItemsPerItemTypePerContainer,
  getCurrentItemsKeptInSyncByOrganizationId,
  getDailyItemsKeptInSyncByOrganizationId,
  getDistinctCurrentItemsKeptInSyncByOrganizationId,
  getDistinctDailyItemsKeptInSyncByOrganizationId,
  getFeatureFlagValue,
  getItemsKeptInSyncLastRefresh,
  getOrganizationById,
  getOrganizationPlanFeaturesWithUsage,
  getProviderNameFromContainer,
  getSyncsWithItemsKeptInSync,
  getItemDefinitionByItemType,
} from 'reducers';

import { LinkContainerIcons } from '../../components/LinkItem/LinkContainerIcons';
import { LinkInformation } from '../../components/LinkItem/LinkInformation';
import { ActiveContainers } from './ActiveContainers';

const Value = styled.div`
  height: 80px;
  padding: 15px 10px 0 0;
  border-right: 1px solid black;
`;

const NormalTooltip = styled.div`
  align-items: center;
  background-color: ${tokens.colors.content.secondary.default};
  border-radius: 4px;
  color: ${tokens.colors.global.primary.light};
  flex-flow: row wrap;
  font-size: ${tokens.fontSize.f7};

  display: inline-block;
  padding: ${tokens.spacing.s4};
  width: max-content;
`;

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const TooltipText = ({ providerName, itemType, count }) => {
  const { reportWarning } = useLogger();
  const itemTypeTerms = useSelector((state) =>
    getItemDefinitionByItemType(state, providerName, itemType)?.get('names'),
  );

  useEffect(() => {
    if (!itemTypeTerms) {
      reportWarning(`No item type definition found for provider ${providerName} and item type ${itemType}`, {
        identifier: 'itemsKeptInSync TooltipText itemTypeNotFound',
      });
    }
  }, [itemType, itemTypeTerms, providerName, reportWarning]);

  return itemTypeTerms ? (
    <Typography variant={Typography.variants.BODY2} color={tokens.colors.global.primary.light}>
      <Bold>{count}</Bold>{' '}
      {`${capitalize(providerName)} ${count > 1 ? itemTypeTerms.get('plural') : itemTypeTerms.get('singular')}`}
    </Typography>
  ) : (
    <Typography variant={Typography.variants.BODY2} color={tokens.colors.global.primary.light}>
      <Bold>{count}</Bold> {capitalize(itemType)}
    </Typography>
  );
};

TooltipText.propTypes = {
  providerName: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

const getTooltipText = (count, providerItemType) => {
  // Splitting the text because it should be in the following format providerName_itemType.
  const providerItemTypeSplit = providerItemType.split('_');

  // Handling some edge case for item types that might not have a provider associated e.g. unknown
  // unknown cases should not happen anymore as it was improved by using PCD V3. However, remain from the past still exists, hence the need to handle these edge cases.
  // Slack convo for more context: https://unitoinc.slack.com/archives/C03TQ1RU84Q/p1674760986824929
  const providerName = providerItemTypeSplit.length === 2 ? providerItemTypeSplit[0] : '';
  const itemType = providerItemTypeSplit.length === 2 ? providerItemTypeSplit[1] : providerItemTypeSplit[0];

  return (
    <Box>
      <TooltipText count={count} itemType={itemType} providerName={providerName} />
    </Box>
  );
};

export const getItemsPerItemType = (date, countItemsPerItemTypePerContainer, providerNameFromContainer) => {
  let total = 0;
  let topItemsMap = Map();

  const itemsPerItemType = countItemsPerItemTypePerContainer.get(date);

  if (!itemsPerItemType) {
    return (
      <Box>
        <Typography variant={Typography.variants.BODY2} color={tokens.colors.global.primary.light}>
          <Bold>The number of items in sync is not</Bold>
        </Typography>
        <Typography variant={Typography.variants.BODY2} color={tokens.colors.global.primary.light}>
          <Bold>available at this time, try again later.</Bold>
        </Typography>
      </Box>
    );
  }

  itemsPerItemType.mapEntries(([containerId, items]) => {
    const providerName = providerNameFromContainer.get(date).get(containerId);

    items.mapEntries(([itemType, count]) => {
      const key = itemType === 'unknown' ? 'unknown' : `${providerName}_${itemType}`;

      if (topItemsMap.has(key)) {
        topItemsMap = topItemsMap.set(key, topItemsMap.get(key) + count);
      } else {
        topItemsMap = topItemsMap.set(key, count);
      }
      total += count;
    });
  });

  topItemsMap = topItemsMap.sortBy((v) => -v).entrySeq();

  return (
    <Box flexDirection={Box.flexDirection.COLUMN} fullWidth>
      <Box m={[0, 0, tokens.spacing.s3, 0]}>
        <Typography variant={Typography.variants.BODY2} color={tokens.colors.global.primary.light}>
          <Bold>{`${moment(date).format('ddd MMM DD')} | ${total} items in sync`}</Bold>
        </Typography>
      </Box>
      {topItemsMap.get(0) && getTooltipText(topItemsMap.get(0)[1], topItemsMap.get(0)[0])}
      {topItemsMap.get(1) && getTooltipText(topItemsMap.get(1)[1], topItemsMap.get(1)[0])}

      {topItemsMap.size > 2 && (
        <Box>
          <Typography variant={Typography.variants.BODY2} color={tokens.colors.global.primary.light}>
            <Bold>{total - topItemsMap.get(1)[1] - topItemsMap.get(0)[1]}</Bold> other item types
          </Typography>
        </Box>
      )}
    </Box>
  );
};

class ItemsKeptInSyncComponent extends Component {
  static propTypes = {
    fetchSyncActivity: PropTypes.func.isRequired,
    currentItemsKeptInSync: PropTypes.number.isRequired,
    distinctCurrentItemsKeptInSync: PropTypes.number.isRequired,
    itemsKeptInSyncLimit: PropTypes.number.isRequired,
    dailyItemsKeptInSync: PropTypes.instanceOf(List).isRequired,
    distinctDailyItemsKeptInSync: PropTypes.instanceOf(List).isRequired,
    syncWithActivityList: PropTypes.instanceOf(List).isRequired,
    lastRefreshDate: PropTypes.string,
    fetchActiveContainers: PropTypes.func.isRequired,
    activeContainers: PropTypes.instanceOf(Map).isRequired,
    hasItemsKeptInSyncByContainer: PropTypes.bool.isRequired,
    fetchActivityLogs: PropTypes.func.isRequired,
    countItemsPerItemTypePerContainer: PropTypes.instanceOf(Map).isRequired,
    hasItemsKeptInSyncTooltip: PropTypes.bool.isRequired,
    providerNameFromContainer: PropTypes.instanceOf(OrderedMap).isRequired,
  };

  static defaultProps = {
    lastRefreshDate: '',
  };

  state = {
    errorOnMount: null,
    hintValue: null,
    isLoading: true,
    containersWithActivity: [],
  };

  async componentDidMount() {
    const { fetchSyncActivity, fetchActiveContainers, hasItemsKeptInSyncByContainer, fetchActivityLogs } = this.props;

    try {
      await fetchSyncActivity();

      if (hasItemsKeptInSyncByContainer) {
        await fetchActiveContainers();
      }

      const logs = await fetchActivityLogs();

      const containersWithActivity = logs.activityLogs.reduce((acc, curLog) => {
        acc[curLog.sourceContainer.id] = true;
        return acc;
      }, {});

      this.setState({
        isLoading: false,
        containersWithActivity,
      });
    } catch (err) {
      this.setState({
        errorOnMount: err,
      });
    }
  }

  onMouseLeave = () => {
    this.setState({ hintValue: null });
  };

  nearestXHandler = (value) => {
    this.setState({ hintValue: value });
  };

  render() {
    const {
      dailyItemsKeptInSync,
      distinctDailyItemsKeptInSync,
      currentItemsKeptInSync,
      distinctCurrentItemsKeptInSync,
      syncWithActivityList,
      itemsKeptInSyncLimit,
      lastRefreshDate,
      activeContainers,
      countItemsPerItemTypePerContainer,
      hasItemsKeptInSyncTooltip,
      providerNameFromContainer,
    } = this.props;
    const { isLoading, errorOnMount, hintValue, containersWithActivity } = this.state;

    if (errorOnMount) {
      throw errorOnMount;
    }

    const limitPercentage = Number.parseInt((currentItemsKeptInSync / itemsKeptInSyncLimit) * 100, 10) || 0;
    const NaNOrUnlimited = Number.isNaN(itemsKeptInSyncLimit) || isFeatureUnlimited(itemsKeptInSyncLimit);

    return isLoading ? (
      <InlineLoading />
    ) : (
      <Section>
        <Title type="h2">Items in sync</Title>
        <Box
          className="row"
          p={[tokens.spacing.s4]}
          borderRadius={tokens.spacing.s4}
          borderSize={1}
          m={[tokens.spacing.s4, 0, tokens.spacing.s4]}
        >
          <Value className="col-md-2">
            <FeatureFlag name="items-kept-in-sync-by-container">
              <FeatureFlagVariant value={true}>
                <Typography variant="h2" align="center">
                  {distinctCurrentItemsKeptInSync} / <small>{itemsKeptInSyncLimit}</small>
                </Typography>
              </FeatureFlagVariant>
              <FeatureFlagVariant value={false}>
                <Typography variant="h2" align="center">
                  {currentItemsKeptInSync} / <small>{itemsKeptInSyncLimit}</small>
                </Typography>
              </FeatureFlagVariant>
            </FeatureFlag>

            {!NaNOrUnlimited && (
              <ProgressBar
                type="horizontal"
                progression={limitPercentage}
                dangerZone={limitPercentage >= 80}
                animateTransition
              />
            )}
          </Value>
          <div className="col-md-10">
            <Typography variant="body1">
              This is the number of <strong>items</strong> that Unito is currently keeping in sync for your workspace.
              {'  '}
              <br />
              <em>Last Update:</em> {lastRefreshDate ? moment(lastRefreshDate).fromNow() : 'Never'} (Updated every ~4
              hours)
            </Typography>
            <FeatureFlag name="items-kept-in-sync-by-container">
              <FeatureFlagVariant value={true}>
                <Button
                  style={{ paddingLeft: 0 }}
                  type="href"
                  btnStyle="link"
                  size="md"
                  href="https://guide.unito.io/what-are-items-in-sync"
                >
                  See how this number is calculated
                </Button>
              </FeatureFlagVariant>
              <FeatureFlagVariant value={false}>
                <Button
                  style={{ paddingLeft: 0 }}
                  type="href"
                  btnStyle="link"
                  size="md"
                  href="https://guide.unito.io/what-are-items-in-sync"
                >
                  See how this number is calculated
                </Button>
              </FeatureFlagVariant>
            </FeatureFlag>
          </div>
        </Box>

        <FeatureFlag name="items-kept-in-sync-by-container">
          <FeatureFlagVariant value={true}>
            <FlexibleWidthXYPlot xType="time-utc" animation height={400} onMouseLeave={this.onMouseLeave}>
              <HorizontalGridLines />

              <VerticalBarSeries
                color={tokens.colors.content.info.default}
                data={distinctDailyItemsKeptInSync.toArray().map((item) => {
                  // value of y (sum of items in sync) can be undefined or null based on snowflake documentation.
                  // https://docs.snowflake.com/en/sql-reference/functions/sum.html
                  if (Number.isNaN(item?.y)) {
                    return { x: item.x, y: 0 };
                  }
                  return item;
                })}
                onNearestX={this.nearestXHandler}
              />

              <XAxis tickTotal={7} tickFormat={(v) => new Date(v).toDateString().substr(0, 10)} />
              <YAxis />

              {hintValue && (
                <Hint value={hintValue} align={{ horizontal: Hint.ALIGN.AUTO, vertical: Hint.ALIGN.TOP }}>
                  {hasItemsKeptInSyncTooltip ? (
                    <NormalTooltip>
                      {getItemsPerItemType(
                        moment(hintValue.x).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                        countItemsPerItemTypePerContainer,
                        providerNameFromContainer,
                      )}
                    </NormalTooltip>
                  ) : (
                    <div className="rv-hint__content">
                      <div> {new Date(hintValue.x).toDateString()} </div>
                      <div> {hintValue.y} items</div>
                    </div>
                  )}
                </Hint>
              )}
            </FlexibleWidthXYPlot>
          </FeatureFlagVariant>

          <FeatureFlagVariant value={false}>
            <FlexibleWidthXYPlot xType="time-utc" animation height={400} onMouseLeave={this.onMouseLeave}>
              <HorizontalGridLines />

              <VerticalBarSeries
                color={tokens.colors.content.info.default}
                data={dailyItemsKeptInSync.toArray()}
                onNearestX={this.nearestXHandler}
              />

              <XAxis tickTotal={7} tickFormat={(v) => new Date(v).toDateString().substr(0, 10)} />
              <YAxis />

              {hintValue && (
                <Hint value={hintValue} align={{ horizontal: Hint.ALIGN.AUTO, vertical: Hint.ALIGN.TOP_EDGE }}>
                  {hasItemsKeptInSyncTooltip ? (
                    <NormalTooltip>
                      {getItemsPerItemType(
                        moment(hintValue.x).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                        countItemsPerItemTypePerContainer,
                        providerNameFromContainer,
                      )}
                    </NormalTooltip>
                  ) : (
                    <div className="rv-hint__content">
                      <div> {new Date(hintValue.x).toDateString()} </div>
                      <div> {hintValue.y} items</div>
                    </div>
                  )}
                </Hint>
              )}
            </FlexibleWidthXYPlot>
          </FeatureFlagVariant>
        </FeatureFlag>

        <FeatureFlag name="items-kept-in-sync-by-container">
          <FeatureFlagVariant value={true}>
            <>
              {activeContainers
                .entrySeq()
                .map(([key, value]) => (
                  <ActiveContainers
                    key={value.get('_id')}
                    container={value}
                    isCalculatingItems={!!containersWithActivity[key]}
                  />
                ))
                .toArray()}
            </>
          </FeatureFlagVariant>
          <FeatureFlagVariant value={false}>
            <>
              {syncWithActivityList
                .valueSeq()
                .map((sync) => (
                  <Flex
                    align="center"
                    style={{
                      padding: tokens.spacing.s3,
                      borderRadius: tokens.spacing.s4,
                      border: `1px solid ${tokens.colors.content.neutral.n10}`,
                      margin: `${tokens.spacing.s2} ${tokens.spacing.s0} ${tokens.spacing.s2}`,
                    }}
                    key={sync.get('_id')}
                  >
                    <Box
                      m={[tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0, tokens.spacing.s0]}
                      alignItems={Box.alignItems.CENTER}
                    >
                      <LinkContainerIcons
                        containerA={sync.get('containerA')}
                        containerB={sync.get('containerB')}
                        providerA={sync.get('providerA')}
                        providerB={sync.get('providerB')}
                        readOnlyA={sync.get('readOnlyA')}
                        readOnlyB={sync.get('readOnlyB')}
                        hideLastSyncAt
                      />
                    </Box>

                    <Flex flex={1}>
                      <LinkInformation linkId={sync.get('_id')} hideLastSyncAt />
                    </Flex>
                    <Box m={[tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0, tokens.spacing.s0]}>
                      {sync.get('itemsCount')} items in sync
                    </Box>
                  </Flex>
                ))
                .toArray()}
            </>
          </FeatureFlagVariant>
        </FeatureFlag>
      </Section>
    );
  }
}

const ItemsKeptInSyncWithErrorBoundary = (props) => {
  const { reportException } = useLogger();
  return (
    <ErrorBoundary
      FallbackComponent={AppError}
      onError={(error, { componentStack }, errMessageContext) =>
        reportException(error, { ...errMessageContext, componentStack })
      }
    >
      <ItemsKeptInSyncComponent {...props} />
    </ErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchSyncActivity: () => dispatch(organizationActions.getSyncActivity(ownProps.match.params.organizationId)),
  fetchActiveContainers: () => dispatch(containerActions.getActiveContainers(ownProps.match.params.organizationId)),
  fetchActivityLogs: () =>
    dispatch(
      activityLogsActions.fetchActivityLogs(
        ownProps.match.params.organizationId,
        0,
        50,
        null,
        null,
        // 4 hours ago
        Date.now() - 144000000,
      ),
    ),
});

const mapStateToProps = (state, ownProps) => {
  const { organizationId } = ownProps.match.params;
  const planFeaturesWithUsage = getOrganizationPlanFeaturesWithUsage(state, organizationId);
  const itemsKeptInSyncLimit = planFeaturesWithUsage.getIn([featureTypes.FEATURES.MAX_ITEMS_KEPT_IN_SYNC, 'limit']);

  return {
    organization: getOrganizationById(state, organizationId),
    currentItemsKeptInSync: getCurrentItemsKeptInSyncByOrganizationId(state, organizationId),
    distinctCurrentItemsKeptInSync: getDistinctCurrentItemsKeptInSyncByOrganizationId(state, organizationId),
    dailyItemsKeptInSync: getDailyItemsKeptInSyncByOrganizationId(state, organizationId),
    distinctDailyItemsKeptInSync: getDistinctDailyItemsKeptInSyncByOrganizationId(state, organizationId),
    syncWithActivityList: getSyncsWithItemsKeptInSync(state, organizationId),
    lastRefreshDate: getItemsKeptInSyncLastRefresh(state, organizationId),
    itemsKeptInSyncLimit,
    activeContainers: getActiveContainers(state),
    hasItemsKeptInSyncByContainer: getFeatureFlagValue(state, 'items-kept-in-sync-by-container'),
    hasItemsKeptInSyncTooltip: getFeatureFlagValue(state, 'items-kept-in-sync-item-type'),
    countItemsPerItemTypePerContainer: getCountItemsPerItemTypePerContainer(state, organizationId),
    providerNameFromContainer: getProviderNameFromContainer(state, organizationId),
  };
};

export const ItemsKeptInSync = connect(mapStateToProps, mapDispatchToProps)(ItemsKeptInSyncWithErrorBoundary);
