import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getFeatureFlagValue } from 'reducers';
import { featureTypes } from 'consts';

import { useProviderItemsCompatible } from './useProviderItemsCompatible';
import { useAreBothSidesReadOnly } from './useAreBothSidesReadOnly';
import { useGetProviderExtraStep } from './useGetProviderExtraStep';
import { useHasMissingExtraStepAction } from './useHasMissingExtraStepAction';
import * as formUtils from '../utils/form';

export const useShouldDisableConfirmButton = (providerA, providerB, loadingState) => {
  const A = useWatch({ name: 'A' });
  const B = useWatch({ name: 'B' });

  const validContainerConfiguration = ['itemType', 'containerType', 'containerId'].every(
    (config) => !!(A[config] && B[config]),
  );

  const areItemsCompatible = useProviderItemsCompatible({
    providerA,
    containerIdA: A.containerId,
    itemTypeA: A.itemType,
    providerB,
    containerIdB: B.containerId,
    itemTypeB: B.itemType,
    areProviderIdentitiesSelected: !!(A.providerIdentityId && B.providerIdentityId),
  });
  const areBothSidesReadOnly = useAreBothSidesReadOnly({
    providerIdA: providerA.get('_id'),
    providerIdB: providerB.get('_id'),
    itemTypeA: A.itemType,
    itemTypeB: B.itemType,
    containerIdA: A.containerId,
    containerIdB: B.containerId,
  });
  const isLoading = [formUtils.loadingStates.LOADING, formUtils.loadingStates.SAVING].includes(loadingState);
  const disableConfirmButton = isLoading || !areItemsCompatible || areBothSidesReadOnly || !validContainerConfiguration;

  // Extra validation when the add-on configuration is on the tool selection page
  const isAddOnImprovements = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.ADD_ON_IMPROVEMENTS),
  );

  const providerItemAExtraStep = useGetProviderExtraStep(providerA.get('name'));
  const providerItemBExtraStep = useGetProviderExtraStep(providerB.get('name'));
  const containerARequiresExtraConfig = useHasMissingExtraStepAction(
    providerItemAExtraStep,
    providerA.get('name'),
    A.providerIdentityId,
    A.containerId,
  );
  const containerBRequiresExtraConfig = useHasMissingExtraStepAction(
    providerItemBExtraStep,
    providerB.get('name'),
    B.providerIdentityId,
    B.containerId,
  );

  // When the add-on is shown on the tool selection page
  if (isAddOnImprovements) {
    return disableConfirmButton;
  }

  return disableConfirmButton || containerARequiresExtraConfig || containerBRequiresExtraConfig;
};
