import { fromJS, Map } from 'immutable';

import { linkTypes } from 'consts';
import { formUtils } from 'utils';

export const linkPayloadToFormData = (linkPayload, { isDuplicate, originalIsAutoSync } = {}) => {
  const sync = fromJS(linkPayload);

  const syncSettings = sync.get('syncSettings', Map());
  const manualOptions = syncSettings.get('manualOptions', Map()).toJS();

  const displayNameA = sync.getIn(['A', 'container', 'displayName']);
  const displayNameB = sync.getIn(['B', 'container', 'displayName']);
  const readOnlyA = syncSettings.getIn(['A', 'readOnly']);
  const readOnlyB = syncSettings.getIn(['B', 'readOnly']);
  const providerItemTermA = sync.getIn(['A', 'itemType']);
  const providerItemTermB = sync.getIn(['B', 'itemType']);

  // Older links do not have a name attribute
  // Generate the default name here so the editName component does not break.
  const syncName =
    sync.get('name') ||
    formUtils.generateDefaultLinkName({
      // Old flows have both container display name and itemType which is all we need to create the default link name
      providerDisplayNameA: '',
      providerDisplayNameB: '',
      containerNameA: displayNameA,
      containerNameB: displayNameB,
      readOnlyA,
      readOnlyB,
      providerItemTermA,
      providerItemTermB,
    });

  // explicitly not defaulted to Map() because an `undefined` associations has a meaning.
  const itemFieldAssociationsA = syncSettings.getIn(['A', 'itemFieldAssociations'])?.toJS();
  const itemFiltersA = Object.keys(itemFieldAssociationsA || {});
  // explicitly not defaulted to Map() because an `undefined` associations has a meaning.
  const itemFieldAssociationsB = syncSettings.getIn(['B', 'itemFieldAssociations'])?.toJS();
  const itemFiltersB = Object.keys(itemFieldAssociationsB || {});

  const formValues = {
    A: {
      providerId: sync.getIn(['A', 'providerIdentity', 'providerId']),
      providerIdentityId: sync.getIn(['A', 'providerIdentity', '_id']),
      containerId: sync.getIn(['A', 'container', 'id']),
      existingContainer: true,
      nodes: sync.getIn(['A', 'nodes']),
      filters: formUtils.getFilters(syncSettings, 'A'),
      tweaks: formUtils.getPcdOptions(syncSettings, 'A'),
      multisyncDiscriminants: formUtils.getMultisyncDiscriminants(syncSettings, 'A'),
      readOnly: readOnlyA,
      closedTasks: syncSettings.getIn(['A', 'closedTasks']),
      onFilterOut: syncSettings.getIn(['A', 'onFilterOut']),
      itemFilters: itemFiltersA,
      itemFieldAssociations: itemFieldAssociationsA,
    },
    B: {
      providerId: sync.getIn(['B', 'providerIdentity', 'providerId']),
      providerIdentityId: sync.getIn(['B', 'providerIdentity', '_id']),
      containerId: isDuplicate ? null : sync.getIn(['B', 'container', 'id']),
      existingContainer: true,
      nodes: sync.getIn(['B', 'nodes']),
      filters: formUtils.getFilters(syncSettings, 'B'),
      tweaks: formUtils.getPcdOptions(syncSettings, 'B'),
      multisyncDiscriminants: formUtils.getMultisyncDiscriminants(syncSettings, 'B'),
      readOnly: readOnlyB,
      closedTasks: syncSettings.getIn(['B', 'closedTasks']),
      onFilterOut: syncSettings.getIn(['B', 'onFilterOut']),
      itemFilters: itemFiltersB,
      itemFieldAssociations: itemFieldAssociationsB,
    },
    automapUsers: sync.getIn(['syncSettings', 'automapUsers']),
    // Use the original isAutoSync value - if user customized its sync
    isAutoSync: sync.get('isAutoSync') || !!originalIsAutoSync,
    isMultiSync: sync.get('isMultiSync') || false,
    manualOptions: formUtils.getFormattedManualOptions(manualOptions),
    name: syncName,
  };

  return {
    values: formValues,
    initial: formValues,
  };
};

export const reducer = {
  syncForm: (state, action) => {
    switch (action.type) {
      case linkTypes.DUPLICATE_SYNC_SUCCESS: {
        const { existingContainerA, existingContainerB } = action.meta;
        const sync = fromJS(action.payload.link);

        const providerIdentityA = sync.getIn(['A', 'providerIdentity']);
        const providerIdentityB = sync.getIn(['B', 'providerIdentity']);

        const providerIdA = sync.getIn(['A', 'providerIdentity', 'providerId']);
        const providerIdB = sync.getIn(['B', 'providerIdentity', 'providerId']);

        const containerIdA = sync.getIn(['A', 'container', 'id']);
        const containerIdB = sync.getIn(['B', 'container', 'id']);
        const syncSettings = sync.get('syncSettings', Map());
        const manualOptions = syncSettings.get('manualOptions', Map()).toJS();
        const readOnlyA = syncSettings.getIn(['A', 'readOnly']);
        const readOnlyB = syncSettings.getIn(['B', 'readOnly']);

        // explicitly not defaulted to Map() because an `undefined` associations has a meaning.
        const itemFieldAssociationsA = syncSettings.getIn(['A', 'itemFieldAssociations'])?.toJS();
        const itemFiltersA = Object.keys(itemFieldAssociationsA || {});
        // explicitly not defaulted to Map() because an `undefined` associations has a meaning.
        const itemFieldAssociationsB = syncSettings.getIn(['B', 'itemFieldAssociations'])?.toJS();
        const itemFiltersB = Object.keys(itemFieldAssociationsB || {});

        return {
          ...state,
          values: {
            A: {
              providerId: providerIdA,
              providerIdentityId: providerIdentityA.get('_id'),
              containerId: containerIdA,
              existingContainer: existingContainerA,
              filters: formUtils.getFilters(syncSettings, 'A', true),
              itemFilters: itemFiltersA,
              itemFieldAssociations: itemFieldAssociationsA,
              readOnly: readOnlyA,
              closedTasks: syncSettings.getIn(['A', 'closedTasks']),
              onFilterOut: syncSettings.getIn(['A', 'onFilterOut']),
            },
            B: {
              providerId: providerIdB,
              providerIdentityId: providerIdentityB.get('_id'),
              containerId: containerIdB,
              existingContainer: existingContainerB,
              filters: formUtils.getFilters(syncSettings, 'B', true),
              itemFilters: itemFiltersB,
              itemFieldAssociations: itemFieldAssociationsB,
              readOnly: readOnlyB,
              closedTasks: syncSettings.getIn(['B', 'closedTasks']),
              onFilterOut: syncSettings.getIn(['B', 'onFilterOut']),
            },
            automapUsers: sync.getIn(['syncSettings', 'automapUsers']),
            isMultiSync: false,
            rules: sync.get('rules'),
            manualOptions: formUtils.getFormattedManualOptions(manualOptions),
            name: null,
          },
        };
      }
      case linkTypes.GET_LINK_SUCCESS: {
        const { isDuplicate, originalIsAutoSync } = action.meta;
        const normalized = linkPayloadToFormData(action.payload.link, { isDuplicate, originalIsAutoSync });
        return {
          ...state,
          ...normalized,
        };
      }

      case linkTypes.SET_AUTO_SYNC_LINK_SUCCESS: {
        // if called outside the syncForm, do nothing.
        if (!state) {
          return state;
        }

        const { link } = action.payload;
        return {
          ...state,
          values: {
            ...state.values,
            isAutoSync: link.isAutoSync,
          },
        };
      }

      default: {
        return state;
      }
    }
  },
};
