import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Icon, message } from '@unitoio/mosaic';

import { Box, Href, Button, InlineLoading } from 'components';
import { routes } from 'consts';
import { getSelectedOrganizationId } from 'reducers';
import { linkActions, providerContainerActions, workflowActions } from 'actions';
import { capitalize } from 'utils';

import { WorkflowDiagramModel } from '../../models/WorkflowDiagramModel';
import { WorkBlockModel } from '../Nodes/WorkBlockModel';

const ButtonBar = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

const ScrollableContent = styled(Box)`
  max-height: 30rem;
  overflow-y: auto;
`;

function useFetchWorkflowResources(workflowModel, node, isPlayground) {
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => getSelectedOrganizationId(state));
  const [isLoading, setIsLoading] = useState(true);
  const [workflowResources, setWorkflowResources] = useState({});

  useEffect(() => {
    if (isPlayground) {
      setIsLoading(false);
      return;
    }

    async function getWorkflowsAndFilter() {
      setIsLoading(true);
      const linkIds = workflowModel.getWorkblockFlowIds(node);
      const fetchedResources = await dispatch(
        workflowActions.generateWorkflowResourcesOnDelete(organizationId, linkIds),
      );
      setWorkflowResources(fetchedResources);
      setIsLoading(false);
    }

    getWorkflowsAndFilter();
  }, [dispatch, organizationId, workflowModel, node, isPlayground]);
  return { workflowResources, isLoading };
}

export const RemoveBlockModal = ({ match, node, workflowModel, updateWorkflow }) => {
  const dispatch = useDispatch();
  const isPlayground = match.url.includes('playground');
  const [isDeleting, setDeleting] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const {
    workflowResources: { deletableLinks = [], removableLinks = [] },
    isLoading,
  } = useFetchWorkflowResources(workflowModel, node, isPlayground);

  const { workflowId } = match.params;
  const blockHasDeletableOrRemovableLinks = deletableLinks.length || removableLinks.length;

  useEffect(() => {
    if (deleteConfirmed && !isDeleting) {
      updateWorkflow();
    }
  }, [isDeleting, deleteConfirmed, updateWorkflow]);

  if (isLoading) {
    return <InlineLoading />;
  }

  async function deleteBlock() {
    setDeleting(true);
    setDeleteConfirmed(true);
    if (node.isPlaceholder()) {
      workflowModel.deleteBlockOfWork(node.getID());
      setDeleting(false);
      return;
    }

    const deletableLinkIds = deletableLinks.map((link) => link._id);
    // TODO: create a new endpoint for batch delete
    try {
      if (!isPlayground) {
        await Promise.all(deletableLinkIds.map((linkId) => dispatch(linkActions.deleteLink(linkId))));
        await dispatch(workflowActions.deleteBlock(workflowId, node.getBlockId()));
        await dispatch(providerContainerActions.removeProviderContainer(node.getProviderContainerId()));
      }
      workflowModel.deleteBlockOfWork(node.getID());
    } catch (err) {
      message.error({
        content: 'Something went wrong while deleting your block of work. Please get in touch with our team!',
      });
    } finally {
      setDeleting(false);
    }
  }

  const containerName = node.getContainerName();
  const toolName = capitalize(node.getToolName() ?? '');
  const displayedContainerName = containerName ? <strong>{containerName}</strong> : `This ${toolName} block`;
  const nbLinks = node.getFlowNodeIds().length;
  const pluralizeFlowTerm = (amount) => `flow${amount > 1 ? 's' : ''}`;
  function getButtonBar(actionsDisabled) {
    return (
      <ButtonBar m={[1.5, 0, 0]}>
        <Button
          btnStyle="subtleLink"
          onClick={() => {
            setDeleteConfirmed(false);
            updateWorkflow();
          }}
          disabled={actionsDisabled}
        >
          Cancel
        </Button>
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <Button btnStyle="error" onClick={deleteBlock} disabled={actionsDisabled}>
          {actionsDisabled && <Icon name="spinner" kind={Icon.KINDS.SOLID} pulse />}
          Confirm removal
        </Button>
      </ButtonBar>
    );
  }

  if (node.isPlaceholder()) {
    const copyToDisplay = isPlayground
      ? `${node.getName() || 'Placeholder block'} will be removed from this workflow.`
      : `${node.getName() || 'Placeholder block'} is not syncing with any block of work. You can safely delete this
          block without worrying about your flows.`;
    return (
      <Fragment>
        <Box $m={[0, 0, 'auto', 0]}>{copyToDisplay}</Box>
        {getButtonBar(isDeleting)}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <ScrollableContent m={[0, 0, 'auto']}>
        {nbLinks === 0 || !blockHasDeletableOrRemovableLinks ? (
          <Fragment>
            {displayedContainerName} is not syncing with any block of work. You can safely delete this block without
            worrying about your flows.
          </Fragment>
        ) : (
          <Fragment>
            <p>
              The block of work {containerName} is currently part of {nbLinks} {pluralizeFlowTerm(nbLinks)}
            </p>
            <p>By deleting this block</p>
            <ul>
              {deletableLinks.length > 0 && (
                <li>
                  The following <strong>{pluralizeFlowTerm(deletableLinks.length)} will be permanently deleted</strong>:
                  <ul>
                    {deletableLinks.map((link) => (
                      <li key={link._id}>{link.name}</li>
                    ))}
                  </ul>
                </li>
              )}
              {removableLinks.length > 0 && (
                <li>
                  The following {pluralizeFlowTerm(removableLinks.length)} will be removed from this workflow's layout
                  but will remain active since they exist outside of this workflow:
                  <ul>
                    {removableLinks.map((link) => (
                      <li key={link._id}>{link.name}</li>
                    ))}
                  </ul>
                </li>
              )}
            </ul>
            <p>
              Note you can also delete any flow from the <Href to={routes.ABSOLUTE_PATHS.SYNCS}>flows list.</Href>
            </p>
          </Fragment>
        )}
      </ScrollableContent>
      {getButtonBar(isDeleting)}
    </Fragment>
  );
};

RemoveBlockModal.propTypes = {
  workflowModel: PropTypes.instanceOf(WorkflowDiagramModel).isRequired,
  updateWorkflow: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      workflowId: PropTypes.string,
    }),
  }).isRequired,
  node: PropTypes.instanceOf(WorkBlockModel).isRequired,
};
