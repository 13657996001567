import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import ReactJson from 'react-json-view';

import {
  Button,
  Box,
  Col,
  Grid,
  Icon,
  Row,
  Typography,
  LoadingPlaceholder,
  tokens,
  Alert,
  NewTag,
  SearchInput,
} from '@unitoio/mosaic';

import { SyncActivityLogs } from 'containers';
import { routes } from 'consts';
import { getIsAuthenticated, getLastActivityLogsFetchTimestamp, isUserSiteAdmin } from 'reducers';
import { Href, ProviderTermsByName } from 'components';

import { useHistory } from 'react-router-dom';
import { useDebounce, useFetchItemInfo } from 'hooks';
import { Card, SecondaryInfo, WorkItemStatusNotSynced } from './components';

const DIRECTIONS = {
  BOTH: 'exchange',
  A: 'long-arrow-left',
  B: 'long-arrow-right',
};

function getArrowIconName(readOnlyA, readOnlyB) {
  if (!readOnlyA && !readOnlyB) {
    return DIRECTIONS.BOTH;
  }

  if (readOnlyA) {
    return DIRECTIONS.B;
  }

  return DIRECTIONS.A;
}

function getFromNow(dateAt) {
  if (!dateAt) {
    return 'Never';
  }

  return moment(dateAt).fromNow();
}

function Loading() {
  return (
    <Box m={[1, 0]}>
      <LoadingPlaceholder borderRadius={tokens.spacing.s2} width="100%" height={tokens.spacing.s8} />
    </Box>
  );
}

const FullHeight = styled(Box)`
  height: 80vh;
`;

export const WorkItemStatus = (props) => {
  const { match } = props;
  const { itemId } = match.params;
  const history = useHistory();
  const updateItemId = useDebounce((value) => {
    history.push(`/items/${value}/status`);
  }, 1000);
  const { isLoading, item, blockFlows, sisterItems, error, fetchItemInfo } = useFetchItemInfo(itemId);
  const lastActivityFetchTimestamp = useSelector(getLastActivityLogsFetchTimestamp);
  const userIsAuthenticated = useSelector(getIsAuthenticated);
  const userIsSiteAdmin = useSelector(isUserSiteAdmin);
  const humanReadableLastActivityFetchDate = lastActivityFetchTimestamp
    ? new Date(lastActivityFetchTimestamp).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'UTC',
        timeZoneName: 'short',
      })
    : 'N/A';

  if (isLoading && !item) {
    return (
      <Grid>
        <Row>
          <Col xl={12} lg={12}>
            <Loading />
          </Col>
        </Row>
      </Grid>
    );
  }

  const itemIsNotSynced = !isLoading && (item === null || blockFlows.length === 0);
  if (itemIsNotSynced || error) {
    return (
      <FullHeight alignItems="center">
        <WorkItemStatusNotSynced isAuthenticated={userIsAuthenticated} />
      </FullHeight>
    );
  }

  let itemContainerSide;
  if (item.flow) {
    itemContainerSide = item.flow.A.container.id === item.containerId ? 'A' : 'B';
  }

  const jsonReactSettings = {
    displayDataTypes: false,
    quotesOnKeys: false,
    indentWidth: 2,
    sortKeys: true,
    style: { fontSize: tokens.fontSize.f8, padding: tokens.spacing.s4 },
    theme: 'monokai',
    name: 'item',
  };
  const { status: itemStatus, column: itemColumn } = item?.data || {};

  const itemTerm = item.flow ? (
    <ProviderTermsByName
      termKey="task"
      plurality="singular"
      providerNameA={item.provider.name}
      itemTypeA={item.itemType}
      containerTypeA={item.flow[itemContainerSide].containerType}
    />
  ) : (
    'item'
  );
  return (
    <Grid>
      <Row>
        <Col xl={8} lg={8}>
          <Box m={[tokens.spacing.s7, 0, 0, 0]}>
            <Typography variant="h2">Welcome to Unito’s item status page</Typography>
            <SecondaryInfo>
              Here you can find out what Unito is keeping in sync with the item you selected
            </SecondaryInfo>
          </Box>
        </Col>
        {userIsSiteAdmin && (
          <Col xl={4} lg={4}>
            <Box m={[tokens.spacing.s8, 0, 0, 0]}>
              <SearchInput
                placeholder="Enter item unique id"
                onChange={(e) => {
                  e.persist();
                  const { value } = e.target;
                  updateItemId(value.replace(/"/g, '').replace(/\s/g, ''));
                }}
              />
            </Box>
          </Col>
        )}
      </Row>

      {!userIsAuthenticated && (
        <Box m={[tokens.spacing.s4, 0, 0]}>
          <Alert size="sm">
            <Typography variant="body2">
              You’re not logged into Unito right now, so we’re showing you very limited and anonymized information. For
              the full details, either <Href to={routes.ABSOLUTE_PATHS.LOGIN}>log in with your Unito account</Href>, or
              ask your Unito administrator to invite you in.
            </Typography>
          </Alert>
        </Box>
      )}

      <Box m={[tokens.spacing.s7, 0, 0]}>
        <Typography variant="h3">
          <Box as="span" justifyContent="space-between" alignItems="center">
            Sync information for {itemTerm}
            {isUserSiteAdmin && (
              <Button variant="subtle" size="sm" onClick={fetchItemInfo} startIcon="rotate">
                Refresh
              </Button>
            )}
          </Box>
        </Typography>
        {isLoading ? (
          <Loading />
        ) : (
          <Card
            providerNameA={item.provider?.name}
            primaryTitle={
              userIsSiteAdmin ? (
                <>
                  {item.displayName} {itemStatus && <NewTag>{itemStatus.displayName}</NewTag>}
                  {itemColumn && <NewTag>{itemColumn.displayName}</NewTag>}
                </>
              ) : (
                item.displayName
              )
            }
            secondaryTitle={
              item.flow
                ? `Last updated: ${getFromNow(item.lastUpdate)}`
                : 'This item is no longer synced as the flow was deleted'
            }
            href={item.url}
            iconDataA={
              item.flow && {
                url: item?.flow[itemContainerSide].container.url,
                tooltipContent: item?.flow[itemContainerSide].container.displayName,
              }
            }
            dockContent={userIsSiteAdmin ? <ReactJson {...jsonReactSettings} src={item} /> : undefined}
            isElevated
          />
        )}
      </Box>
      <Row>
        <Col xl={6} lg={6}>
          <Box m={[tokens.spacing.s7, 0, 0]}>
            <Typography variant="h3">Items in sync with this {itemTerm}</Typography>
            {isLoading ? (
              <Loading />
            ) : (
              sisterItems.map((sisterItem) => {
                const { status, column } = sisterItem?.data || {};
                let iconData;
                if (sisterItem?.flow) {
                  const containerSide = sisterItem.flow.A.container.id === sisterItem.containerId ? 'A' : 'B';
                  iconData = {
                    url: sisterItem.flow[containerSide].container.url,
                    tooltipContent: sisterItem.flow[containerSide].container.displayName,
                  };
                }

                return (
                  <Card
                    key={sisterItem.itemId}
                    providerNameA={sisterItem.provider?.name}
                    primaryTitle={
                      userIsSiteAdmin ? (
                        <>
                          {sisterItem.displayName} {status && <NewTag>{status.displayName}</NewTag>}
                          {column && <NewTag>{column.displayName}</NewTag>}
                        </>
                      ) : (
                        sisterItem.displayName
                      )
                    }
                    secondaryTitle={
                      sisterItem?.flow
                        ? `Last updated: ${getFromNow(sisterItem.lastUpdate)}`
                        : 'This item is no longer synced as the flow was deleted'
                    }
                    href={sisterItem.url}
                    iconDataA={iconData}
                    dockContent={userIsSiteAdmin ? <ReactJson {...jsonReactSettings} src={sisterItem} /> : undefined}
                  />
                );
              })
            )}
          </Box>
        </Col>

        <Col xl={6} lg={6}>
          <Box m={[tokens.spacing.s7, 0, 0]}>
            <Typography variant="h3">Flows syncing this {itemTerm}</Typography>
            {isLoading ? (
              <Loading />
            ) : (
              blockFlows.map((flow) => (
                <Card
                  key={flow._id}
                  providerNameA={flow.A.providerName}
                  providerNameB={flow.B.providerName}
                  primaryTitle={
                    userIsSiteAdmin ? (
                      <>
                        {flow.name} <NewTag>{flow.state}</NewTag>{' '}
                      </>
                    ) : (
                      flow.name
                    )
                  }
                  secondaryTitle={`Last updated: ${getFromNow(flow.lastSyncRequest)} ${
                    flow.user && userIsSiteAdmin ? `| Created by ${flow.user.fullName}` : ''
                  }`}
                  href={`/#${routes.getEditFlowBuilderRoute(flow._id)}`}
                  iconName={getArrowIconName(flow.syncSettings?.A?.readOnly, flow.syncSettings?.B?.readOnly)}
                  iconDataA={{ url: flow.A.container?.url, tooltipContent: flow.A.container?.displayName }}
                  iconDataB={{ url: flow.B.container?.url, tooltipContent: flow.B.container?.displayName }}
                  dockContent={
                    userIsSiteAdmin ? <ReactJson {...jsonReactSettings} name="flow" src={flow} /> : undefined
                  }
                />
              ))
            )}
          </Box>
        </Col>

        <Col xl={12} lg={12}>
          <Box m={[tokens.spacing.s7, 0]}>
            {isLoading ? (
              <Loading />
            ) : (
              <SyncActivityLogs
                organizationId={item.flow.organization}
                pageSize={5}
                generalSearch={item.itemId}
                showAutoRefreshToggle={userIsAuthenticated}
                renderEmptyState={(activityLogerror) => {
                  if (!userIsAuthenticated && activityLogerror) {
                    return (
                      <Box>
                        For the full details, either{' '}
                        <Href to={routes.ABSOLUTE_PATHS.LOGIN}>log in with your Unito account</Href>, or ask your Unito
                        administrator to invite you in.
                      </Box>
                    );
                  }

                  if (activityLogerror?.message) {
                    return (
                      <>
                        {activityLogerror.message}
                        <br />
                        Try to turn on Auto-refresh via the toggle above
                      </>
                    );
                  }

                  return `No recent activity on ${item.displayName}`;
                }}
                title={
                  <Box>
                    <Box alignItems="center">
                      <Typography variant="h3">Most recent activity</Typography>
                      <Button
                        href={`/#${routes.ABSOLUTE_PATHS.ACTIVITY_LOGS}?search=${item.itemId}`}
                        size="sm"
                        variant="subtle"
                        target="_blank"
                      >
                        <Icon name="external-link" />
                      </Button>
                    </Box>
                    <SecondaryInfo fontStyle="body2">Last updated: {humanReadableLastActivityFetchDate}</SecondaryInfo>
                  </Box>
                }
              />
            )}
          </Box>
        </Col>
      </Row>
    </Grid>
  );
};

WorkItemStatus.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({ itemId: PropTypes.string }).isRequired,
  }).isRequired,
};
