import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { Button, Section, Title } from 'components';
import { useQueryParams } from 'hooks';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../../AuthTitleWrapper';
import { AuthOrderedList } from '../../AuthOrderedList';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import stepImage from './jira-oauth2-1.png';

function AuthJiraOAuth2Step1Component({ history, onCancel, trackEvent }) {
  const { windowOpenerId } = useQueryParams();
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (OAuth2 - Step 1 of 4)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>
          Before continuing, you'll need to create an <strong>Application Link</strong> to allow Unito to properly
          connect to Jira Data Center.
        </p>
        <AuthImageWrapper className="clearfix">
          <img src={stepImage} alt="Jira OAuth2 Step - Step 1" />
        </AuthImageWrapper>
        <AuthOrderedList>
          <li>
            Open Jira's settings, click <strong>Applications</strong> and navigate to the{' '}
            <strong>Application links</strong> section on the left
          </li>
          <li>
            Click <strong>Create link</strong>
          </li>
        </AuthOrderedList>
        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            to={`step2?windowOpenerId=${windowOpenerId}`}
            onClick={() => trackEvent('oauth2 onboarding 1 (of 4)')}
            type="href"
            pullRight
          >
            Continue
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthJiraOAuth2Step1Component.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

const formSelector = formValueSelector('authentication');

const mapStateToProps = (state) => ({
  domain: formSelector(state, 'domain'),
});

export const AuthJiraOAuth2Step1 = connect(mapStateToProps)(AuthJiraOAuth2Step1Component);
