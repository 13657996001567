import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Box, tokens, Typography, Icon, Button } from '@unitoio/mosaic';
import { getSelectedOrganizationId, getUserId, getUserEmail, getLoginProviderIdentity } from 'reducers';
import { CopyToClipboard } from 'components';

import { FeatureFlagOverride } from './components/FeatureFlagOverride';
import { EmbedOverride } from './components/EmbedOverride';

const Container = styled(Box)`
  height: 100vh;
  min-width: 35%;
  position: fixed;
  z-index: 99999;
  right: 0;
  background: ${tokens.colors.global.primary.light};
  pre {
    margin: 0 0 0 ${tokens.spacing.s3};
    padding: ${tokens.spacing.s3};
  }
`;

const DevToolButton = styled(Button)`
  z-index: 99998;
  position: fixed;
  right: 1.5rem;
  bottom: 5.5rem;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  opacity: 0.3;
  transition: all 0.2s;
  &:hover {
    opacity: 1;
  }
`;

const ClickableIcon = styled.div`
  cursor: pointer;
`;

export const DevTools = () => {
  const [isOpen, setIsOpen] = useState(false);
  const organizationId = useSelector((state) => getSelectedOrganizationId(state));
  const userId = useSelector((state) => getUserId(state));
  const userEmail = useSelector((state) => getUserEmail(state));
  const loginProviderIdentityId = useSelector((state) => getLoginProviderIdentity(state));

  if (!isOpen) {
    return (
      <DevToolButton onClick={() => setIsOpen(!isOpen)} variant="outlined">
        <Icon name="cog" />
      </DevToolButton>
    );
  }

  return (
    <Container elevation="medium" p={[1]}>
      <Box justifyContent="space-between" alignItems="center">
        <Typography variant="h1">Ultra secret dev tools</Typography>
        <ClickableIcon onClick={() => setIsOpen(!isOpen)}>
          <Icon name="times-circle" size="lg" />
        </ClickableIcon>
      </Box>

      <Box m={[tokens.spacing.s4, 0, 0, 0]}>
        <Box m={[0, 0, tokens.spacing.s3, 0]} justifyContent="flex-start" alignItems="center">
          Current organizationId: <CopyToClipboard as="pre">{organizationId}</CopyToClipboard>
        </Box>
        <Box m={[0, 0, tokens.spacing.s3, 0]} justifyContent="flex-start" alignItems="center">
          Current userId: <CopyToClipboard as="pre">{userId}</CopyToClipboard>
        </Box>
        <Box m={[0, 0, tokens.spacing.s3, 0]} justifyContent="flex-start" alignItems="center">
          Current user email: <CopyToClipboard as="pre">{userEmail}</CopyToClipboard>
        </Box>
        <Box m={[0, 0, tokens.spacing.s3, 0]} justifyContent="flex-start" alignItems="center">
          Current login providerIdentityId: <CopyToClipboard as="pre">{loginProviderIdentityId}</CopyToClipboard>
        </Box>
      </Box>
      <hr />
      <FeatureFlagOverride organizationId={organizationId} />
      <EmbedOverride />
    </Container>
  );
};
