import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';

import { FlowBuilderErrorContext } from 'contexts';
import { getLinkById, getLinkFiltersInitializedAtBySide } from 'reducers';
import { ProviderTermsByName } from 'components';
import { fieldTypes, linkTypes, trackingTypes, routes } from 'consts';
import { useGetItemTypes } from 'hooks';

import { getIsWorkItemsPageCompleted } from '../../utils/form';
import { GuideStepTitleWithTime } from './GuideStepTitleWithTime';
import { GuideStepTitle } from './GuideStepTitle';
import { GuideStep } from './GuideStep';
import { useGetLabelAndStatusFromAnomalies, PAGES } from '../../hooks/useGetAnomalies';
import { useMandatoryDeepFiltersMissing } from '../../hooks/useMandatoryDeepFiltersMissing';
import { useGetIncompleteItemFieldAssociation } from '../../hooks/useGetIncompleteItemFieldAssociation';
import RulesIcon from '../../images/steps_icons/rules.svg';

function useGetFilters(formData) {
  const syncDirection = formData?.syncDirection;
  if (!syncDirection) {
    return [];
  }

  const rulesA = formData?.A?.filters ?? [];
  const rulesB = formData?.B?.filters ?? [];

  if (syncDirection === fieldTypes.TARGET.A) {
    return rulesA;
  }

  if (syncDirection === fieldTypes.TARGET.B) {
    return rulesB;
  }

  return [...rulesA, ...rulesB];
}

function getNumberOfModernRules(rules = {}) {
  let totalRules = 0;

  ['A', 'B'].forEach((side) => {
    const startSyncing = Object.values(rules?.[side]?.filters?.startSyncing ?? {}) ?? [];
    const stopSyncing = Object.values(rules?.[side]?.filters?.stopSyncing ?? {}) ?? [];

    [startSyncing, stopSyncing].forEach((rule) => {
      rule.forEach((value) => {
        if (Array.isArray(value)) {
          totalRules += value.length;
        } else {
          totalRules += 1;
        }
      });
    });
  });

  return Number.isNaN(totalRules) ? 0 : totalRules;
}

export const GuideStepRules = ({ linkState }) => {
  const { linkId } = useParams();
  const { getValues } = useFormContext();
  const { errors } = useFormState();
  useMandatoryDeepFiltersMissing();
  useGetIncompleteItemFieldAssociation();
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const filtersInitializedAtA = useSelector((state) => getLinkFiltersInitializedAtBySide(state, linkId, 'A'));
  const filtersInitializedAtB = useSelector((state) => getLinkFiltersInitializedAtBySide(state, linkId, 'B'));
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const formData = getValues();
  const isWorkItemStepCompleted = getIsWorkItemsPageCompleted(currentLink);
  const filters = useGetFilters(formData);
  const numberOfModernRules = useMemo(() => getNumberOfModernRules(formData.rules), [formData]);
  const isSyncDirectionSet = !!getValues('syncDirection');
  const pageName = useContext(FlowBuilderErrorContext);

  const hasFiltersInitialized =
    filters?.length > 0 ||
    (filtersInitializedAtA && filtersInitializedAtB) ||
    // If the flow has been launched, we should consider the filters initialized even if no filtersInitalizedAt is set
    ![linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.DUPLICATE].includes(linkState);
  const providerIdentityAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.providerIdentityId;
  const providerIdentityBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.providerIdentityId;
  const containerAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.containerId;
  const containerBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.containerId;
  const hasContainerErrors = providerIdentityAError || providerIdentityBError || containerAError || containerBError;
  const hasRulesErrors = !!errors[pageName];

  const [labelFromAnomalies, statusFromAnomalies] = useGetLabelAndStatusFromAnomalies(linkId, PAGES.RULES);

  if (hasContainerErrors) {
    return hasFiltersInitialized ? (
      <GuideStep status="inaccessible" statusLabel="Flow rules status">
        <GuideStepTitle title="Rules created" number={filters.length} />
      </GuideStep>
    ) : (
      <GuideStep status="inaccessible" statusLabel="Flow rules status">
        <GuideStepTitleWithTime
          title={
            <>
              3. Choose the{' '}
              <ProviderTermsByName
                providerNameA={currentLink.getIn(['A', 'providerName'])}
                providerNameB={currentLink.getIn(['B', 'providerName'])}
                termKey="task"
                plurality="plural"
                pcdv3
                itemTypeA={itemTypeA}
                itemTypeB={itemTypeB}
              />{' '}
              you want to sync
            </>
          }
          icon={RulesIcon}
          time={4}
        />
      </GuideStep>
    );
  }

  if (statusFromAnomalies) {
    return (
      <GuideStep
        status={statusFromAnomalies}
        actionLabel={labelFromAnomalies}
        actionSlug={routes.FLOW_BUILDER_PAGES.RULES}
        statusLabel="Flow rules status"
      >
        <GuideStepTitle title="Rules created" number={filters.length} />
      </GuideStep>
    );
  }

  if (isWorkItemStepCompleted && hasFiltersInitialized) {
    let hasFiltersStatus = 'edit';
    if (!hasFiltersInitialized || hasRulesErrors) {
      hasFiltersStatus = 'review';
    } else if (linkState === linkTypes.LINK_STATES.DRAFT) {
      hasFiltersStatus = 'done';
    }

    return (
      <GuideStep
        status={hasFiltersStatus}
        actionSlug={routes.FLOW_BUILDER_PAGES.RULES}
        actionLabel="Edit rules"
        statusLabel="Flow rules status"
      >
        <GuideStepTitle title="Rules created" number={filters.length + numberOfModernRules} />
      </GuideStep>
    );
  }

  return (
    <GuideStep
      status={isSyncDirectionSet && isWorkItemStepCompleted ? 'current' : 'default'}
      actionLabel={isSyncDirectionSet && isWorkItemStepCompleted ? 'Continue' : null}
      actionSlug={routes.FLOW_BUILDER_PAGES.RULES}
      statusLabel="Flow rules status"
    >
      <GuideStepTitleWithTime
        title={
          <>
            3. Choose the{' '}
            <ProviderTermsByName
              providerNameA={currentLink.getIn(['A', 'providerName'])}
              providerNameB={currentLink.getIn(['B', 'providerName'])}
              termKey="task"
              plurality="plural"
              pcdv3
              itemTypeA={itemTypeA}
              itemTypeB={itemTypeB}
            />{' '}
            you want to sync
          </>
        }
        icon={RulesIcon}
        time={4}
      />
    </GuideStep>
  );
};

GuideStepRules.propTypes = {
  linkState: PropTypes.oneOf(Object.values(linkTypes.LINK_STATES)).isRequired,
};
