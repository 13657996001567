import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLogger } from 'hooks';
import { containerActions } from 'actions';
import { useFormContext } from 'react-hook-form';

function hasWarningsOrErrors(container) {
  return (
    container.warnings?.length > 0 ||
    container.richWarnings?.length > 0 ||
    container.errors?.length > 0 ||
    container.richErrors?.length > 0
  );
}

// TODO we seem to have a duplicate useFetchContainers hook, in the flowbuilder hooks, clean this up
export function useFetchContainers({
  containerIdA,
  containerIdB,
  containerTypeA,
  containerTypeB,
  providerIdentityIdA,
  providerIdentityIdB,
  itemTypeA,
  itemTypeB,
  linkId,
}) {
  const dispatch = useDispatch();
  const { setError } = useFormContext();
  const { reportException } = useLogger();
  const [haveRestrictions, setContainerHaveRestrictions] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchContainers() {
      try {
        setIsLoading(true);
        const [responseA, responseB] = await Promise.all([
          dispatch(
            containerActions.getContainerById({
              providerIdentityId: providerIdentityIdA,
              containerId: containerIdA,
              containerType: containerTypeA,
              itemType: itemTypeA,
              linkId,
              options: {
                displayError: false,
              },
            }),
          ),
          dispatch(
            containerActions.getContainerById({
              providerIdentityId: providerIdentityIdB,
              containerId: containerIdB,
              containerType: containerTypeB,
              itemType: itemTypeB,
              linkId,
              options: {
                displayError: false,
              },
            }),
          ),
        ]);

        const hasRestrictionsA = hasWarningsOrErrors(responseA.container);
        const hasRestrictionsB = hasWarningsOrErrors(responseB.container);
        setContainerHaveRestrictions(hasRestrictionsA || hasRestrictionsB);
      } catch (err) {
        if (err.name === 'ForbiddenError') {
          const containerTypeToDisplay =
            containerTypeA === containerTypeB
              ? `all the ${containerTypeA}s`
              : `the ${containerTypeA} or the ${containerTypeB}`;
          setError('convert', {
            type: 'manual',
            message: `You do not have access to ${containerTypeToDisplay} used to create this flow. Please ask the original creator of this flow to launch it or give you access.`,
          });
        } else {
          reportException(err);
        }
        setContainerHaveRestrictions(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchContainers();
  }, [
    dispatch,
    containerIdA,
    containerIdB,
    containerTypeA,
    containerTypeB,
    itemTypeA,
    itemTypeB,
    providerIdentityIdA,
    providerIdentityIdB,
    reportException,
    linkId,
    setError,
  ]);

  return [haveRestrictions, isLoading];
}
