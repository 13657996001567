import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getMergeOptionsBySide } from 'reducers';
import { FlowBuilderErrorContext } from '../../../contexts';

import { useCanMergeSide } from './useCanMergeSide';

export function useHasMissingMergeSettings(enableAlert = true) {
  const { linkId } = useParams();
  const { setError, clearErrors } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);

  const canMergeA = useCanMergeSide(linkId, 'A');
  const canMergeB = useCanMergeSide(linkId, 'B');

  const isMergeContext = canMergeA && canMergeB;
  const mergeOptionsA = useSelector((state) => getMergeOptionsBySide(state, linkId, 'A'));
  const mergeOptionsB = useSelector((state) => getMergeOptionsBySide(state, linkId, 'B'));

  const mergeFieldsMissingA = !mergeOptionsA.mergeFields?.size;
  const mergeFieldsMissingB = !mergeOptionsB.mergeFields?.size;
  const isATruthSide = mergeOptionsA.truthSide;
  const isBTruthSide = mergeOptionsB.truthSide;

  const isMergeFieldsMissing = mergeFieldsMissingA || mergeFieldsMissingB;
  const isTruthSideInvalid = (!isATruthSide && !isBTruthSide) || (isATruthSide && isBTruthSide);

  useEffect(() => {
    if (enableAlert && isMergeContext) {
      if (isMergeFieldsMissing) {
        setError(`${pageName}.hasMissingMergeSettings`, { type: 'manual' });
      } else {
        clearErrors(`${pageName}.hasMissingMergeSettings`);
      }
    }
  }, [isMergeFieldsMissing, isMergeContext, enableAlert, setError, clearErrors, pageName]);

  if (!isMergeContext) {
    return false;
  }

  return isMergeFieldsMissing || isTruthSideInvalid;
}
