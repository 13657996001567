import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Icon } from '@unitoio/mosaic';

import { Avatar, Box } from 'components';

export const ProfileAvatar = ({ accountAvatarUrl, colorScheme = 'light' }) => (
  <Fragment>
    <Box $m={[0, 0.75, 0, 0]} as="span">
      <Avatar src={accountAvatarUrl} colorScheme={colorScheme} />
    </Box>
    <Icon name="caret-down" kind={Icon.KINDS.SOLID} size="lg" />
  </Fragment>
);

ProfileAvatar.propTypes = {
  accountAvatarUrl: PropTypes.string,
  colorScheme: PropTypes.oneOf(['light', 'dark']),
};
