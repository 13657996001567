import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, Link, Tooltip, tokens } from '@unitoio/mosaic';

import { linkActions } from 'actions';
import { Title, ProviderTermsByName, Href } from 'components';
import { linkTypes } from 'consts';
import { useGetItemTypes } from 'hooks';
import { getLinkSyncStatusActivity } from 'reducers';

const ManualOptionsInput = styled.textarea`
  ::-webkit-resizer {
    display: none;
  }
  &:focus {
    outline: none;
  }
  resize: none;
  border: 1px solid ${tokens.colors.content.neutral.n10};
  border-radius: ${tokens.spacing.s2};
  padding: ${tokens.spacing.s4};
  margin: 0 0 1rem 0;
`;

export const AdvancedOptions = () => {
  const dispatch = useDispatch();
  const { register, watch, setValue } = useFormContext();
  const lastSyncRequest = watch('lastSyncRequest');
  const linkSettingsLastModifiedOn = watch('linkSettingsLastModifiedOn');
  const providerNameA = watch('A.providerName');
  const providerNameB = watch('B.providerName');
  const { linkId } = useParams();
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const linkSyncStatusActivity = useSelector((state) => getLinkSyncStatusActivity(state, linkId));
  const isSyncInProgress = !!linkTypes.LINK_STATUS_VALUES[linkSyncStatusActivity]?.inProgress;

  function isLinkResyncable() {
    if (!lastSyncRequest && !linkSettingsLastModifiedOn) {
      return false;
    }

    return !isSyncInProgress && lastSyncRequest < linkSettingsLastModifiedOn;
  }

  function resyncLink() {
    dispatch(linkActions.syncLink(linkId, true));
  }

  function handleChangeManualOptions(event) {
    setValue('manualOptions', event.target.value, { shouldDirty: true });
  }

  const registerManualOptions = register('manualOptions');

  const providerTerms = (
    <ProviderTermsByName
      providerNameA={providerNameA}
      providerNameB={providerNameB}
      termKey="task"
      plurality="plural"
      pcdv3
      itemTypeA={itemTypeA}
      itemTypeB={itemTypeB}
    />
  );

  return (
    <>
      <Box
        borderColor={tokens.colors.content.neutral.n10}
        borderRadius={tokens.spacing.s4}
        flexDirection="column"
        m={[0, 0, tokens.spacing.s4, 0]}
        p={[tokens.spacing.s6]}
      >
        <Title type="h3">
          <b>Resync all</b>
        </Title>
        <p>
          Use this option to apply changes to <strong>all historical {providerTerms} associated with this flow</strong>.
        </p>
        <Alert level="warning" size="md" showIcon={false}>
          <ul>
            <li>This flow might take a significant amount of time to sync.</li>
            <li>Many notifications could appear due to the update of existing {providerTerms}.</li>
            <li>
              You could experience slowdowns when reaching the{' '}
              <Href href="https://guide.unito.io/what-are-rate-limits">rate limit</Href> of your tools.
            </li>
          </ul>
        </Alert>

        <Box m={[tokens.spacing.s4, 0, 0, 0]}>
          <Tooltip placement="top" content="You can resync only if you have made changes to this flow settings.">
            {/* eslint-disable-next-line react/jsx-no-bind */}
            <Button onClick={resyncLink} variant="primary" disabled={!isLinkResyncable()}>
              Sync all {providerTerms}
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box
        borderColor={tokens.colors.content.neutral.n10}
        borderRadius={tokens.spacing.s4}
        flexDirection="column"
        m={[0, 0, tokens.spacing.s4, 0]}
        p={[tokens.spacing.s6]}
      >
        <Title type="h3">
          <b>Advanced features</b>
        </Title>
        <p>Paste in your configuration for advanced options.</p>
        <ManualOptionsInput
          name={registerManualOptions.name}
          onBlur={registerManualOptions.onBlur}
          onChange={handleChangeManualOptions} // eslint-disable-line react/jsx-no-bind
          ref={registerManualOptions.ref}
          placeholder='{"format": "JSON"}'
          rows={6}
        />
        <p>
          For more information about some of our advanced features,{' '}
          <Link href="https://guide.unito.io/a-guide-to-advanced-manual-options" isExternalLink>
            see this article
          </Link>{' '}
          or reach out to our support team via the chat bubble in the bottom right corner.
        </p>
      </Box>
    </>
  );
};
