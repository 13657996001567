import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { linkActions } from 'actions';
import { trackingTypes } from 'consts';
import { useLogger, useTrackEvent } from 'hooks';
import { notification } from '@unitoio/mosaic';

export const AUTOPOPULATE_FAILED_NOTIFICATION = {
  title: 'The auto-populate failed',
  message: 'We could not create and/or map anything. Please take a moment to individually map things together.',
  status: 'error',
};

export const TOO_MANY_FIELDS_NOTIFICATION = {
  title: 'Auto-populate got interrupted',
  status: 'warning',
  message:
    'Too many fields were found, making it challenging to identify key fields for mapping. Please, map fields manually in a way that works in this flow.',
};

export const AUTOPOPULATE_SUCCESS_TITLE = (mappingLength) =>
  `${mappingLength} mapping${mappingLength <= 1 ? '' : 's'} successfully added`;
export const AUTOPOPULATE_PARTIAL_SUCCESS_MESSAGE =
  'It seems like we were not able to create and/or map everything. Please take a moment to check what might be missing.';

export function useGetAutopopulateFields(linkId, goBackToMapping) {
  const dispatch = useDispatch();
  const { reportWarning } = useLogger();
  const trackEvent = useTrackEvent();
  const { setValue } = useFormContext();
  const [isAutopopulating, setIsAutopopulating] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);

  return [
    isAutopopulating,
    async () => {
      setIsAutopopulating(true);
      try {
        const [{ link, unmappedFields }] = await Promise.all([
          dispatch(linkActions.autopopulateFields(linkId)),
          new Promise((resolve) => {
            setTimeout(resolve, 4000);
          }),
        ]);
        setValue('associations', link.syncSettings.fieldAssociations, { shouldDirty: true });
        trackEvent(trackingTypes.SUBMIT);
        notification.success({
          message: AUTOPOPULATE_SUCCESS_TITLE(link.syncSettings.fieldAssociations.length),
          description: unmappedFields.length ? AUTOPOPULATE_PARTIAL_SUCCESS_MESSAGE : undefined,
          placement: 'topRight',
          duration: 5,
        });
      } catch (err) {
        trackEvent(trackingTypes.BLOCKED);
        setHasErrored(true);
        reportWarning(`Error while autopopulating fields for link ${linkId}`, { error: err });

        if (err.name === 'TooManyFieldsToAutopopulateError') {
          notification.warning({
            message: TOO_MANY_FIELDS_NOTIFICATION.title,
            description: TOO_MANY_FIELDS_NOTIFICATION.message,
            placement: 'topRight',
            duration: 5,
          });
        } else {
          notification.error({
            message: AUTOPOPULATE_FAILED_NOTIFICATION.title,
            description: AUTOPOPULATE_FAILED_NOTIFICATION.message,
            placement: 'topRight',
            duration: 5,
          });
        }
      } finally {
        setIsAutopopulating(false);
        goBackToMapping();
      }
    },
    hasErrored,
  ];
}
