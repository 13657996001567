/**
 *  This file contains utility functions that can be used accross the client side.
 */
import { logger } from '@unitoio/sherlock';

import { appTypes, appcuesTypes, fieldTypes, organizationTypes, linkTypes, featureTypes } from 'consts';
import { Map, OrderedMap, List } from 'immutable';
import * as formUtils from './forms';
import * as fromLocalStorage from './localStorage';
import * as authUtils from './auth';
/**
 *  Helper function to get the other side
 *  @param {'A'|'B'} containerSide - The container we want the oposite side of;
 *
 *  @returns {'A'|'B'} - The oposite side
 */
export const otherSide = (containerSide) => (containerSide === 'A' ? 'B' : 'A');

export const capitalize = (string = '') => string.charAt(0).toUpperCase() + string.substring(1);

/**
 *  Creates a generator for containers tree
 *  @param {object} containerList - The array of containers
 *  @param {int} depth - The current depth of the container.
 *  @return Generator
 */
export function* containerGenerator(containerList, depth = 1, parentId = undefined) {
  for (const container of containerList) {
    yield { ...container, depth, parentId };
    if (container.children) {
      yield* containerGenerator(container.children, depth + 1, container.id);
    }
  }
}

/**
 * Merges containersMapB into containersMapA.
 * Both maps should be immutable maps created by "containerGenerator".
 * @param {Map} containersMapA
 * @param {Map} containersMapB
 */
export function mergeContainers(containersMapA, containersMapB) {
  const updatedContainersList = containersMapB.toList().reduce((containersList, container) => {
    if (containersMapA.has(container.get('id'))) {
      return containersList;
    }

    if (!containersMapA.has(container.get('parentId'))) {
      return containersList.push(container);
    }

    // By this point the container belongs to a workspace that already is in the containers list, but the container is not.
    // Thus it makes sense to put it under said workspace. We include it at the top of the workspace instead of the botton to save
    // ourselves the trouble of having to search for the last container that belongs to the corresponding workspace.
    const containerWorkspaceIndex = containersList.findIndex(
      (oldContainer) => oldContainer.get('id') === container.get('parentId'),
    );
    return containersList.insert(containerWorkspaceIndex + 1, container);
  }, containersMapA.toList());

  return updatedContainersList.reduce(
    (containersMap, container) => containersMap.set(container.get('id'), container),
    OrderedMap(),
  );
}

export function normalizeEntityIdsBy(key, entities) {
  let entitiesByKey = Map();
  entities.forEach((entity) => {
    entitiesByKey = entitiesByKey.updateIn([entity.get(key)], List(), (list) => list.push(entity.get('_id')));
  });
  return entitiesByKey;
}

export function normalizeEntitiesBy(key, entities) {
  let entitiesByKey = Map();
  entities.forEach((entity) => {
    entitiesByKey = entitiesByKey.set(entity.get(key), entity);
  });
  return entitiesByKey;
}

export function normalizeEntitiesById(entities, removeEntityId = false) {
  let entitiesById = Map();
  entities.forEach((entity) => {
    const idKeyName = entity.has('id') ? 'id' : '_id';
    let payload = entity;
    if (removeEntityId) {
      const nonIdKeys = entity.keySeq().filter((key) => key !== idKeyName);
      payload = nonIdKeys.reduce((reducer, retainedKey) => reducer.set(retainedKey, entity.get(retainedKey)), Map());
    }
    entitiesById = entitiesById.set(entity.get(idKeyName), payload);
  });
  return entitiesById;
}

const isMergeable = (a) => a && typeof a === 'object' && typeof a.mergeWith === 'function' && !List.isList(a);

// https://github.com/immutable-js/immutable-js/issues/1452#issuecomment-386162309
export const mergeDeepOverwriteLists = (a, b) => {
  if (!b) {
    return b;
  }

  return isMergeable(a) ? a.mergeWith(mergeDeepOverwriteLists, b) : b;
};

export function toLocaleAmount(centAmount) {
  const dollarAmount = (centAmount || 0) / 100;
  return `US$${dollarAmount.toFixed(2)}`;
}

export function isBrowserDoNotTrackEnabled() {
  const dntOnOldIEVersions = global.window.external?.msTrackingProtectionEnabled; // IE < 11
  if (dntOnOldIEVersions) {
    return dntOnOldIEVersions();
  }

  const dnt = global.window.doNotTrack || global.navigator.doNotTrack || global.navigator.msDoNotTrack;

  return ['1', 'yes'].includes(dnt);
}

export function getSearchParams(search = '') {
  if (!search) {
    return {};
  }

  const searchString = search.startsWith('?') ? search.slice(1) : search;
  const queryParams = new URLSearchParams(searchString);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent#Decoding_query_parameters_from_a_URL
  const decodeQueryParam = (value) => {
    let decodedValue;
    try {
      decodedValue = JSON.parse(decodeURIComponent(value.replace(/\+/g, ' ')));
    } catch {
      decodedValue = value;
    }

    return decodedValue;
  };

  const queryParamsObject = {};
  queryParams.forEach((value, key) => {
    queryParamsObject[key] = decodeQueryParam(value);
  });

  return queryParamsObject;
}

export function delay(callbackFn, delayMs) {
  const timer = setTimeout(() => {
    callbackFn();
  }, delayMs);
  return () => clearTimeout(timer);
}

export function isHorizontal(sourceX, sourceY, targetX, targetY) {
  // detects if the link is horizontal or vertical + if it's right to left or left to right
  // http://www.montereyinstitute.org/courses/DevelopmentalMath/COURSE_TEXT2_RESOURCE/U13_L2_T1_text_final.html
  // tl;dr a slope over 1 is over 45deg
  const rise = Math.abs(targetY - sourceY);
  const run = Math.abs(targetX - sourceX);
  const slope = Math.abs(rise / run);
  return slope <= 1;
}

export function expandPermissions(permissionsByOrgId) {
  const PERMISSIONS_LETTER_TO_ACTION = {
    c: 'create',
    r: 'read',
    u: 'update',
    d: 'delete',
  };

  return Object.entries(permissionsByOrgId).reduce((expandedPermissionsByOrgId, [orgId, permissions]) => {
    const expandedPermissions = Object.entries(permissions).reduce((acc, [subject, actionString]) => {
      const permissionsForSubject = [...actionString].map((actionLetter) => ({
        subject,
        action: PERMISSIONS_LETTER_TO_ACTION[actionLetter],
      }));
      return acc.concat(...permissionsForSubject);
    }, []);
    return { ...expandedPermissionsByOrgId, [orgId]: expandedPermissions };
  }, {});
}

export function makeLimitMessageTemplate(feature = '', templateVariables = {}) {
  const templateString = featureTypes.LIMIT_MESSAGES_PER_FEATURE[feature];
  const regex = /\${[A-Za-z]+}/;
  const matchTemplateString = regex.test(templateString);
  if (!matchTemplateString) {
    return templateString;
  }

  const keys = Object.keys(templateVariables);
  const values = Object.values(templateVariables);

  try {
    const templateFunction = new Function(...keys, `return \`${templateString}\`;`); // eslint-disable-line
    return templateFunction(...values);
  } catch (error) {
    return templateString;
  }
}

/**
 * @param {*} Array of tuples in the form of [theKey, theValue][]
 */
export function querifyData(tuples) {
  function sanitizeValue(val) {
    if (Array.isArray(val) || typeof val === 'object') {
      return JSON.stringify(val);
    }
    return val;
  }

  return tuples
    .filter(([, val]) => val !== null && val !== undefined)
    .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(sanitizeValue(val))}`)
    .join('&');
}

export const getSortedProviderNames = (providerNameA, providerNameB, separator = ', ') =>
  [providerNameA?.toLowerCase(), providerNameB?.toLowerCase()]
    .filter((p) => !!p)
    .sort()
    .join(separator);

export function getPlanTier(planId) {
  if (planId.includes('trial')) return BillingPlanTier.TRIAL;
  if (planId.includes('starter') || planId.includes('personal')) return BillingPlanTier.PERSONAL;
  if (planId.includes('team')) return BillingPlanTier.TEAM;
  if (planId.includes('custom-invoiced') || planId.includes('enterprise')) return BillingPlanTier.ENTERPRISE;
  if (planId.includes('wrike')) return BillingPlanTier.FREE_WITH_WRIKE;
  if (planId.includes('pause')) return BillingPlanTier.PAUSE;
  if (planId.includes('business')) {
    if (planId.includes('x')) return BillingPlanTier.BUSINESS_PLUS;
    return BillingPlanTier.BUSINESS;
  }
  if (planId.includes('company')) {
    if (planId.includes('plus')) return BillingPlanTier.COMPANY_PLUS;
    return BillingPlanTier.COMPANY;
  }
  return null;
}

export const formatDate = (value) => Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(value);

/**
 * Returns a number with thousands separators
 * Copied with love from https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 * @param {number} num
 * @returns {string}
 */
export const formatNumberWithComma = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const BillingPlanTier = {
  TRIAL: 'Trial',
  PERSONAL: 'Personal',
  TEAM: 'Team',
  BUSINESS_PLUS: 'Business +',
  BUSINESS: 'Business',
  COMPANY_PLUS: 'Company +',
  COMPANY: 'Company',
  ENTERPRISE: 'Enterprise',
  FREE_WITH_WRIKE: 'Free with Wrike',
  PAUSE: 'Pause',
};

export const isFeatureUnlimited = (limit) =>
  typeof limit === 'string' && limit?.toLowerCase() === featureTypes.UNLIMITED;

export const getEmbedAppcuesResourceId = (embedName) => {
  const embedResourceMap = {
    [appTypes.EMBED.TRELLO]: appcuesTypes.CTA_IDS.FB_NEW_TRELLO_EMBED_RESOURCES,
    [appTypes.EMBED.WRIKE]: appcuesTypes.CTA_IDS.FB_NEW_WRIKE_EMBED_RESOURCES,
    [appTypes.EMBED.JIRA_CLOUD]: appcuesTypes.CTA_IDS.FB_NEW_JIRA_EMBED_RESOURCES,
    [appTypes.EMBED.MONDAY]: appcuesTypes.CTA_IDS.MONDAY_EMBED_RESOURCES,
  };

  return embedResourceMap[embedName] ?? appcuesTypes.CTA_IDS.SUGGEST_ANYTHING;
};

/**
 *  Search recursively the PCD to find the item that matches the item type. It searches through the fields attribute.
 * @param {object} item  -item from PCDItem. Definition can be found in Connectors under PCDFields
 * @param {string} itemType - The item type name (native) e.g. card, task..
 * @returns items
 */
export const findItemByItemType = (item, itemType) => {
  const currentItem = item.get('item') || item.get('itemType');

  // Handle case where the item item type is itself
  if (currentItem === 'self') {
    if (item.getIn(['names', 'native']) === itemType) {
      return item;
    }

    // Since the item is itself, can't go deeper into the object.
    return undefined;
  }

  if (currentItem.getIn(['names', 'native']) === itemType) {
    return currentItem;
  }

  const fields = currentItem.get('fields');

  if (!fields) {
    return undefined;
  }

  const subItem = fields.find(
    (v) => v.get('semantic') === fieldTypes.SUBTASKS && v.get('type') === fieldTypes.TYPES.ITEM,
  );

  if (!subItem) {
    return undefined;
  }

  if (subItem.getIn(['names', 'native']) === itemType) {
    return subItem;
  }

  return findItemByItemType(subItem, itemType);
};

/**
 * Return the activity status to display based on its status and whether it is retryable or not.
 * @param {*} status
 * @param {*} publisherError
 * @returns
 */
export const getActivityStatusOption = (status, publisherError) => {
  if (publisherError?.retryable === true) {
    return 'retrying';
  }
  if (publisherError !== undefined || status === true) {
    return 'failed';
  }
  if (status === false || (publisherError === undefined && status === undefined)) {
    return 'success';
  }

  return undefined;
};

/**
 *
 * @param {*} organization
 * @returns if org is valid
 */
export const isOrganizationValid = (organization) => {
  if (
    organization?.get('status') &&
    ![
      organizationTypes.STATUSES.TRIAL_EXPIRED,
      organizationTypes.STATUSES.CHURNED,
      organizationTypes.STATUSES.EXPIRED,
    ].includes(organization.get('status'))
  ) {
    return true;
  }

  return false;
};

export function getSyncActivityStatus(linkState, activity, health, isSuspended = false) {
  const isLinkStateDraft = linkState === linkTypes.LINK_STATES.DRAFT;
  const isLinkDegraded = linkTypes.DEGRADED_LINK_STATES.includes(linkState);

  if (isLinkStateDraft) {
    return linkTypes.LINK_STATES.DRAFT;
  }

  if (isSuspended) {
    return linkTypes.LINK_ACTIVITY_STATUS.SUSPENDED;
  }

  if (isLinkDegraded) {
    return linkTypes.LINK_ACTIVITY_STATUS.DEGRADED;
  }

  return activity || health;
}

export function isValidUrl(string) {
  try {
    const url = new URL(string);
    return !!url;
  } catch (_) {
    return false;
  }
}

const MONGO_ID_REGEX = /^[0-9a-fA-F]{24}$/;

export function isMongooseModelId(idOrDoc) {
  if (idOrDoc == null) return false;

  if (typeof idOrDoc === 'number') {
    return true;
  }

  if (typeof idOrDoc === 'string') {
    return idOrDoc.length === 12 || (idOrDoc.length === 24 && MONGO_ID_REGEX.test(idOrDoc));
  }

  return false;
}
// Removed
// Since it caused an import error (probably due to circular dependencies imports)
export { formUtils, fromLocalStorage, authUtils, logger };
