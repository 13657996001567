import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { tokens } from '@unitoio/mosaic';

import { workflowActions, linkActions } from 'actions';
import { Box, Card, Href, InlineLoading, Title, Subheading, CreateWorkflowButton, SiteAdminSearch } from 'components';
import { linkTypes, trackingTypes } from 'consts';
import { getWorkflows } from 'reducers';
import { SiteAdminContext } from 'contexts';

import { trackableHoC } from '../TrackableHoC/TrackableHoC';
import EmptyState from './resources/emptyState.svg';
import { WorkflowItem } from './components/WorkflowItem';

const EmptyStateImg = styled.img`
  margin-bottom: 2rem;
`;

const Content = styled.div`
  background: ${tokens.colors.global.primary.light};
  min-height: calc(100vh - 16rem);
  padding-top: 2rem;
  padding-bottom: 4rem;
`;

const ContentEmptyState = styled.div`
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Description = styled.p`
  color: ${tokens.colors.content.neutral.n30};
`;

const Grid = styled.div`
  display: inline-grid;
  row-gap: 3rem;
  column-gap: 1.5rem;
  grid-template-columns: repeat(3, auto);
`;

const Table = styled.div`
  border-collapse: separate;
  border-spacing: 0 1.25rem;
  display: flex;
  flex-direction: column;
`;

const EmptyStateCard = styled.div`
  align-self: center;
  max-width: 600px;
`;

const usefulLinks = [
  {
    title: 'How to build your first workflow',
    description: `Creating your first workflow in Unito is a simple, three-step process. All you need to do is add your blocks
            of work, connect them with flows, and create your rules.`,
    link: 'https://guide.unito.io/how-to-build-your-first-workflow-with-unito',
    linkText: 'Read the guide',
  },
  {
    title: 'Glossary & terminology',
    description: `Since Unito create flows between different tools with distinct vocabulary, we've created a useful glossary of
            terms that should help make sense of it all.`,
    link: 'https://guide.unito.io/unito-terminology-a-glossary',
    linkText: 'Discover our glossary',
  },
  {
    title: 'How to set up a flow.',
    description: `Creating your first workflow in Unito is a simple, three-step process. All you need to do is add your blocks
            of work, connect them with flows, and create your rules.`,
    link: 'https://guide.unito.io/how-to-create-your-first-flow',
    linkText: 'Read more',
  },
];

function useFetchWorkflows(organizationId) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchWorkflows() {
      try {
        await dispatch(workflowActions.getWorkflows(organizationId));
        await dispatch(
          linkActions.getLinks({
            page: 0,
            pageSize: 0,
            kinds: [linkTypes.KIND.MIRROR_SYNC, linkTypes.KIND.MULTI_SYNC, linkTypes.KIND.REPORT_SYNC],
          }),
        );
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
    fetchWorkflows();
  }, [organizationId, dispatch]);

  return isLoading;
}

export const WorkflowList = trackableHoC(({ organizationId, trackEvent }) => {
  const isLoading = useFetchWorkflows(organizationId);
  const dispatch = useDispatch();
  const workflows = useSelector((state) => getWorkflows(state));
  const sortedWorkflows = workflows.toList().sortBy((workflow) => workflow.get('name'));

  const { isSiteAdmin } = useContext(SiteAdminContext);
  const trackUsefulLinkClick = (selectedUsefulLink) => {
    trackEvent(trackingTypes.USER_DASHBOARD_EVENTS.ACTION_NAME, {
      action_name: 'clicked on useful link article',
      articleTitle: selectedUsefulLink,
    });
  };

  if (isLoading) {
    return <InlineLoading />;
  }

  if (workflows.isEmpty()) {
    return (
      <ContentEmptyState className="workflow-list">
        <EmptyStateCard className="text-center">
          <EmptyStateImg src={EmptyState} alt="work items to sync" />
          <Title type="h3">Create your first workflow!</Title>
          <div>
            <Subheading>
              Workflows map all the places where work happens, giving you powerful visibility across the tools your team
              uses — all in just a few clicks.
            </Subheading>
          </div>
          <CreateWorkflowButton trackingActionName="clicked on create a new workflow button (empty state)" />
        </EmptyStateCard>
        <Box $m={[4, 0]}>
          <Title type="h3">Useful links</Title>
          <Grid>
            {usefulLinks.map((usefulLink) => (
              <Card key={usefulLink.title}>
                <Box $m={[0, 0, 1, 0]}>
                  <Title type="h4">{usefulLink.title}</Title>
                </Box>
                <Description>{usefulLink.description}</Description>
                <Href href={usefulLink.link} onClick={() => trackUsefulLinkClick(usefulLink.title)}>
                  {usefulLink.linkText}
                </Href>
              </Card>
            ))}
          </Grid>
        </Box>
      </ContentEmptyState>
    );
  }

  return (
    <Content className="workflow-list">
      {isSiteAdmin && (
        <SiteAdminSearch
          isWorkflow
          onChangeSiteAdminSearch={async (searchValue) => {
            await dispatch(
              linkActions.searchLinks({
                page: 0,
                pageSize: 0,
                siteAdminSearchString: searchValue,
                kinds: [linkTypes.KIND.MIRROR_SYNC, linkTypes.KIND.MULTI_SYNC, linkTypes.KIND.REPORT_SYNC],
              }),
            );
          }}
          onReset={async () => {
            await dispatch(
              linkActions.getLinks({
                page: 0,
                pageSize: 0,
                kinds: [linkTypes.KIND.MIRROR_SYNC, linkTypes.KIND.MULTI_SYNC, linkTypes.KIND.REPORT_SYNC],
              }),
            );
          }}
        />
      )}
      <Table>
        {sortedWorkflows.map((workflow) => (
          <WorkflowItem key={`workflow-${workflow.get('_id')}`} workflow={workflow} organizationId={organizationId} />
        ))}
      </Table>
    </Content>
  );
});

WorkflowList.propTypes = {
  organizationId: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
