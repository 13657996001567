import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import { SelectField } from 'components';
import { getProviderByName, getProviderCapabilitiesByIdV3, hasMandatoryConditionalContainers } from 'reducers';
import { fieldTypes } from 'consts';
import { capitalize } from 'utils';

const allObjectsOption = { value: 'all', label: 'All Objects' };

function useGetItemFields(providerName, itemType) {
  const provider = useSelector((state) => getProviderByName(state, providerName));
  const primaryItem = useSelector((state) => getProviderCapabilitiesByIdV3(state, provider.get('_id'), itemType));
  const fields = primaryItem.getIn(['item', 'fields'], Map());
  return fields.filter((field) => field.get('type') === fieldTypes.TYPES.ITEM);
}

function buildOption({ field, fieldId, fieldKind, fieldDisplayName, isOptionDisabledHandler }) {
  const { disabled, disabledText } = isOptionDisabledHandler(field.set('fieldId', fieldId).set('kind', fieldKind));

  return {
    type: field.get('type'),
    value: fieldId,
    label: capitalize(fieldDisplayName),
    disabled,
    disabledText,
    kind: fieldKind,
  };
}

function getOptions({ itemFields, isOptionDisabledHandler, includeAllObjectsOption }) {
  const fieldOptions = itemFields
    .filter((field) => field.getIn(['deepFilterable'], false) && field.hasIn(['itemType', 'fields']))
    .map((field, fieldId) => {
      const fieldDisplayName = field.getIn(['names', 'singular'], '');
      return buildOption({
        field,
        fieldId,
        fieldKind: field.has('type') ? fieldTypes.KINDS.PCD_TYPED_FIELD : fieldTypes.KINDS.PCD_FIELD,
        fieldDisplayName,
        isOptionDisabledHandler,
      });
    })
    .toArray();

  if (includeAllObjectsOption) {
    return [allObjectsOption, ...fieldOptions];
  }

  return fieldOptions;
}

export const ItemFieldsSelect = ({
  formatLabel,
  name,
  noResultsText = 'No matching fields found',
  onChange,
  placeholder = '',
  providerName,
  readOnly = false,
  searchPlaceholder,
  searchable = true,
  value,
  isOptionDisabledHandler,
  disabled = false,
  itemType,
}) => {
  const itemFields = useGetItemFields(providerName, itemType);
  const providerId = useSelector((state) => getProviderByName(state, providerName)).get('_id');
  const includeAllObjectsOption = !useSelector((state) => hasMandatoryConditionalContainers(state, providerId));
  const options = getOptions({ itemFields, isOptionDisabledHandler, includeAllObjectsOption });
  return (
    <SelectField
      label="Select a field"
      formatLabel={formatLabel}
      placeholder={placeholder}
      name={name}
      noResultsText={noResultsText}
      onChange={onChange}
      options={options}
      readOnly={readOnly || options.length === 1}
      searchable={searchable}
      searchPlaceholder={searchPlaceholder}
      value={value}
      disabled={disabled}
    />
  );
};

ItemFieldsSelect.propTypes = {
  formatLabel: PropTypes.func,
  name: PropTypes.string.isRequired,
  noResultsText: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  providerName: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  searchable: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  isOptionDisabledHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  itemType: PropTypes.string.isRequired,
};
