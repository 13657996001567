import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { hasProviderCustomFieldOfTypeSupport, getProviderByName } from 'reducers';
import { formUtils } from 'utils';

export function useSetSyncDirection({ providerNameA, providerNameB, setValue, watch }) {
  const [showInfo, setShowInfo] = useState(false);
  const filtersA = watch('A.filters');
  const filtersB = watch('B.filters');
  const syncDirection = watch('syncDirection');

  const providerIdA = useSelector((state) => getProviderByName(state, providerNameA).get('_id'));
  const providerIdB = useSelector((state) => getProviderByName(state, providerNameB).get('_id'));
  const providerASupportStringCustomField = useSelector((state) =>
    hasProviderCustomFieldOfTypeSupport(state, providerIdA),
  );
  const providerBSupportStringCustomField = useSelector((state) =>
    hasProviderCustomFieldOfTypeSupport(state, providerIdB),
  );
  const hasItemFilterSideA = !!formUtils.getItemFiltersForSideForFlowBuilder(filtersA).length;
  const hasItemFilterSideB = !!formUtils.getItemFiltersForSideForFlowBuilder(filtersB).length;

  const shouldUpdateSyncDirectionToA =
    hasItemFilterSideB && !providerASupportStringCustomField && syncDirection !== 'A';
  const shouldUpdateSyncDirectionToB =
    hasItemFilterSideA && !providerBSupportStringCustomField && syncDirection !== 'B';

  useEffect(() => {
    if (shouldUpdateSyncDirectionToA) {
      setValue('syncDirection', 'A', { shouldDirty: true });
      setShowInfo(true);
    } else if (shouldUpdateSyncDirectionToB) {
      setValue('syncDirection', 'B', { shouldDirty: true });
      setShowInfo(true);
    }
  }, [showInfo, setValue, shouldUpdateSyncDirectionToA, shouldUpdateSyncDirectionToB]);

  return showInfo;
}
