// [DEPRECATED] remove this hook once we refactor the account validation logic in the components
import { useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { linkTypes } from 'consts';
import { FlowBuilderErrorContext } from 'contexts';
import { getContainersByProviderIdentityId, getLinkById, isUserSiteAdmin } from 'reducers';

export function useValidateProviderIdentities(setErrors = true, draftOverride = null) {
  const { setError, clearErrors, watch } = useFormContext();
  const { linkId } = useParams();
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const linkState = useWatch({ name: 'state' });
  const isLinkEmpty = Object.keys(currentLink.toJS()).length === 0;
  const linkIsDraftOrDuplicate =
    draftOverride ?? [linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.DUPLICATE].includes(linkState);

  const providerIdentityIdA = watch('A.providerIdentityId');
  const containerIdA = watch('A.containerId');
  const providerIdentityIdB = watch('B.providerIdentityId');
  const containerIdB = watch('B.containerId');

  // TODO not sure how to best handle site admins since the code below might not work for them.
  //  Just making sure we don't display the error i guess ?
  const isSiteAdmin = useSelector((state) => isUserSiteAdmin(state));

  const providerIdentityAContainers = useSelector((state) =>
    getContainersByProviderIdentityId(state, providerIdentityIdA),
  );
  const providerIdentityBContainers = useSelector((state) =>
    getContainersByProviderIdentityId(state, providerIdentityIdB),
  );

  const containerBelongsToIdentityA = providerIdentityAContainers.keySeq().some((id) => id === containerIdA);
  const containerBelongsToIdentityB = providerIdentityBContainers.keySeq().some((id) => id === containerIdB);

  const validSelectionA = isLinkEmpty || linkIsDraftOrDuplicate || containerBelongsToIdentityA;
  const validSelectionB = linkIsDraftOrDuplicate || containerBelongsToIdentityB;

  const pageName = useContext(FlowBuilderErrorContext);

  useEffect(() => {
    if (setErrors) {
      let errorName = `${pageName}.invalidProviderIdentityA`;
      if (!validSelectionA && !isSiteAdmin) {
        setError(errorName, { type: 'manual' });
      } else {
        clearErrors(errorName);
      }

      errorName = `${pageName}.invalidProviderIdentityB`;
      if (!validSelectionB && !isSiteAdmin) {
        setError(errorName, { type: 'manual' });
      } else {
        clearErrors(errorName);
      }
    }
  }, [setError, clearErrors, validSelectionA, validSelectionB, isSiteAdmin, pageName, setErrors]);

  return [validSelectionA, validSelectionB];
}
