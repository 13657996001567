import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@unitoio/mosaic';

import { flowBuilderTypes } from '../consts';
import { FlowContainerInfo } from './FlowContainerInfo';

export const FlowContainerInfos = ({
  isLoading,
  linkId,
  pcdFieldsTotalA,
  pcdFieldsTotalB,
  completeFieldAssociations,
}) => (
  <Box justifyContent={Box.justifyContent.SPACE_BETWEEN}>
    <FlowContainerInfo
      isLoading={isLoading}
      containerSide="A"
      linkId={linkId}
      subtitle={
        pcdFieldsTotalA ? `${completeFieldAssociations.length} out of ${pcdFieldsTotalA} fields mapped` : undefined
      }
    />
    <FlowContainerInfo
      isLoading={isLoading}
      containerSide="B"
      linkId={linkId}
      subtitle={
        pcdFieldsTotalB ? `${completeFieldAssociations.length} out of ${pcdFieldsTotalB} fields mapped` : undefined
      }
    />
  </Box>
);

FlowContainerInfos.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  linkId: PropTypes.string.isRequired,
  pcdFieldsTotalA: PropTypes.number.isRequired,
  pcdFieldsTotalB: PropTypes.number.isRequired,
  completeFieldAssociations: PropTypes.arrayOf(flowBuilderTypes.fieldAssociationPropTypes).isRequired,
};
