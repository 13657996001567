import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notification } from '@unitoio/mosaic';

import { Button, InlineLoading, Modal, SearchBox } from 'components';
import { appActions, workflowActions } from 'actions';
import { getScripts } from 'reducers';

import { UnitoPrompt } from './UnitoPrompt';

export const SiteAdminSearch = ({ isWorkflow = false, onChangeSiteAdminSearch, onReset }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showUnitoPrompt, setShowUnitoPrompt] = useState(false);
  const [siteAdminSearchString, setSiteAdminSearchString] = useState('');
  const dispatch = useDispatch();
  const scripts = useSelector(getScripts);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(appActions.getScripts());
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const onSearch = async () => {
    const sanitizedSearch = siteAdminSearchString.trim();
    if (sanitizedSearch.split(',').every((elem) => !!elem.match(/^[0-9a-fA-F]{24}$/))) {
      setSiteAdminSearchString(sanitizedSearch);
      if (isWorkflow) {
        await dispatch(workflowActions.searchWorkflows(sanitizedSearch));
      }
      onChangeSiteAdminSearch(sanitizedSearch);
    } else {
      notification.error({
        message: 'Invalid search',
        description:
          'Make sure to enter valid "Link Id", "Organization Id", "Multi-sync Id" or "User Id". Separate multiple searches with commas.',
        placement: 'top',
      });
    }
  };

  const openUnitoPrompt = () => {
    setShowUnitoPrompt(true);
  };

  const closeUnitoPrompt = () => {
    setShowUnitoPrompt(false);
  };

  const onResetSearch = async () => {
    setSiteAdminSearchString('');
    if (isWorkflow) {
      await dispatch(workflowActions.searchWorkflows());
    }
    onReset();
  };

  const linkPlaceholder =
    'Search links by LinkId, OrganizationId, MultisyncId or UserId. Separate multiple searches with commas.';
  const wfPlaceholder =
    'Search workflows by WorkflowId, LinkId, OrganizationId or UserId. Separate multiple searches with commas.';

  if (isLoading) {
    return <InlineLoading size="small" />;
  }

  return (
    <>
      <Modal
        isOpen={showUnitoPrompt}
        type="plainModal"
        displayCloseButton
        onRequestClose={closeUnitoPrompt}
        size="full"
      >
        <UnitoPrompt scripts={scripts} onClose={closeUnitoPrompt} />
      </Modal>

      <SearchBox
        placeholder={isWorkflow ? wfPlaceholder : linkPlaceholder}
        onChange={setSiteAdminSearchString}
        onPressEnter={onSearch}
        value={siteAdminSearchString}
      >
        <Button btnStyle="secondary" onClick={onSearch}>
          Search
        </Button>
        <Button btnStyle="error" onClick={onResetSearch}>
          Reset
        </Button>
        <Button onClick={openUnitoPrompt} btnStyle="dark">
          Unito Prompt
        </Button>
      </SearchBox>
    </>
  );
};

SiteAdminSearch.propTypes = {
  isWorkflow: PropTypes.bool,
  onChangeSiteAdminSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};
