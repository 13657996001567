import React from 'react';

import { useTrackEvent } from 'hooks';

export function trackableHoC(WrappedComponent) {
  return (props) => {
    const trackEvent = useTrackEvent();
    return <WrappedComponent {...props} trackEvent={trackEvent} />;
  };
}
