import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import {
  getCustomFields,
  getIsCustomFieldsLoading,
  getProviderByName,
  getSupportedFieldsForItem,
  getCustomFieldsTypeDefinition,
  getFeatureFlagValue,
} from 'reducers';
import { fieldTypes } from 'consts';
import { capitalize } from 'utils';

function useGetPcdFields(providerName, containerId, itemType) {
  const provider = useSelector((state) => getProviderByName(state, providerName));
  const fields = useSelector((state) => getSupportedFieldsForItem(state, provider.get('_id'), containerId, itemType));

  return fields;
}

function useGetCustomFields({ containerSide, providerName, itemType }) {
  const isLoading = useSelector((state) => getIsCustomFieldsLoading(state));
  const customFields = useSelector((state) => getCustomFields(state, { containerSide, providerName, itemType }));
  return [isLoading, customFields];
}

function buildOption({ field, fieldId, fieldKind, fieldDisplayName, isOptionDisabledHandler, readOnly }) {
  const { disabled, disabledText } =
    isOptionDisabledHandler?.(field.set('fieldId', fieldId).set('kind', fieldKind)) || {};

  return {
    type: field.get('type'),
    value: fieldId,
    label: capitalize(fieldDisplayName),
    disabled,
    disabledText,
    kind: fieldKind,
    readOnly,
    // FIXME: We only do defaults on field types that aren't string for now, to exclude unsupported defaults on fields
    // like title that may be required on create. Removed the type string check once we support setting defaults on string
    isSetDefault: field.get('requiredOnCreate', false) && field.get('type') !== 'string',
    semantic: field.get('semantic'),
  };
}

const sortFields = (fieldA, fieldB) => {
  const labelA = fieldA.label.toLowerCase();
  const labelB = fieldB.label.toLowerCase();
  if (fieldA.disabled && fieldB.disabled) {
    return labelA.localeCompare(labelB, 'en', { sensitivity: 'base' });
  }

  if (fieldA.disabled) {
    return 1;
  }

  if (fieldB.disabled) {
    return -1;
  }

  return labelA.localeCompare(labelB, 'en', { sensitivity: 'base' });
};

export function useGetPCDFieldsSelectorOptions(
  providerName,
  containerId,
  containerSide,
  itemType,
  displayTypes,
  displayType,
  parentFieldId,
  labelPrefix,
  labelSuffix,
  /** Function that receives a field, and returns { disabled: boolean, disabledText: string } */
  isOptionDisabledHandler,
  /** Options to append to the options built by the hook */
  additionalOptions = [],
) {
  const customFieldsDefinition = useSelector((state) => getCustomFieldsTypeDefinition(state, providerName, itemType));
  const pcdFields = useGetPcdFields(providerName, containerId, itemType);
  const [status, customFields] = useGetCustomFields({ containerSide, providerName, itemType });
  const isGithubAttachmentEnabled = useSelector((state) => getFeatureFlagValue(state, 'github-attachments-enabled'));
  const hasLinkBlockCapabilities = pcdFields.some(
    (field) => field.get('semantic') === fieldTypes.SEMANTIC.DESCRIPTION && !field.get('readOnlyOnUpdate'),
  );
  let visibleFields = pcdFields.filter((field) => !field.get('hidden', false));

  // Github attachments are only available for Hubspot (client)
  // This is a 'temporary' feature so that they can perform a migration from Jira to Github
  // https://app.asana.com/0/1206509750791517/1207870932175297/f
  if (!isGithubAttachmentEnabled && providerName === 'githubappinstallation') {
    visibleFields = visibleFields.filter((field) => field.get('semantic') !== fieldTypes.SEMANTIC.ATTACHMENTS);
  }

  if (displayType === displayTypes.MERGEABLE_FIELDS_ONLY) {
    const [fieldId, firstEmailField] = visibleFields.entrySeq().find(([, values]) => values.get('mergeField', false));
    return [
      status,
      [
        buildOption({
          field: firstEmailField,
          fieldId,
          fieldKind: fieldTypes.KINDS.PCD_TYPED_FIELD,
          fieldDisplayName: firstEmailField.getIn(['names', 'singular'], ''),
          readOnly: false,
          isOptionDisabledHandler,
        }),
      ],
    ];
  }

  if (displayType === displayTypes.REQUIRES_FIELD_VALUE) {
    const defaultTypeOptions = pcdFields
      // FIXME: We only do defaults on field types that aren't string for now, to exclude unsupported defaults on fields
      // like title that may be required on create. Removed the type string check once we support setting defaults on string
      .filter((pcdField) => pcdField.get('requiredOnCreate') && pcdField.get('type') !== 'string')
      .map((field, fieldId) =>
        buildOption({
          field,
          fieldId: field.get('field', fieldId),
          fieldKind: fieldTypes.KINDS.PCD_TYPED_FIELD,
          fieldDisplayName: labelPrefix
            ? `${labelPrefix} ${field.getIn(['names', 'singular'], '').toLowerCase()} ${labelSuffix}`
            : field.getIn(['names', 'singular'], '').toLowerCase(),
          readOnly: false,
          isOptionDisabledHandler,
        }),
      );

    return [status, defaultTypeOptions.sort(sortFields).toArray()];
  }

  if (displayType === displayTypes.STRING_CUSTOM_FIELDS_ONLY) {
    const stringTypedCustomFields = customFields
      .filter((field) => field.get('type') === fieldTypes.TYPES.STRING)
      .map((field, fieldId) =>
        buildOption({
          field,
          fieldId,
          fieldKind: fieldTypes.KINDS.CUSTOM_FIELD,
          fieldDisplayName: labelPrefix ? `${labelPrefix} ${field.get('name')}` : field.get('name'),
          isOptionDisabledHandler,
          readOnly: field.get('readOnlyOnUpdate'),
        }),
      );

    return [status, stringTypedCustomFields.sort(sortFields).toArray()];
  }

  if (parentFieldId && displayType === displayTypes.DEEP_FIELDS_ONLY) {
    const itemField = pcdFields.get(parentFieldId);
    if (itemField) {
      const deepFields = itemField.getIn(['itemType', 'fields']);
      return [
        status,
        deepFields
          .filter((field) => field.hasIn(['filterable', 'denyList']) || field.hasIn(['filterable', 'allowList']))
          .map((field, fieldId) => {
            const fieldDisplayName = field.getIn(['names', 'singular'], '');
            return buildOption({
              field,
              fieldId,
              fieldKind: fieldTypes.KINDS.PCD_TYPED_FIELD,
              fieldDisplayName: labelPrefix ? `${labelPrefix} ${fieldDisplayName}` : fieldDisplayName,
              readOnly: field.get('readOnlyOnUpdate'),
              isOptionDisabledHandler,
            });
          })
          .toArray(),
      ];
    }
  }

  let optionsCommon = Map();
  if (hasLinkBlockCapabilities && displayType !== displayTypes.FILTERS_ONLY) {
    const linkBlockOption = buildOption({
      field: Map(),
      fieldId: fieldTypes.DESCRIPTION_FOOTER,
      fieldKind: fieldTypes.KINDS.PCD_COMMON,
      fieldDisplayName: 'description footer',
      readOnly: null,
      isOptionDisabledHandler,
    });
    optionsCommon = optionsCommon.set(fieldTypes.DESCRIPTION_FOOTER, linkBlockOption);
  }

  let optionsPcdFields;
  // Build options for the mapping page
  if (displayType === displayTypes.MAPPING) {
    optionsPcdFields = visibleFields.map((field, fieldId) => {
      const isArray = field.get('isArray', false);
      const plurality = isArray ? 'plural' : 'singular';
      const fieldDisplayName = field.getIn(['names', plurality], '');
      return buildOption({
        field,
        fieldId,
        fieldKind: field.has('type') ? fieldTypes.KINDS.PCD_TYPED_FIELD : fieldTypes.KINDS.PCD_FIELD,
        fieldDisplayName: labelPrefix ? `${labelPrefix} ${fieldDisplayName} ${labelSuffix}` : fieldDisplayName,
        readOnly: field.get('readOnlyOnUpdate'),
        isOptionDisabledHandler,
      });
    });
  }

  // Build options for the rules page
  if (displayType === displayTypes.FILTERS_ONLY) {
    optionsPcdFields = pcdFields
      .filter((field) => field.getIn(['filterable', 'allowList']) || field.getIn(['filterable', 'denyList']))
      .map((field, fieldId) => {
        const fieldDisplayName = field.getIn(['names', 'singular'], '');
        return buildOption({
          field,
          fieldId,
          fieldKind: field.has('type') ? fieldTypes.KINDS.PCD_TYPED_FIELD : fieldTypes.KINDS.PCD_FIELD,
          fieldDisplayName: labelPrefix ? `${labelPrefix} ${fieldDisplayName} ${labelSuffix}` : fieldDisplayName,
          readOnly: field.get('readOnlyOnUpdate'),
          isOptionDisabledHandler,
        });
      });
  }

  const optionsCustomFields = customFields
    .filter((field) => {
      if (displayType !== displayTypes.FILTERS_ONLY) {
        return true;
      }
      const customFieldNativeType = field.get('nativeType');
      const customFieldTypeDefinition = customFieldsDefinition.get(customFieldNativeType, Map());
      return (
        customFieldTypeDefinition.getIn(['filterable', 'allowList']) ||
        customFieldTypeDefinition.getIn(['filterable', 'denyList'])
      );
    })
    .map((field, fieldId) =>
      buildOption({
        field,
        fieldId,
        fieldKind: fieldTypes.KINDS.CUSTOM_FIELD,
        fieldDisplayName: labelPrefix ? `${labelPrefix} ${field.get('name')}` : field.get('name'),
        isOptionDisabledHandler,
        readOnly: field.get('readOnlyOnUpdate'),
      }),
    );

  return [
    status,
    optionsCommon
      .merge(optionsPcdFields)
      .merge(optionsCustomFields)
      .toArray()
      .concat(additionalOptions)
      .sort(sortFields),
  ];
}
