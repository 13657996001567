import { useSelector } from 'react-redux';

import { getLinkProviderIdentityIdBySide } from 'reducers';

export const useGetProviderIdentities = (linkId) => {
  const providerIdentityIdA = useSelector((state) =>
    getLinkProviderIdentityIdBySide(state, { containerSide: 'A', linkId }),
  );
  const providerIdentityIdB = useSelector((state) =>
    getLinkProviderIdentityIdBySide(state, { containerSide: 'B', linkId }),
  );
  return [providerIdentityIdA, providerIdentityIdB];
};
