import { Map, List, fromJS } from 'immutable';

import { unitoIdentityTypes } from 'consts';
import { normalizeEntitiesById, normalizeEntityIdsBy } from 'utils';

export const initialState = Map({
  entitiesById: Map(),
  entitiesByOrganizationId: List(),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case unitoIdentityTypes.GET_UNITO_IDENTITIES_SUCCESS: {
      const unitoIdentities = fromJS(action.payload.unitoIdentities);
      const entitiesById = normalizeEntitiesById(unitoIdentities).map((entity) =>
        entity.update('providerIdentities', (providerIdentities) =>
          providerIdentities.map((providerIdentity) => providerIdentity.get('_id')),
        ),
      );
      const newState = state
        .set('entitiesById', entitiesById)
        .set('entitiesByOrganizationId', normalizeEntityIdsBy('organization', unitoIdentities));

      return newState;
    }

    default:
      return state;
  }
};

export function getById(state, unitoIdentityId) {
  return state.getIn(['entitiesById', unitoIdentityId], Map());
}

export function getByOrganizationId(state, organizationId) {
  return state.getIn(['entitiesByOrganizationId', organizationId], List());
}
