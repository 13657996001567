export const STATES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

// TODO: Move this stuff to the providers collection or connectors
export const COGNITO = 'cognito';
export const AUTH0 = 'auth0';

export const GET_PROVIDERS_REQUEST = 'GET_PROVIDERS_REQUEST';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAILURE = 'GET_PROVIDERS_FAILURE';

export const GET_PROVIDER_CONNECT_URL_REQUEST = 'GET_PROVIDER_CONNECT_URL_REQUEST';
export const GET_PROVIDER_CONNECT_URL_SUCCESS = 'GET_PROVIDER_CONNECT_URL_SUCCESS';
export const GET_PROVIDER_CONNECT_URL_FAILURE = 'GET_PROVIDER_CONNECT_URL_FAILURE';

export const GET_PROVIDER_CAPABILITIES_REQUEST = 'GET_PROVIDER_CAPABILITIES_REQUEST';
export const GET_PROVIDER_CAPABILITIES_SUCCESS = 'GET_PROVIDER_CAPABILITIES_SUCCESS';
export const GET_PROVIDER_CAPABILITIES_FAILURE = 'GET_PROVIDER_CAPABILITIES_FAILURE';

export const GET_PROVIDER_CAPABILITIES_FOR_ITEM_REQUEST = 'GET_PROVIDER_CAPABILITIES_FOR_ITEM_REQUEST';
export const GET_PROVIDER_CAPABILITIES_FOR_ITEM_SUCCESS = 'GET_PROVIDER_CAPABILITIES_FOR_ITEM_SUCCESS';
export const GET_PROVIDER_CAPABILITIES_FOR_ITEM_FAILURE = 'GET_PROVIDER_CAPABILITIES_FOR_ITEM_FAILURE';

// We need this until the datepicker exists
// see comment in `getIsCalendarTool` in reducers
export const CALENDAR_ITEMTYPE = [{ providerName: 'googlecalendar', itemType: 'event' }];
export const OUTLOOK_CALENDAR_ITEMTYPE = {
  providerName: 'outlook',
  itemType: 'event',
};

export const INTEGRATION_BRIDGE_CONNECTOR_NAME = 'integrationbridge';
export const INTEGRATION_NAME = {
  GA4: 'google-analytics',
  FB_ADS: 'facebook-ads',
  FB_INSIGHTS: 'facebook-insights',
  GADS: 'google-ads',
};

export const CONNECTORS_WITH_FILE_PICKER = ['excel'];
