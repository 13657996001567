import React from 'react';
import { useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';

import { NewAlertLevel, NewAlert } from '@unitoio/mosaic';

import { routes } from 'consts';
import { ProviderTermsByName } from 'components';

import { useIsFlowDuplicate } from '../hooks';

function useGetBannerCopy() {
  const { pageName, workflowId } = useParams();
  const [providerNameA, containerTypeA, itemTypeA, providerNameB, containerTypeB, itemTypeB] = useWatch({
    name: ['A.providerName', 'A.containerType', 'A.itemType', 'B.providerName', 'B.containerType', 'B.itemType'],
  });

  switch (pageName) {
    case routes.FLOW_BUILDER_PAGES.GUIDE:
      return 'This flow is a duplicate. Review the rules and the mappings to make sure they make sense for this flow.';

    case routes.FLOW_BUILDER_PAGES.TOOL_SELECTION: {
      const providerTerms = (
        <ProviderTermsByName
          plurality={providerNameA !== providerNameB ? 'singular' : 'plural'}
          termKey="container"
          pcdv3
          providerNameA={providerNameA}
          providerNameB={providerNameB}
          containerTypeA={containerTypeA}
          containerTypeB={containerTypeB}
          itemTypeA={itemTypeA}
          itemTypeB={itemTypeB}
          middleWord={workflowId ? 'and' : 'and/or'}
          showProviderName
        />
      );
      if (workflowId) {
        return <>This flow is a duplicate and is locked to the {providerTerms} from the workflow designer.</>;
      }

      return <>This flow is a duplicate. Please update the {providerTerms} on one or both sides before confirming.</>;
    }

    case routes.FLOW_BUILDER_PAGES.FLOW_DIRECTION:
      return 'This flow is a duplicate. Ensure the direction is appropriate for this flow.';

    case routes.FLOW_BUILDER_PAGES.RULES:
      return 'This flow is a duplicate. Review the rules and actions to make sure they make sense for this flow.';

    case routes.FLOW_BUILDER_PAGES.MAPPINGS:
      return 'This flow is a duplicate. Review the mappings to make sure they make sense for this flow.';

    default:
      return null;
  }
}

export const DuplicateBanner = () => {
  const isDuplicate = useIsFlowDuplicate();
  const pageCopy = useGetBannerCopy();

  if (!isDuplicate || !pageCopy) {
    return null;
  }

  return <NewAlert level={NewAlertLevel.INFO} message={pageCopy} style={{ marginBottom: 16 }} />;
};
