import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import styled from 'styled-components';
import { color, fontSize, lineHeight, borderRadius, fontWeight } from 'theme';
import { trackingTypes } from 'consts';

import { ProviderIcon } from '@unitoio/mosaic';

import { Button, Box, InlineLoading, Card } from 'components';

import { trackableHoC } from '../../containers/TrackableHoC/TrackableHoC';
import duplicateLinkImg from './duplicate-link.svg';
import plusIconImg from './plus-icon.svg';

const CenteredBox = styled.div`
  text-align: center;
  overflow-y: auto;
  height: 100%;
`;

const CenteredOr = styled(Box)`
  align-self: center;
`;

const Header = styled.div`
  font-size: ${fontSize.h2};
  line-height: ${lineHeight.lh2};
`;

const Bold = styled.span`
  font-weight: ${fontWeight.medium};
`;

const ProviderIconWrapper = styled(Box)`
  border: 1px solid ${color.dark.dark5};
  border-radius: ${borderRadius.round};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexContainer = styled(Box)`
  display: flex;
  justify-content: center;
  > div {
    width: 32rem;
  }
`;

const CenteredIcon = styled(Box)`
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  ${(props) => props.$iconSize?.height && `height: ${props.$iconSize?.height}px`}
  ${(props) => props.$iconSize?.width && `width: ${props.$iconSize?.width}px`}
`;

const DuplicateModalComponent = ({
  providerA,
  providerB,
  history,
  trackEvent,
  disabled = false,
  onCreate,
  onDelete,
  duplicateUrl,
}) => (
  <CenteredBox p={[0, 2, 0]} m={[0, -3, 0, 0]}>
    <Header>
      We noticed that you have created flows between <br />
      <Bold>
        <CenteredIcon>
          <ProviderIconWrapper $m={[0, 0.5]}>
            <ProviderIcon size="small" name={providerA.get('name')} />
          </ProviderIconWrapper>{' '}
          {providerA.get('displayName')} and{' '}
          <ProviderIconWrapper $m={[0, 0.5]}>
            <ProviderIcon size="small" name={providerB.get('name')} />
          </ProviderIconWrapper>{' '}
          {providerB.get('displayName')}
        </CenteredIcon>
      </Bold>
    </Header>

    <FlexContainer $m={[1.5, 0, 0, 0]}>
      <Card
        p={[2, 1.5]}
        border="dashed"
        onClick={() => {
          onCreate();
          trackEvent(trackingTypes.WORKFLOW_FLOW_DUPLICATE.FLOW_ACTION, {
            action_name: trackingTypes.WORKFLOW_FLOW_DUPLICATE.ACTIONS.NEW_FLOW,
            selected_tool_names: `${providerA.get('name')},${providerB.get('name')}`,
          });
        }}
        disabled={disabled}
      >
        <CenteredIcon $p={[2, 1.5, 1]} $iconSize={{ height: 130 }}>
          {!disabled ? <img alt="New flow" src={plusIconImg} height="40" /> : <InlineLoading />}
        </CenteredIcon>
        <Bold>Start from scratch</Bold>
        <div>Set up the flow direction, define rules, and map fields starting from default settings</div>
      </Card>

      <CenteredOr as="span" $m={[0, 2]}>
        OR
      </CenteredOr>

      <Card p={[2, 1.5]} onClick={() => history.push(duplicateUrl)} disabled={disabled} color={color.brand.infoLight}>
        <CenteredIcon $p={[2, 1.5, 1]} $iconSize={{ height: 130 }}>
          <img alt="Apply existing settings" src={duplicateLinkImg} height="130" />
        </CenteredIcon>
        <Bold>Start from an existing flow</Bold>
        <div>Use previous rules and mappings, and edit from there</div>
      </Card>
    </FlexContainer>

    <Box $m={[3, 0, 0, 0]}>
      <Button disabled={disabled} btnStyle="link" color={color.brand.error} onClick={onDelete}>
        Delete this flow
      </Button>
    </Box>
  </CenteredBox>
);

DuplicateModalComponent.propTypes = {
  providerA: PropTypes.instanceOf(Map).isRequired,
  providerB: PropTypes.instanceOf(Map).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  trackEvent: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  duplicateUrl: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export const DuplicateModal = trackableHoC(DuplicateModalComponent);
