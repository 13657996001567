import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, SidePanel, Typography, tokens } from '@unitoio/mosaic';

import { TrackingFunnel } from 'containers';
import { useTrackEvent } from 'hooks';
import { getLinkById } from 'reducers';
import { trackingTypes } from 'consts';

import { ResourceBlock } from '../../components/ResourceBlock';

import Rules2 from '../../images/side_panel/rules/rules2.svg';
import Rules1 from '../../images/side_panel/rules/rules1.svg';
import { SidePanelVideoIframe } from './SidePanelVideoIframe';

const RuleSidePanel = ({ isOpen, onClose, linkId }) => {
  const link = useSelector((state) => getLinkById(state, linkId));
  const trackEvent = useTrackEvent({
    selected_tool_names: `${link.getIn(['A', 'providerName'])},${link.getIn(['B', 'providerName'])}`,
  });

  useEffect(() => {
    if (isOpen) {
      trackEvent(trackingTypes.START);
    }
  }, [isOpen, trackEvent]);

  return (
    <SidePanel onClose={onClose} isOpen={isOpen} backButtonText="Close">
      <Box>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <Typography variant={Typography.variants.H4}>Need some help setting up your rules?</Typography>
        </Box>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <SidePanelVideoIframe videoId="0Xvqkdf2e1g" title="Flow Creation Step 3/4 - Rules" />
        </Box>
        <Box
          alignItems={Box.alignItems.CENTER}
          flexDirection={Box.flexDirection.COLUMN}
          m={[0, 0, tokens.spacing.s7, 0]}
        >
          <Box m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              Now you can choose which work items to keep in sync. By default Unito will sync all open items created
              after you launch your flow. But chances are you'll want to narrow it down to only a subset of items.
            </Typography>
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              For example, you could choose to only sync items that display a red label or a blue label.{' '}
            </Typography>
          </Box>
          <Box m={[0, 0, tokens.spacing.s4, 0]}>
            <img alt="sync red-blue label" src={Rules1} width="100%" height="100%" />
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              Note that for items to continue syncing, the rules you choose need to be met at all times. As soon as the
              rules no longer apply, your work item will no longer be in sync with Unito.
            </Typography>
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              In our example above, if an item has a red label and gets synced via Unito, we want to make sure not to
              remove this red label, otherwise this item and its duplicate in your other tool will stop syncing. In that
              case, changes in one tool will no longer be reflected in the other.
            </Typography>
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s4, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              This condition: “Creation date on or after xx/xx/xxxx" means Unito will only sync items created from today
              onwards, after you’ve created the flow. This is a safeguard so you can test out your flow and make sure it
              does what you expect it to do before you sync all your historical work items.
            </Typography>
          </Box>
          <Box m={[0, 0, tokens.spacing.s3, 0]}>
            <img alt="Unito date safeguard" src={Rules2} width="100%" height="100%" />
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s5, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              Once you're sure your flow behaves the way you want it to, you can remove this rule by selecting the trash
              can next to it. Doing so will allow your flow to sync older items that meet the rules you've created.
            </Typography>
          </Box>
          <Box fullWidth>
            <ResourceBlock trackEvent={trackEvent} />
          </Box>
        </Box>
      </Box>
    </SidePanel>
  );
};

RuleSidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  linkId: PropTypes.string.isRequired,
};

const RuleSidePanelWithTracking = (props) => (
  <TrackingFunnel contextName={trackingTypes.FUNNELS.SIDE_PANEL}>
    <RuleSidePanel {...props} />
  </TrackingFunnel>
);

export { RuleSidePanelWithTracking as RuleSidePanel };
