import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import { Route } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Href } from 'components';
import { UnderlinedNav } from './UnderlinedNav';
import { NavTabItem } from './NavTabItem';

const Sticky = styled.ul`
  background: white;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 3;
`;

export class RoutedNavTabs extends Component {
  static propTypes = {
    callToAction: PropTypes.node,
    className: PropTypes.string,
    isJustified: PropTypes.bool,
    isSticky: PropTypes.bool,
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        activeOnPaths: PropTypes.arrayOf(PropTypes.string),
        tab: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        tabKey: PropTypes.string,
        onClick: PropTypes.func,
        exact: PropTypes.bool,
      }),
    ).isRequired,
    tabStyle: PropTypes.oneOf(['tabs', 'pills', 'underline']),
  };

  static defaultProps = {
    callToAction: undefined,
    className: undefined,
    isJustified: false,
    isSticky: false,
    tabStyle: 'tabs',
  };

  getNavElement = () => {
    const { isSticky, tabStyle } = this.props;

    if (tabStyle === 'underline') {
      return UnderlinedNav;
    }

    if (isSticky) {
      return Sticky;
    }

    return 'ul';
  };

  render() {
    const { callToAction, className, isJustified, isSticky, tabStyle, routes } = this.props;

    const NavElement = this.getNavElement();
    const navTabsClasses = classnames(className, 'nav', `nav-${tabStyle}`, {
      'nav-justified': isJustified,
      'nav-sticky': isSticky,
      'nav-tabs': tabStyle === 'underline',
    });

    return (
      <NavElement className={navTabsClasses} $hideInactiveState={routes.length < 2}>
        {routes.map((route) => (
          <Route
            // tabKey is useful when route.tab is a component instead of a string. Lets us give component tabs
            // an identifier to use on the key field.
            key={route.tabKey || route.tab}
            exact={route.exact}
            path={route.activeOnPaths || route.path}
            // eslint-disable-next-line react/no-children-prop
            children={({ match }) => (
              <NavTabItem isActive={!!match} tabStyle={tabStyle}>
                <Href to={route.path} linkStyle="subtleLink" onClick={route.onClick}>
                  {route.tab}
                </Href>
              </NavTabItem>
            )}
          />
        ))}
        {callToAction && (
          <Box $m={[0, 0, 0, 'auto']} className="pull-right">
            {callToAction}
          </Box>
        )}
      </NavElement>
    );
  }
}
