import React from 'react';
import PropTypes from 'prop-types';

import { Button, Href, Title, Accordion, AccordionItem } from 'components';
import { trackingTypes, routes } from 'consts';

import emptyStateBoardSync from './trello_boardsync_anim.gif';

export const ProjectSyncPromotionalState = ({ trackEvent, organizationId }) => (
  <div>
    <div className="row">
      <div className="col-md-12">
        <Title style={{ marginBottom: '2rem' }} type="h2">
          Build workflows between teams and tools.
        </Title>
      </div>
    </div>
    <div className="row">
      <div className="col-md-7">
        <Button
          type="href"
          btnStyle="dark"
          to={routes.ABSOLUTE_PATHS.ADD_LINK}
          style={{ marginBottom: '2rem' }}
          onClick={() => trackEvent(trackingTypes.USAGE_EVENTS.ACTIONS.CREATE_SYNC)}
        >
          Create a flow
        </Button>
        <Accordion defaultItem={0}>
          <AccordionItem title="What is a flow?">
            Unito is a workflow solution that allows you to build connections between projects across several teams and
            tools. You can build a master board to track work across multiple team boards in Trello, or connect to other
            tools and update issues in Jira or customer service tickets in Zendesk.
          </AccordionItem>
          <AccordionItem title="How does it differ from Mirror?">
            <p>
              Essentially, Unito allows you to build workflows that span projects, teams, and tools. With Unito, you can
              build a single master board in Trello to track work across multiple team boards, or link your work in
              Trello to development issues in Jira or customer service tickets in Zendesk.
            </p>
            <p>
              And this is just the tip of the iceberg. The Mirror Power-Up, on the other hand, is designed specifically
              to work on individual Trello cards and directly on your Trello boards, making setting it up and using it
              simple and familiar for Trello users.
            </p>
          </AccordionItem>
          <AccordionItem title="How much does it cost?">
            Unito plans vary depending on usage, team size and features. You can find out more on
            <Href to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/pricing`}> our pricing page</Href>.
          </AccordionItem>
        </Accordion>
      </div>
      <div className="col-md-5">
        <img
          alt="empty-state-board-sync"
          src={emptyStateBoardSync}
          style={{ height: '12rem', width: '22rem', borderRadius: '0.25rem' }}
        />
      </div>
    </div>
  </div>
);

ProjectSyncPromotionalState.propTypes = {
  trackEvent: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
};
