import { Map } from 'immutable';

import { websocketTypes } from 'consts';

export const initialState = Map({
  socketReadyState: websocketTypes.WEBSOCKET_STATE.CLOSED,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case websocketTypes.WS_CONNECTED: {
      return state.set('socketReadyState', action.meta.socketReadyState);
    }

    case websocketTypes.WS_CONNECT: {
      return state.set('socketReadyState', action.meta.socketReadyState);
    }

    case websocketTypes.WS_DISCONNECTED: {
      return state.set('socketReadyState', action.meta.socketReadyState);
    }

    default:
      return state;
  }
};
