import { providerTypes, routes, appTypes } from 'consts';
import { fromLocalStorage } from 'utils';

export const getProviders = () => ({
  types: [
    providerTypes.GET_PROVIDERS_REQUEST,
    providerTypes.GET_PROVIDERS_SUCCESS,
    providerTypes.GET_PROVIDERS_FAILURE,
  ],
  url: routes.API_PATHS.PROVIDERS(fromLocalStorage.loadStateByKey(appTypes.LOCAL_STORAGE_ORGANIZATION_KEY)),
});

export const getProviderCapabilities = (providerName, providerIdentityId) => ({
  types: [
    providerTypes.GET_PROVIDER_CAPABILITIES_REQUEST,
    providerTypes.GET_PROVIDER_CAPABILITIES_SUCCESS,
    providerTypes.GET_PROVIDER_CAPABILITIES_FAILURE,
  ],
  url: routes.API_PATHS.PROVIDER_CAPABILITIES(providerName, providerIdentityId),
});

export const getProviderCapabilitiesForItem = (providerName, providerIdentityId, containerId, itemType, linkId) => ({
  types: [
    providerTypes.GET_PROVIDER_CAPABILITIES_FOR_ITEM_REQUEST,
    providerTypes.GET_PROVIDER_CAPABILITIES_FOR_ITEM_SUCCESS,
    providerTypes.GET_PROVIDER_CAPABILITIES_FOR_ITEM_FAILURE,
  ],
  url: routes.API_PATHS.PROVIDER_CAPABILITIES_FOR_ITEM(providerName, providerIdentityId, containerId, itemType, linkId),
  meta: {
    providerName,
    containerId,
    itemType,
  },
});

export const getProviderConnectUrl = (providerName, linkId) => ({
  types: [
    providerTypes.GET_PROVIDER_CONNECT_URL_REQUEST,
    providerTypes.GET_PROVIDER_CONNECT_URL_SUCCESS,
    providerTypes.GET_PROVIDER_CONNECT_URL_FAILURE,
  ],
  url: routes.API_PATHS.PROVIDER_CONNECT_URL(
    providerName,
    fromLocalStorage.loadStateByKey(appTypes.LOCAL_STORAGE_ORGANIZATION_KEY),
    linkId,
  ),
});
