import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getProviderByName,
  getProviderCapabilitiesById,
  getProviderSupportedItems,
  getCapabilitiesForItem,
} from 'reducers';
import { useGetContainers } from 'hooks';
import { capitalize } from 'utils';

import { useIsModernIntegration } from '../../containers/FlowBuilder/hooks/useIsModernIntegration';

const PLURALITY = {
  SINGULAR: 'singular',
  PLURAL: 'plural',
};

export const TERM_KEY = {
  TASK: 'task',
  CONTAINER: 'container',
};

const TEXT_TRANSFORM = {
  CAPITALIZE: 'capitalize',
  LOWERCASE: 'lowercase',
};

function applyTextTransform(text, textTransform, providerDisplayName) {
  let transformedText = text;
  switch (textTransform) {
    case TEXT_TRANSFORM.CAPITALIZE:
      transformedText = capitalize(text);
      break;
    case TEXT_TRANSFORM.LOWERCASE:
      transformedText = text.toLowerCase();
      break;
    default:
      transformedText = text;
  }

  return providerDisplayName ? `${providerDisplayName} ${transformedText}` : transformedText;
}

export const ProviderTermsByName = ({
  providerNameA,
  providerNameB,
  plurality = PLURALITY.SINGULAR,
  termKey,
  textTransform = TEXT_TRANSFORM.LOWERCASE,
  middleWord = 'and',
  pcdv3 = true,
  itemTypeA,
  containerTypeA,
  itemTypeB,
  containerTypeB,
  fallbackTerm,
  showProviderName = false,
}) => {
  const { linkId } = useParams();
  const [containerA, containerB] = useGetContainers(linkId);

  const providerA = useSelector((state) => getProviderByName(state, providerNameA));
  const providerB = useSelector((state) => getProviderByName(state, providerNameB));

  const isProviderAModernIntegration = useIsModernIntegration(providerA);
  const isProviderBModernIntegration = useIsModernIntegration(providerB);

  const termsProviderA = useSelector((state) => getProviderCapabilitiesById(state, providerA.get('_id'), 'terms'));
  const termsProviderB = useSelector((state) => getProviderCapabilitiesById(state, providerB.get('_id'), 'terms'));

  let termProviderA = termsProviderA.getIn([termKey, plurality]);
  let termProviderB = termsProviderB.getIn([termKey, plurality]);

  const providerAItems = useSelector((state) => getProviderSupportedItems(state, providerA.get('_id')));
  const providerBItems = useSelector((state) => getProviderSupportedItems(state, providerB.get('_id')));

  const providerAItemsCapabilities = useSelector((state) =>
    getCapabilitiesForItem(state, providerA?.get('_id'), containerA?.get('id'), itemTypeA),
  );

  const providerBItemsCapabilities = useSelector((state) =>
    getCapabilitiesForItem(state, providerB?.get('_id'), containerB?.get('id'), itemTypeB),
  );

  if (pcdv3) {
    const containerTermRequested = termKey === TERM_KEY.CONTAINER;

    if (containerTermRequested) {
      if (providerAItems && itemTypeA && containerTypeA) {
        termProviderA = providerAItems.getIn([itemTypeA, 'containers', containerTypeA, 'names', plurality]);
      }
      if (providerBItems && itemTypeB && containerTypeB) {
        termProviderB = providerBItems.getIn([itemTypeB, 'containers', containerTypeB, 'names', plurality]);
      }
    } else {
      if (providerAItems && itemTypeA) {
        if (isProviderAModernIntegration) {
          termProviderA = providerAItemsCapabilities.getIn(['names', plurality]);
        } else {
          termProviderA = providerAItems.getIn([itemTypeA, 'item', 'names', plurality]);
        }
      }
      if (providerBItems && itemTypeB) {
        if (isProviderBModernIntegration) {
          termProviderB = providerBItemsCapabilities.getIn(['names', plurality]);
        } else {
          termProviderB = providerBItems.getIn([itemTypeB, 'item', 'names', plurality]);
        }
      }
    }
  }

  if (!termProviderA && !termProviderB) {
    if (fallbackTerm) {
      return applyTextTransform(fallbackTerm, textTransform);
    }
    return applyTextTransform(termKey, textTransform);
  }

  if (termProviderA && !termProviderB) {
    return applyTextTransform(termProviderA, textTransform, showProviderName ? providerA.get('displayName') : null);
  }

  if (termProviderB && !termProviderA) {
    return applyTextTransform(termProviderB, textTransform, showProviderName ? providerB.get('displayName') : null);
  }

  if (termProviderA === termProviderB) {
    return applyTextTransform(termProviderA, textTransform, showProviderName ? providerA.get('displayName') : null);
  }

  return `${applyTextTransform(termProviderA, textTransform, showProviderName ? providerA.get('displayName') : null)} ${middleWord} ${applyTextTransform(termProviderB, textTransform, showProviderName ? providerB.get('displayName') : null)}`;
};

ProviderTermsByName.propTypes = {
  providerNameA: PropTypes.string,
  providerNameB: PropTypes.string,
  middleWord: PropTypes.string,
  plurality: PropTypes.oneOf(Object.values(PLURALITY)),
  termKey: PropTypes.string.isRequired,
  textTransform: PropTypes.oneOf(Object.values(TEXT_TRANSFORM)),
  // TODO refactor component to remove pcdv3 property if this can be done salefy in legacy components
  pcdv3: PropTypes.bool,
  showProviderName: PropTypes.bool,
  itemTypeA: PropTypes.string,
  containerTypeA: PropTypes.string,
  itemTypeB: PropTypes.string,
  containerTypeB: PropTypes.string,
  fallbackTerm: PropTypes.string,
};
