import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';

import { Box, tokens } from '@unitoio/mosaic';

import { routes, trackingTypes, appTypes } from 'consts';
import { appActions } from 'actions';
import { useTrackEvent } from 'hooks';
import { TrackingFunnel } from 'containers';
import { ExportAndSyncErrorFunnel } from './components/ExportAndSyncErrorFunnel';
import * as formUtils from '../FlowBuilder/utils/form';
import { ConnectTools } from './pages/ConnectTools';
import { AddOnSetup } from './pages/AddOnSetup';
import { SetupCompleted } from './pages/SetupCompleted';
import { OneClickExplanation } from './pages/OneClickExplanation';
import { WarningModal } from './components/WarningModal';

export const OneClickExport = ({ match, defaultValues = {} }) => {
  const [loadingState, setLoadingState] = useState(formUtils.loadingStates.INITIAL);
  const [draft, setDraft] = useState();
  // defaultValues for test purposes
  const formMethods = useForm({ defaultValues });
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    dispatch(appActions.setProductNameContext(appTypes.PRODUCT_NAMES.REPORT_SYNC));
    trackEvent(trackingTypes.EVENT_NAME.START);
  }, [dispatch, trackEvent]);

  return (
    <FormProvider {...formMethods}>
      <WarningModal />
      <Box flexDirection="row" justifyContent="space-evenly">
        <Box m={[tokens.spacing.s7, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
          <Switch>
            <Redirect
              exact
              from={`${routes.ABSOLUTE_PATHS.CREATE_ONE_CLICK_EXPORT}`}
              to={`${routes.ABSOLUTE_PATHS.CREATE_ONE_CLICK_EXPORT}/${routes.ONE_CLICK_EXPORT_PAGES.START_EXPORT}`}
            />
            <Redirect
              exact
              from={`/embed/:embedName${routes.ABSOLUTE_PATHS.CREATE_ONE_CLICK_EXPORT}`}
              to={`/embed/:embedName${routes.ABSOLUTE_PATHS.CREATE_ONE_CLICK_EXPORT}/${routes.ONE_CLICK_EXPORT_PAGES.START_EXPORT}${search}`}
            />
            <Route
              path={`${match.path}/${routes.ONE_CLICK_EXPORT_PAGES.START_EXPORT}`}
              render={() => (
                <OneClickExplanation
                  loadingState={loadingState}
                  onComplete={() => {
                    history.push(`${match.url}/${routes.ONE_CLICK_EXPORT_PAGES.CONNECT_TOOLS}${search}`);
                  }}
                />
              )}
            />
            <Route
              path={`${match.path}/${routes.ONE_CLICK_EXPORT_PAGES.CONNECT_TOOLS}`}
              render={() => (
                <TrackingFunnel contextName={trackingTypes.MODULE.TOOL_SELECTION}>
                  <ExportAndSyncErrorFunnel pageName={trackingTypes.MODULE.TOOL_SELECTION}>
                    <ConnectTools
                      setDraft={setDraft}
                      loadingState={loadingState}
                      setLoadingState={setLoadingState}
                      onComplete={() => {
                        history.push(`${match.url}/${routes.ONE_CLICK_EXPORT_PAGES.ADD_ON_SETUP}`);
                      }}
                    />
                  </ExportAndSyncErrorFunnel>
                </TrackingFunnel>
              )}
            />
            <Route
              path={`${match.path}/${routes.ONE_CLICK_EXPORT_PAGES.ADD_ON_SETUP}`}
              render={() => (
                <AddOnSetup
                  draft={draft}
                  loadingState={loadingState}
                  setLoadingState={setLoadingState}
                  onComplete={() => {
                    history.push(`${match.url}/${routes.ONE_CLICK_EXPORT_PAGES.SETUP_COMPLETED}`);
                  }}
                />
              )}
            />
            <Route
              path={`${match.path}/${routes.ONE_CLICK_EXPORT_PAGES.SETUP_COMPLETED}`}
              render={() => (
                <SetupCompleted draft={draft} loadingState={loadingState} setLoadingState={setLoadingState} />
              )}
            />
          </Switch>
        </Box>
      </Box>
    </FormProvider>
  );
};

OneClickExport.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  // for test purposes only
  defaultValues: PropTypes.shape({}),
};
