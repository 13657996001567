import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';

import { Modal, Href } from 'components';
import { routes } from 'consts';
import { getLinkById } from 'reducers';

export const RemoveFlowModal = ({ isOpen = true, isDeletable, workflowNames, onConfirm, onCancel, linkId }) => {
  const link = useSelector((state) => getLinkById(state, linkId));
  const sideA = link.get('A', Map());
  const sideB = link.get('B', Map());
  const containerA = sideA.getIn(['container', 'displayName'], null);
  const containerB = sideB.getIn(['container', 'displayName'], null);

  const modalContent = {
    deleteFlow: {
      title: 'Delete flow from this workflow?',
      intro: (
        <>
          will be <strong>removed from this workflow.</strong>
        </>
      ),
      getItems: () => [
        <>
          Work items will stop syncing between <strong>{containerA}</strong> and <strong>{containerB}</strong>
        </>,
        <>
          Synced work <strong>won’t be reverted, changed or deleted</strong>
        </>,
      ],
    },
    removeFlow: {
      title: 'Remove flow from this workflow?',
      intro:
        "will be removed from this workflow's layout but will remain active since it exists outside of this workflow.",
      getItems: () => [
        <>This flow will remain active in the workflow: {workflowNames}</>,
        <>
          Works items <strong>will keep syncing</strong> between {containerA} and {containerB}
        </>,
      ],
      outro: (
        <p>
          To delete this flow permanently, do so from from <Href to={routes.ABSOLUTE_PATHS.SYNCS}>flows list.</Href>
        </p>
      ),
    },
  };

  const { title, intro, getItems, outro } = isDeletable ? modalContent.deleteFlow : modalContent.removeFlow;

  return (
    <Modal
      danger
      confirmLabel={`Confirm ${isDeletable ? 'delete' : 'removal'}`}
      displayCloseButton
      isOpen={isOpen}
      size="lg"
      onCancel={onCancel}
      onConfirm={onConfirm}
      onRequestClose={onCancel}
      title={title}
    >
      The flow{' '}
      <strong>
        {containerA} - {containerB}
      </strong>{' '}
      {intro}
      <ul>
        {getItems().map((item, idx) => (
          // using idx here is fine, this data is static and the entries are always different
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${item}-${idx}`}>{item}</li>
        ))}
      </ul>
      {outro}
    </Modal>
  );
};

RemoveFlowModal.propTypes = {
  isOpen: PropTypes.bool,
  isDeletable: PropTypes.bool.isRequired,
  workflowNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  linkId: PropTypes.string.isRequired,
};
