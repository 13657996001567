import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NewAlert as Alert, NewAlertLevel, tokens } from '@unitoio/mosaic';

import { featureTypes } from 'consts';
import { useGetFeatureLimit } from '../../../../../hooks/useGetFeatureLimit';
import { useIsOverFieldMappingsLimit } from '../../../hooks/useIsOverFieldMappingsLimit';
import { IncompleteItemFieldAssociationsAlert } from '../../IncompleteItemFieldAssociationsAlert';
import { IncompleteFieldMappingGroupAlert } from './IncompleteFieldMappingGroupAlert';
import { MissingMergeSettingsAlert } from './MissingMergeSettingsAlert';
import { MandatoryFieldsAlertWrapper } from './MandatoryFieldsAlerts';

const InlineDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    margin: ${tokens.spacing.s2} 0;

    &:last-child {
      margin-bottom: ${tokens.spacing.s3};
    }
  }
`;

export const MappedFieldsAlerts = ({ linkId }) => {
  const limit = useGetFeatureLimit(featureTypes.FEATURES.MAPPED_FIELDS);
  const isFieldMappingLimitReached = useIsOverFieldMappingsLimit(linkId);

  return (
    <InlineDiv data-testid="mapped-fields-alerts">
      <MandatoryFieldsAlertWrapper />

      <MissingMergeSettingsAlert />

      <IncompleteFieldMappingGroupAlert />

      <IncompleteItemFieldAssociationsAlert />

      {isFieldMappingLimitReached && (
        <Alert
          level={NewAlertLevel.INFO}
          message={
            <>
              Based on your plan, you are limited to <b>{limit} mapped fields per flow</b> (excluding workflow mappings
              such as List, Status, Section & Column). In order to map a different field, you’ll first need to remove or
              edit one of the mappings below.
            </>
          }
        />
      )}
    </InlineDiv>
  );
};

MappedFieldsAlerts.propTypes = {
  linkId: PropTypes.string.isRequired,
};
