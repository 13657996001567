import PropTypes from 'prop-types';
import styled from 'styled-components';

import { containerTypes } from 'consts';
import { color, borderRadius } from 'theme';

const STATES_BORDER_COLORS = {
  [containerTypes.CONTAINER_STATUSES.DEGRADED]: color.content.message.destructive,
  [containerTypes.CONTAINER_STATUSES.WARNING]: color.content.message.warning,
  hovered: color.content.secondary.default,
  [containerTypes.CONTAINER_STATUSES.DEFAULT]: color.content.neutral.border,
  [containerTypes.CONTAINER_STATUSES.PLACEHOLDER]: color.content.neutral.border,
  [containerTypes.CONTAINER_STATUSES.NEW]: color.content.secondary.default,
  [containerTypes.CONTAINER_STATUSES.LOADING]: color.content.secondary.disabled,
};

const STATES_CURSORS = {
  [containerTypes.CONTAINER_STATUSES.NEW]: 'none',
  [containerTypes.CONTAINER_STATUSES.LOADING]: 'cursor',
};

export const WorkBlockStyled = styled.div`
  align-items: center;
  background: ${color.content.neutral.white};
  border-radius: ${borderRadius.large};
  border-width: 2px;
  border-style: ${(props) => (props.$containerStatus === containerTypes.CONTAINER_STATUSES.NEW ? 'dashed' : 'solid')};
  border-color: ${(props) => STATES_BORDER_COLORS[props.$containerStatus] || STATES_BORDER_COLORS.default};
  box-sizing: border-box;
  cursor: ${(props) => STATES_CURSORS[props.$containerStatus]};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  text-align: center;
  outline: none;
`;

WorkBlockStyled.propTypes = {
  $containerStatus: PropTypes.oneOf(Object.keys(STATES_BORDER_COLORS)),
};
