import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box } from 'components';
import { color, borderRadius, fontSize } from 'theme';

const TipContainer = styled.div`
  display: flex;
  border: 1px solid ${color.content.neutral.border};
  border-radius: ${borderRadius.large};
  align-content: center;
`;

const IconContainer = styled(Box)`
  display: flex;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  border-radius: ${borderRadius.large} 0px 0px ${borderRadius.large};
`;

const ContentContainer = styled(Box)`
  display: inline-flex;
  align-items: center;
  font-size: ${fontSize.small};
`;

export const Tip = ({ icon, children }) => (
  <TipContainer>
    {icon && <IconContainer $p={[1, 2.5]}>{icon}</IconContainer>}
    <ContentContainer p={[1, 1]}>
      <div>{children}</div>
    </ContentContainer>
  </TipContainer>
);

Tip.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
};
