import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import Color from 'color';

import { Href } from 'components';
import { color as themeColor, borderRadius, fontWeight, fontSize } from 'theme';

const paddingButton = {
  xs: '0.25rem 1rem',
  sm: '0.5rem 1rem',
  md: '0.625rem 1.25rem',
  lg: '1rem',
};

const TEXT_ALIGN = {
  CENTER: 'center',
  RIGHT: 'right',
  LEFT: 'left',
};

const StyledButton = styled.button.attrs((props) => {
  if (props.$textalign === TEXT_ALIGN.CENTER) {
    return {
      ...props,
      $padding: props.$nopadding ? 0 : paddingButton[props.$btnsize],
    };
  }

  const [paddingTopBottom] = paddingButton[props.$btnsize].split(' ');
  return {
    ...props,
    $padding: [paddingTopBottom, '1rem'].join(' '),
  };
})`
  background-color: ${(props) => props.$maincolor};
  border-color: ${(props) => props.$maincolor};
  border-radius: ${(props) => props.$borderRadius};
  border-style: solid;
  border-width: ${(props) => (props.$nopadding ? 0 : '2px')};
  font-size: ${(props) => fontSize.button[props.$btnsize]};
  font-weight: ${fontWeight.regular};
  line-height: 1rem;
  padding: ${(props) => props.$padding};
  text-align: ${(props) => props.$textalign};
  text-decoration: none;
  text-transform: none;
  transition:
    opacity 0.1s ease-in-out,
    background-color 0.1s ease-in-out;

  && {
    color: ${themeColor.light.primary};
    opacity: ${(props) => (props.$disabled ? '0.4' : '1')};
  }

  &[disabled]:active {
    color: ${themeColor.light.primary};
  }

  &[disabled]:hover {
    color: ${themeColor.light.primary};
  }

  &:not([disabled]):active {
    outline: none;
    border-color: ${(props) => props.$hovercolor};
    background-color: ${(props) => props.$hovercolor};
    color: ${themeColor.light.primary};
    opacity: ${(props) => (props.$disabled ? '0.4' : '1')};
  }

  &:not([disabled]):hover {
    border-color: ${(props) => props.$hovercolor};
    background-color: ${(props) => props.$hovercolor};
    color: ${themeColor.light.primary};
    opacity: ${(props) => (props.$disabled ? '0.4' : '1')};
  }
`;

const ReverseButton = styled(StyledButton)`
  background-color: ${(props) => (props.$transparent ? 'transparent' : themeColor.light.primary)};

  && {
    color: ${(props) => props.$maincolor};
  }

  &[disabled]:active,
  &[disabled]:hover {
    color: ${(props) => props.$maincolor};
  }
`;

const LinkButton = styled(ReverseButton)`
  border-color: transparent;
  background: transparent;
  vertical-align: initial;

  && {
    &:not([disabled]):focus,
    &:not([disabled]):active,
    &:not([disabled]):hover {
      background: transparent;
      outline: 0;
      border-color: ${(props) => (props.$btnstyle === 'linkHoverWithBorder' ? props.$maincolor : 'transparent')};
      box-shadow: none;
      color: ${(props) => props.$hovercolor};
      text-decoration: underline;
    }
  }
`;

const SubtleLinkButton = styled(LinkButton)`
  &:not([disabled]):active,
  &:not([disabled]):hover {
    color: ${(props) => props.$maincolor};
  }
`;

export class Button extends Component {
  static propTypes = {
    active: PropTypes.bool,
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
    block: PropTypes.bool,
    btnStyle: PropTypes.oneOf([...Object.keys(themeColor.brand), 'linkHoverWithBorder', 'subtleLink']),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    dropdown: PropTypes.element,
    noPadding: PropTypes.bool,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    pullLeft: PropTypes.bool,
    pullRight: PropTypes.bool,
    regularCase: PropTypes.bool,
    reverse: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    textAlign: PropTypes.oneOf(['center', 'left', 'right']),
    transparent: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit', 'reset', 'menu', 'href']),
  };

  static defaultProps = {
    active: false,
    backgroundColor: undefined,
    borderRadius: borderRadius.default,
    block: false,
    btnStyle: 'primary',
    color: undefined,
    className: '',
    disabled: false,
    dropdown: undefined,
    noPadding: false,
    onBlur: undefined,
    onClick: () => null,
    onFocus: undefined,
    onMouseOut: undefined,
    onMouseOver: undefined,
    pullLeft: false,
    pullRight: false,
    regularCase: false,
    reverse: false,
    size: 'md',
    textAlign: 'center',
    transparent: false,
    type: 'button',
  };

  getButton = () => {
    const { btnStyle, reverse } = this.props;
    let ButtonComponent;

    if (reverse) {
      ButtonComponent = ReverseButton;
    } else if (['link', 'linkHoverWithBorder'].includes(btnStyle)) {
      ButtonComponent = LinkButton;
    } else if (btnStyle === 'subtleLink') {
      ButtonComponent = SubtleLinkButton;
    } else {
      ButtonComponent = StyledButton;
    }

    return ButtonComponent;
  };

  render() {
    const {
      active,
      backgroundColor,
      block,
      btnStyle,
      borderRadius: borderRadiusProp,
      children,
      className,
      color,
      disabled,
      dropdown,
      noPadding,
      onBlur,
      onClick,
      onFocus,
      onMouseOut,
      onMouseOver,
      pullLeft,
      pullRight,
      regularCase,
      reverse,
      textAlign,
      transparent,
      size,
      type,
      ...rest
    } = this.props;

    const buttonClasses = classnames(
      'btn',
      {
        [`btn-${size}`]: !!size,
        'btn-block': block,
        'btn-regular-case': regularCase,
        active,
        disabled,
      },
      className,
    );
    const caretClasses = classnames(buttonClasses, 'dropdown-toggle');

    const ButtonComponent = this.getButton();
    const mainColor = backgroundColor || color || themeColor.brand[btnStyle];
    const hoverColor = Color(mainColor).darken(0.5).string();
    const sharedProps = {
      $btnsize: size,
      $btnstyle: btnStyle,
      $hovercolor: hoverColor,
      $maincolor: mainColor,
      $textalign: textAlign,
    };

    if (!dropdown) {
      return (
        <ButtonComponent
          {...rest}
          {...sharedProps}
          as={type === 'href' && Href}
          className={classnames(buttonClasses, { 'pull-right': pullRight, 'pull-left': pullLeft })}
          $nopadding={noPadding}
          $disabled={disabled}
          $linkStyle={type === 'href' && btnStyle}
          $borderRadius={borderRadiusProp}
          onBlur={onBlur || onMouseOut}
          onClick={onClick}
          onFocus={onFocus || onMouseOver}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          $transparent={transparent}
          type={type}
        >
          {children}
        </ButtonComponent>
      );
    }

    const caret = (
      <ButtonComponent
        {...rest}
        {...sharedProps}
        as="a"
        $disabled={disabled}
        style={{ borderLeft: `1px solid ${hoverColor}`, paddingLeft: '12px', paddingRight: '12px' }}
        className={caretClasses}
        $nopadding={noPadding}
        $borderRadius={borderRadiusProp}
      >
        <span className="caret" />
        <span className="sr-only">Toggle Dropdown</span>
      </ButtonComponent>
    );

    return (
      <div className={classnames('btn-group', { 'pull-right': pullRight, 'pull-left': pullLeft })}>
        <ButtonComponent
          {...rest}
          {...sharedProps}
          as={type === 'href' && Href}
          className={buttonClasses}
          $disabled={disabled}
          $borderRadius={borderRadiusProp}
          $linkStyle={type === 'href' && btnStyle}
          onBlur={onBlur || onMouseOut}
          onClick={onClick}
          onFocus={onFocus || onMouseOver}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          type={type}
        >
          {children}
        </ButtonComponent>
        {React.cloneElement(dropdown, { button: caret })}
      </div>
    );
  }
}
