import { useEffect } from 'react';

import { trackingTypes } from 'consts';

import { useTrackEvent } from './useTrackEvent';

export function useTrackStartEvent() {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(trackingTypes.START);
  }, [trackEvent]);
}
