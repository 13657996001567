import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { ErrorBoundary } from '@unitoio/sherlock';

import { authActions } from 'actions';
import { Title, Footer, Href } from 'components';
import { routes, trackingTypes } from 'consts';
import { AppError, AuthorizeProviderButton } from 'containers';
import { useLogger, useTrackEvent } from 'hooks';
import { getProviderByName } from 'reducers';

const Content = styled.div`
  max-width: 768px;
  padding-top: 112px;
`;

const Paragraph = styled.p`
  margin-bottom: 2rem;
`;

function useTrackStartEvent() {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(trackingTypes.START);
  }, [trackEvent]);
}

async function onAuthorizeSuccess(dispatch, history, data) {
  dispatch(authActions.setAuthEmbedData(data));
  await dispatch(authActions.rehydrateAuthState());

  history.replace(routes.ABSOLUTE_PATHS.DASHBOARD);
}

function EmbedLoginComponent({ history, match }) {
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch();
  const provider = useSelector((state) => getProviderByName(state, match.params.embedName));
  useTrackStartEvent();

  if (provider.isEmpty()) {
    return null;
  }

  const providerName = provider.get('displayName');

  return (
    <div className="embed-login-container">
      <Content className="container text-center">
        <Title>Welcome to Unito</Title>

        <Paragraph>
          Unito needs access to {providerName} to sync. <br />
          Authorize {providerName} to get started.
        </Paragraph>

        <AuthorizeProviderButton
          includeProviderIcon
          isEmbed
          isLogin
          onClick={() => trackEvent(trackingTypes.SUBMIT, { selected_tool_name: provider.get('name') })}
          onSuccess={(data) => onAuthorizeSuccess(dispatch, history, data)}
          providerId={provider.get('_id')}
          data-test="embed__btn--authorize-provider"
        >
          Authorize {providerName}
        </AuthorizeProviderButton>

        <Footer trackEvent={trackEvent}>
          By clicking <strong>Authorize {providerName}</strong>, you agree to Unito's{' '}
          <Href
            onClick={() =>
              trackEvent(trackingTypes.ACTION, {
                action_name: trackingTypes.AUTHENTICATION.ACTIONS.CLICKED_TERMS_OF_SERVICE,
              })
            }
            target="_blank"
            href="https://unito.io/terms/"
            title="Unito Terms of service"
          >
            Terms of service
          </Href>{' '}
          and{' '}
          <Href
            onClick={() =>
              trackEvent(trackingTypes.ACTION, {
                action_name: trackingTypes.AUTHENTICATION.ACTIONS.CLICKED_PRIVACY_POLICY,
              })
            }
            target="_blank"
            href="https://unito.io/privacy/"
            title="Unito Privacy Policy"
          >
            Privacy policy
          </Href>
          .
        </Footer>
      </Content>
    </div>
  );
}

function EmbedLoginContainerWithErrorBoundary(props) {
  const { reportException } = useLogger();
  return (
    <ErrorBoundary
      FallbackComponent={AppError}
      onError={(error, { componentStack }, errMessageContext) =>
        reportException(error, { ...errMessageContext, componentStack })
      }
    >
      <EmbedLoginComponent {...props} />
    </ErrorBoundary>
  );
}

EmbedLoginComponent.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ embedName: PropTypes.string.isRequired }).isRequired }).isRequired,
};

export const EmbedLoginContainer = withRouter(EmbedLoginContainerWithErrorBoundary);
