import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { tokens, Box, Typography, Button } from '@unitoio/mosaic';

import { Section, Href } from 'components';
import { useQueryParams } from 'hooks';
import { getProviderByName } from 'reducers';

import { AuthImageWrapper } from '../AuthImageWrapper';

import googleContactOAuthScreen from './googleContact_preOauth.svg';
import googleCalendarOAuthScreen from './googleCalendar_preOauth.svg';
import Illustration from './illustration.svg';

export function GoogleInstructionsStep1({ onCancel, match }) {
  const { windowOpenerId } = useQueryParams();
  const googlePreOAuthScreen = {
    googlecontacts: googleContactOAuthScreen,
    googlecalendar: googleCalendarOAuthScreen,
  };

  const provider = useSelector((state) => getProviderByName(state, match.params.providerName));
  const providerDisplayName = provider.get('displayName');

  return (
    <Section>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <Typography variant="body1" align="center">
          You must <b>check all the listed options on the next screen</b> in order for Unito to build your flow and sync
          information properly.
        </Typography>
      </Box>
      <AuthImageWrapper>
        <img
          src={googlePreOAuthScreen[match.params.providerName]}
          alt="Google authorization screen"
          width="555"
          height="50%"
        />
      </AuthImageWrapper>
      <Box
        p={[tokens.spacing.s4]}
        m={[tokens.spacing.s5, 0, 0, 0]}
        alignItems="center"
        backgroundColor={tokens.colors.background.neutral.grey}
        borderRadius={tokens.spacing.s3}
      >
        <Box m={[0, tokens.spacing.s4, 0, 0]}>
          <img src={Illustration} alt="hey-there" />
        </Box>
        <Typography variant="body1">
          Any questions regarding Unito's security policy? Read our article on{' '}
          <Href href="https://guide.unito.io/how-secure-is-unito" target="_blank" rel="noreferrer">
            how secure Unito is
          </Href>
          .
        </Typography>
      </Box>
      <Box m={[tokens.spacing.s6, 0, 0, 0]} justifyContent="flex-end">
        <Button variant="subtle" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          href={`#/${match.params.method}/${match.params.providerName}/configure/instructions/step2?windowOpenerId=${windowOpenerId}`}
          type="href"
          pullRight
        >
          Connect {providerDisplayName}
        </Button>
      </Box>
    </Section>
  );
}

GoogleInstructionsStep1.propTypes = {
  onCancel: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      method: PropTypes.string.isRequired,
      providerName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
