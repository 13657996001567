export const RUN_SCRIPT_REQUEST = 'RUN_SCRIPT_REQUEST';
export const RUN_SCRIPT_SUCCESS = 'RUN_SCRIPT_SUCCESS';
export const RUN_SCRIPT_FAILURE = 'RUN_SCRIPT_FAILURE';
export const GET_SCRIPTS_REQUEST = 'GET_SCRIPTS_REQUEST';
export const GET_SCRIPTS_SUCCESS = 'GET_SCRIPTS_SUCCESS';
export const GET_SCRIPTS_FAILURE = 'GET_SCRIPTS_FAILURE';
export const CLEAR_SCRIPT_OUTPUT = 'CLEAR_SCRIPT_OUTPUT';
export const GET_QUERY_PARAMETERS = 'GET_QUERY_PARAMETERS';
export const GET_IS_EMBED = 'GET_IS_EMBED';
export const UPDATE_EMBED_LOCALLY = 'UPDATE_EMBED_LOCALLY';
export const GET_APP_CONFIG_REQUEST = 'GET_APP_CONFIG_REQUEST';
export const GET_APP_CONFIG_SUCCESS = 'GET_APP_CONFIG_SUCCESS';
export const GET_APP_CONFIG_FAILURE = 'GET_APP_CONFIG_FAILURE';
export const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION';
export const SET_PRODUCT_NAME_CONTEXT = 'SET_PRODUCT_NAME_CONTEXT';
export const LOCAL_STORAGE_ORGANIZATION_KEY = 'selectedOrganizationId';

export const EMBED = {
  TRELLO: 'trello',
  WRIKE: 'wrike',
  JIRA_CLOUD: 'jiracloud',
  MONDAY: 'monday',
};

export const PRODUCT_NAMES = {
  PROJECT_SYNC: 'projectSync',
  TASK_SYNC: 'taskSync',
  REPORT_SYNC: 'reportSync',
};

export const PRODUCT_NAMES_PARAM = {
  PROJECT_SYNC: 'project-sync',
  TASK_SYNC: 'task-sync',
};

export const mapUrlParamToProductName = {
  [PRODUCT_NAMES_PARAM.PROJECT_SYNC]: PRODUCT_NAMES.PROJECT_SYNC,
  [PRODUCT_NAMES_PARAM.TASK_SYNC]: PRODUCT_NAMES.TASK_SYNC,
};
