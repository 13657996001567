import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { Map } from 'immutable';
import styled from 'styled-components';

import { Box, Button, tokens } from '@unitoio/mosaic';

import { fieldTypes, trackingTypes, linkTypes, routes } from 'consts';
import { FlowBuilderErrorContext } from 'contexts';
import { getFeatureFlagValue } from 'reducers';
import { otherSide as getOtherSide } from 'utils';
import { InlineLoading } from 'components';
import {
  useGetContainers,
  useGetCustomFields,
  useGetItemTypes,
  useGetProviderIdentities,
  useQueryParams,
  useTrackEvent,
} from 'hooks';

import { displayTypes } from '../components/PCDFieldsSelect';
import { useGetProvidersFields } from '../hooks/useGetProvidersFields';
import * as formUtils from '../utils/form';
import { PageContainer } from './PageContainer/PageContainer';
import { FlowContainerInfos } from '../components/FlowContainerInfos';
import { PageHeader } from '../components/PageHeader';
import { FieldAssociationsEmptyState } from '../components/FieldAssociationsEmptyState';
import { ProviderSpecificMappingHelp } from '../components/ProviderSpecificMappingHelp';
import { SyncStatusPageAlert } from '../components/SyncStatusPageAlert';
import { MappedFieldsLoading } from '../components/mapping/MappedFieldsLoading';
import { useHasAnomaliesOnPage, PAGES } from '../hooks/useGetAnomalies';
import { useGetProviderNames } from '../hooks/useGetProviderNames';
import { useAutoMapFieldValues } from '../hooks/useAutomapFieldValues';
import { useGetPCDFieldsSelectorOptions } from '../hooks/useGetPCDFieldsSelectorOptions';
import { MappingSidePanel } from './SidePanels/MappingSidePanel';
import { useWatchFieldArray } from '../hooks';
import { useCanPerformFieldMappingActions } from './Mapping/hooks/useCanPerformFieldMappingActions';
import { FieldAssociationItems } from '../components/FieldAssociationItems';
import { AutomappingError } from '../components/mapping/alerts/AutomappingError';
import { RestartMappingButton } from '../components/mapping/RestartMappingButton';
import { DuplicateBanner } from '../components/DuplicateBanner';
import { MappedFieldsAlerts } from '../components/mapping/alerts/MappedFieldsAlerts';
import { ChooseMappingStrategyModal } from '../components/mapping/ChooseMappingStrategyModal';
import { PageHeaderTitle } from './Mapping/PageHeaderTitle';
import { statuses as STATUSES } from '../../../hooks/useGetCustomFields';
import { useGetGenerateFieldAssociations } from '../hooks/useGetGenerateFieldAssociations';

const Divider = styled.hr`
  border-top: 1px solid ${tokens.colors.content.neutral.n10};
  margin: ${tokens.spacing.s5} 0;
`;

const OpenSidePanelLink = styled.span`
  color: ${tokens.colors.content.info.default};
  cursor: pointer;
`;

function shouldShowEmptyState(fieldAssociations, isGeneratingFieldAssociations) {
  if (isGeneratingFieldAssociations) {
    return false;
  }

  const partiallyCompleteFieldAssociations = fieldAssociations.filter(
    (fieldAssociation) => fieldAssociation.A?.field || fieldAssociation.B?.field,
  );

  return !partiallyCompleteFieldAssociations.length;
}

export const MappedFields = ({ match, loadingState: flowBuilderLoadingState, isDuplicating }) => {
  const { linkId } = match.params;
  const { sideAutopopulated } = useQueryParams();
  const deleteMultiMappingRestrictions = useSelector((state) =>
    getFeatureFlagValue(state, 'delete-multimapping-restriction'),
  );
  const currentLinkState = useWatch({ name: 'state' });
  const isDraftFlow = linkId && currentLinkState === linkTypes.LINK_STATES.DRAFT;

  const {
    watch,
    formState: { errors },
    handleSubmit,
  } = useFormContext();

  const syncDirection = watch('syncDirection');
  const { fields: fieldAssociations, append, remove } = useWatchFieldArray('associations');
  const [containerA, containerB] = useGetContainers(linkId);
  const [providerIdentityIdA, providerIdentityIdB] = useGetProviderIdentities(linkId);
  const [providerNameA, providerNameB] = useGetProviderNames(linkId);
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const [providerFieldsA, providerFieldsB] = useGetProvidersFields(linkId);

  const isDraftSavingInProgress = flowBuilderLoadingState === formUtils.loadingStates.SAVING;

  const fieldsByProvider = { A: providerFieldsA, B: providerFieldsB };

  const completeFieldAssociations = fieldAssociations.filter(
    (fieldAssociation) => fieldAssociation.A?.field && fieldAssociation.B?.field,
  );
  const [shouldShowModal, setShouldShowModal] = useState(completeFieldAssociations.length < 1);
  const [generateFieldAssociations, isGeneratingFieldAssociations, hasFieldAssociationsError] =
    useGetGenerateFieldAssociations();

  const customFieldsStatus = useGetCustomFields({
    containerIdA: containerA.get('id'),
    containerIdB: containerB.get('id'),
    providerIdentityIdA,
    providerIdentityIdB,
    itemTypeA,
    itemTypeB,
    getUncachedA: sideAutopopulated === 'A',
    getUncachedB: sideAutopopulated === 'B',
  });

  const [canRemoveAssociation, canAddMapping] = useCanPerformFieldMappingActions(fieldAssociations);
  const [hasSyncStatusErrors, hasSyncStatusWarnings] = useHasAnomaliesOnPage(linkId, PAGES.MAPPED_FIELDS);

  const hasCustomFieldsError = customFieldsStatus === STATUSES.ERROR_CF;

  const isLoading =
    isGeneratingFieldAssociations || [STATUSES.INITIAL, STATUSES.LOADING].includes(customFieldsStatus) || isDuplicating;

  const isAutomappingValuesStatus = useAutoMapFieldValues({
    containerIdA: containerA.get('id'),
    containerIdB: containerB.get('id'),
    providerIdentityIdA,
    providerIdentityIdB,
    providerNameA,
    providerNameB,
    linkId,
    isLoading,
    itemTypeA,
    itemTypeB,
  });

  const trackEvent = useTrackEvent();
  useEffect(() => {
    trackEvent(trackingTypes.START, {
      selected_tool_names: `${providerNameA}, ${providerNameB}`,
    });
  }, [trackEvent, providerNameA, providerNameB]);

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  const pageName = useContext(FlowBuilderErrorContext);

  const sideA = watch('A');
  const sideB = watch('B');

  const [, sortedOptionsSideA] = useGetPCDFieldsSelectorOptions(
    sideA.providerName,
    sideA.containerId,
    'A',
    sideA.itemType,
    displayTypes,
    displayTypes.MAPPING,
  );
  const pcdFieldsTotalA = sortedOptionsSideA.length;

  const [, sortedOptionsSideB] = useGetPCDFieldsSelectorOptions(
    sideB.providerName,
    sideB.containerId,
    'B',
    sideB.itemType,
    displayTypes,
    displayTypes.MAPPING,
  );
  const pcdFieldsTotalB = sortedOptionsSideB.length;

  const shouldConfirmButtonBeDisabled = fieldAssociations.length === 0 || isAutomappingValuesStatus || errors[pageName];

  const showEmptyState = shouldShowEmptyState(fieldAssociations, isGeneratingFieldAssociations);
  const isMapped = (fieldId, fieldKind, side, otherFieldId, otherFieldKind) => {
    if (!fieldId || !fieldKind || !side || fieldId === fieldTypes.DESCRIPTION_FOOTER) {
      return false;
    }

    // In the mapping page we mix fields, options and custom fields and here since only fields can be "multi-mappable"
    // we don't fetch custom fields and options so `fieldDefinition` could be undefined if the option happens to be an option or custom field.
    const fieldDefinition = fieldsByProvider[side].get(fieldId, Map());
    const isWorkflowField = fieldDefinition.get('semantic', '')?.startsWith(fieldTypes.SEMANTIC.WORKFLOW);
    const hasOtherSideBeenPicked = otherFieldId && otherFieldKind;
    const hasFieldBeenMapped = !!fieldAssociations.find(
      (fieldAssociation) => fieldAssociation[side]?.field === fieldId && fieldAssociation[side]?.kind === fieldKind,
    );

    if (!isWorkflowField) {
      return hasFieldBeenMapped;
    }

    const otherSide = getOtherSide(side);
    const associationsWithWorkflowField = fieldAssociations.filter((fieldAssociation) => {
      const sideDefinition = fieldsByProvider[side].get(fieldAssociation[side]?.field, Map());
      const otherSideDefinition = fieldsByProvider[otherSide].get(fieldAssociation[otherSide]?.field, Map());
      const isAssociationComplete = fieldAssociation[side]?.field && fieldAssociation[otherSide]?.field;

      return (
        isAssociationComplete &&
        (sideDefinition.get('semantic')?.startsWith(fieldTypes.SEMANTIC.WORKFLOW) ||
          otherSideDefinition.get('semantic')?.startsWith(fieldTypes.SEMANTIC.WORKFLOW))
      );
    });
    const hasReachedWorkflowFieldMappingLimit = !deleteMultiMappingRestrictions
      ? associationsWithWorkflowField.length >= fieldTypes.MULTIMAPPING.GROUP_LIMIT
      : false;

    const sameAssociationAlreadyExists = fieldAssociations.find(
      (fieldAssociation) =>
        fieldAssociation[side]?.field === fieldId &&
        fieldAssociation[side]?.kind === fieldKind &&
        fieldAssociation[otherSide]?.field === otherFieldId &&
        fieldAssociation[otherSide]?.kind === otherFieldKind,
    );

    return hasReachedWorkflowFieldMappingLimit || (hasOtherSideBeenPicked && sameAssociationAlreadyExists);
  };

  if (!providerIdentityIdA || !providerIdentityIdB) {
    return null;
  }

  const handleOnSubmitClick = async () => {
    trackEvent(trackingTypes.SUBMIT, {
      selected_tool_names: `${providerNameA}, ${providerNameB}`,
    });
    await handleSubmit({ redirectPage: routes.FLOW_BUILDER_PAGES.GUIDE });
  };

  return (
    <PageContainer>
      <PageHeader
        title={<PageHeaderTitle linkId={linkId} />}
        subtitle={
          <>
            Choose which fields you’d like to map, then decide how updates should occur: in a single direction or
            bidirectionally.{' '}
            <OpenSidePanelLink
              onClick={() => {
                setIsSidePanelOpen(true);
                trackEvent(trackingTypes.ACTION, {
                  action_name: trackingTypes.FLOW_BUILDER_SIDE_PANEL.ACTIONS.NEED_HELP,
                  selected_tool_name: `${providerNameA},${providerNameB}`,
                });
              }}
            >
              Need some help mapping your fields?
            </OpenSidePanelLink>
            <MappingSidePanel isOpen={isSidePanelOpen} onClose={() => setIsSidePanelOpen(false)} linkId={linkId} />
          </>
        }
      />
      <DuplicateBanner />

      {(hasSyncStatusErrors || hasSyncStatusWarnings) && (
        <SyncStatusPageAlert key="sync_status_mapped_fields" page={PAGES.MAPPED_FIELDS} />
      )}

      <ChooseMappingStrategyModal
        isOpen={shouldShowModal}
        onClose={() => setShouldShowModal(false)}
        generateFieldAssociations={generateFieldAssociations}
        hasCompleteFieldAssociations={completeFieldAssociations.length > 0}
      />

      <Box
        p={[tokens.spacing.s5]}
        borderRadius={tokens.spacing.s4}
        borderSize={1}
        m={[tokens.spacing.s6, 0, tokens.spacing.s4]}
      >
        <>
          <FlowContainerInfos
            isLoading={isGeneratingFieldAssociations}
            linkId={linkId}
            pcdFieldsTotalA={pcdFieldsTotalA}
            pcdFieldsTotalB={pcdFieldsTotalB}
            completeFieldAssociations={completeFieldAssociations}
          />

          {isLoading ? (
            <MappedFieldsLoading isGeneratingFieldAssociations={isGeneratingFieldAssociations} />
          ) : (
            <>
              <Divider />
              <Box flexDirection={Box.flexDirection.COLUMN} alignItems={Box.justifyContent.FLEX_END}>
                <AutomappingError
                  hasCustomFieldsError={hasCustomFieldsError}
                  hasFieldAssociationsError={hasFieldAssociationsError}
                />
                <RestartMappingButton
                  onRestartMapping={() => {
                    trackEvent(trackingTypes.ACTION, {
                      action_name: 'clicked start over to revisit automapping option',
                    });
                    setShouldShowModal(true);
                  }}
                />
              </Box>
              {showEmptyState ? (
                <FieldAssociationsEmptyState
                  shouldShowAlerts={!shouldShowModal}
                  containerA={containerA}
                  containerB={containerB}
                  fieldAssociations={fieldAssociations}
                  isEntityAlreadyMapped={isMapped}
                  itemTypeA={itemTypeA}
                  itemTypeB={itemTypeB}
                  providerIdentityIdA={providerIdentityIdA}
                  providerIdentityIdB={providerIdentityIdB}
                  providerNameA={providerNameA}
                  providerNameB={providerNameB}
                  isFieldAssociationDisabled={isDraftSavingInProgress}
                  syncDirection={syncDirection}
                />
              ) : (
                <>
                  <Box
                    flexDirection={Box.flexDirection.COLUMN}
                    alignItems={Box.flexDirection.FLEX_END}
                    m={[0, 0, tokens.spacing.s5, 0]}
                  >
                    <Button
                      disabled={!canAddMapping || isAutomappingValuesStatus || isDraftSavingInProgress}
                      onClick={() => {
                        append({
                          target: syncDirection ?? fieldTypes.TARGET.BOTH,
                          A: { field: null, fieldId: null, kind: null },
                          B: { field: null, fieldId: null, kind: null },
                        });
                      }}
                      block
                      startIcon={isDraftSavingInProgress ? '' : 'plus'}
                    >
                      {isDraftSavingInProgress ? <InlineLoading size="small" /> : 'Add mapping'}
                    </Button>
                  </Box>

                  {!shouldShowModal && <MappedFieldsAlerts linkId={linkId} />}

                  <FieldAssociationItems
                    remove={remove}
                    disabledDelete={!canRemoveAssociation || isAutomappingValuesStatus}
                    isAutomappingValuesStatus={isAutomappingValuesStatus}
                    fieldAssociations={fieldAssociations}
                    isLoading={isLoading}
                    isMapped={isMapped}
                    providerFieldsA={providerFieldsA}
                    providerFieldsB={providerFieldsB}
                    itemTypeA={itemTypeA}
                    itemTypeB={itemTypeB}
                    providerNameA={providerNameA}
                    providerNameB={providerNameB}
                    containerIdA={containerA.get('id')}
                    containerIdB={containerB.get('id')}
                    providerIdentityIdA={providerIdentityIdA}
                    providerIdentityIdB={providerIdentityIdB}
                  />
                </>
              )}
            </>
          )}
        </>
      </Box>
      <ProviderSpecificMappingHelp providerName={providerNameA} />
      {providerNameA !== providerNameB && <ProviderSpecificMappingHelp providerName={providerNameB} />}
      {isDraftFlow && !shouldShowModal && (
        <Box justifyContent="flex-end" m={[tokens.spacing.s7, 0, 0, 0]}>
          <Button
            type="submit"
            disabled={shouldConfirmButtonBeDisabled}
            loading={flowBuilderLoadingState === formUtils.loadingStates.SAVING}
            onClick={handleOnSubmitClick}
          >
            Confirm
          </Button>
        </Box>
      )}
    </PageContainer>
  );
};

MappedFields.propTypes = {
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ linkId: PropTypes.string }).isRequired,
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  isDuplicating: PropTypes.bool.isRequired,
};
