import { Map } from 'immutable';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Button, ProviderIcon, tokens } from '@unitoio/mosaic';

import { linkActions } from 'actions';
import { Card, Title, Href, TurnOffTestModeModal } from 'components';
import { fieldTypes, trackingTypes } from 'consts';
import { useTrackEvent } from 'hooks';
import { useGetTestModeTerm } from '../FlowBuilder/hooks/useGetTestModeTerm';

import ActivityStreamTestMode from './images/ActivityStreamTestMode.svg';

const TestModeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: ${(props) => (props.$withinTwoStep ? '70%' : '40%')};
`;

const LinkToProviderContainers = styled(Box)`
  display: flex;
  justify-content: center;
`;

const ImageWrapper = styled.img`
  height: ${tokens.spacing.s9};
`;

export function ActivityStreamEmptyStateTestMode({
  link,
  taskTerm,
  withinTwoStep = false,
  plurality = fieldTypes.PLURALITY.SINGULAR,
}) {
  const linkId = link.get('_id');
  const [isTurnOffTestModeModalOpen, setIsTurnOffTestModeModalOpen] = useState(false);
  const trackEvent = useTrackEvent();

  const dispatch = useDispatch();

  const testModeTerm = useGetTestModeTerm(linkId);

  const handleOnTurnOffTestMode = async () => {
    await dispatch(linkActions.turnOffTestMode(linkId));
    setIsTurnOffTestModeModalOpen(false);
  };

  function handleOnClickLinkToProvider() {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on create new tasks on one of the projects',
      action_taken_from: 'activity log troubleshooting messages',
    });
  }

  function handleOnClickIncludeOlderTasks() {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on include older tasks',
      action_taken_from: 'activity log troubleshooting messages',
    });
    setIsTurnOffTestModeModalOpen(true);
  }

  // trigger tracking only on 1st render
  useEffect(() => {
    trackEvent(trackingTypes.TROUBLESHOOT_MESSAGE_ACTION, {
      action_name: 'viewed troubleshoot messages',
      message: 'turn test mode off',
    });
  }, [trackEvent]);

  const LinkToProviderContainer = (side) => (
    <Href href={link.getIn([side, 'container', 'url'])} onClick={() => handleOnClickLinkToProvider()}>
      <Button variant="subtle">
        <Box flexWrap={Box.flexWrap.WRAP} alignItems={Box.alignItems.CENTER}>
          <Box p={[tokens.spacing.s0, tokens.spacing.s2, tokens.spacing.s0, tokens.spacing.s0]}>
            <ProviderIcon name={link.getIn([side, 'providerName'])} size="small" />{' '}
          </Box>
          {link.getIn([side, 'container', 'displayName'])}
        </Box>
      </Button>
    </Href>
  );

  return (
    <TestModeWrapper $withinTwoStep={withinTwoStep}>
      <ImageWrapper src={ActivityStreamTestMode} alt="" />
      <Title type="h3">
        Your flow will only sync {testModeTerm} {taskTerm}
      </Title>
      <Card borderless color={tokens.colors.background.neutral.grey} m={[0.5, 0]} p={[1, 1.5, 1.5, 1.5]}>
        Create{plurality === fieldTypes.PLURALITY.SINGULAR && ' a'} new {taskTerm} to see activity
        <LinkToProviderContainers m={[0.5, 0, 0, 0]}>
          <Box m={[0, 0.5, 0, 0]}>{LinkToProviderContainer('A')}</Box>
          <Box m={[0, 0, 0, 0.5]}>{LinkToProviderContainer('B')}</Box>
        </LinkToProviderContainers>
      </Card>
      {!withinTwoStep && (
        <>
          <Title type="h4">Or</Title>
          <Button variant="subtle" onClick={() => handleOnClickIncludeOlderTasks()}>
            Include older {taskTerm}
          </Button>
        </>
      )}
      <TurnOffTestModeModal
        isOpen={isTurnOffTestModeModalOpen}
        onSuccess={handleOnTurnOffTestMode}
        onRequestClose={() => {
          setIsTurnOffTestModeModalOpen(false);
        }}
        taskTerm={taskTerm}
        linkId={linkId}
      />
    </TestModeWrapper>
  );
}

ActivityStreamEmptyStateTestMode.propTypes = {
  link: PropTypes.instanceOf(Map).isRequired,
  taskTerm: PropTypes.element.isRequired,
  withinTwoStep: PropTypes.bool,
  plurality: PropTypes.string,
};
