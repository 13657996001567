import { useSelector } from 'react-redux';
import { List } from 'immutable';

import { containerTypes } from 'consts';
import { getContainerById } from 'reducers';

// This hook is not use to display the Connect Tool Extra Step.
// It actually tells the Flow creation step that the container needs extra actions from the user,
// blocking them from continuing in the Flow process.
export function useHasMissingExtraStepAction(providerItemExtraStep, providerName, providerIdentityId, containerId) {
  const selectedContainer = useSelector((state) => getContainerById(state, providerIdentityId, containerId));
  if (!providerItemExtraStep || !providerName || !providerIdentityId || !containerId) {
    return undefined;
  }

  const alerts = selectedContainer.get('requirements', List());
  return alerts.find(
    (alert) =>
      alert.get('context') === containerTypes.REQUIREMENT_CONTEXT_ADDON &&
      alert.get('type') === containerTypes.REQUIREMENT_CONTEXT_ERROR,
  );
}
