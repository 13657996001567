import { fieldTypes } from 'consts';

export const isDisabledOptionHandler = (field, groupedFields, isActionField = false) => {
  const disableFilterItems = field.has('fields') && !groupedFields[fieldTypes.DEEP_FILTER_ITEM]?.values.includes('all');
  if (disableFilterItems) {
    return { disabled: true };
  }

  const fieldId = field.get('fieldId') || field?.get('value');
  let nbPossibilities = 0;
  if (!isActionField && groupedFields[fieldId]?.canBeDenied) {
    nbPossibilities += 1;
  }
  if (groupedFields[fieldId]?.canBeAllowed) {
    nbPossibilities += 1;
  }
  return {
    disabled: !!(groupedFields[fieldId]?.nbRulesForField >= nbPossibilities),
  };
};
