import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { organizationActions } from 'actions';
import { useLogger } from 'hooks';

export function useGetOrgMetrics(organizationId) {
  const dispatch = useDispatch();
  const { reportWarning } = useLogger();
  const [isLoading, setIsLoading] = useState(true);
  const [hasFailed, setHasFailed] = useState(false);

  useEffect(() => {
    const getMetrics = async () => {
      try {
        await dispatch(organizationActions.getMetrics(organizationId, { displayError: false }));
      } catch (err) {
        reportWarning(err, {
          identifier: 'useGetOrgMetrics getMetrics failedGetMetrics ',
        });
        setHasFailed(true);
      } finally {
        setIsLoading(false);
      }
    };

    getMetrics();
  }, [dispatch, organizationId, reportWarning]);

  return { isLoading, hasFailed };
}
