import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import {
  Box,
  Bold,
  Button,
  Col,
  Grid,
  Link,
  Row,
  tokens,
  Typography,
  TypographyVariants,
  message,
} from '@unitoio/mosaic';

import { useTrackEvent } from 'hooks';
import { trackingTypes } from 'consts';
import { GOOGLE_ADD_ON } from '../../FlowBuilder/consts/flowBuilder';

import GoogleWorkspaceMarketplace from '../img/google_workspace_marketplace.png';
import { PageHeader } from '../components/PageHeader';
import { Steps } from '../components/Steps';
import { StepDot } from '../components/StepDot';

const ConnectGrid = styled(Grid)`
  width: 65vw;
  padding: ${tokens.spacing.s6} ${tokens.spacing.s6};
`;
const ActivableBox = styled(Box)`
  opacity: ${(props) => (props.$isBoxDisabled ? '0.3' : '1')};
`;
const InstallBox = styled(Box)`
  border-radius: ${tokens.spacing.s4};
  height: 150px;
  max-width: 350px;
  border-color: ${tokens.colors.content.neutral.n40};
`;
const StepTitle = styled(Typography)`
  padding-bottom: ${tokens.spacing.s3};
  padding-top: ${tokens.spacing.s3};
`;
const GoogleImage = styled.img`
  width: 110%;
`;

const AddonLink = styled(Link)`
  pointer-events: ${(props) => props.$isBoxDisabled && 'none'};
  margin: ${tokens.spacing.s0};
`;

const Extension = styled(Box)`
  pointer-events: ${(props) => props.$isBoxDisabled && 'none'};
  text-align: ${Box.alignItems.CENTER};
`;

export const AddOnSetup = ({ draft, onComplete }) => {
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch();
  const [isBoxDisabled, setIsBoxDisabled] = useState(true);
  const [installationStep, setInstallationStep] = useState(1);

  const marketplaceLink =
    process.env.REACT_APP_ENV === 'staging' ? GOOGLE_ADD_ON.STAGING_LINK : GOOGLE_ADD_ON.PRODUCTION_LINK;

  const selectedContainerBUrl = draft ? draft.link.B.container.url : undefined;

  useEffect(() => {
    if (selectedContainerBUrl) {
      message.success({
        content: 'New Google Sheet successfully created',
      });
    }
  }, [dispatch, selectedContainerBUrl]);

  const handleOnClickAddon = () => {
    trackEvent(trackingTypes.EVENT_NAME.ACTION, {
      action_name: 'clicked on install the unito add on here',
    });
    setIsBoxDisabled(false);
    setInstallationStep(2);
  };

  const handleOnClick = () => {
    trackEvent(trackingTypes.EVENT_NAME.SUBMIT);
    // opens a new tab with the new created sheet and redirects user to it
    // changes the current Unito route at the same time
    const containerWindow = window.open(selectedContainerBUrl, '_blank');
    if (containerWindow) {
      containerWindow.focus();
    }
    onComplete();
  };
  return (
    <>
      <Steps activeStep={2} totalStepCircles={2} />
      <Box>
        <PageHeader title="Your export is almost ready! 🚀" align="center" />
        <ConnectGrid>
          <Row nogutter>
            <Col md={6} lg={5}>
              <Box flexDirection={Box.flexDirection.ROW}>
                <StepDot shouldColorDot={installationStep > 1} stepId={1} activeStep={installationStep} />
                <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4]}>
                  <StepTitle variant={TypographyVariants.H3}>Install the Unito add-on</StepTitle>
                  <InstallBox
                    borderColor={tokens.colors.content.neutral.n10}
                    m={[tokens.spacing.s4, tokens.spacing.s0]}
                    p={[tokens.spacing.s6]}
                    alignItems={Box.alignItems.CENTER}
                  >
                    <Box flexDirection={Box.flexDirection.COLUMN} alignItems={Box.alignItems.CENTER}>
                      <GoogleImage src={GoogleWorkspaceMarketplace} />
                      <Box m={[tokens.spacing.s3, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
                        <Button
                          onClick={handleOnClickAddon}
                          onBlur={(e) => e.target.blur()}
                          target="_blank"
                          href={marketplaceLink}
                        >
                          Install
                        </Button>
                      </Box>
                    </Box>
                  </InstallBox>
                  <AddonLink onClick={handleOnClickAddon} href={marketplaceLink} isExternalLink>
                    Return to this tab after installing the Unito add-on here
                  </AddonLink>
                </Box>
              </Box>
            </Col>
            <Col lg={2} md={12} />
            <Col md={6} lg={5}>
              <ActivableBox $isBoxDisabled={isBoxDisabled} flexDirection={Box.flexDirection.ROW}>
                <StepDot shouldColorDot={installationStep > 2} stepId={2} activeStep={installationStep} />
                <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4]}>
                  <StepTitle variant={TypographyVariants.H3}>Activate your Google Sheet</StepTitle>
                  <InstallBox
                    borderColor={tokens.colors.content.neutral.n10}
                    p={[tokens.spacing.s6]}
                    m={[tokens.spacing.s4, tokens.spacing.s0]}
                    alignItems={Box.alignItems.CENTER}
                  >
                    <Box flexDirection={Box.flexDirection.COLUMN} alignItems={Box.alignItems.CENTER}>
                      <Extension
                        p={[tokens.spacing.s1, tokens.spacing.s4]}
                        m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s3, tokens.spacing.s0]}
                        backgroundColor={tokens.colors.background.neutral.grey}
                        borderRadius="1"
                        onClick={handleOnClick}
                        $isBoxDisabled={isBoxDisabled}
                      >
                        <Bold>Extensions</Bold>
                      </Extension>
                      <Extension
                        p={[tokens.spacing.s1, tokens.spacing.s4]}
                        backgroundColor={tokens.colors.background.message.positive}
                        borderRadius="1"
                        onClick={handleOnClick}
                        $isBoxDisabled={isBoxDisabled}
                      >
                        <Bold>Unito - Automatic Data Import & Record Syncing</Bold>
                      </Extension>
                    </Box>
                  </InstallBox>
                  <AddonLink onClick={handleOnClick} $isBoxDisabled={isBoxDisabled} isExternalLink>
                    Activate in Google Sheets via the Extensions menu
                  </AddonLink>
                </Box>
              </ActivableBox>
            </Col>
          </Row>
        </ConnectGrid>
      </Box>
    </>
  );
};

AddOnSetup.propTypes = {
  draft: PropTypes.objectOf.isRequired,
  onComplete: PropTypes.func.isRequired,
};
