import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { tokens, Button } from '@unitoio/mosaic';

import { Box, Card, ChatBubble, Title } from 'components';
import { trackingTypes } from 'consts';
import { useTrackEvent } from 'hooks';

import ActivityStreamReviewRulesImg from './images/ActivityStreamReviewRules.svg';

const ActivityStreamReviewRulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 60%;
`;

export function ActivityStreamReviewRules({ changePage }) {
  const trackEvent = useTrackEvent();

  const handleOnClickReviewRules = () => {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on review rules',
      action_taken_from: 'activity log troubleshooting messages',
    });
    changePage(2);
  };

  const handleOnClickChatWithUs = () => {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on chat with our team',
      action_taken_from: 'activity log troubleshooting messages',
    });
  };

  return (
    <ActivityStreamReviewRulesWrapper>
      <Box $m={[1, 0, 0, 0]}>
        <img src={ActivityStreamReviewRulesImg} alt="review sync rules" />
      </Box>
      <Box $m={[1, 0]}>
        <Title type="h3">No activity detected</Title>
      </Box>
      <Card color={tokens.colors.background.neutral.grey} borderless>
        <Box $m={[0, 0, 1, 0]}>
          We haven't found any changes that could be synced. You might need to review your rules.
        </Box>
        <Button onClick={handleOnClickReviewRules}>Review rules</Button>
      </Card>
      <Box $m={[1, 0]}>
        <b>Or</b>
      </Box>
      <div>
        <p>Contact us if you need help setting up your flow.</p>
        <ChatBubble type="default" onClick={handleOnClickChatWithUs}>
          Chat with our team
        </ChatBubble>
      </div>
    </ActivityStreamReviewRulesWrapper>
  );
}

ActivityStreamReviewRules.propTypes = {
  changePage: PropTypes.func.isRequired,
};
