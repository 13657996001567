import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { SidePanel as MimicSidePanel, notification } from '@unitoio/mosaic';

import { activityLogsActions, linkActions } from 'actions';
import { linkTypes, trackingTypes } from 'consts';
import { TrackingFunnel } from 'containers';
import { useInterval, useLogger, useTrackEvent } from 'hooks';
import { getFirstActivityLogsByItemId, getLinkSyncStatus } from 'reducers';

import { ActivityBlock } from './ActivityBlock';
import { ContactSupportBlock } from './ContactSupportBlock';
import { ItemCardBlock } from './ItemCardBlock';
import { ResourceBlock } from './RessourceBlock';

const labelTypes = new Map([
  [false, 'Success'],
  [true, 'Error'],
]);

function useGetSyncButtonState() {
  const { linkId } = useParams();
  const syncStatus = useSelector((state) => getLinkSyncStatus(state, linkId));

  const syncStatusActivity = syncStatus.get('activity');
  const { SYNCING, TRIGGERED } = linkTypes.LINK_ACTIVITY_STATUS;
  const isLinkSyncing = syncStatusActivity === SYNCING || syncStatusActivity === TRIGGERED;
  const buttonCopy = isLinkSyncing ? 'Sync in progress' : 'Sync this item';

  return [isLinkSyncing, buttonCopy];
}

function getSourceItemAndContainerId(firstActivityLog) {
  const sourceItemId = firstActivityLog.getIn(['sourceTask', 'id']);
  const sourceContainerId = firstActivityLog.getIn(['sourceContainer', 'id']);

  return [sourceItemId, sourceContainerId];
}

function useGetSyncLinkItem(itemId) {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const { linkId } = useParams();
  const { reportWarning } = useLogger();
  const firstActivityLog = useSelector((state) => getFirstActivityLogsByItemId(state, itemId));
  const [sourceItemId, sourceContainerId] = getSourceItemAndContainerId(firstActivityLog);

  return async () => {
    try {
      trackEvent(trackingTypes.ACTION, { action_name: 'clicked on sync this item' });
      await dispatch(linkActions.syncLinkItem({ itemId: sourceItemId, linkId, containerId: sourceContainerId }));
    } catch (error) {
      reportWarning(
        `Error while triggering sync for link ${linkId} for item ${sourceItemId} in container ${sourceContainerId}`,
        {
          error,
          identifier: 'ActivityLogs SidePanel ErrorSyncItem',
        },
      );
      notification.error({
        message: 'Error',
        description: 'An error occured while syncing this item. Please try again later or contact the support',
        placement: 'top',
        duration: 2,
      });
    }
  };
}

function useFetchActivityLogs(itemId) {
  const firstActivityLog = useSelector((state) => getFirstActivityLogsByItemId(state, itemId));
  const flowId = firstActivityLog.get('linkId');
  const flowOrganization = firstActivityLog.get('organizationId');
  const itemUrl = firstActivityLog.getIn(['targetTask', 'url']);

  const dispatch = useDispatch();
  const [activityLogs, setActivityLogs] = useState([]);
  const [isLoadingActivityLogs, setIsLoadingActivityLogs] = useState(true);
  const [hasError, setHasError] = useState(false);

  const { reportWarning } = useLogger();

  const fetchActivityLogs = useCallback(async () => {
    try {
      const response = await dispatch(
        activityLogsActions.fetchActivityLogs(
          flowOrganization,
          0,
          5,
          {
            generalSearch: `${flowId}, ${itemUrl}`,
          },
          [{ field: 'changeTime', direction: 'desc' }],
          null,
          true,
          false,
        ),
      );
      setActivityLogs(response.activityLogs);
    } catch (err) {
      setHasError(true);
      reportWarning(err, { identifier: 'activityLogError fetchActivityLogs ActivityBlock' });
    } finally {
      setIsLoadingActivityLogs(false);
    }
  }, [dispatch, flowId, flowOrganization, itemUrl, reportWarning]);

  useEffect(() => {
    fetchActivityLogs();
  }, [fetchActivityLogs]);

  return {
    activityLogs,
    isLoadingActivityLogs,
    hasError,
    fetchActivityLogs,
  };
}

function useTrackStartEvent() {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(trackingTypes.START);
  }, [trackEvent]);
}

function useGetItemLastUpdatedAt(itemId, activityLogs) {
  const firstActivityLog = useSelector((state) => getFirstActivityLogsByItemId(state, itemId));

  if (!activityLogs || !activityLogs.length) {
    return firstActivityLog.get('changeTime');
  }

  return activityLogs[0].changeTime;
}

const SidePanel = ({ isOpen, itemId, onClose }) => {
  useTrackStartEvent();

  const { activityLogs, isLoadingActivityLogs, hasError, fetchActivityLogs } = useFetchActivityLogs(itemId);

  useInterval(() => fetchActivityLogs(), hasError ? null : 5000);

  const [isLinkSyncing, syncButtonCopy] = useGetSyncButtonState();
  const syncLinkItem = useGetSyncLinkItem(itemId);
  const itemLastUpdatedAt = useGetItemLastUpdatedAt(itemId, activityLogs);

  return (
    <MimicSidePanel
      onClose={onClose}
      backButtonText="Close item details"
      isOpen={isOpen}
      CTAButtonText={syncButtonCopy}
      CTAButtonDisabled={isLinkSyncing}
      onClickCTAButton={syncLinkItem}
    >
      <ItemCardBlock itemId={itemId} itemLastUpdatedAt={itemLastUpdatedAt} />
      <ActivityBlock activityLogs={activityLogs} isLoadingActivityLogs={isLoadingActivityLogs} hasError={hasError} />
      <ResourceBlock itemId={itemId} />
      <ContactSupportBlock itemId={itemId} />
    </MimicSidePanel>
  );
};

const SidePanelPropTypes = {
  isOpen: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

SidePanel.propTypes = SidePanelPropTypes;

const SidePanelWithTracking = (props) => {
  const { itemId } = props;
  const firstActivityLog = useSelector((state) => getFirstActivityLogsByItemId(state, itemId));
  const targetConnectorName = firstActivityLog.get('targetConnectorName');
  const status = labelTypes.get(firstActivityLog.get('status'));

  return (
    <TrackingFunnel
      contextName={trackingTypes.FUNNELS.ITEM_DETAILS}
      sharedProperties={{
        selected_tool_name: targetConnectorName,
        status,
      }}
    >
      <SidePanel {...props} />
    </TrackingFunnel>
  );
};

SidePanelWithTracking.propTypes = SidePanelPropTypes;

export { SidePanelWithTracking as SidePanel };
