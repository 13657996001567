import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useRouteMatch, generatePath } from 'react-router-dom';

import { Shadow, tokens, Icon, NewTag, Box, Button, Tooltip, Typography, NewTagColor } from '@unitoio/mosaic';

import { routes } from 'consts';

const GuideStepStatusStyles = {
  default: {
    $background: null,
    $borderColor: tokens.colors.content.neutral.n10,
    color: tokens.colors.content.neutral.n10,
    elevation: 'none',
    $borderSize: '1px',
  },
  edit: {
    $background: null,
    $borderColor: tokens.colors.content.neutral.n10,
    color: tokens.colors.content.neutral.n10,
    elevation: 'none',
    $borderSize: '1px',
    $hoverBorderColor: tokens.colors.content.secondary.default,
  },
  current: {
    $background: null,
    $borderColor: tokens.colors.content.secondary.default,
    color: tokens.colors.content.neutral.n10,
    elevation: 'medium',
    $borderSize: '2px',
  },
  error: {
    $background: tokens.colors.background.message.destructive,
    $borderColor: tokens.colors.content.destructive.default,
    color: tokens.colors.content.destructive.default,
    elevation: 'none',
    $borderSize: '1px',
    tagVariant: NewTagColor.ERROR,
    tagLabel: 'Error',
    inverse: true,
  },
  done: {
    $background: tokens.colors.background.message.positive,
    $borderColor: tokens.colors.content.positive.default,
    color: tokens.colors.content.positive.default,
    elevation: 'none',
    $borderSize: '1px',
    tagVariant: NewTagColor.POSITIVE,
    inverse: true,
    tagLabel: 'Done',
  },
  review: {
    $background: tokens.colors.background.message.warning,
    $borderColor: tokens.colors.content.warning.default,
    color: tokens.colors.content.warning.default,
    elevation: 'none',
    $borderSize: '1px',
    tagVariant: NewTagColor.WARNING,
    inverse: true,
    tagLabel: 'Needs review',
  },
  inaccessible: {
    $background: tokens.colors.global.primary.light,
    $borderColor: tokens.colors.content.neutral.n10,
    color: tokens.colors.content.neutral.n10,
    elevation: 'none',
    $borderSize: '1px',
    tagVariant: NewTagColor.DEFAULT,
    tagLabel: 'Inaccessible',
  },
  locked: {
    $background: tokens.colors.background.message.positive,
    $borderColor: tokens.colors.content.positive.default,
    color: tokens.colors.content.positive.default,
    elevation: 'none',
    $borderSize: '1px',
    tagVariant: NewTagColor.POSITIVE,
    inverse: true,
    tagLabel: 'Done',
  },
};

const Container = styled(Box)`
  background: ${({ $background }) => $background};
  border: ${({ $borderSize }) => $borderSize} solid ${({ $borderColor }) => $borderColor};
  border-radius: ${tokens.spacing.s4};
  display: flex;
  margin-bottom: ${tokens.spacing.s4};

  &:hover {
    border-color: ${({ $hoverBorderColor }) => $hoverBorderColor};
  }
`;

const loading = keyframes`
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: 0 0;
  }
`;

const LoadingContainer = styled.div`
  min-height: ${tokens.spacing.s8};
  display: flex;
`;

const LoadingBox = styled(Box)`
  border-radius: ${tokens.spacing.s4};

  animation: ${loading} 2s linear infinite;
  background: linear-gradient(
    to right,
    ${tokens.colors.background.neutral.grey} 0%,
    ${tokens.colors.content.neutral.n10} 50%,
    ${tokens.colors.background.neutral.grey} 100%
  );
  background-size: 200% 200%;
`;

const LoadingLinesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoadingLine = styled(LoadingBox)`
  width: calc(${tokens.spacing.s9} * ${({ $size }) => $size});
  height: ${tokens.spacing.s3};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  min-height: ${tokens.spacing.s8};
`;

const SideContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: ${tokens.spacing.s8};
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-end')};
`;

const EditLinkLabel = styled(Typography)`
  margin-right: ${tokens.spacing.s3};
`;

const EditLinkIcon = styled(Link)`
  color: ${tokens.colors.content.secondary.default};
`;

const EditLinkButton = styled(Link)`
  color: ${tokens.colors.content.secondary.default};
  flex: none;
  padding: ${tokens.spacing.s3} ${tokens.spacing.s4};
  border-radius: ${tokens.spacing.s2};
  display: flex;
  align-items: center;
  height: ${tokens.spacing.s6};

  ${EditLinkLabel} {
    display: none;
  }

  &:hover {
    background: ${tokens.colors.background.neutral.grey};
    color: ${tokens.colors.content.secondary.default};
    text-decoration: none;

    ${EditLinkLabel} {
      display: inline;
    }
  }
`;

const ReviewLinkButton = styled(Link)`
  color: ${tokens.colors.content.secondary.default};
  flex: none;
  padding: ${tokens.spacing.s3} ${tokens.spacing.s4};
  border-radius: ${tokens.spacing.s2};
  display: flex;
  align-items: center;
  height: ${tokens.spacing.s6};

  ${EditLinkLabel} {
    display: none;
  }

  &:hover {
    background: ${tokens.colors.background.message.warning};
    color: ${tokens.colors.content.secondary.default};
    text-decoration: none;

    ${EditLinkLabel} {
      display: inline;
    }
  }
`;

const TagContainer = styled.div`
  flex: 1 1 0;
`;

export const GuideStep = ({ status, actionLabel = '', actionSlug = '', actionOnClick, children, statusLabel }) => {
  const match = useRouteMatch();
  const matchPath = actionSlug ? generatePath(match.path, { ...match.params, pageName: actionSlug }) : '';

  if (status === 'loading') {
    return (
      <Container p={[tokens.spacing.s4]} {...GuideStepStatusStyles.default}>
        <LoadingContainer>
          <LoadingBox p={[tokens.spacing.s6]} />
          <LoadingLinesContainer p={[tokens.spacing.s3, tokens.spacing.s4]}>
            <LoadingLine $size={3} m={[0, 0, 1, 0]} />
            <LoadingLine $size={1} />
          </LoadingLinesContainer>
        </LoadingContainer>
      </Container>
    );
  }

  const { tagLabel, tagVariant, elevation, inverse } = GuideStepStatusStyles[status];

  const statusContentMap = {
    default: (
      <SideContent>
        <TagContainer aria-label={statusLabel}>
          <NewTag bordered={false} color={tagVariant} inverse={inverse}>
            {tagLabel}
          </NewTag>
        </TagContainer>
      </SideContent>
    ),
    current: (
      <SideContent $center>
        <Button
          as={actionSlug ? Link : undefined}
          to={actionSlug ? matchPath : undefined}
          onClick={actionOnClick}
          aria-label={statusLabel}
        >
          {actionLabel}
        </Button>
      </SideContent>
    ),
    edit: (
      <SideContent>
        {tagLabel && (
          <TagContainer aria-label={statusLabel}>
            <NewTag bordered={false} color={tagVariant} inverse={inverse}>
              {tagLabel}
            </NewTag>
          </TagContainer>
        )}
        <EditLinkButton to={matchPath}>
          <EditLinkLabel variant="body2">{actionLabel}</EditLinkLabel>
          <Icon name="pencil" size="sm" kind={Icon.KINDS.SOLID} />
        </EditLinkButton>
      </SideContent>
    ),
    review: (
      <SideContent>
        {tagLabel && (
          <TagContainer aria-label={statusLabel}>
            <NewTag bordered={false} color={tagVariant} inverse={inverse}>
              {tagLabel}
            </NewTag>
          </TagContainer>
        )}
        <ReviewLinkButton to={matchPath}>
          <EditLinkLabel variant="body2">{actionLabel}</EditLinkLabel>
          <Icon name="pencil" size="sm" kind={Icon.KINDS.SOLID} />
        </ReviewLinkButton>
      </SideContent>
    ),
    error: (
      <SideContent $center>
        <Button
          as={actionSlug ? Link : undefined}
          to={actionSlug ? matchPath : undefined}
          onClick={actionOnClick}
          variant="destructive"
          aria-label={statusLabel}
        >
          {actionLabel}
        </Button>
      </SideContent>
    ),
    inaccessible: (
      <SideContent $center>
        {tagLabel && (
          <TagContainer aria-label={statusLabel} role="main">
            <NewTag bordered={false} color={tagVariant} inverse={inverse}>
              {tagLabel}
            </NewTag>
          </TagContainer>
        )}
        <Tooltip content="This page will only be accessible once you've fixed your errors in the tool selection step above.">
          <Icon name="lock" size="sm" kind={Icon.KINDS.SOLID} />
        </Tooltip>
      </SideContent>
    ),
    locked: (
      <SideContent bottom>
        {tagLabel && (
          <TagContainer aria-label={statusLabel}>
            <NewTag bordered={false} color={tagVariant} inverse={inverse}>
              {tagLabel}
            </NewTag>
          </TagContainer>
        )}
        <Box p={[0, tokens.spacing.s4]}>
          <Icon data-testid="icon-lock" name="lock" size="sm" kind={Icon.KINDS.SOLID} />
        </Box>
      </SideContent>
    ),
  };

  const getSideContent = () => {
    if (!actionLabel || (!actionSlug && !actionOnClick)) {
      if (!tagLabel) {
        return null;
      }
      return statusContentMap[status] ?? statusContentMap.default;
    }

    const statusSideContent = statusContentMap[status];

    if (statusSideContent) {
      return statusSideContent;
    }

    return (
      <SideContent>
        {tagLabel && (
          <TagContainer aria-label={statusLabel}>
            <NewTag bordered={false} color={tagVariant} inverse={inverse}>
              {tagLabel}
            </NewTag>
          </TagContainer>
        )}
        <Box p={[0, tokens.spacing.s4]}>
          <EditLinkIcon to={matchPath}>
            <Icon name="pencil" size="sm" kind={Icon.KINDS.SOLID} />
          </EditLinkIcon>
        </Box>
      </SideContent>
    );
  };

  return (
    <Shadow elevation={elevation}>
      <Container p={[tokens.spacing.s4]} {...GuideStepStatusStyles[status]}>
        <Content>{children}</Content>
        {getSideContent()}
      </Container>
    </Shadow>
  );
};

GuideStep.propTypes = {
  children: PropTypes.node,
  status: PropTypes.oneOf([...Object.keys(GuideStepStatusStyles), 'loading']).isRequired,
  actionLabel: PropTypes.string,
  actionSlug: PropTypes.oneOf(Object.values(routes.FLOW_BUILDER_PAGES)),
  actionOnClick: PropTypes.func,
  statusLabel: PropTypes.string,
};
