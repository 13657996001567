import { Map, fromJS } from 'immutable';

import { providerContainerTypes, workflowTypes } from 'consts';
import { normalizeEntitiesById } from 'utils';

export const initialState = Map({
  entitiesById: Map(),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case workflowTypes.GET_PROVIDER_CONTAINERS_BY_WORKFLOW_SUCCESS: {
      const { providerContainers } = action.payload;
      return state.set('entitiesById', normalizeEntitiesById(fromJS(providerContainers)));
    }

    case providerContainerTypes.CREATE_PROVIDER_CONTAINER_SUCCESS: {
      const { providerContainer } = action.payload;
      return state.setIn(['entitiesById', providerContainer._id], fromJS(providerContainer));
    }

    case providerContainerTypes.REMOVE_PROVIDER_CONTAINER: {
      const { providerContainerId } = action.payload;
      return state.deleteIn(['entitiesById', providerContainerId]);
    }

    default:
      return state;
  }
};

export const getContainerIdsByProviderInstanceId = (state, providerInstanceId) =>
  state
    .get('entitiesById')
    .filter((providerContainer) => providerContainer.get('providerInstanceId') === providerInstanceId)
    .map((providerContainer) => providerContainer.get('unitoUniqueId'))
    .toList();
