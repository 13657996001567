import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { AddProviderAccount } from 'containers';
import { Modal } from '../Modal/Modal';

const StyledModal = styled(Modal)`
  width: 750px;
  text-align: center;
`;

export function NewProviderModal({ isOpen, onRequestClose, onSuccess, shouldOpenAuthWindow = true }) {
  return (
    <StyledModal
      displayCloseButton
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="Connect a new account"
      type="plainModal"
    >
      <AddProviderAccount onSuccess={onSuccess || onRequestClose} shouldOpenAuthWindow={shouldOpenAuthWindow} />
    </StyledModal>
  );
}

NewProviderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  shouldOpenAuthWindow: PropTypes.bool,
};
