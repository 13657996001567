import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { fieldTypes } from 'consts';
import { FlowBuilderErrorContext } from 'contexts';
import { getProviderByName, hasMandatoryConditionalContainers, getProviderVisibleFields } from 'reducers';
import { useGetContainers, useGetItemTypes } from 'hooks';

export function useMandatoryDeepFiltersMissing(setErrors = true) {
  const { setError, clearErrors, watch } = useFormContext();
  const { linkId } = useParams();
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);

  const providerNameA = watch('A.providerName');
  const providerNameB = watch('B.providerName');
  const hasDeepFiltersA = watch('A.filters')?.some((filter) => filter.fieldId === fieldTypes.DEEP_FILTER_ITEM);
  const hasDeepFiltersB = watch('B.filters')?.some((filter) => filter.fieldId === fieldTypes.DEEP_FILTER_ITEM);

  const pageName = useContext(FlowBuilderErrorContext);
  const deepFiltersA = watch('A.deepFilters');
  const deepFiltersB = watch('B.deepFilters');
  const hasDeepFiltersForA = !!(deepFiltersA?.length && deepFiltersA[0].value?.length);
  const hasDeepFiltersForB = !!(deepFiltersB?.length && deepFiltersB[0].value?.length);
  const providerIdA = useSelector((state) => getProviderByName(state, providerNameA).get('_id'));
  const providerIdB = useSelector((state) => getProviderByName(state, providerNameB).get('_id'));
  const mandatoryDeepFiltersProviderA = useSelector((state) => hasMandatoryConditionalContainers(state, providerIdA));
  const mandatoryDeepFiltersProviderB = useSelector((state) => hasMandatoryConditionalContainers(state, providerIdB));

  const [containerA, containerB] = useGetContainers(linkId);
  const fieldsA = useSelector((state) =>
    getProviderVisibleFields(state, providerIdA, itemTypeA, containerA?.get('id')),
  );
  const fieldsB = useSelector((state) =>
    getProviderVisibleFields(state, providerIdB, itemTypeB, containerB?.get('id')),
  );

  // FIXME : this is a temporary fix since the check for mandatoryDeepFilters is at the provider level
  // it needs to be moved to the primaryItem level
  // https://app.asana.com/0/1201266349202149/1201389525821761
  const hasDeepFilterableFieldsA = fieldsA.some((field) => field.get('deepFilterable'));
  const hasDeepFilterableFieldsB = fieldsB.some((field) => field.get('deepFilterable'));

  const missingDeepFiltersA = !hasDeepFiltersForA && mandatoryDeepFiltersProviderA && hasDeepFilterableFieldsA;
  const missingDeepFiltersB = !hasDeepFiltersForB && mandatoryDeepFiltersProviderB && hasDeepFilterableFieldsB;

  useEffect(() => {
    if (setErrors) {
      let errorName = `${pageName}.mandatoryDeepFiltersMissingSideA`;
      if (hasDeepFiltersA && missingDeepFiltersA) {
        setError(errorName, { type: 'manual' });
      } else {
        clearErrors(errorName);
      }

      errorName = `${pageName}.mandatoryDeepFiltersMissingSideB`;
      if (hasDeepFiltersB && missingDeepFiltersB) {
        setError(errorName, { type: 'manual' });
      } else {
        clearErrors(errorName);
      }
    }
  }, [
    setError,
    clearErrors,
    hasDeepFiltersA,
    hasDeepFiltersB,
    missingDeepFiltersA,
    missingDeepFiltersB,
    pageName,
    setErrors,
  ]);

  return [missingDeepFiltersA, missingDeepFiltersB];
}
