import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';

import {
  Box,
  tokens,
  Typography,
  Button,
  LoadingPlaceholder,
  Input,
  notification,
  NewAlertLevel,
  NewButton,
  NewAlert,
} from '@unitoio/mosaic';

import { formUtils } from 'utils';
import { trackingTypes } from 'consts';
import { organizationActions } from 'actions';
import {
  getOrganizationById,
  getOrganizationMembers,
  getUserId,
  isOnCustomPlan,
  isOnFreeTrial,
  isOrganizationChurned,
  isOrganizationTrialExpired,
} from 'reducers';
import { color } from 'theme';
import { useLogger, useTrackEvent } from 'hooks';
import { TrackingFunnel } from 'containers';

import { DeleteWorkspaceModal } from './DeleteWorkspaceModal';
import { DeleteWorkspaceWarningModal } from './DeleteWorkspaceWarningModal';

const Divider = styled.hr`
  margin-top: 5px;
  border-top-color: ${color.dark.whisper};
`;

const StyledBox = styled(Box)`
  width: 40%;
`;

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const ButtonBox = styled(Box)`
  width: 35%;
`;

const useGetOrganizationMembers = (fetchOrganizationMembers) => {
  useEffect(() => {
    fetchOrganizationMembers();
  }, [fetchOrganizationMembers]);
};

const handleOnCloseWarningModal = (setIsModalOpen, fetchOrganizationMembers) => {
  fetchOrganizationMembers();
  setIsModalOpen(false);
};

const handleOnCloseDeleteWorkspaceModal = (setIsModalOpen) => {
  setIsModalOpen(false);
};

const handleOpenModal = (trackEvent, setIsModalOpen) => {
  trackEvent(trackingTypes.ACTION, {
    action_name: trackingTypes.WORKSPACE_EVENTS.ACTIONS.DELETE_ACCOUNT,
  });
  setIsModalOpen(true);
};

export const WorkspaceSettings = () => {
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const [hasErrorLoadingMembers, setHasErrorLoadingMembers] = useState(false);
  const { reportException } = useLogger();

  const members = useSelector((state) => getOrganizationMembers(state, organizationId));
  const organization = useSelector((state) => getOrganizationById(state, organizationId));
  const userId = useSelector((state) => getUserId(state));

  const isCustomPlan = useSelector((state) => isOnCustomPlan(state, organizationId));
  const isTrialing = useSelector((state) => isOnFreeTrial(state, organizationId));
  const isTrialExpired = useSelector((state) => isOrganizationTrialExpired(state, organizationId));
  const isChurned = useSelector((state) => isOrganizationChurned(state, organizationId));

  const organizationOwner = organization.get('founder');
  const originalWorkspaceName = organization.get('name');
  const originalBillingEmail = organization.get('billingEmail');

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      workspaceName: originalWorkspaceName,
      billingEmail: originalBillingEmail,
    },
  });

  const onSubmit = async (data) => {
    const { workspaceName: name, billingEmail } = data;
    await dispatch(organizationActions.patchOrganization(organizationId, { name, billingEmail }));
    trackEvent(trackingTypes.ACTION, {
      action_name: trackingTypes.WORKSPACE_EVENTS.ACTIONS.SAVED_CHANGES,
    });
    notification.success({
      message: 'Workspace information saved',
      description: 'Your workspace informations have been successfully updated 🎉',
      placement: 'topRight',
      duration: 3,
    });
  };

  const fetchOrganizationMembers = useCallback(async () => {
    try {
      setIsLoadingMembers(true);
      await dispatch(organizationActions.getMembers(organizationId));
      setIsLoadingMembers(false);
    } catch (error) {
      setHasErrorLoadingMembers(true);
      reportException(`Fail to retrieve organization ${organizationId} members`, {
        identifier: 'useGetOrganizationMembers fetchOrganizationMembers failToGetOrganzationMembers',
        error,
      });
      setIsLoadingMembers(false);
    }
  }, [dispatch, organizationId, reportException]);

  useGetOrganizationMembers(fetchOrganizationMembers);

  const [api, contextHolder] = notification.useNotification('warning');
  const openErrorNotification = () => {
    api.open({
      message: "Your workspace couldn't be deleted",
      description: 'Please try again or reach out to the team at support@unito.io.',
      type: 'error',
    });
  };

  const getDeleteWorkspaceBlock = () => {
    if (isLoadingMembers) {
      return (
        <Box m={[tokens.spacing.s7, 0, 0, 0]}>
          <LoadingPlaceholder width="100%" height={tokens.spacing.s9} borderRadius={tokens.spacing.s4} />
        </Box>
      );
    }

    if (hasErrorLoadingMembers) {
      return (
        <NewAlert
          level={NewAlertLevel.WARNING}
          title="There was an error while getting your plan or usage information"
          message="Please try again or reach out to the team at support@unito.io"
          action={
            <NewButton type="default" onClick={() => window.location.reload()}>
              Reload page
            </NewButton>
          }
        />
      );
    }

    return (
      <>
        {contextHolder}
        <Box m={[tokens.spacing.s7, 0, 0, 0]}>
          <Box
            borderColor={tokens.colors.content.destructive.default}
            fullWidth
            borderRadius={tokens.spacing.s4}
            p={[tokens.spacing.s5]}
          >
            <Box m={[0, 0, tokens.spacing.s2, 0]}>
              <Typography>
                <Bold>Close {originalWorkspaceName}</Bold>
              </Typography>
            </Box>
            <Box flexDirection={Box.flexDirection.ROW} justifyContent="space-between">
              <Box>
                <Typography>
                  Closing your workspace will permanently remove all flows, workflows, and personal information. You
                  will also lose access to this account.
                </Typography>
              </Box>
              <ButtonBox m={[0, 0, 0, tokens.spacing.s4]}>
                <Button
                  block
                  variant={Button.variants.DESTRUCTIVE}
                  onClick={() => handleOpenModal(trackEvent, setIsModalOpen)}
                >
                  Close workspace
                </Button>
              </ButtonBox>
            </Box>
          </Box>
        </Box>

        {members.count() > 1 ? (
          <DeleteWorkspaceWarningModal
            isOpen={isModalOpen}
            onClose={() => handleOnCloseWarningModal(setIsModalOpen, fetchOrganizationMembers)}
          />
        ) : (
          <TrackingFunnel contextName={trackingTypes.JOURNEY.DELETE_WORKSPACE}>
            <DeleteWorkspaceModal
              isOpen={isModalOpen}
              onClose={() => handleOnCloseDeleteWorkspaceModal(setIsModalOpen)}
              onError={() => {
                handleOnCloseDeleteWorkspaceModal(setIsModalOpen);
                openErrorNotification();
              }}
            />
          </TrackingFunnel>
        )}
      </>
    );
  };

  return (
    <Box flexDirection={Box.flexDirection.COLUMN}>
      <Box>
        <Typography variant={Typography.variants.H2}>Workspace settings</Typography>
        <Divider />
      </Box>
      <StyledBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box m={[0, 0, tokens.spacing.s5]}>
            <Input
              {...register('workspaceName', {
                maxLength: {
                  value: formUtils.ORGANIZATION_NAME_MAX_LENGTH,
                  message: `The workspace name must be ${formUtils.ORGANIZATION_NAME_MAX_LENGTH} characters or fewer`,
                },
                required: 'Required',
              })}
              label="Workspace name"
              id="Workspace name"
              errorText={formState.errors.workspaceName?.message}
            />
          </Box>
          {!isCustomPlan && (
            <Box m={[0, 0, tokens.spacing.s5]}>
              <Input
                {...register('billingEmail', {
                  maxLength: {
                    value: formUtils.FIELD_MAX_LENGTH,
                    message: `The email must be ${formUtils.FIELD_MAX_LENGTH}`,
                  },
                  required: 'Required',
                  validate: (value) => formUtils.validateEmailAddress(value) || 'Invalid email address',
                })}
                label="Contact email"
                id="Contact email"
                errorText={formState.errors.billingEmail?.message}
              />
            </Box>
          )}
          <Button
            variant={Button.variants.SECONDARY}
            type="submit"
            disabled={formState.isSubmitting || !formState.isDirty}
          >
            Save Changes
          </Button>
        </form>
      </StyledBox>
      {(isTrialing || isTrialExpired || isChurned) && organizationOwner === userId && getDeleteWorkspaceBlock()}
    </Box>
  );
};
