import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { FlowBuilderErrorContext } from 'contexts';

import { getLastAssociation } from '../utils/getLastAssociation';

export function useGetIsLastAssociationCompleted(fieldAssociations = []) {
  const { setError, clearErrors } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);
  const [lastAssociation] = getLastAssociation(fieldAssociations);

  const canLastAssociationBeDeleted = lastAssociation?.A?.field === null || lastAssociation?.B?.field === null;
  const isLastAssociationCompleted = lastAssociation
    ? !!(lastAssociation?.A?.field && lastAssociation?.B?.field && lastAssociation?.target)
    : true;

  useEffect(() => {
    if (!isLastAssociationCompleted && !canLastAssociationBeDeleted) {
      setError(`${pageName}.lastAssociationNotCompleted`, { type: 'manual' });
    } else {
      clearErrors(`${pageName}.lastAssociationNotCompleted`);
    }
  }, [setError, clearErrors, isLastAssociationCompleted, pageName, canLastAssociationBeDeleted]);

  return isLastAssociationCompleted;
}
