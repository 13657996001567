import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { getStates, getCountry } from 'country-state-picker';

import { tokens, Input, Modal, Typography, TypographyVariants, Select, Box } from '@unitoio/mosaic';

import { trackingTypes } from 'consts';
import { formUtils } from 'utils';
import { billingActions } from 'actions';

import { countries, getCountryOptions } from './utils';

const Grid = styled.div`
  display: grid;
  gap: ${tokens.spacing.s4};
  grid-template-columns: repeat(4, 1fr);
  margin-top: ${tokens.spacing.s4};
`;

const Cell = styled.div`
  grid-column: ${(props) => (props.$span ? `span ${props.$span}` : 'span 1')} / auto;
`;

export function getStateOptions(countryOption) {
  if (!countryOption) {
    return [];
  }
  const country = getCountry(countryOption);
  const allCountryStates = getStates(country.code);

  return allCountryStates.map((state) => ({
    label: state,
    value: state,
  }));
}

export function getTaxIdTypeOptions() {
  const taxIdTypeOptions = Object.entries(countries)
    .filter(([, countryInfo]) => !!countryInfo.taxIds?.length)
    .flatMap(([, countryInfo]) => {
      const { emoji, name, taxIds } = countryInfo;
      const formatTaxIdType = (taxIdType) => taxIdType.split('_').join(' ').toUpperCase();

      return taxIds.map((taxIdType) => ({
        label: `${emoji} ${formatTaxIdType(taxIdType)} (${name})`,
        value: taxIdType,
      }));
    });

  return taxIdTypeOptions;
}

export const CompanyDetailsStep = ({
  onNextStep,
  setCompanyDetailsFormState,
  defaultValues,
  isOpen,
  onCancel,
  trackEvent,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, control, formState, watch, setValue, reset } = useForm({ defaultValues });

  const watchCountryField = watch(['country']);
  const [selectedCountry] = watchCountryField || {};

  const onSubmit = async (formValues) => {
    const nonEmptyForm = Object.values(formValues).some((value) => !formUtils.isEmpty(value));
    if (nonEmptyForm) {
      const {
        taxId,
        taxValue,
        city,
        country,
        state,
        addressLine1,
        addressLine2,
        postalCode,
        companyName,
        billingEmail,
      } = formValues;

      const address = {
        city,
        line1: addressLine1,
        line2: addressLine2,
        postal_code: postalCode,
        country: country.value,
        state: state.value,
      };

      const tax =
        taxId && taxValue
          ? {
              type: taxId.value,
              value: taxValue,
            }
          : undefined;

      const editPayload = {
        address,
        tax,
        name: companyName,
        email: billingEmail,
      };

      if (!formUtils.isEmptyObject(editPayload)) {
        await dispatch(billingActions.editOrganization(editPayload));
      }
    }

    trackEvent(trackingTypes.ACTION, { action_name: 'clicked on go to payment' });
    setCompanyDetailsFormState(formValues);
    onNextStep();
  };

  const handleOnClose = () => {
    reset();
    onCancel();
  };

  return (
    <Modal
      isOpen={isOpen}
      onConfirm={handleSubmit(onSubmit)}
      title="Company information (Optional)"
      isConfirmButtonDisabled={formState.isSubmitting}
      onRequestClose={handleOnClose}
      confirmLabel="Go to payment"
      displayCancelButton={false}
    >
      <Typography variant={TypographyVariants.BODY1}>This information will be displayed on your invoice.</Typography>
      <Grid>
        <Cell $span="2">
          <Input {...register('companyName')} label="Company name" id="companyName" />
        </Cell>
        <Cell $span="2">
          <Input {...register('billingEmail')} label="Billing email" id="billingEmail" />
        </Cell>
        <Cell $span="3">
          <Input {...register('addressLine1')} label="Address" id="addressLine1" />
        </Cell>
        <Cell>
          <Input {...register('addressLine2')} label="Suite, apt, etc." id="addressLine2" />
        </Cell>
        <Cell $span="2">
          <Input {...register('city')} label="City" id="city" />
        </Cell>
        <Cell $span="2">
          <Input {...register('postalCode')} label="Postal/Zip Code" id="postalCode" />
        </Cell>
        <Cell $span="2">
          <Typography variant={TypographyVariants.BODY1}>Country</Typography>

          <Controller
            render={({ field: { onChange, value } }) => (
              <Box m={[tokens.spacing.s2, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
                <Select
                  value={value}
                  onChange={(countryOption) => {
                    setValue('state', '', { shouldDirty: true });
                    onChange(countryOption);
                  }}
                  options={getCountryOptions()}
                  placeholder="Choose a country"
                  searchable
                  size="md"
                />
              </Box>
            )}
            control={control}
            name="country"
            rules={{}}
            defaultValue=""
          />
        </Cell>

        <Cell $span="2">
          <Typography variant={TypographyVariants.BODY1}>State/Province</Typography>

          <Controller
            render={({ field: { onChange, value } }) => (
              <Box m={[tokens.spacing.s2, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
                <Select
                  value={value}
                  onChange={onChange}
                  options={getStateOptions(selectedCountry)}
                  placeholder="Choose a state"
                  searchable
                  size="md"
                  disabled={!selectedCountry}
                />
              </Box>
            )}
            control={control}
            name="state"
            rules={{}}
            defaultValue=""
          />
        </Cell>

        <Cell $span="2">
          <Typography variant={TypographyVariants.BODY1}>Tax ID Type</Typography>

          <Controller
            render={({ field: { onChange, value } }) => (
              <Box m={[tokens.spacing.s2, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
                <Select
                  value={value}
                  onChange={(taxId) => {
                    setValue('taxId', '', { shouldDirty: true });
                    onChange(taxId);
                  }}
                  options={getTaxIdTypeOptions()}
                  placeholder="ID type"
                  searchable
                  size="md"
                />
              </Box>
            )}
            control={control}
            name="taxId"
            rules={{}}
            defaultValue=""
          />
        </Cell>

        <Cell $span="2">
          <Input {...register('taxValue')} label="Tax ID" id="taxValue" />
        </Cell>
      </Grid>
    </Modal>
  );
};

CompanyDetailsStep.propTypes = {
  defaultValues: PropTypes.shape({
    state: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    companyName: PropTypes.string,
    billingEmail: PropTypes.string,
    taxId: PropTypes.string,
  }),
  setCompanyDetailsFormState: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
