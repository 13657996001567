import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Bold, Link, Typography } from '@unitoio/mosaic';

import { routes } from 'consts';

export const SidebarIframe = ({ isActivating, activationFailed, linkId }) => (
  <>
    {!isActivating && (
      <Alert level={activationFailed ? 'error' : 'success'} size="sm">
        {activationFailed ? (
          <Typography>
            The export launch failed.
            <Link href={`#${routes.getEditFlowBuilderRoute(linkId)}`} isExternalLink>
              Go to the app
            </Link>
            to launch it manually.
          </Typography>
        ) : (
          <Typography>
            Your export is completed! You can find it in the <Bold>My flows</Bold> section.
          </Typography>
        )}
      </Alert>
    )}
  </>
);

SidebarIframe.propTypes = {
  isActivating: PropTypes.bool.isRequired,
  activationFailed: PropTypes.bool.isRequired,
  linkId: PropTypes.string.isRequired,
};
