import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Href, SelectField } from 'components';
import { useTrackEvent } from 'hooks';
import { getConnectedAndOtherProviders, getProvidersThatCanConnect } from 'reducers';
import { trackingTypes } from 'consts';

import { Placeholder } from './Placeholder';
import { SelectorLabel } from './SelectorLabel';

const ExternalLink = styled((props) => <Href {...props} />)`
  &:hover {
    text-decoration: none;
  }
`;

export function ProvidersSelect({ readOnly = false, side, onChange = () => null }) {
  const { watch } = useFormContext();
  const selectedProviderName = watch(`${side}.providerName`);
  const trackEvent = useTrackEvent();

  const enabledProviders = useSelector((state) => getProvidersThatCanConnect(state));
  const connectedAndOtherProviders = useSelector((state) => getConnectedAndOtherProviders(state));

  function buildOptions(providers) {
    return providers
      .sortBy((p) => p.get('displayName'))
      .map((provider) => ({
        value: provider.get('name'),
        label: provider.get('displayName'),
        icon: { type: 'provider', name: provider.get('name') },
      }))
      .toList()
      .toArray();
  }

  function getProviderOptions() {
    const [connectedProviders, otherProviders] = connectedAndOtherProviders;

    if (connectedProviders.isEmpty()) {
      return buildOptions(enabledProviders);
    }

    const myTools = buildOptions(connectedProviders);
    const moreTools = buildOptions(otherProviders);

    return [
      { label: 'My tools', options: myTools },
      { label: 'More tools', options: moreTools },
    ];
  }

  function getNewToolOption() {
    return (
      <ExternalLink href="https://unito.io/connectors#upcomingIntegrationsAnchor" target="_blank">
        Looking to add another tool?{' '}
      </ExternalLink>
    );
  }

  const trackNewToolOption = useCallback(() => {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on Looking to add another tool',
    });
  }, [trackEvent]);

  return (
    <SelectorLabel label="Sync work in">
      <SelectField
        createText={getNewToolOption()}
        createIcon="question-circle"
        onCreate={trackNewToolOption}
        value={selectedProviderName}
        onChange={onChange}
        placeholder={Placeholder({ text: 'Add a tool to this flow' })}
        readOnly={readOnly}
        name={`${side}.providerName`}
        searchable
        options={getProviderOptions()}
      />
    </SelectorLabel>
  );
}
ProvidersSelect.propTypes = {
  readOnly: PropTypes.bool,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  onChange: PropTypes.func,
};
