import { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { fieldTypes } from 'consts';
import { useLogger } from 'hooks';

import { useAddInitialRules } from '../utils';
import { statuses } from '../consts';

export function useInitializeDefaultRules({
  sides,
  isDuplicatedLink,
  isDraft,
  areActionsLoaded,
  providerFieldsA,
  providerFieldsB,
}) {
  const { setValue } = useFormContext();
  const filtersInitializedAtA = useWatch({ name: 'A.filtersInitializedAt' });
  const filtersInitializedAtB = useWatch({ name: 'B.filtersInitializedAt' });
  const { reportException } = useLogger();
  const {
    A: {
      fields: fieldsA,
      canCloseTasks: canCloseTasksA,
      hasSubfolders: hasSubfoldersA,
      hasDeepFiltering: hasDeepFilteringA,
      defaultDeepFilterItemFieldId: defaultDeepFilterItemFieldIdA,
      hasRequiredDate: hasRequiredDateA,
    },
    B: {
      fields: fieldsB,
      canCloseTasks: canCloseTasksB,
      hasSubfolders: hasSubfoldersB,
      hasDeepFiltering: hasDeepFilteringB,
      defaultDeepFilterItemFieldId: defaultDeepFilterItemFieldIdB,
      hasRequiredDate: hasRequiredDateB,
    },
  } = sides;

  const dateRangeIsMissingA = !fieldsA.some(
    (field) => field.fieldId === fieldTypes.FUTURE_DATE || field.fieldId === fieldTypes.PAST_DATE,
  );
  const dateRangeIsMissingB = !fieldsB.some(
    (field) => field.fieldId === fieldTypes.FUTURE_DATE || field.fieldId === fieldTypes.PAST_DATE,
  );

  const hasCreatedDateA = providerFieldsA.some((field) => field.get('semantic') === fieldTypes.SEMANTIC.CREATED_AT);
  const hasCreatedDateB = providerFieldsB.some((field) => field.get('semantic') === fieldTypes.SEMANTIC.CREATED_AT);

  const filtersQtyA = fieldsA?.length;
  const filtersQtyB = fieldsB?.length;
  const areRulesInitializedSideA = !!(filtersQtyA || filtersInitializedAtA);
  const areRulesInitializedSideB = !!(filtersQtyB || filtersInitializedAtB);
  const areRulesInitialized = !isDraft || (areRulesInitializedSideA && areRulesInitializedSideB);
  const shouldReinitializeSideRules =
    isDraft &&
    areActionsLoaded &&
    ((areRulesInitializedSideA && !areRulesInitializedSideB) ||
      (!areRulesInitializedSideA && areRulesInitializedSideB));
  const shouldInitializeRulesForTheFirstTime = isDraft && areActionsLoaded && !areRulesInitialized && !isDuplicatedLink;
  const areRulesInitializedOrRulesNeedDuplication = areActionsLoaded && (areRulesInitialized || isDuplicatedLink);
  const [status, setStatus] = useState(statuses.INITIAL);

  const rulesA = useAddInitialRules(
    hasCreatedDateA && hasCreatedDateB,
    hasSubfoldersA,
    canCloseTasksA,
    hasDeepFilteringA,
    defaultDeepFilterItemFieldIdA,
    hasRequiredDateA,
    dateRangeIsMissingA,
  );
  const rulesB = useAddInitialRules(
    hasCreatedDateB && hasCreatedDateA,
    hasSubfoldersB,
    canCloseTasksB,
    hasDeepFilteringB,
    defaultDeepFilterItemFieldIdB,
    hasRequiredDateB,
    dateRangeIsMissingB,
  );

  // TODO PCDv3 - aug 28
  useEffect(() => {
    async function initializeRules() {
      if (!areActionsLoaded || status === statuses.SUCCESS_RULES) {
        // If we're still waiting on the actions to load, return the current status of the hook
        return status;
      }

      if (areRulesInitializedOrRulesNeedDuplication) {
        // If the rules have already been initialized or we need to duplicate the rules, return a "success" status
        setStatus(statuses.SUCCESS_RULES);
        return status;
      }

      if (status === statuses.INITIAL) {
        try {
          if (shouldInitializeRulesForTheFirstTime) {
            setStatus(statuses.LOADING);
            setValue('A.filters', rulesA.filters, { shouldDirty: true });
            setValue('A.filtersInitializedAt', rulesA.filtersInitializedAt, { shouldDirty: true });
            setValue('B.filters', rulesB.filters, { shouldDirty: true });
            setValue('B.filtersInitializedAt', rulesB.filtersInitializedAt, { shouldDirty: true });
          } else if (shouldReinitializeSideRules) {
            setStatus(statuses.LOADING);
            if (!areRulesInitializedSideA) {
              setValue('A.filters', rulesA.filters, { shouldDirty: true });
              setValue('A.filtersInitializedAt', rulesA.filtersInitializedAt, { shouldDirty: true });
              setValue('B.filters', fieldsB, { shouldDirty: true });
            }
            if (!areRulesInitializedSideB) {
              setValue('A.filters', fieldsA, { shouldDirty: true });
              setValue('B.filters', rulesB.filters, { shouldDirty: true });
              setValue('B.filtersInitializedAt', rulesB.filtersInitializedAt, { shouldDirty: true });
            }
          }
        } catch (err) {
          reportException(err);
          setStatus(statuses.ERROR_DFV);
        }
      }

      setStatus(statuses.SUCCESS_RULES);
      return status;
    }

    initializeRules();
  }, [
    areActionsLoaded,
    areRulesInitializedOrRulesNeedDuplication,
    areRulesInitializedSideA,
    areRulesInitializedSideB,
    fieldsA,
    fieldsB,
    reportException,
    rulesA.filters,
    rulesA.filtersInitializedAt,
    rulesB.filters,
    rulesB.filtersInitializedAt,
    setValue,
    shouldInitializeRulesForTheFirstTime,
    shouldReinitializeSideRules,
    status,
  ]);

  return status;
}
