import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classnames from 'classnames';

import { RadioButton, RadioButtonGroup, Section, Title } from 'components';

export function AuthorizationMethods({ input, authorizationMethods }) {
  return (
    <div className={classnames({ hide: authorizationMethods.size === 1 })}>
      <Section>
        <Title type="h3">Authorization method</Title>
        <RadioButtonGroup {...input} selectedValue={input.value}>
          {authorizationMethods
            .map((authMethod, authMethodName) => (
              <RadioButton
                key={authMethodName}
                label={
                  <Title type="h4">
                    {authMethod.get('label')} {authMethod.get('preferred') && <small>(Recommended)</small>}
                  </Title>
                }
                value={authMethodName}
                subLabel={authMethod.get('help')}
              />
            ))
            .toArray()}
        </RadioButtonGroup>
      </Section>
    </div>
  );
}

AuthorizationMethods.propTypes = {
  authorizationMethods: PropTypes.instanceOf(Map).isRequired,
  input: PropTypes.shape({ value: PropTypes.string }).isRequired,
};
