import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Alert, Box, Button, Icon, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import { routes } from 'consts';
import { ModalIframeSection } from './ModalIframeSection';
import StepTwoImg from '../img/step_two_export_and_sync.png';

const BottomInstructions = styled(Typography)`
  margin-top: ${(props) => (props.$activationFailed ? tokens.spacing.s4 : tokens.spacing.s9)};
`;

const StyledIcon = styled(Icon)`
  margin-right: ${tokens.spacing.s3};
`;

const StepTwo = styled.img`
  padding: ${tokens.spacing.s4} ${tokens.spacing.s0};
`;

export const ModalIframe = ({ isActivating, activationFailed, linkId }) => {
  const button = () => (
    <Box m={[tokens.spacing.s5, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
      <Button href={`#${routes.getEditFlowBuilderRoute(linkId)}`} target="_blank">
        <StyledIcon name="external-link" />
        {!activationFailed ? 'Open app.unito.io' : 'Launch from app.unito.io'} &nbsp;
      </Button>
    </Box>
  );

  return (
    <>
      {!isActivating && (
        <Box>
          <ModalIframeSection
            circleBackgroundColor={tokens.colors.background.message.positive}
            circleBorderColor={tokens.colors.content.positive.default}
            isStepCompleted
            stepNumber={1}
          >
            <Typography variant={TypographyVariants.H5}>The Unito add-on is installed</Typography>
          </ModalIframeSection>

          <ModalIframeSection
            circleBackgroundColor={tokens.colors.background.message.positive}
            circleBorderColor={tokens.colors.content.positive.default}
            activationFailed={activationFailed}
            isStepCompleted
            stepNumber={2}
          >
            <Box>
              <Typography variant={TypographyVariants.H5}>Export your Trello board</Typography>
              {activationFailed && (
                <Box>
                  <Typography color={tokens.colors.content.neutral.n30} variant={TypographyVariants.BODY1}>
                    Click the "Launch export" button below. This will add the 2 required columns that will be filled by
                    Unito once the flow will be launched.
                  </Typography>
                  <StepTwo alt="ExportAndSync" src={StepTwoImg} />
                  <Alert level="error" size="sm">
                    The export failed to launch. Please click "Launch from app.unito.io" below to open the Unito app.
                    From there, you can proceed by clicking "Continue" to launch.
                  </Alert>
                  {button()}
                </Box>
              )}
            </Box>
          </ModalIframeSection>

          {!activationFailed && (
            <ModalIframeSection
              circleBackgroundColor={tokens.colors.global.primary.default}
              circleBorderColor={tokens.colors.content.neutral.n10}
              activationFailed={activationFailed}
              stepNumber={3}
            >
              <Box>
                <Typography variant={TypographyVariants.H5}>
                  Monitor your export and unlock Unito's full potential
                </Typography>
                <Typography color={tokens.colors.content.neutral.n30} variant={TypographyVariants.BODY1}>
                  Keep an eye on the cards being exported directly from the Unito platform. You can also add rules and
                  filters on your export based on members, labels and more.
                </Typography>
                {button()}
              </Box>
            </ModalIframeSection>
          )}
          <BottomInstructions
            align={Box.alignItems.CENTER}
            color={tokens.colors.content.neutral.n30}
            variant={TypographyVariants.BODY2}
            $activationFailed={activationFailed}
          >
            See those instructions again through the menu Extensions {`>`} Unito Spreadsheet Sync {`>`} Open Unito
          </BottomInstructions>
        </Box>
      )}
    </>
  );
};

ModalIframe.propTypes = {
  isActivating: PropTypes.bool.isRequired,
  activationFailed: PropTypes.bool.isRequired,
  linkId: PropTypes.string.isRequired,
};
