import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { NewAlert as Alert, Box, Icon, NewAlertLevel, Select, tokens, Tooltip, Typography } from '@unitoio/mosaic';

import { fieldTypes, pcdFilterOperatorTypes } from 'consts';
import { useSelector } from 'react-redux';
import { areCustomFieldsLoaded, getField, getProviderByName, getProviderCapabilitiesByIdV3 } from 'reducers';
import { getDisplayTypeForRulesRowField } from '../utils/getDisplayTypeForRulesRowField';
import { PCDFieldsSelect } from './PCDFieldsSelect';
import { FieldValuesSelect } from './FieldValuesSelect';

const MaxWidthInput = styled(Box)`
  min-width: 0;
`;

function getOperators(fieldDetails, isAction) {
  if (isAction) {
    return [{ label: 'to', value: pcdFilterOperatorTypes.pcdFilterOperator.IN }];
  }

  const filteringOperators = [{ label: 'is', value: pcdFilterOperatorTypes.pcdFilterOperator.IN }];

  if (fieldDetails.canBeDenied) {
    filteringOperators.push({ label: 'is not', value: pcdFilterOperatorTypes.pcdFilterOperator.NIN });
  }

  return filteringOperators;
}

const formatLabelWithHelp = (option, content) => (
  <Typography textOverflow="ellipsis">
    {option.label}
    <Tooltip placement="top" content={content}>
      <Box as="span" p={[0, 0, 0, tokens.spacing.s3]}>
        <Icon name="question-circle" kind={Icon.KINDS.SOLID} aria-label={content} />
      </Box>
    </Tooltip>
  </Typography>
);

export function RulesRowNormalField({
  name,
  data = null,
  isLocked = false,
  containerId = null,
  containerSide,
  providerIdentityId = null,
  providerName = null,
  fieldDetails = {},
  isAction = false,
  itemType = null,
  containerType,
}) {
  const { setValue, register } = useFormContext();
  const { parentFieldId, operator, fieldId, value, kind, isSetDefault, type, _id } = data;
  const currentField = useSelector((state) =>
    getField(state, { fieldId, kind, providerName, containerSide, containerId, itemType }),
  );
  const areCFsLoaded = useSelector((state) => areCustomFieldsLoaded(state, { containerSide }));
  const isDeletedCustomField = data.kind === fieldTypes.KINDS.CUSTOM_FIELD && currentField.isEmpty() && areCFsLoaded;

  const provider = useSelector((state) => getProviderByName(state, providerName));
  const providerDisplayName = provider?.get('displayName');
  const itemTypeCapabilities = useSelector((state) =>
    getProviderCapabilitiesByIdV3(state, provider.get('_id'), itemType),
  );
  const displayType = getDisplayTypeForRulesRowField(parentFieldId, isSetDefault, isAction);

  const operators = getOperators(fieldDetails, isAction);

  const toolTipContent =
    fieldId === 'taskType'
      ? `Setting the ${currentField
          .getIn(['names', 'singular'], '')
          .toLowerCase()} is required so that incoming ${itemTypeCapabilities.getIn(
          ['item', 'names', 'plural'],
          'tasks',
        )} created in ${provider.get(
          'displayName',
        )} can be properly identified and categorized based on the way you work.`
      : `Setting a default ${currentField
          .getIn(['names', 'singular'], '')
          .toLowerCase()} is required so that work items always have a place to go, even if you haven’t mapped your ${currentField
          .getIn(['names', 'plural'], '')
          .toLowerCase()} on the field mapping page.`;

  const formatLabel = isSetDefault ? (option) => formatLabelWithHelp(option, toolTipContent) : null;

  return (
    <>
      {isDeletedCustomField ? (
        <Box m={[0, tokens.spacing.s2]}>
          <Alert
            level={NewAlertLevel.WARNING}
            message={
              <>
                The custom field used in this rule is no longer available in {providerDisplayName}. You can remove this
                rule.
              </>
            }
          />
        </Box>
      ) : (
        <>
          <Box m={[0, tokens.spacing.s2]}>
            <PCDFieldsSelect
              fullWidth
              itemType={itemType}
              value={fieldId}
              parentFieldId={parentFieldId}
              isOptionDisabledHandler={() => false}
              name={`${name}.fieldId`}
              searchable={false}
              containerSide={containerSide}
              displayType={displayType}
              providerIdentityId={providerIdentityId}
              providerName={providerName}
              containerId={containerId}
              labelPrefix={isAction ? 'Set default' : undefined}
              labelSuffix={isAction ? 'to' : undefined}
              readOnly
              formatLabel={formatLabel}
            />
          </Box>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input type="hidden" {...register(`${name}._id`)} defaultValue={_id} />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input type="hidden" {...register(`${name}.kind`)} defaultValue={kind} />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input type="hidden" {...register(`${name}.operator`)} defaultValue={operator} />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input type="hidden" {...register(`${name}.type`)} defaultValue={type} />
          {operator && !isAction && (
            <Box m={[0, tokens.spacing.s2]}>
              <Select
                name={`${name}.operator`}
                value={operator}
                options={operators.map((o) => ({
                  ...o,
                  disabled:
                    (fieldDetails.existingOperators?.includes(o.value) && o.value !== data.operator) ||
                    (o.value === pcdFilterOperatorTypes.pcdFilterOperator.IN && !fieldDetails.canBeAllowed) ||
                    (o.value === pcdFilterOperatorTypes.pcdFilterOperator.NIN && !fieldDetails.canBeDenied),
                }))}
                readOnly={isLocked || isAction || operators.length === 1}
                onChange={(newOperator) => {
                  setValue(`${name}.operator`, newOperator, { shouldDirty: true });
                }}
                placeholder=""
              />
            </Box>
          )}
          {fieldId && itemType && (
            <MaxWidthInput m={[0, tokens.spacing.s2]}>
              <FieldValuesSelect
                name={`${name}.value`}
                kind={kind}
                fieldId={fieldId}
                parentFieldId={parentFieldId}
                providerIdentityId={providerIdentityId}
                containerId={containerId}
                containerSide={containerSide}
                value={value}
                multiSelect={!isSetDefault}
                placeholder="Select a value"
                readOnly={isLocked}
                itemType={itemType}
                containerType={containerType}
                isOptionDisabled={({ id }) => ({
                  disabled: fieldDetails.values?.includes(id) && !value?.includes(id),
                })}
                onChange={(newValue) => {
                  setValue(`${name}.value`, newValue, { shouldDirty: true });
                }}
              />
            </MaxWidthInput>
          )}
        </>
      )}
    </>
  );
}

RulesRowNormalField.propTypes = {
  name: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
  isAction: PropTypes.bool,
  containerSide: PropTypes.oneOf(['A', 'B']),
  providerName: PropTypes.string,
  providerIdentityId: PropTypes.string,
  containerId: PropTypes.string,
  // FIXME: PCDv3 - Remove fieldDetails as we don't need to differenciate when displaying if the field is a filter, set default value or component from mapping
  fieldDetails: PropTypes.shape({
    existingOperators: PropTypes.arrayOf(PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator))),
    canBeDenied: PropTypes.bool,
    canBeAllowed: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  }),
  data: PropTypes.shape({
    operator: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)),
    fieldId: PropTypes.string,
    parentFieldId: PropTypes.string,
    kind: PropTypes.oneOf([...Object.values(fieldTypes.KINDS)]),
    type: PropTypes.string,
    id: PropTypes.string,
    _id: PropTypes.string,
    isSetDefault: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])),
    ]),
  }),
  itemType: PropTypes.string,
  containerType: PropTypes.string.isRequired,
};
