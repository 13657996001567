import { useMemo } from 'react';

import { fieldTypes, linkTypes } from 'consts';

import * as formUtils from '../utils/form';
import { useGetProvidersCanCreateWorkItem } from './useGetProvidersCanCreateWorkItem';
import { useGetQueryParams } from './useGetQueryParams';
import { useAreBothSidesReadOnly } from './useAreBothSidesReadOnly';

const defaultSideFormData = {
  actions: [],
  filters: [],
  filtersInitializedAt: undefined,
  containerType: null,
  itemType: null,
  /** Modern integrations construct nodes while navigating the integration tree */
  nodes: [],
  streamAttachments: null,
  includePublicComments: null,
  providerContainerId: null,
  containerId: null,
  parentContainer: null,
  providerName: null,
  providerIdentityId: null,
  itemFieldAssociations: null,
  /**
   * (Temporary?) Make merge fields handling more palatable in ManageDuplicates since the real merge field (fields)
   * is an array but we only support one value, we use this intermediary field to make the implementation a bit simpler
   * in the modal.
   */
  mergeField: null,
  onFilterOut: linkTypes.ON_FILTER_OUT_VALUES.IGNORE,
  merge: { fields: null },
  deepFilters: [],
  prefix: false,
  prefixes: [],
};

const defaultSideRulesFormData = {
  filters: { startSyncing: undefined },
  itemsQueryLimit: null,
};

const defaultFormData = {
  _id: null,
  A: defaultSideFormData,
  B: defaultSideFormData,
  associations: [],
  duplicateLinkId: null,
  isAutoSync: true,
  lazyResync: true,
  linkSettingsLastModifiedOn: null,
  lastSyncRequest: null,
  /** By default the syncDirection is BOTH, unless the tool is one-way which is handled on the Connect Tools page */
  syncDirection: fieldTypes.TARGET.BOTH,
  manualOptions: null,
  name: '',
  rules: {
    A: defaultSideRulesFormData,
    B: defaultSideRulesFormData,
  },
  state: linkTypes.LINK_STATES.DRAFT,
  /** Used for integrations supporting merging items (contacts) */
  truthSide: null,
  workflowId: null,
};

export function formatSide({ formDataSide = {}, queryParamsSide = {}, isDuplicateRoute }) {
  let {
    providerName = null,
    containerId = null,
    itemType = null,
    containerType = null,
    providerIdentityId = null,
  } = formDataSide;
  const actions = formDataSide.actions ?? [];
  const filters = formDataSide.filters ?? [];
  let filtersInitializedAt = formDataSide.filtersInitializedAt || null;

  if (isDuplicateRoute) {
    const now = Date.now();

    // If actions and filters were prepared from a duplicate, indicate that they were initialized at the current time
    // NOTE: This could potentially be removed (to be tested) since we now have a new mechanism to handle duplicate flow
    // form data initialization
    filtersInitializedAt = formDataSide.filtersInitializedAt || now;

    // Duplicate values should be prioritized over query params thus why it's in a else if
  } else if (Object.keys(queryParamsSide).length) {
    containerId = formDataSide.containerId ?? queryParamsSide.containerId;
    providerName = formDataSide.providerName ?? queryParamsSide.providerName;
    itemType = formDataSide.itemType ?? queryParamsSide.itemType;
    containerType = formDataSide.containerType ?? queryParamsSide.containerType;
    providerIdentityId = formDataSide.providerIdentityId ?? queryParamsSide.providerIdentityId;
  }

  return {
    ...formDataSide,
    containerId,
    providerName,
    itemType,
    containerType,
    providerIdentityId,
    actions,
    filters,
    filtersInitializedAt,
  };
}

export function formatFormData({
  formDataFromLinkPayload = defaultFormData,
  isDuplicateRoute = false,
  linkId = null,
  queryParams = {},
}) {
  let formData = formDataFromLinkPayload;
  if (Object.keys(formData).length === 0) {
    formData = defaultFormData;
  }

  const data = {
    ...formData,
    A: formatSide({
      formDataSide: formData.A,
      queryParamsSide: formData.state === linkTypes.LINK_STATES.DRAFT ? queryParams.A : {},
      isDuplicateRoute,
    }),
    B: formatSide({
      formDataSide: formData.B,
      queryParamsSide: formData.state === linkTypes.LINK_STATES.DRAFT ? queryParams.B : {},
      isDuplicateRoute,
    }),
    lazyResync: formData.lazyResync ?? true,
    isAutoSync: formData.isAutoSync ?? true,
    _id: isDuplicateRoute ? null : linkId ?? formData._id,
    associations: isDuplicateRoute ? [] : formData.associations ?? [],
    duplicateLinkId: isDuplicateRoute ? linkId : formData.duplicateLinkId ?? null,
    name: formData.name ?? '',
    state: formData.state ?? linkTypes.LINK_STATES.DRAFT,
  };
  return data;
}

export function useGetFormData(linkId, currentLink, isDuplicateRoute = false) {
  const queryParams = useGetQueryParams();
  const formDataFromLinkPayload = useMemo(() => formUtils.linkPayloadToFlowBuilderFormData(currentLink), [currentLink]);

  const formData = useMemo(
    () =>
      formatFormData({
        formDataFromLinkPayload,
        isDuplicateRoute,
        linkId,
        queryParams,
      }),
    [formDataFromLinkPayload, isDuplicateRoute, linkId, queryParams],
  );

  // Below this point, these are form data values that might be enforced by our integration capabilities and own business logic
  formData.syncDirection = useGetSyncDirection(formData);

  return formData;
}

/**
 * Retrieves the sync direction based on the provider capabilities
 *
 * - If the sync direction is null, it means that the 2 integrations are incompatible (2 one-way tools)
 * - If the tool has a one-way restriction, the sync direction is forced to the target
 * - Otherwise, the sync direction is the one set by the user or the default (BOTH)
 */
export function useGetSyncDirection(formData) {
  const canCreateWorkItem = useGetProvidersCanCreateWorkItem({
    containerIdA: formData.A.containerId,
    containerIdB: formData.B.containerId,
    providerNameA: formData.A.providerName,
    providerNameB: formData.B.providerName,
    itemTypeA: formData.A.itemType,
    itemTypeB: formData.B.itemType,
  });

  const areBothSidesReadOnly = useAreBothSidesReadOnly({
    containerIdA: formData.A.containerId,
    containerIdB: formData.B.containerId,
    providerNameA: formData.A.providerName,
    providerNameB: formData.B.providerName,
    itemTypeA: formData.A.itemType,
    itemTypeB: formData.B.itemType,
  });

  // The sync direction is null, as these are 2 incompatible integrations (2 one-way tools)
  if (areBothSidesReadOnly) {
    return null;
  }

  // Enforce direction if tool is one-way
  if (canCreateWorkItem.target !== fieldTypes.TARGET.BOTH) {
    return canCreateWorkItem.target;
  }

  // The sync direction set by the user is prioritized over the default BOTH
  return formData.syncDirection ?? canCreateWorkItem.target ?? fieldTypes.TARGET.BOTH;
}
