import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { NewAlert, Icon, NewAlertLevel, tokens } from '@unitoio/mosaic';

import { capitalize } from 'utils';
import { getFieldById, getProviderCapabilitiesV3 } from 'reducers';
import { fieldTypes } from 'consts';
import { useGetIncompleteGroupField } from '../../../hooks/useGetIncompleteGroupField';

const SyncDirectionIconName = {
  A: 'long-arrow-left',
  both: 'exchange',
  B: 'long-arrow-right',
};

const BoldText = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const getMappingName = (mappingSide, field, workItem) => {
  if (mappingSide.kind === fieldTypes.KINDS.CUSTOM_FIELD) {
    return capitalize(field.get('name'));
  }

  return capitalize(workItem.getIn(['fields', mappingSide.field, 'names', 'singular']));
};

const IncompleteMappingText = ({ mapping, areAllItemsListed, syncDirection, A, B }) => {
  const workItemA = useSelector((state) => getProviderCapabilitiesV3(state, A).get('item'));
  const workItemB = useSelector((state) => getProviderCapabilitiesV3(state, B).get('item'));

  const fieldA = useSelector((state) =>
    getFieldById(state, { kind: mapping.A.kind, fieldId: mapping.A.field, containerSide: 'A' }),
  );
  const fieldB = useSelector((state) =>
    getFieldById(state, { kind: mapping.B.kind, fieldId: mapping.B.field, containerSide: 'B' }),
  );

  return (
    <BoldText key={`${mapping.A.field}_${mapping.B.field}`}>
      {getMappingName(mapping.A, fieldA, workItemA)}{' '}
      <Icon name={SyncDirectionIconName[mapping.target ?? syncDirection]} />{' '}
      {getMappingName(mapping.B, fieldB, workItemB)}
      {!areAllItemsListed && ', '}
    </BoldText>
  );
};

IncompleteMappingText.propTypes = {
  mapping: PropTypes.shape({
    A: PropTypes.shape({
      field: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
    }).isRequired,
    B: PropTypes.shape({
      field: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
    }).isRequired,
    target: PropTypes.string,
  }).isRequired,
  areAllItemsListed: PropTypes.bool.isRequired,
  syncDirection: PropTypes.string.isRequired,
  A: PropTypes.shape({
    providerName: PropTypes.string.isRequired,
    itemType: PropTypes.string.isRequired,
  }).isRequired,
  B: PropTypes.shape({
    providerName: PropTypes.string.isRequired,
    itemType: PropTypes.string.isRequired,
  }).isRequired,
};

export function IncompleteFieldMappingGroupAlert() {
  const { watch } = useFormContext();
  const [incompleteMapping, incompleteMappingCount] = useGetIncompleteGroupField();

  const syncDirection = watch('syncDirection');
  const A = {
    providerName: watch('A.providerName'),
    itemType: watch('A.itemType'),
  };
  const B = {
    providerName: watch('B.providerName'),
    itemType: watch('B.itemType'),
  };

  return (
    incompleteMappingCount > 0 && (
      <NewAlert
        level={NewAlertLevel.WARNING}
        message={
          <>
            In order for this flow to sync properly, you will need to make sure you have fields selected for both tools
            in the following mappings:{' '}
            {incompleteMapping.map((mapping, index) => {
              // Using this variable to show a comma between different FA
              const areAllItemsListed = incompleteMappingCount - 1 === index;
              return (
                <IncompleteMappingText
                  key={`${mapping.A.field}_${mapping.B.field}`}
                  mapping={mapping}
                  areAllItemsListed={areAllItemsListed}
                  syncDirection={syncDirection}
                  A={A}
                  B={B}
                />
              );
            })}
          </>
        }
      />
    )
  );
}
