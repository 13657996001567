import { useSelector } from 'react-redux';

import { getLinkById } from 'reducers';

export function useGetItemTypes(linkId) {
  const link = useSelector((state) => getLinkById(state, linkId));
  const itemTypeA = link.getIn(['A', 'itemType']);
  const itemTypeB = link.getIn(['B', 'itemType']);
  return [itemTypeA, itemTypeB];
}
