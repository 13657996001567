import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { Box, Link, Modal, tokens, Typography } from '@unitoio/mosaic/';

import { routes } from 'consts';

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const StyledLink = styled(Link)`
  margin: 0;
`;

export const DeleteWorkspaceWarningModal = ({ isOpen, onClose }) => {
  const { organizationId } = useParams();

  return (
    <Modal
      isOpen={isOpen}
      title="Before closing your workspace"
      confirmLabel="Got it"
      displayCancelButton={false}
      onRequestClose={onClose}
      onConfirm={onClose}
    >
      <Box>
        <Box m={[0, 0, tokens.spacing.s5, 0]}>
          <Typography variant={Typography.variants.BODY1}>
            In order to close your workspace, <Bold>you need to be the only member using it</Bold>.
          </Typography>
          <ul>
            <li>
              <Typography variant={Typography.variants.BODY1}>
                First, remove everyone else from the{' '}
                <StyledLink target="_blank" href={`#${routes.ABSOLUTE_PATHS.MEMBERS(organizationId)}`}>
                  members page
                </StyledLink>
              </Typography>
            </li>
            <li>
              <Typography variant={Typography.variants.BODY1}>
                Then, return to this page and click Close workspace again
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </Modal>
  );
};

DeleteWorkspaceWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
