import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { Button, Href, Section, Title } from 'components';
import { useQueryParams } from 'hooks';

import { AuthButtonsWrapper } from '../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../AuthTitleWrapper';
import { AuthOrderedList } from '../AuthOrderedList';
import { AuthImageWrapper } from '../AuthImageWrapper';
import zendeskApiToken1 from './zendesk-api-token-1.png';

function AuthZendeskApiTokenStep1Component({ domain, history, onCancel, trackEvent }) {
  const { windowOpenerId } = useQueryParams();
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          <strong>(API Token - Step 1 of 2)</strong>
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>
          Next, create a new <strong>API Token</strong> for Unito.
        </p>
        <AuthImageWrapper>
          <img src={zendeskApiToken1} alt="Zendesk API Token Step - Step 1" />
        </AuthImageWrapper>
        <AuthOrderedList>
          <li>
            Open the{' '}
            <Href href={`${domain}/agent/admin/api/settings/tokens`}>
              Admin {'>'} Channels {'>'} API
            </Href>{' '}
            page to create a new API Token.
          </li>
          <li>
            In the Token Access section, click the <strong>+</strong> button to the right of{' '}
            <strong>Active API Tokens</strong>.
          </li>
        </AuthOrderedList>
        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            to={`step2?windowOpenerId=${windowOpenerId}`}
            onClick={() => trackEvent('api token onboarding 1 (of 2)')}
            type="href"
            pullRight
          >
            Continue
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthZendeskApiTokenStep1Component.propTypes = {
  domain: PropTypes.string.isRequired,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

const formSelector = formValueSelector('authentication');

const mapStateToProps = (state) => ({
  domain: formSelector(state, 'domain'),
});

export const AuthZendeskApiTokenStep1 = connect(mapStateToProps)(AuthZendeskApiTokenStep1Component);
