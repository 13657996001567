import { List, Map, fromJS, Iterable } from 'immutable';

import { fieldTypes, pcdFilterOperatorTypes } from 'consts';
import { capitalize, formUtils } from 'utils';

const { PCD_TYPED_FIELD } = fieldTypes.KINDS;
const { ID } = fieldTypes.FIELD_VALUES_TYPE;
const CONTAINER_LINK_MAX_LENGTH = 64;
const CONTAINER_LINK_DISPLAY_LENGTH = 32;

// reference: https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address/574698#574698
export const FIELD_MAX_LENGTH = 254;

export const ORGANIZATION_NAME_MAX_LENGTH = 60;

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'string' && value.trim() === '') ||
  (Array.isArray(value) && !value.length);

export const isEmptyObject = (maybeObject) => typeof maybeObject === 'object' && !Object.keys(maybeObject).length;

export const getFormattedManualOptions = (manualOptions) => {
  // This is so we don't display an empty object in the manual option textearea
  // when no specific manual setting exist
  const formattedManualOptions =
    manualOptions && Object.keys(manualOptions).length > 0 ? JSON.stringify(manualOptions, null, 2) : undefined;

  return formattedManualOptions;
};

const isFilterMapping = (setting) =>
  Map.isMap(setting) &&
  ['whiteList', 'blackList', 'multisyncDiscriminant'].some((filterType) => setting.has(filterType));

const settingHasItemFilters = (setting) => Map.isMap(setting) && setting.some(isFilterMapping);

export const getMultisyncDiscriminants = (syncSettings, containerSide) => {
  const discriminants = [];

  syncSettings.get(containerSide)?.forEach((setting, fieldId) => {
    if (Map.isMap(setting) && setting.has('multisyncDiscriminant')) {
      discriminants.push({
        fieldId,
        fieldValueType: setting.get('fieldValueType', ID),
        kind: setting.get('kind', PCD_TYPED_FIELD),
        value: setting.get('multisyncDiscriminant'),
      });
    }
  });

  return discriminants;
};

const getFiltersForSetting = (setting, fieldId, allowEmpty, parentField) => {
  const filters = [];
  ['whiteList', 'blackList'].forEach((filterType) => {
    const filterValues = setting.get(filterType);
    const shouldAddFilter = filterValues && List.isList(filterValues) && (!filterValues.isEmpty() || allowEmpty);

    if (shouldAddFilter) {
      const formattedValues =
        typeof filterValues.first() === 'boolean'
          ? filterValues.first()
          : filterValues
              .toArray()
              .filter((f) => f !== null)
              // Show display name with a star, if the value was deleted
              .map((fieldValue) => ({ id: fieldValue, displayName: `${fieldValue} *` }));

      filters.push({
        fieldId,
        fieldValueType: setting.get('fieldValueType', ID),
        kind: setting.get('kind', PCD_TYPED_FIELD),
        type: filterType,
        values: formattedValues,
        parentField,
      });
    }
  });

  return filters;
};

export const getFilters = (syncSettings, containerSide, allowEmpty = false) =>
  syncSettings.get(containerSide).reduce((outerAcc, setting, fieldId) => {
    if (isFilterMapping(setting)) {
      return [...outerAcc, ...getFiltersForSetting(setting, fieldId, allowEmpty)];
    }

    if (settingHasItemFilters(setting)) {
      return [
        ...outerAcc,
        ...setting.reduce((innerAcc, value, key) => {
          if (!isFilterMapping(value)) {
            return innerAcc;
          }
          return [...innerAcc, ...getFiltersForSetting(value, key, allowEmpty, fieldId)];
        }, []),
      ];
    }

    return outerAcc;
  }, []);

/**
 * Returns the pcdOptions from syncSettings. Done by removing the hardcoded values.
 *
 * @param {object} formData[side] - The formData of the syncForm on a specific side
 * @returns {object} - The plucked pcdOptions the sideFormData to be sent to maestro
 */
export const getPcdOptions = (syncSettings, containerSide) => {
  const STATIC_OPTIONS = ['closedTasks', 'onFilterOut', 'readOnly', 'user', 'itemFieldAssociations'];
  const sideSettings = syncSettings.get(containerSide);
  const pcdOptions = {};

  sideSettings?.forEach((setting, settingKey) => {
    // These are not pcd options
    if (STATIC_OPTIONS.includes(settingKey)) {
      return;
    }

    if (isFilterMapping(setting)) {
      return;
    }

    pcdOptions[settingKey] = fromJS(setting);
  });

  return pcdOptions;
};

/**
 * sanitizeFieldFilters() deletes filters from syncSettings that were removed by the user.
 *
 * It also covers the specific use case of item field filters we encounter with conditional containers.
 * 👇 See below for more details.👇
 *
 * ABOUT ITEM FIELD FILTERS
 * We need to remove all the previsouly added item field filters when they get deleted from the sync configuration.
 * For instance when the previous sync configuration has a filter on dealstage
 * syncSettings: {
 *   A: {
 *     deals: {
 *       dealstage: {
 *         whiteList: [myDealStage],
 *       },
 *     },
 *   },
 * }
 *
 * and the user edit the sync and remove this filter, we need to remove the dealstage from the configuration
 * and in this specific case we also have to remove the deals key since the dealstage was the only item field filter.
 */
export function sanitizeFieldFilters({ syncSettings, side, filters, deepFilters, isFlowBuilder }) {
  let updatedSyncSettings = syncSettings;

  updatedSyncSettings.get(side).forEach((value, key) => {
    if (isFilterMapping(value)) {
      // Return all the filter fields that match the current field (e.g. labels)
      const fieldFilters = filters.filter((item) => item.fieldId === key);
      if (fieldFilters.length === 0) {
        // If we no longer have that field, delete it from syncSettings
        updatedSyncSettings = updatedSyncSettings.deleteIn([side, key]);
      }
      if (!isFlowBuilder) {
        ['whiteList', 'blackList'].forEach((type) => {
          // For each type of filter, find the first field that has that same type
          const filter = fieldFilters.find((fieldFilter) => fieldFilter.type === type);
          if (!filter || isEmpty(filter?.values)) {
            // If you can't find a field with that type or you find a filter but all the values inside were deleted, then delete it from syncSettings
            updatedSyncSettings = updatedSyncSettings.deleteIn([side, key, type]);
          }
        });
      }
    }

    if (settingHasItemFilters(value)) {
      value.forEach((_itemFieldFilter, itemFieldName) => {
        let hasFilter;
        if (isFlowBuilder) {
          const filterFound = deepFilters.find((filter) => filter.fieldId === itemFieldName);
          hasFilter = filterFound?.value?.length;
        } else {
          const filterFound = filters.find((filter) => filter.fieldId === itemFieldName);
          hasFilter = filterFound?.values.length;
        }

        if (!hasFilter) {
          updatedSyncSettings = updatedSyncSettings.deleteIn([side, key, itemFieldName]);

          if (!settingHasItemFilters(updatedSyncSettings.getIn([side, key]))) {
            updatedSyncSettings = updatedSyncSettings.deleteIn([side, key]);
          }
        }
      });
    }
  });

  return updatedSyncSettings;
}

/**
 * Returns the marshalling formData to be sent to maestro.
 *
 * @param {Immutable.Map} state - The redux state
 * @param {object} formData - The formData of the syncForm
 * @returns {object} - The payload of the sync to be sent to maestro
 */
export const getEditSyncPayload = (formData, syncSettings, isFlowBuilder = false) => {
  const {
    automapUsers,
    A,
    B,
    isAutoSync,
    manualOptions,
    name,
    lazyResync = false,
    syncDirection,
    truthSide,
    rules,
  } = formData;

  const isReadOnlyA = isFlowBuilder
    ? toReadOnlyFlowBuilder(syncDirection, 'A')
    : (A.readOnly || A.settings?.readOnly) ?? false;

  const isReadOnlyB = isFlowBuilder
    ? toReadOnlyFlowBuilder(syncDirection, 'B')
    : (B.readOnly || B.settings?.readOnly) ?? false;

  const normalizeTweaks = (tweaks = {}) => {
    const normalizedTweaks = Object.entries(tweaks).reduce(
      (normalized, [tweakName, tweakValue]) => {
        if (tweakValue === null || typeof tweakValue !== 'object' || Iterable.isIterable(tweakValue)) {
          return normalized;
        }
        return {
          ...normalized,
          // TODO validate if we always want to take the id field here, might not be true with PCD v3
          [tweakName]: tweakValue?.id ?? tweakValue,
        };
      },
      tweaks, // initialize with all tweaks by default, then go over the ones with a special structure
    );

    return normalizedTweaks;
  };

  const actionsA = isFlowBuilder ? toLinkNormalizedActions(formData.A.actions) : normalizeTweaks(A.tweaks);
  const actionsB = isFlowBuilder ? toLinkNormalizedActions(formData.B.actions) : normalizeTweaks(B.tweaks);

  let updatedSyncSettings = syncSettings
    .set('manualOptions', manualOptions)
    .set('automapUsers', automapUsers)
    .mergeDeepIn(
      ['A'],
      fromJS({
        readOnly: isReadOnlyA,
        closedTasks: A.closedTasks ?? A.settings?.includeClosedTasks,
        ...(A?.includePublicComments !== undefined ? { includePublicComments: !!A?.includePublicComments } : {}),
        user: syncSettings.getIn(['A', 'user']),
        itemFieldAssociations: A.itemFieldAssociations ?? syncSettings.getIn(['A', 'itemFieldAssociations']),
        streamAttachments: A.streamAttachments,
        onFilterOut: A.onFilterOut,
        ...actionsA,
      }),
    )
    .mergeDeepIn(
      ['B'],
      fromJS({
        readOnly: isReadOnlyB,
        closedTasks: B.closedTasks ?? B.settings?.includeClosedTasks,
        ...(B?.includePublicComments !== undefined ? { includePublicComments: !!B?.includePublicComments } : {}),
        user: syncSettings.getIn(['B', 'user']),
        itemFieldAssociations: B.itemFieldAssociations ?? syncSettings.getIn(['B', 'itemFieldAssociations']),
        streamAttachments: B.streamAttachments,
        onFilterOut: B.onFilterOut,
        ...actionsB,
      }),
    );

  ['A', 'B'].forEach((side) => {
    // Filters
    const filters = formData[side].filters || [];
    const deepFilters = formData[side].deepFilters || [];
    if (isFlowBuilder) {
      updatedSyncSettings = updatedSyncSettings.setIn([side, 'truthSide'], truthSide === side);
      updatedSyncSettings = updatedSyncSettings.mergeIn([side], toLinkPayloadFlowBuilderFilters(filters, deepFilters));
      updatedSyncSettings = updatedSyncSettings.setIn(
        [side, 'itemFieldAssociations'],
        formData[side].itemFieldAssociations,
      );
      if (typeof formData[side].prefix !== 'undefined') {
        if (!formData[side].prefixes.length || !formData[side].prefix) {
          updatedSyncSettings = updatedSyncSettings.setIn([side, 'taskNumberPrefix'], formData[side].prefix);
        } else {
          updatedSyncSettings = updatedSyncSettings.setIn(
            [side, 'taskNumberPrefix'],
            formUtils.toLinkPayloadPrefixes(formData[side].prefixes),
          );
        }
      }
    } else {
      // TODO legacy logic for parsing filters
      // try to replace with toLinkPayloadFilters at some point
      filters.forEach((filter) => {
        const { fieldValueType, fieldId, kind, parentField, type, values } = filter;

        // Only save non-empty filters
        if (!isEmpty(values)) {
          const formatedValues = typeof values === 'boolean' ? [values] : values.map((opt) => opt.id);

          const pathToFilter = parentField ? [side, parentField, fieldId] : [side, fieldId];

          updatedSyncSettings = updatedSyncSettings
            .setIn([...pathToFilter, type], formatedValues)
            .setIn([...pathToFilter, 'fieldValueType'], fieldValueType)
            .setIn([...pathToFilter, 'kind'], kind);
        }
      });
    }

    updatedSyncSettings = sanitizeFieldFilters({
      syncSettings: updatedSyncSettings,
      side,
      filters,
      deepFilters,
      isFlowBuilder,
    });
  });

  if (isFlowBuilder) {
    // Add mappings from FlowBuilder MappedFields sicne they're located somewhere else
    const earliestCreatedAt =
      A.filters.find((item) => item.fieldId === fieldTypes.EARLIEST_CREATED_AT) ||
      B.filters.find((item) => item.fieldId === fieldTypes.EARLIEST_CREATED_AT)
        ? syncSettings.get(fieldTypes.EARLIEST_CREATED_AT)
        : null;
    updatedSyncSettings = updatedSyncSettings
      .set('fieldAssociations', toLinkPayloadAssociations(formData.associations))
      .set(fieldTypes.EARLIEST_CREATED_AT, earliestCreatedAt);
  }

  const payload = {
    A: { providerIdentity: A.providerIdentityId },
    B: { providerIdentity: B.providerIdentityId },
    isAutoSync,
    name,
    lazyResync,
    syncSettings: updatedSyncSettings.toJS(),
    rules,
  };

  return payload;
};

/**
 * Shorten a string, potentially user-provided and containing problematic characters.
 * @param {string} stringToShorten
 * @param {number} maxLength
 * @param {string} shorteningCharacter Character appended at the end of the truncated string.
 */
export const shortenString = (stringToShorten, maxLength, shorteningCharacter = '…') => {
  if (maxLength === undefined) {
    throw new Error('maxLength argument is required');
  }

  // When truncating a user string, we must be careful to not cut in the middle of a double-width
  // UTF-16 char (e.g. emojis). OldJS is clueless, but modernJS spread (...) does the right thing:
  // "ab".slice(0, 1)                   ← returns "a",  good!
  // "👍🙂".slice(0, 1)                 ← returns '�',  baad!
  // [..."👍🙂"].slice(0, 1).join('')   ← returns '👍', good!
  const shortened =
    stringToShorten.length > maxLength
      ? `${[...stringToShorten].slice(0, maxLength).join('').trim()}${shorteningCharacter}`
      : stringToShorten;

  return shortened;
};

const generateFlowIcon = ({ areBothSidesSelected, readOnlyA, readOnlyB }) => {
  if (!areBothSidesSelected) {
    return '';
  }

  if (readOnlyA) {
    return '→';
  }

  if (readOnlyB) {
    return '←';
  }

  if (readOnlyA === false && readOnlyB === false) {
    return '⇄';
  }

  return '+';
};

const generateDisplayNameBySide = (providerName, providerItemTerm, containerName) => {
  const displayNameBySide = [];
  if (containerName && providerItemTerm) {
    displayNameBySide.push(containerName, providerItemTerm);
  } else if (providerName && providerItemTerm) {
    displayNameBySide.push(providerName, providerItemTerm);
  } else {
    displayNameBySide.push(providerName);
  }

  return shortenString(displayNameBySide.join(displayNameBySide.length > 1 ? ' ' : ''), CONTAINER_LINK_DISPLAY_LENGTH);
};

export const generateDefaultLinkName = ({
  providerDisplayNameA,
  providerDisplayNameB,
  containerNameA,
  containerNameB,
  readOnlyA = null,
  readOnlyB = null,
  providerItemTermA,
  providerItemTermB,
}) => {
  const displayNameSideA = generateDisplayNameBySide(providerDisplayNameA, providerItemTermA, containerNameA);
  const displayNameSideB = generateDisplayNameBySide(providerDisplayNameB, providerItemTermB, containerNameB);
  const areBothSidesSelected = !!displayNameSideA && !!displayNameSideB;
  const icon = generateFlowIcon({ areBothSidesSelected, readOnlyA, readOnlyB });
  const flowName = `${displayNameSideA} ${icon} ${displayNameSideB}`;

  return shortenString(flowName.trim(), CONTAINER_LINK_MAX_LENGTH);
};

export const validateEmailAddress = (emailAddress) => {
  if (!emailAddress || emailAddress.length >= FIELD_MAX_LENGTH) {
    return false;
  }

  const emailAddressPattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return emailAddressPattern.test(emailAddress.toLowerCase());
};

/**
 * Bug with `fields.getAll()` 😱
 * https://github.com/redux-form/redux-form/issues/3679
 */
export function reduxFormFieldsGetAll(fields) {
  const fixedFields = [];
  (fields || []).forEach((_, index) => fixedFields.push(fields.get(index)));
  return fixedFields;
}

export function toClosedTasksFlowBuilder(formSideFilters) {
  if (!formSideFilters || formSideFilters.length === 0) {
    return undefined;
  }

  const includeClosedTasks = formSideFilters.find((field) => field.fieldId === fieldTypes.INCLUDE_CLOSED_TASKS);

  if (!includeClosedTasks) {
    return undefined;
  }

  return includeClosedTasks.value === 'all';
}

export function toReadOnlyFlowBuilder(syncDirection, containerSide) {
  if (!syncDirection) {
    return undefined;
  }

  if (syncDirection === 'both') {
    return false;
  }

  return syncDirection !== containerSide;
}

export function toTestModeFlowBuilder(formSideFilters = []) {
  return !!formSideFilters.find((field) => field.fieldId === fieldTypes.EARLIEST_CREATED_AT);
}

export function toLinkPayloadPrefixes(prefixes) {
  if (!prefixes.length) {
    return true;
  }
  return prefixes.reduce((acc, { key, value }) => {
    if (key && typeof value === 'string') {
      acc[key] = value;
    }
    return acc;
  }, {});
}

function toLinkPayloadFlowBuilderDeepFilters(formSideDeepFilters = []) {
  const filters = formSideDeepFilters.reduce((filterObj, condition) => {
    const filter = {
      kind: condition.kind,
      fieldValueType:
        condition.type === fieldTypes.FIELD_VALUES_TYPE.BOOLEAN && condition.kind === fieldTypes.KINDS.CUSTOM_FIELD
          ? fieldTypes.FIELD_VALUES_TYPE.BOOLEAN
          : fieldTypes.FIELD_VALUES_TYPE.ID,
    };

    if (condition.operator === pcdFilterOperatorTypes.pcdFilterOperator.NIN) {
      filter.blackList = condition.value;
    }

    if (
      [
        pcdFilterOperatorTypes.pcdFilterOperator.IN,
        pcdFilterOperatorTypes.pcdFilterOperator.EXISTS,
        pcdFilterOperatorTypes.pcdFilterOperator.NOT_EXISTS,
        pcdFilterOperatorTypes.pcdFilterOperator.EQ,
      ].includes(condition.operator)
    ) {
      filter.whiteList = Array.isArray(condition.value)
        ? condition.value
        : [condition.operator === pcdFilterOperatorTypes.pcdFilterOperator.EXISTS];
    }

    return {
      ...filterObj,
      [condition.fieldId]: {
        ...(filterObj[condition.fieldId] || {}),
        ...filter,
      },
    };
  }, {});

  return filters;
}

export function toLinkPayloadFlowBuilderFilters(formSideFilters = [], formSideDeepFilters = []) {
  const filters = formSideFilters.reduce((filterObj, condition) => {
    if (condition.fieldId === fieldTypes.EARLIEST_CREATED_AT) {
      return filterObj;
    }

    if (condition.fieldId === fieldTypes.SUBFOLDERS) {
      const subfoldersIncluded = condition.value === 'included';
      return { ...filterObj, [fieldTypes.SUBFOLDERS]: subfoldersIncluded };
    }

    if (condition.fieldId === fieldTypes.INCLUDE_CLOSED_TASKS) {
      return { ...filterObj, [fieldTypes.CLOSED_TASKS]: condition.value === 'all' };
    }

    const filter = {
      kind: condition.kind,
      fieldValueType:
        condition.type === fieldTypes.FIELD_VALUES_TYPE.BOOLEAN && condition.kind === fieldTypes.KINDS.CUSTOM_FIELD
          ? fieldTypes.FIELD_VALUES_TYPE.BOOLEAN
          : fieldTypes.FIELD_VALUES_TYPE.ID,
    };

    if (condition.operator === pcdFilterOperatorTypes.pcdFilterOperator.NIN) {
      filter.blackList = condition.value;
    }

    if (
      [
        pcdFilterOperatorTypes.pcdFilterOperator.IN,
        pcdFilterOperatorTypes.pcdFilterOperator.EXISTS,
        pcdFilterOperatorTypes.pcdFilterOperator.NOT_EXISTS,
        pcdFilterOperatorTypes.pcdFilterOperator.EQ,
      ].includes(condition.operator)
    ) {
      filter.whiteList = Array.isArray(condition.value)
        ? condition.value
        : [condition.operator === pcdFilterOperatorTypes.pcdFilterOperator.EXISTS];
    }

    // deep filters
    if (condition.fieldId === fieldTypes.DEEP_FILTER_ITEM) {
      if (!condition?.value || condition.value === 'all') {
        // remove deep filters if we are syncing all objects
        return filterObj;
      }
      return {
        ...filterObj,
        [condition.value]: toLinkPayloadFlowBuilderDeepFilters(formSideDeepFilters),
      };
    }

    return {
      ...filterObj,
      [condition.fieldId]: {
        ...(filterObj[condition.fieldId] || {}),
        ...filter,
      },
    };
  }, {});

  return filters;
}

// TODO investigate why and if it's normal that formAssociations is sometimes
// undefined.
export function toLinkPayloadAssociations(formAssociations) {
  const associations = formAssociations || [];
  return (
    associations
      // Subtasks is not a real field mapping for now, remove once PCDv3 is out
      .filter(
        (fieldAssociation) => ![fieldAssociation.A?.field, fieldAssociation.B?.field].includes(fieldTypes.SUBTASK),
      )
      .map((fieldAssociation) =>
        /**
         * FROM {
         *   A: {
         *     mapping: {
         *       values: [{value: 'a value'}]
         *     }
         *   },
         *   B: {
         *     mapping: {
         *       values: [{value: 'another value'}]
         *     }
         *   }
         * }
         *
         * TO
         * {
         *   A: {
         *     mapping: [['a value']],
         *   },
         *   B: {
         *     mapping: [['another value']]
         *   }
         * }
         */

        ['A', 'B'].reduce((acc, side) => {
          if (!acc[side]?.mapping && !acc[side]?.hasMapping) {
            if (acc[side]) {
              delete acc[side].hasMapping;
            }
            return acc;
          }

          if (acc[side]?.hasMapping && !acc[side]?.mapping) {
            // mapping gets lost if it's empty due to fieldArray being hidden
            acc[side].mapping = [];
          }

          const fieldMapping = acc[side].mapping.map((mapping) =>
            mapping.values.map((mappingValue) => mappingValue.value),
          );

          // we don't need to save the hasMapping flag to the database
          delete acc[side].hasMapping;

          return { ...acc, [side]: { ...acc[side], mapping: fieldMapping } };
        }, fieldAssociation),
      )
  );
}

export function toLinkPayloadFilters(formSideFilters = []) {
  return formSideFilters
    .reduce((payloadFilters, filter) => {
      const { fieldValueType, fieldId, kind, parentField, type, values } = filter;
      if (!isEmpty(values)) {
        // TODO: see how we can leverage fieldValueType in new flow builder to make the normalizedArrayValues
        // more flexible. Instead of assuming 'id', we could use 'fieldValueType' to determine the correct field
        // to extract the value from. Also values sent have to arrayified.
        const normalizedArrayValues = typeof values === 'boolean' ? [values] : values.map((opt) => opt.id);
        const newFilter = parentField
          ? fromJS({
              [parentField]: {
                [fieldId]: {
                  fieldValueType,
                  kind,
                  [type]: normalizedArrayValues,
                },
              },
            })
          : fromJS({
              [fieldId]: {
                fieldValueType,
                kind,
                [type]: normalizedArrayValues,
              },
            });
        // Need to merge here because the same filter can be parsed several times
        // based on whether it's been used as a whiteList or blackList
        return payloadFilters.mergeDeep(newFilter);
      }
      return payloadFilters;
    }, Map())
    .toJS();
}

export const passwordPolicies = [
  {
    id: 'lengthPolicy',
    label: 'More than 12 characters',
    validationFn: (text) => text.length >= 12,
  },
  {
    id: 'uppercasePolicy',
    label: 'At least one uppercase character',
    validationFn: (text) => /(?=.*[A-Z])/.test(text),
  },
  {
    id: 'lowercasePolicy',
    label: 'At least one lowercase character',
    validationFn: (text) => /(?=.*[a-z])/.test(text),
  },
];

export const isPasswordValid = (password) =>
  password && passwordPolicies.every((policy) => policy.validationFn(password));

export function getItemFieldAssociations(itemFieldAssociations, itemFilters) {
  // `itemFieldAssociations: {}` means we're sycing all objects
  // undefined means the provider doesn't support conditional containers
  // itemFieldAssociations: { [object]: null } means [object] was selected, but the other side doesn't support custom fields so no association is needed, but we still have to know the object selected.
  // (itemFieldAssociations: { [object]: null } is also a possible state with a draft link)
  // otherwise: itemFieldAssociations: { [object]: [customFieldId] } means we're syncing items in relation to [object]
  if (itemFilters.length === 0) {
    return undefined;
  }

  if (itemFilters.find((itemFilter) => itemFilter === 'all')) {
    return {};
  }

  return itemFilters.reduce((acc, itemFilter) => {
    if (!itemFieldAssociations || !itemFieldAssociations[itemFilter]) {
      return { ...acc, [itemFilter]: null };
    }

    return { ...acc, [itemFilter]: itemFieldAssociations[itemFilter] };
  }, {});
}

export function toLinkNormalizedActions(actions = []) {
  return actions.reduce((toPayloadActions, action) => {
    const { value, isSetDefault, fieldId } = action;
    // FIXME: PCDv3 - Remove this once PCDv3 is implemented as we won't have the notion of tweaks
    if (!isSetDefault) {
      return toPayloadActions;
    }

    return { ...toPayloadActions, [`default${capitalize(fieldId)}`]: value };
  }, {});
}

export const getItemFiltersForSideForFlowBuilder = (filters, filterOutAllObjectsOption) => {
  if (!filters) {
    return [];
  }

  let itemFilters = filters
    .filter((filter) => filter.fieldId === fieldTypes.DEEP_FILTER_ITEM)
    .map((filter) => filter.value);

  if (!Array.isArray(itemFilters)) {
    itemFilters = [itemFilters];
  }

  if (filterOutAllObjectsOption) {
    return itemFilters.filter((itemFilter) => itemFilter !== 'all');
  }

  return itemFilters;
};
