import { providerIdentityTypes, routes } from 'consts';

export const getProviderIdentities = () => ({
  types: [
    providerIdentityTypes.GET_PROVIDER_IDENTITIES_REQUEST,
    providerIdentityTypes.GET_PROVIDER_IDENTITIES_SUCCESS,
    providerIdentityTypes.GET_PROVIDER_IDENTITIES_FAILURE,
  ],
  url: routes.API_PATHS.PROVIDER_IDENTITIES,
});

export const validateProviderIdentity = (providerIdentityId) => ({
  types: [
    providerIdentityTypes.VALIDATE_PROVIDER_IDENTITY_REQUEST,
    providerIdentityTypes.VALIDATE_PROVIDER_IDENTITY_SUCCESS,
    providerIdentityTypes.VALIDATE_PROVIDER_IDENTITY_FAILURE,
  ],
  method: routes.METHODS.POST,
  url: routes.API_PATHS.PROVIDER_IDENTITY_VALIDATED(providerIdentityId),
});

export const disconnectProviderIdentity = (providerIdentityId) => ({
  types: [
    providerIdentityTypes.DISCONNECT_PROVIDER_IDENTITY_REQUEST,
    providerIdentityTypes.DISCONNECT_PROVIDER_IDENTITY_SUCCESS,
    providerIdentityTypes.DISCONNECT_PROVIDER_IDENTITY_FAILURE,
  ],
  method: routes.METHODS.DELETE,
  url: routes.API_PATHS.DISCONNECT_PROVIDER_IDENTITY(providerIdentityId),
});

export const fetchAllowedProviderIdentities = (providerIdentityId, containerId, containerType, itemType) => ({
  types: [
    providerIdentityTypes.GET_ALLOWED_PROVIDER_IDENTITIES_REQUEST,
    providerIdentityTypes.GET_ALLOWED_PROVIDER_IDENTITIES_SUCCESS,
    providerIdentityTypes.GET_ALLOWED_PROVIDER_IDENTITIES_FAILURE,
  ],
  meta: { containerId, providerIdentityId },
  url: routes.API_PATHS.GET_ALLOWED_PROVIDER_IDENTITIES(providerIdentityId, containerId, containerType, itemType),
});

export const connectProviderIdentity = (providerIdentityId) => ({
  types: [
    providerIdentityTypes.CONNECT_PROVIDER_IDENTITY_REQUEST,
    providerIdentityTypes.CONNECT_PROVIDER_IDENTITY_SUCCESS,
    providerIdentityTypes.CONNECT_PROVIDER_IDENTITY_FAILURE,
  ],
  method: routes.METHODS.POST,
  url: routes.API_PATHS.CONNECT_PROVIDER_IDENTITY(providerIdentityId), // eslint-disable-line
});

export const fetchAvailableInstallations = (providerName) => async (dispatch) => {
  const response = await dispatch({
    method: 'GET',
    types: [
      providerIdentityTypes.GET_AVAILABLE_INSTALLATIONS_REQUEST,
      providerIdentityTypes.GET_AVAILABLE_INSTALLATIONS_SUCCESS,
      providerIdentityTypes.GET_AVAILABLE_INSTALLATIONS_FAILURE,
    ],
    url: routes.API_PATHS.GET_PROVIDER_IDENTITY_INSTALLATIONS(providerName),
    displayError: false,
  });

  return response;
};
