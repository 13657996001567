import React from 'react';
import PropTypes from 'prop-types';

import { FlowBuilderErrorContext } from 'contexts';

export function FlowBuilderErrorFunnel({ children, pageName }) {
  return <FlowBuilderErrorContext.Provider value={pageName}>{children}</FlowBuilderErrorContext.Provider>;
}

FlowBuilderErrorFunnel.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string.isRequired,
};
