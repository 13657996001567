import PropTypes from 'prop-types';
import React from 'react';

import { NewButton } from '@unitoio/mosaic';

import { Href } from 'components';
import { appcuesTypes } from 'consts';

export function AppcuesLink({
  ctaId,
  children,
  btnProps = { type: 'link', shape: 'default', size: 'middle' },
  className = '',
  asHref = false,
  onClick,
  isWidget = false,
}) {
  if (!window.Appcues) {
    return null;
  }

  if (asHref) {
    return (
      <Href
        className={className}
        onClick={() => {
          onClick && onClick();
          window.Appcues.show(ctaId);
        }}
      >
        {children}
      </Href>
    );
  }

  if (isWidget) {
    return (
      <NewButton
        {...btnProps}
        onClick={() => {
          onClick && onClick();
          window.Appcues.show(ctaId);
        }}
      >
        {children}
      </NewButton>
    );
  }

  return (
    <NewButton
      {...btnProps}
      type="text"
      className={className}
      style={{ padding: btnProps.noPadding ? 0 : undefined }}
      onClick={() => {
        onClick && onClick();
        window.Appcues.show(ctaId);
      }}
    >
      {children}
    </NewButton>
  );
}

AppcuesLink.propTypes = {
  ctaId: PropTypes.oneOf(Object.values(appcuesTypes.CTA_IDS)).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  asHref: PropTypes.bool,
  isWidget: PropTypes.bool,
  onClick: PropTypes.func,
  btnProps: PropTypes.shape({
    shape: PropTypes.oneOf(['round', 'circle', 'default']),
    size: PropTypes.oneOf(['large', 'middle', 'small']),
    reverse: PropTypes.bool,
    btnStyle: PropTypes.string,
    type: PropTypes.string,
    noPadding: PropTypes.bool,
  }),
};
