import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { Box, Button, Link, tokens, Typography } from '@unitoio/mosaic';

import { getOrganizationPlanProfile, getOrganizationSubscription, getPlans, isOnLegacyPlan } from 'reducers';
import { ChatBubble } from 'components';
import { routes, trackingTypes } from 'consts';
import { useLogger, useTrackEvent } from 'hooks';
import { getNextPlanTier, getTierFromPlan } from '../../utils';

import { PlanFeatureList } from './PlanFeatureList';

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const ButtonBox = styled(Box)`
  margin-left: auto;
`;

const Tier = styled.span`
  text-transform: capitalize;
`;

const NoUnderlineLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

// Plan name anatomy: variant-tierXX-interval (ex: 20200915-personal5-month)
// The number after the tier name will dictate how many { active users | items in sync } and other limit based features is included in the plan.
// Those values don't matter for the purpose of displaying the next plan's features. We just need to fetch the first plan in the tier above.
const getNextPlan = (currentPlan, allPlans) => {
  const currentPlanTier = getTierFromPlan(currentPlan);
  const nextPlanTier = getNextPlanTier(currentPlanTier);

  if (!nextPlanTier) {
    return undefined;
  }

  return allPlans.find((plan) => plan.get('id').includes(nextPlanTier));
};

const getCTAButton = ({ tier, nextPlanTier, organizationId, isLegacyPlan, trackEvent }) => {
  if (!tier) {
    return null;
  }

  if (isLegacyPlan) {
    return (
      <NoUnderlineLink href={`#${routes.ABSOLUTE_PATHS.PROJECT_SYNC_PRICING(organizationId)}`}>
        <Button variant={Button.variants.SECONDARY}>See pricing</Button>
      </NoUnderlineLink>
    );
  }

  if (tier === 'company') {
    return <ChatBubble type="default">Contact us</ChatBubble>;
  }

  return (
    nextPlanTier && (
      <NoUnderlineLink href={`#${routes.ABSOLUTE_PATHS.PROJECT_SYNC_PRICING(organizationId)}`}>
        <Button
          variant={Button.variants.SECONDARY}
          onClick={() =>
            trackEvent(trackingTypes.ACTION, {
              action_name: 'clicked on upgrade',
            })
          }
        >
          Upgrade to <Tier>{nextPlanTier}</Tier>
        </Button>
      </NoUnderlineLink>
    )
  );
};

const getTitle = (tier, nextPlanTier, isLegacyPlan) => {
  if (!tier) {
    return null;
  }

  if (isLegacyPlan) {
    return (
      <Box m={[tokens.spacing.s3, 0, 0, 0]}>
        <Typography variant={Typography.variants.BODY1}>
          <Bold>Find out about what other plans offer</Bold>
        </Typography>
      </Box>
    );
  }

  if (tier === 'company') {
    return (
      <Box m={[tokens.spacing.s3, 0, 0, 0]}>
        <Typography variant={Typography.variants.BODY1}>
          <Bold>Go fully custom with an Enterprise plan</Bold>
        </Typography>
      </Box>
    );
  }

  return (
    nextPlanTier && (
      <Typography variant={Typography.variants.BODY1}>
        <Bold>
          Available on <Tier>{nextPlanTier}</Tier> plan
        </Bold>
      </Typography>
    )
  );
};

export const NextPlanDetail = () => {
  const { organizationId } = useParams();
  const { reportWarning } = useLogger();
  const trackEvent = useTrackEvent();

  const allPlans = useSelector((state) => getPlans(state));
  const currentPlan = useSelector((state) => getOrganizationPlanProfile(state));
  const nextPlan = getNextPlan(currentPlan, allPlans);
  const currentPlanTier = getTierFromPlan(currentPlan);
  const nextPlanTier = getTierFromPlan(nextPlan);

  const currentSubscription = useSelector((state) => getOrganizationSubscription(state, organizationId));
  const isLegacyPlan = useSelector((state) => isOnLegacyPlan(state, currentSubscription, organizationId));

  const hasNextPlan = !!nextPlan;

  useEffect(() => {
    if (!hasNextPlan && currentPlanTier !== 'company' && !isLegacyPlan) {
      reportWarning(`Unable to retrieve next plan tier for ${organizationId}`, {
        identifier: 'NextPlanDetail getNextPlan unableToRetrieveNextPlanTier',
      });
    }
  }, [currentPlanTier, hasNextPlan, isLegacyPlan, organizationId, reportWarning]);

  if (!hasNextPlan && currentPlanTier !== 'company') {
    return null;
  }

  return (
    <Box flexDirection={Box.flexDirection.ROW} borderSize="1" borderRadius={tokens.spacing.s4} p={[tokens.spacing.s5]}>
      <Box flexDirection={Box.flexDirection.COLUMN}>
        <Box m={[0, 0, tokens.spacing.s3, 0]}>{getTitle(currentPlanTier, nextPlanTier, isLegacyPlan)}</Box>
        {hasNextPlan && !isLegacyPlan && (
          <Box>
            <PlanFeatureList
              icon="lock"
              plan={nextPlan}
              textColor={tokens.colors.content.neutral.n30}
              disableFeaturesCTA
            />
          </Box>
        )}
      </Box>
      <ButtonBox currentPlanTier={currentPlanTier} alignItems="center">
        {getCTAButton({ tier: currentPlanTier, nextPlanTier, organizationId, isLegacyPlan, trackEvent })}
      </ButtonBox>
    </Box>
  );
};
