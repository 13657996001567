import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLogger } from 'hooks';
import { billingActions } from 'actions';

export const useFetchBillingOrganization = (organizationId) => {
  const dispatch = useDispatch();
  const { reportWarning } = useLogger();
  const [isLoading, setIsLoading] = useState(true);
  const [hasFailed, setHasFailed] = useState(false);

  useEffect(() => {
    const getBillingSubscription = async () => {
      try {
        await dispatch(billingActions.getOrganization(organizationId, { displayError: false }));
      } catch (err) {
        reportWarning(err, {
          identifier: 'useFetchBillingSubscription getBillingSubscription failedGetBillingSubscription ',
        });
        setHasFailed(true);
      } finally {
        setIsLoading(false);
      }
    };

    getBillingSubscription();
  }, [dispatch, organizationId, reportWarning]);

  return { isLoading, hasFailed };
};
