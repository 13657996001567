import { useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { trackingTypes, featureTypes } from 'consts';
import { getFeatureFlagValue } from 'reducers';
import { useIsFlowDraft } from './useIsFlowDraft';
import { useIsWorkItemStepMissingConfig } from './useIsWorkItemStepMissingConfig';

export const useHasContainerErrors = () => {
  const { errors } = useFormState();

  const providerIdentityAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.providerIdentityId;
  const providerIdentityBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.providerIdentityId;
  const containerAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.containerId;
  const containerBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.containerId;

  const hasContainerErrors = providerIdentityAError || providerIdentityBError || containerAError || containerBError;

  // Extra validation when the add-on configuration is on the tool selection page
  const isAddOnImprovements = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.ADD_ON_IMPROVEMENTS),
  );
  const isFlowDraft = useIsFlowDraft();
  const isWorkItemStepMissingConfig = useIsWorkItemStepMissingConfig();

  // When the add-on is shown on the tool selection page
  if (isAddOnImprovements && isFlowDraft) {
    return !!hasContainerErrors;
  }

  return !!(hasContainerErrors || isWorkItemStepMissingConfig);
};
