import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Icon, Link, ProviderIcon, Tooltip, Typography, tokens } from '@unitoio/mosaic';

import { Href } from 'components';
import { routes, trackingTypes } from 'consts';
import { useTrackEvent } from 'hooks';
import { fontWeight } from 'theme';
import { getActivityStatusOption } from 'utils';

import { BlockOfWork } from './BlockOfWork';

const TableCell = styled.div`
  .flex-items-center {
    display: flex;
    align-items: center;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .wide {
    width: 12.5rem;
  }

  .ellipsized {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .capitalized {
    text-transform: capitalize;
  }
`;

const ChangeDate = styled.span`
  font-weight: ${fontWeight.medium};
`;

const NoWrapHeader = ({ children }) => (
  <TableCell>
    <span className="no-wrap">{children}</span>
  </TableCell>
);

NoWrapHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

const SyncId = styled.span`
  line-height: 1;
  display: inline-block;
  max-width: 6.25rem;
`;

export const activityTypesOptions = [
  { label: 'Create', value: 'Create' },
  { label: 'Update', value: 'Update' },
];

export const activityStatusOptions = [
  { label: 'Success', value: 'success' },
  { label: 'Failed', value: 'failed' },
  { label: 'Retrying...', value: 'retrying' },
];

export const pageSizeOptions = [
  { label: '10 per page', value: 10 },
  { label: '20 per page', value: 20 },
  { label: '50 per page', value: 50 },
];

export const timeOptions = [
  {
    label: 'Past 1 hour',
    value: 60 * 60 * 1000, // in msec
  },
  {
    label: 'Past 4 hours',
    value: 4 * 60 * 60 * 1000,
  },
  {
    label: 'Past 1 day',
    value: 1 * 24 * 60 * 60 * 1000,
  },
  {
    label: 'Past 7 days',
    value: 7 * 24 * 60 * 60 * 1000,
  },
  {
    label: 'Past 14 days',
    value: 14 * 24 * 60 * 60 * 1000,
  },
  {
    label: 'Past 30 days',
    value: 30 * 24 * 60 * 60 * 1000,
  },
];

export const dataTableSort = [
  {
    id: 'changeTime',
    desc: true,
  },
];

// Used because we define a lot of anonymous components as header and cell selectors, and the proptypes
// rule expects a PropType definition for each one of them, which is too much.
/* eslint-disable react/prop-types */

const timestampColumn = {
  header: () => <NoWrapHeader>Date</NoWrapHeader>,
  accessor: 'changeTime',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ value: epochDate }) => {
    const parsedValue = moment(epochDate);
    const datePart = parsedValue.format('DD MMM');
    const timePart = parsedValue.format('h:mm:ss A');
    return (
      <TableCell>
        <div className="no-wrap">
          <ChangeDate>{datePart}</ChangeDate> | {timePart}
        </div>
      </TableCell>
    );
  },
};

const activityTypeColumn = (title) => ({
  header: () => <NoWrapHeader>{title}</NoWrapHeader>,
  accessor: 'type',
  disableFilters: true,
  disableSortBy: true,
});

const updatedFieldsColumn = {
  header: () => <NoWrapHeader>Updated fields</NoWrapHeader>,
  accessor: 'fields',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ value: fields }) => {
    const fieldsStr = fields.sort().join(', ');
    return (
      <TableCell>
        <Tooltip as="div" content={fieldsStr}>
          <div className="wide ellipsized capitalized">
            <span>{fieldsStr}</span>
          </div>
        </Tooltip>
      </TableCell>
    );
  },
};

const statusColumn = {
  id: 'status',
  header: () => <NoWrapHeader>Status</NoWrapHeader>,
  accessor: 'targetBlockOfWorkId',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row: { original: activityLog } }) => {
    const { status, publisherError } = activityLog;
    const iconTypes = new Map([
      ['success', 'circle-check'],
      ['failed', 'circle-xmark'],
      ['retrying', 'clock-rotate-left'],
    ]);
    const iconColors = new Map([
      ['success', tokens.colors.content.positive.default],
      ['failed', tokens.colors.content.destructive.default],
      ['retrying', tokens.colors.content.neutral.n30],
    ]);

    const activityStatusOption = getActivityStatusOption(status, publisherError);

    const displayValue =
      activityStatusOptions.find((statusOption) => statusOption.value === activityStatusOption)?.label ||
      'Not available';

    return (
      <Box alignItems={Box.alignItems.CENTER}>
        <Icon
          name={iconTypes.get(activityStatusOption) || 'xmark'}
          color={iconColors.get(activityStatusOption) || tokens.colors.content.neutral.n30}
          kind={Icon.KINDS.SOLID}
        />
        <Box m={[0, 0, tokens.spacing.s1, tokens.spacing.s3]}>{displayValue}</Box>
      </Box>
    );
  },
};

const activityIdColumn = {
  header: () => (
    <NoWrapHeader>
      <Tooltip
        as="span"
        placement="bottom"
        content="A unique ID assigned to each synced items. Use it when you speak to Unito support team for advanced troubleshooting."
      >
        Activity ID
      </Tooltip>
    </NoWrapHeader>
  ),
  accessor: 'syncId',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ value: syncId }) => (
    <TableCell>
      <div className="no-wrap">
        <Tooltip as="div" content={syncId}>
          <SyncId className="ellipsized">{syncId}</SyncId> &nbsp;
          <Icon
            onClick={() => navigator.clipboard.writeText(syncId)}
            name="clone"
            color={tokens.colors.content.secondary.default}
            cursor="pointer"
            title="copy"
            kind={Icon.KINDS.SOLID}
          />
        </Tooltip>
      </div>
    </TableCell>
  ),
};

const updatedItemColumn = {
  header: () => (
    <NoWrapHeader>
      <Tooltip as="span" placement="bottom" content="URL of the created or updated item.">
        Updated item
      </Tooltip>
    </NoWrapHeader>
  ),
  accessor: 'targetTask.url',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ value: url }) => (
    <TableCell>
      <div className="no-wrap wide ellipsized">
        <Href target="_blank" href={url}>
          {url}
        </Href>
      </div>
    </TableCell>
  ),
};

const updatedItemColumnV2 = {
  id: 'target-link-item-v2',
  header: () => (
    <NoWrapHeader>
      <Tooltip as="span" placement="bottom" content="URL of the created or updated item.">
        Updated item
      </Tooltip>
    </NoWrapHeader>
  ),
  accessor: 'targetBlockOfWorkId',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row: { original: activityLog } }) => {
    const { targetTask, targetConnectorName, status, publisherError } = activityLog;

    const trackEvent = useTrackEvent();

    const activityStatusOption = getActivityStatusOption(status, publisherError);

    return (
      <TableCell>
        <div className="no-wrap wide ellipsized">
          {targetTask.url ? (
            <Link
              onClick={() =>
                trackEvent(trackingTypes.ACTION, {
                  action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.CLICKED_LINK_ITEM,
                  status: activityStatusOption,
                  selected_tool_name: targetConnectorName,
                })
              }
              isExternalLink
              href={targetTask.url}
              className="flex-items-center"
            >
              <Box m={[0, tokens.spacing.s2, 0, 0]}>
                <ProviderIcon name={targetConnectorName} size="default" />
              </Box>
              Link to item
            </Link>
          ) : null}
        </div>
      </TableCell>
    );
  },
};

const MoreInfoText = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  float: right;
`;

const updatedItemStatusColumn = (setSelectedItemId) => ({
  id: 'target-status-item-v2',
  header: () => null,
  accessor: 'targetBlockOfWorkId',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row: { original: activityLog } }) => {
    const trackEvent = useTrackEvent();
    const { targetTask, targetConnectorName, status, publisherError } = activityLog;

    const activityStatusOption = getActivityStatusOption(status, publisherError);

    return (
      <TableCell>
        <div className="no-wrap wide ellipsized">
          {targetTask.id && (
            <MoreInfoText
              color={tokens.colors.content.info.default}
              onClick={() => {
                setSelectedItemId(targetTask.id);
                trackEvent(trackingTypes.ACTION, {
                  action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.MORE_INFO,
                  selected_tool_name: targetConnectorName,
                  status: activityStatusOption,
                });
              }}
            >
              <Icon name="memo-circle-info" />
              &nbsp;More info
            </MoreInfoText>
          )}
        </div>
      </TableCell>
    );
  },
});

const updateLocationColumn = {
  header: () => (
    <NoWrapHeader>
      <Tooltip as="span" placement="bottom" content="Name of the block of work where the item was created or updated.">
        Update location
      </Tooltip>
    </NoWrapHeader>
  ),
  accessor: 'targetBlockOfWorkId',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row: { original: activityLog } }) => {
    // Accessing via the table instance instead of the value, because this cell requires information from 2
    // different fields and there's no other way of getting that. Accessor only gives us info about a single field.
    const provider = activityLog.targetConnectorName;
    const { displayName, url } = activityLog.targetContainer;

    return (
      <TableCell>
        <div className="wide">
          <BlockOfWork providerName={provider} url={url}>
            {displayName}
          </BlockOfWork>
        </div>
      </TableCell>
    );
  },
};

const flowColumn = {
  header: 'Flow',
  accessor: 'link',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ value }) => (
    <TableCell>
      <div className="wide">
        <Href target="_blank" href={`#${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${value.id}`}>
          {value.name}
        </Href>
      </div>
    </TableCell>
  ),
};

const workflowColumn = {
  header: 'Workflow',
  accessor: 'workflows',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ value: workflows }) => (
    <TableCell>
      <div className="wide">
        {workflows.map(({ id, name }) => (
          <Href key={id} target="_blank" href={`#${routes.ABSOLUTE_PATHS.EDIT_WORKFLOW}/${id}`}>
            {name}
          </Href>
        ))}
      </div>
    </TableCell>
  ),
};

const originalItemColumn = {
  header: () => (
    <NoWrapHeader>
      <Tooltip as="span" placement="bottom" content="URL of the item that is at the origin of the sync.">
        Original item
      </Tooltip>
    </NoWrapHeader>
  ),
  accessor: 'sourceTask.url',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ value: url }) => (
    <TableCell>
      <div className="no-wrap wide ellipsized">
        <Href target="_blank" href={url}>
          {url}
        </Href>
      </div>
    </TableCell>
  ),
};

const originalLocationColumn = {
  header: () => (
    <NoWrapHeader>
      <Tooltip as="span" placement="left" content="Name of the block of work where the source item lives.">
        Original location
      </Tooltip>
    </NoWrapHeader>
  ),
  accessor: 'sourceBlockOfWorkId',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ row: { original: activityLog } }) => {
    const provider = activityLog.sourceConnectorName;
    const { displayName, url } = activityLog.sourceContainer;

    return (
      <TableCell>
        <div className="wide">
          <BlockOfWork providerName={provider} url={url}>
            {displayName}
          </BlockOfWork>
        </div>
      </TableCell>
    );
  },
};

export const activityLogsColumns = [
  timestampColumn,
  activityTypeColumn('Activity type'),
  updatedFieldsColumn,
  statusColumn,
  updatedItemColumn,
  updateLocationColumn,

  originalItemColumn,
  originalLocationColumn,
  flowColumn,
  workflowColumn,
  activityIdColumn,
];

export const minimizedActivityLogsColumns = (setSelectedItemId) => [
  statusColumn,
  timestampColumn,
  updatedItemColumnV2,
  updatedItemStatusColumn(setSelectedItemId),
];

/* eslint-enable react/prop-types */
