import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { Button, CopyToClipboard, Href, Section, Title } from 'components';
import { useQueryParams } from 'hooks';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../../AuthTitleWrapper';
import { AuthOrderedList } from '../../AuthOrderedList';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import jiraAppLink1 from './jira-app-link-1.jpg';
import oldJiraAppLink1 from './old-jira-app-link-1.png';

function AuthJiraAppLinkStep1Component({ domain, history, newUI, onCancel, switchUI, trackEvent }) {
  const { windowOpenerId } = useQueryParams();
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (Application Link - Step 1 of 3)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>
          Next, Jira requires you to add an <strong>Application Link</strong> for Unito.
        </p>
        <AuthImageWrapper className="clearfix">
          <img
            src={newUI ? jiraAppLink1 : oldJiraAppLink1}
            alt="Jira Application Link Step - Step 1"
            width="555"
            height="100%"
          />
          <Button noPadding size="xs" pullRight btnStyle="link" onClick={switchUI}>
            Not your Jira version? Switch instructions for {newUI ? 'v6 or v7' : 'v8'}
          </Button>
        </AuthImageWrapper>
        <AuthOrderedList>
          <li>
            Open <Href href={`${domain}/plugins/servlet/applinks/listApplicationLinks`}>Jira’s settings</Href> to add an
            Application Link
          </li>
          <li>
            Enter <CopyToClipboard>https://app.unito.io</CopyToClipboard> in the Application field
          </li>
          <li>
            Click <strong>Create new link</strong>
          </li>
          {!newUI && (
            <li>
              Click <strong>Continue</strong>
            </li>
          )}
        </AuthOrderedList>
        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            to={`step2?windowOpenerId=${windowOpenerId}`}
            onClick={() => trackEvent('application link onboarding 1 (of 3)')}
            type="href"
            pullRight
          >
            Continue
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthJiraAppLinkStep1Component.propTypes = {
  domain: PropTypes.string.isRequired,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  newUI: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  switchUI: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

const formSelector = formValueSelector('authentication');

const mapStateToProps = (state) => ({
  domain: formSelector(state, 'domain'),
});

export const AuthJiraAppLinkStep1 = connect(mapStateToProps)(AuthJiraAppLinkStep1Component);
