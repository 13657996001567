import { Map, fromJS } from 'immutable';

import { billingTypes } from 'consts';
import { normalizeEntitiesById, mergeDeepOverwriteLists } from 'utils';

export const initialState = Map({
  customersById: Map(),
  plansById: Map(),
  invoicesById: Map(),
  subscriptionsById: Map(),
  sourcesById: Map(), // Stripe sources (payment methods)
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case billingTypes.GET_ORGANIZATION_PLAN_PROFILE_SUCCESS: {
      const { planProfile } = action.payload;
      const featuresById = planProfile.features ? normalizeEntitiesById(fromJS(planProfile.features)) : undefined;
      return state.mergeIn(['plansById', planProfile.id], fromJS({ ...planProfile, features: featuresById }));
    }

    case billingTypes.GET_PLANS_SUCCESS: {
      return state.merge({
        plansById: normalizeEntitiesById(fromJS(action.payload.plans)),
      });
    }

    case billingTypes.GET_PLAN_REJECTION_REASONS_SUCCESS: {
      const { rejectionReasons } = action.payload;
      const normalizedRejectionsById = Map({
        plansById: normalizeEntitiesById(fromJS(rejectionReasons), true),
      });
      return mergeDeepOverwriteLists(state, normalizedRejectionsById);
    }

    case billingTypes.GET_BILLING_ORGANIZATION_SUCCESS:
    case billingTypes.UPDATE_BILLING_ORGANIZATION_SUCCESS:
    case billingTypes.UPDATE_SUBSCRIPTION_SUCCESS: {
      const {
        sources, // eslint-disable-line
        subscriptions,
        ...customer
      } = action.payload.customer;

      return state
        .mergeIn(
          ['customersById', customer.id],
          fromJS({
            ...customer,
            subscriptions: subscriptions.map((sub) => sub.id), // Only keep subscription ids
            sources: sources.map((source) => source.id), // Only keep source ids
          }),
        )
        .merge({
          sourcesById: normalizeEntitiesById(fromJS(sources)),
          subscriptionsById: normalizeEntitiesById(fromJS(subscriptions)),
        });
    }

    case billingTypes.PAUSE_SUBSCRIPTION_SUCCESS:
    case billingTypes.UNPAUSE_SUBSCRIPTION_SUCCESS: {
      const { subscription } = action.payload;

      return state.mergeIn(['subscriptionsById', subscription.id], fromJS(subscription));
    }

    case billingTypes.CANCEL_SUBSCRIPTION_SUCCESS: {
      const { customer, subscription } = action.payload;
      return state
        .mergeIn(['customersById', customer.id], fromJS(customer))
        .mergeIn(['subscriptionsById', subscription.id], fromJS(subscription));
    }

    case billingTypes.GET_ORGANIZATION_INVOICES_SUCCESS: {
      const invoices = fromJS(action.payload.invoices);
      const updatedInvoices = invoices.map((invoice) => {
        const lines = invoice.get('lines').map((line) => line.set('plan', line.getIn(['plan', 'id'])));
        return invoice.set('lines', lines);
      });

      return state.merge({
        invoicesById: normalizeEntitiesById(updatedInvoices),
      });
    }

    default:
      return state;
  }
};

export const getPlanById = (state, planId) => state.getIn(['plansById', planId], Map());

export const getPlans = (state) => state.get('plansById', Map());

export const getSubscriptionById = (state, subscriptionId) => state.getIn(['subscriptionsById', subscriptionId], Map());

export const isSubscriptionScheduledToPause = (state, subscriptionId) => {
  const subscription = getSubscriptionById(state, subscriptionId);
  const planId = subscription.getIn(['schedule', 'phases', 1, 'plans', 0, 'plan']);
  if (!planId) {
    return false;
  }

  return planId.includes(billingTypes.PLAN_TYPES.PAUSE);
};

export const getPausedDate = (state, subscriptionId) => {
  const subscription = getSubscriptionById(state, subscriptionId);
  return subscription.getIn(['schedule', 'created']);
};

export const getCustomerById = (state, customerId) => state.getIn(['customersById', customerId], Map());

export const getCustomerDefaultPaymentSource = (state, customerId) => {
  const customer = getCustomerById(state, customerId);
  const defaultSourceId = customer.get('defaultSource');
  return state.getIn(['sourcesById', defaultSourceId], Map());
};

export const getCustomerInvoices = (state, customerId) =>
  state
    .get('invoicesById')
    .filter((invoice) => invoice.get('customer') === customerId)
    .sortBy((invoice) => -invoice.get('created'));

const getEntityCoupon = (entity) => {
  const coupon = entity.getIn(['discount', 'coupon']);
  if (!coupon || !coupon.get('valid')) {
    return undefined;
  }
  return coupon;
};

export const getCustomerCoupon = (state, customerId) => {
  const customer = getCustomerById(state, customerId);
  return getEntityCoupon(customer);
};

export const getSubscriptionCoupon = (state, subscriptionId) => {
  const subscription = getSubscriptionById(state, subscriptionId);
  return getEntityCoupon(subscription);
};
