import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fieldActions } from 'actions';
import { useLogger } from 'hooks';
import { getIsCustomFieldsLoading } from 'reducers';

export const statuses = {
  INITIAL: 'initial',
  SUCCESS: 'success',
  LOADING: 'loading',
  ERROR_FA: 'errorFieldAssociations',
  ERROR_CF: 'errorCustomFields',
};

export const useGetCustomFields = ({
  containerIdA,
  containerIdB,
  providerIdentityIdA,
  providerIdentityIdB,
  itemTypeA,
  itemTypeB,
  getUncachedA = false,
  getUncachedB = false,
}) => {
  const dispatch = useDispatch();
  const { reportException } = useLogger();
  const isLoading = useSelector((state) => getIsCustomFieldsLoading(state));
  const [status, setStatus] = useState(isLoading ? statuses.LOADING : statuses.INITIAL);

  useEffect(() => {
    async function fetchCustomFields() {
      try {
        // If the link is being loaded, the container ids might not be set yet
        if (status === statuses.INITIAL && containerIdA && containerIdB) {
          setStatus(statuses.LOADING);
          // TODO: Look into replace Promise.all with Promise.allSettled. Do we want to fail everything if one call fails?
          await Promise.all([
            dispatch(
              fieldActions.getCustomFields({
                containerId: containerIdA,
                providerIdentityId: providerIdentityIdA,
                containerSide: 'A',
                itemType: itemTypeA,
                getUncached: getUncachedA,
              }),
            ),
            dispatch(
              fieldActions.getCustomFields({
                containerId: containerIdB,
                providerIdentityId: providerIdentityIdB,
                containerSide: 'B',
                itemType: itemTypeB,
                getUncached: getUncachedB,
              }),
            ),
          ]);
          setStatus(statuses.SUCCESS);
        }
      } catch (err) {
        reportException(err, { containerIdA, containerIdB, providerIdentityIdA, providerIdentityIdB }); // FIXME: What to do if error in terms of UI
        setStatus(statuses.ERROR_CF);
      }
    }
    fetchCustomFields();
  }, [
    containerIdA,
    containerIdB,
    dispatch,
    getUncachedA,
    getUncachedB,
    itemTypeA,
    itemTypeB,
    providerIdentityIdA,
    providerIdentityIdB,
    reportException,
    status,
  ]);

  return status;
};
