import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button, Href, InlineLoading, Section, Title } from 'components';
import { trackingActions, billingActions } from 'actions';
import { getActiveCoupon, getCustomerDefaultPaymentSource, getOrganizationBillingEmail } from 'reducers';
import { trackingTypes, routes } from 'consts';
import { color, fontSize, fontWeight } from 'theme';

import { AddCouponForm } from '../AddCouponForm/AddCouponForm';
import { ChangeCreditCardModal } from '../ChangeCreditCardModal/ChangeCreditCardModal';
import { SendEmailReceiptForm } from '../SendEmailReceiptForm/SendEmailReceiptForm';
import { CouponInfo } from '../CouponInfo/CouponInfo';

const Divider = styled.hr`
  color: ${color.dark.midGray};
`;

const SendEmailCheckboxWrapper = styled.div`
  margin-top: 1rem;
`;

const TextBold = styled.span`
  font-weight: ${fontWeight.medium};
`;

const TextWrapper = styled.div`
  font-size: ${(props) => props.$fontSize || fontSize.body};
  margin-bottom: ${(props) => props.$marginBottom || '0.5rem'};
`;

export function BillingInformation({
  isPaymentSourceLoading,
  organizationId,
  orgCustomerId,
  showBillingInformation,
  onUpdatePaymentSource,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const dispatch = useDispatch();

  const couponId = useSelector((state) => getActiveCoupon(state, orgCustomerId, organizationId).get('id'));
  const currentOrgBillingEmail = useSelector((state) => getOrganizationBillingEmail(state, organizationId));
  const paymentSource = useSelector((state) => getCustomerDefaultPaymentSource(state, orgCustomerId));

  const hasPaymentSource = !paymentSource.isEmpty();

  const trackEvent = (...params) => {
    dispatch(trackingActions.trackEvent(...params));
  };

  useEffect(() => {
    async function fetchCustomer() {
      try {
        await dispatch(billingActions.getOrganization(organizationId));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
    fetchCustomer();
  }, [dispatch, organizationId]);

  const changeCard = () => {
    trackEvent(trackingTypes.USER_DROPDOWN_EVENTS.USER_CLICKED_CHANGE_CARD);
  };

  const renderWithPaymentSourceSection = () => (
    <Section className="billing-container__billing-info--update">
      <Title type="h2">Payment Information</Title>
      <TextWrapper $marginBottom="0rem">{paymentSource.get('name')}</TextWrapper>
      <TextWrapper $fontSize="16px">**** **** **** {paymentSource.get('last4')}</TextWrapper>

      <Button
        btnStyle="link"
        size="md"
        onClick={() => {
          setShowCheckoutModal(true);
          changeCard();
        }}
        noPadding
      >
        Change your credit card information
      </Button>

      <ChangeCreditCardModal
        isOpen={showCheckoutModal}
        onCancel={() => setShowCheckoutModal(false)}
        onSuccess={(token) => {
          onUpdatePaymentSource(token);
          setShowCheckoutModal(false);
        }}
      />
    </Section>
  );

  const renderNoPaymentSourceSection = () => (
    <Section className="billing-container__billing-info--new">
      <Title type="h2">Payment Method</Title>
      <TextWrapper $marginBottom="1.5rem">
        It looks like we don’t have a credit card on file for this account.{' '}
        <TextBold>Add your payment method below.</TextBold>
      </TextWrapper>

      <Button
        btnStyle="link"
        size="md"
        onClick={() => {
          setShowCheckoutModal(true);
          changeCard();
        }}
        noPadding
      >
        Add new credit card
      </Button>

      <ChangeCreditCardModal
        isOpen={showCheckoutModal}
        onCancel={() => setShowCheckoutModal(false)}
        onSuccess={(token) => {
          onUpdatePaymentSource(token);
          setShowCheckoutModal(false);
        }}
      />
    </Section>
  );

  if (isLoading) {
    return <InlineLoading />;
  }

  return (
    <div className="billing-information">
      {showBillingInformation && (
        <Section className="billing-container__billing-info">
          {!isPaymentSourceLoading && hasPaymentSource
            ? renderWithPaymentSourceSection()
            : renderNoPaymentSourceSection()}
          <Divider />
        </Section>
      )}
      <Section>
        <Title type="h2">Invoicing Information</Title>
        <TextWrapper>
          <SendEmailCheckboxWrapper>
            <SendEmailReceiptForm currentOrgBillingEmail={currentOrgBillingEmail} organizationId={organizationId} />
          </SendEmailCheckboxWrapper>
        </TextWrapper>
        <Href to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/workspace`}>Change your contact email</Href>
      </Section>
      <Divider />
      <Section className="billing-container__coupons">
        <Title type="h2">Coupon</Title>
        <TextWrapper>
          <CouponInfo couponName={couponId} />
          <AddCouponForm
            currentCouponName={couponId}
            maxLength={455}
            helpText="If you redeem a coupon, the promotion will be applied on your next invoice."
            trackEvent={trackEvent}
          />
        </TextWrapper>
      </Section>
    </div>
  );
}

BillingInformation.propTypes = {
  isPaymentSourceLoading: PropTypes.bool.isRequired,
  orgCustomerId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  onUpdatePaymentSource: PropTypes.func.isRequired,
  showBillingInformation: PropTypes.bool.isRequired,
};
