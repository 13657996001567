import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';

import { useQueryParams } from 'hooks';

import { AuthZendeskApiTokenStep1 } from './AuthZendeskApiTokenStep1';
import { AuthZendeskApiTokenStep2 } from './AuthZendeskApiTokenStep2';

export function AuthZendeskInstructions({ children: instructionFields, match, onCancel, onSubmit, trackEvent }) {
  const { windowOpenerId } = useQueryParams();
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/step1?windowOpenerId=${windowOpenerId}`} />
      <Route
        exact
        path={`${match.path}/step1`}
        render={(routeProps) => (
          <AuthZendeskApiTokenStep1 {...routeProps} onCancel={onCancel} trackEvent={trackEvent} />
        )}
      />
      <Route
        exact
        path={`${match.path}/step2`}
        render={(routeProps) => (
          <AuthZendeskApiTokenStep2 {...routeProps} onCancel={onCancel} onSubmit={onSubmit} trackEvent={trackEvent}>
            {instructionFields}
          </AuthZendeskApiTokenStep2>
        )}
      />
    </Switch>
  );
}

AuthZendeskInstructions.propTypes = {
  children: PropTypes.node,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
