import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, ProviderIcon, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import { ProviderTermsByName } from 'components';
import { capitalize } from 'utils';

const StretchedBox = styled(Box)`
  width: 100%;
  gap: ${tokens.spacing.s3};
`;

export const ThenStatementRuleRow = ({ otherContainerSection, otherItemType }) => (
  <StretchedBox p={[tokens.spacing.s3, tokens.spacing.s4]} flexDirection="row" alignItems="flex-start">
    <Box m={[0, tokens.spacing.s2, 0, 0]}>
      <Typography variant="h4">THEN</Typography>
    </Box>
    <Typography variant={TypographyVariants.BODY2}>
      <Box
        backgroundColor={tokens.colors.background.neutral.grey}
        p={[tokens.spacing.s2, tokens.spacing.s3]}
        borderRadius={0.4}
      >
        Create a matching{' '}
        <ProviderTermsByName
          providerNameA={otherContainerSection.providerName}
          termKey="task"
          pcdv3
          itemTypeA={otherItemType}
        />{' '}
        in
      </Box>
    </Typography>
    <Typography variant={TypographyVariants.BODY2}>
      <Box
        backgroundColor={tokens.colors.background.neutral.grey}
        p={[tokens.spacing.s2, tokens.spacing.s3]}
        borderRadius={0.4}
        flexDirection={Box.flexDirection.ROW}
      >
        <Box m={[0, tokens.spacing.s3, 0, 0]}>
          <ProviderIcon name={otherContainerSection.providerName} size="small" />
        </Box>
        {capitalize(otherContainerSection.providerDisplayName)}
      </Box>
    </Typography>
    <Typography variant={TypographyVariants.BODY2}>
      <Box
        backgroundColor={tokens.colors.background.neutral.grey}
        p={[tokens.spacing.s2, tokens.spacing.s3]}
        borderRadius={0.4}
      >
        {otherContainerSection.containerName}
      </Box>
    </Typography>
  </StretchedBox>
);

ThenStatementRuleRow.propTypes = {
  otherContainerSection: PropTypes.shape({
    providerName: PropTypes.string.isRequired,
    providerDisplayName: PropTypes.string.isRequired,
    containerName: PropTypes.string.isRequired,
  }).isRequired,
  otherItemType: PropTypes.string.isRequired,
};
