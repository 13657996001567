import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { inviteActions, organizationActions } from 'actions';

export const useLoadResources = (organizationId) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadResources() {
      setIsLoading(true);
      await Promise.all([
        dispatch(inviteActions.getInvites(organizationId)),
        dispatch(organizationActions.getMembers(organizationId)),
        dispatch(organizationActions.getCoworkers(organizationId)),
        dispatch(organizationActions.getOrganizationRoles(organizationId)),
      ]);
      setIsLoading(false);
    }
    loadResources();
  }, [organizationId, dispatch]);

  return isLoading;
};
