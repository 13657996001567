import { DiagramModel } from '@projectstorm/react-diagrams';
import { Point } from '@projectstorm/geometry';

import { color } from 'theme';
import { workflowTypes } from 'consts';
import { isHorizontal } from 'utils';

import { WorkBlockModel, WORKBLOCK_HEIGHT, WORKBLOCK_WIDTH } from '../components/Nodes/WorkBlockModel';
import { FlowNodeModel } from '../components/Nodes/FlowModel';

export const WORKFLOW_ACTION = 'ACTION';

export const CLICK_MOVE_MARGIN_OF_ERROR = 5; // acceptable margin of error in pixels to differenciate a click to a pan
export const MIDDLE_BLOCK_OFFSET = 2; // offset to define which block is the middle one (3 out of 5)
const MIN_ZOOM_LEVEL = 60;
const MAX_ZOOM_LEVEL = 125;

export class WorkflowDiagramModel extends DiagramModel {
  constructor(options, engine, diagramRepresentation, workflowId, trackEvent) {
    super(options);

    if (diagramRepresentation && Object.entries(diagramRepresentation).length !== 0) {
      try {
        this.deserializeModel(diagramRepresentation, engine);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Something went wrong', err);
      }
    }

    this.workflowId = workflowId;
    this.trackEvent = trackEvent;

    // GRID_SIZE simulates an invisible grid for the model. This makes the dragging of a node (or any element) behave like if there was grid.
    // The elements can be moved in any direction by the number of pixel defined by GRID_SIZE. See: http://projectstorm.cloud/react-diagrams/?path=/story/simple-usage--canvas-grid-size
    this.setGridSize(workflowTypes.GRID_SIZE);
    // Pass a percentage to set the initial zoom
    this.setZoomLevel(workflowTypes.INITIAL_ZOOM_LEVEL);

    this.engine = engine;
    this.lastKnownPositions = {};
  }

  isCursorOverlapping = (event) => {
    const point = this.engine.getRelativeMousePoint(event);
    const nodes = this.getNodes();
    return nodes.find((node) => {
      const { x, y } = node.getPosition();
      const nodeHeight = node.height;
      const nodeWidth = node.width;
      return point.x >= x && point.x <= x + nodeWidth && point.y >= y && point.y <= y + nodeHeight;
    });
  };

  getScaledQuadrilleSize() {
    return workflowTypes.GRID_SIZE * this.getScale();
  }

  getScale() {
    return this.getZoomLevel() / 100;
  }

  targetPortChanged(edge) {
    const sourcePort = edge.getSourcePort();
    const targetPort = edge.getTargetPort();

    const sameNode = targetPort.getParent().getID() === sourcePort.getParent().getID();

    if (sameNode) {
      edge.remove();
      return;
    }

    // stops the event during the flow creation process
    if (targetPort.getOptions().name === 'FlowPort' || sourcePort.getOptions().name === 'FlowPort') {
      return;
    }

    // prevent flow duplications
    const allNodes = this.getNodes();
    const flowNodes = allNodes.filter((node) => node.options.type === 'Flow');

    const isNotUnique = flowNodes.some((flow) =>
      flow.isAlreadyConnected(sourcePort.getParent().getID(), targetPort.getParent().getID()),
    );
    if (isNotUnique) {
      edge.remove();
      return;
    }
    // Add flow between workBlocks
    this.addFlowNode({ edge, sourcePort, targetPort });
  }

  /**
   * Returns all the physical blocks of the diagram, regardless of whether
   * they're "connected" blocks or "placeholder" blocks.
   * */
  getWorkBlockNodes() {
    return this.getNodes().filter((node) => node.options?.type === 'WorkBlock');
  }

  getWorkBlockNodeByNodeId(nodeId) {
    const workblockNodes = this.getWorkBlockNodes();
    return workblockNodes.find((node) => node.options.id === nodeId);
  }

  addFlowNode({ edge, sourcePort, targetPort }) {
    const targetWorkBlock = targetPort.getParent();
    const sourceWorkBlock = sourcePort.getParent();

    const node = new FlowNodeModel({
      name: `${targetWorkBlock.getContainerName()} ⇄ ${sourceWorkBlock.getContainerName()}`,
      A: {
        providerIdentityId: targetWorkBlock.getProviderIdentityId(),
        containerId: targetWorkBlock.getContainerId(),
        providerContainerId: targetWorkBlock.getProviderContainerId(),
        itemType: targetWorkBlock.getItemType(),
        containerType: targetWorkBlock.getContainerType(),
      },
      B: {
        providerIdentityId: sourceWorkBlock.getProviderIdentityId(),
        containerId: sourceWorkBlock.getContainerId(),
        providerContainerId: sourceWorkBlock.getProviderContainerId(),
        itemType: sourceWorkBlock.getItemType(),
        containerType: sourceWorkBlock.getContainerType(),
      },
      siblings: [sourceWorkBlock.getID(), targetWorkBlock.getID()],
      creating: true,
    });

    const nodeId = node.getID();
    sourceWorkBlock.addFlowNodeId(nodeId);
    targetWorkBlock.addFlowNodeId(nodeId);

    const targetCenterPort = targetWorkBlock.getPort(workflowTypes.PORT_POSITIONS.CENTER);
    const sourceCenterPort = sourceWorkBlock.getPort(workflowTypes.PORT_POSITIONS.CENTER);

    const port = node.getPort('FlowPort');
    const outEdge = port.link(targetCenterPort);

    // resets the edges position, as it would do if it was a real edge that was dragged
    // Necessary to act against a rendering quirk of the library
    outEdge.setPoints([
      outEdge.getFirstPoint(),
      outEdge.generatePoint(targetCenterPort.getPosition().x, targetCenterPort.getPosition().y),
    ]);

    // also creates the first Edge if it doesn't exist, ie.: if it comes from a magical link
    if (!edge) {
      const inEdge = port.link(sourceCenterPort);
      inEdge.getOptions().curvyness = 0; // eslint-disable-line no-param-reassign

      inEdge.setPoints([
        inEdge.getFirstPoint(),
        inEdge.generatePoint(sourceCenterPort.getPosition().x, sourceCenterPort.getPosition().y),
      ]);
      this.addAll(inEdge);
    } else {
      edge.getOptions().curvyness = 0; // eslint-disable-line no-param-reassign
      try {
        /** There seems to be an race condition in React-Diagrams that throws an error but it doesn't seem to affect our rendering or the diagram representation. So, adding a try/catch to avoid polluting console in staging/prod */
        edge.setTargetPort(port);
        edge.setSourcePort(sourceCenterPort);
        edge.setPoints([
          edge.generatePoint(sourceCenterPort.getPosition().x, sourceCenterPort.getPosition().y),
          edge.getFirstPoint(),
        ]);
      } catch (error) {
        if (process.env.NODE_ENV !== 'development') {
          return;
        }

        // eslint-disable-next-line no-console
        console.log({ error });
      }
    }

    // removes curvyness once the links are created, still allowing curvyness on drag
    outEdge.getOptions().curvyness = 0;

    const sourceBlockPosition = sourcePort.getNode().getPosition();
    const { x: sourceBlockX, y: sourceBlockY } = sourceBlockPosition;

    const targetBlockPosition = targetPort.getNode().getPosition();
    const { x: targetBlockX, y: targetBlockY } = targetBlockPosition;

    // the * 0.5 is to position it as close to the center as possible (40%) while accounting for the fact that the flow node will have a width
    // there is no real way to get a perfect number here as we're on a grid, this is the closest thing we can get for now
    let x = sourceBlockX + (targetBlockX - sourceBlockX) * 0.5;
    let y = sourceBlockY + (targetBlockY - sourceBlockY) * 0.5;

    const edgeIsHorizontal = isHorizontal(sourceBlockX, sourceBlockY, targetBlockX, targetBlockY);

    /**
     * Launches a test to check if the flow (as a rectangle) is touching any other node (also as a rectangle)
     * if it does, it pushes the flow node down 1 block down (or right if the edge was vertical) the grid and re-runs the test until it finds an empty spot
     */
    const recursivelyMoveToEmptySpace = () => {
      const flowRectangle = {
        x: this.getGridPosition(x),
        y: this.getGridPosition(y),
        width: 48, // needs to force a size because the node hasn't been rendered yet, it doesn't exist
        height: 48,
      };
      if (this.nodeIsOverlapping(flowRectangle)) {
        if (edgeIsHorizontal) {
          y += workflowTypes.GRID_SIZE;
        } else {
          x += workflowTypes.GRID_SIZE;
        }
        recursivelyMoveToEmptySpace();
      }
    };
    recursivelyMoveToEmptySpace();

    node.setPosition(this.getGridPosition(x), this.getGridPosition(y));

    this.addAll(node, outEdge);
    // Data team prefers tool names to be ordered alphabetically
    const orderedToolNames = [sourceWorkBlock.getToolName(), targetWorkBlock.getToolName()].sort().join(', ');
    this.trackEvent(WORKFLOW_ACTION, {
      workflow_id: this.workflowId,
      action_name: 'linked 2 blocks',
      selected_tool_names: orderedToolNames,
    });
  }

  // simply takes 2 rectangle and compares them to know if they're touching
  // https://developer.mozilla.org/en-US/docs/Games/Techniques/2D_collision_detection
  nodeIsOverlapping(otherNode) {
    const nodes = this.getNodes();

    return nodes.some((node) => {
      if (otherNode.id === node.getID()) {
        return false;
      }

      const { x, y } = node.getPosition();
      return (
        otherNode.x < x + node.width &&
        otherNode.x + otherNode.width > x &&
        otherNode.y < y + node.height &&
        otherNode.y + otherNode.height > y
      );
    });
  }

  registerWorkflowModelListeners = () => {
    this.registerListener({
      eventDidFire: (e) => {
        const { link: edge, function: type, node } = e;

        if (type === workflowTypes.DIAGRAM_EVENTS.NODE_GRABBED) {
          this.setNodeLastKnownPosition(node);
          this.reorderNodeLayer(node.getID());
        }

        if (type === workflowTypes.DIAGRAM_EVENTS.NODE_DROPPED) {
          const nodeRectange = {
            x: node.position.x,
            y: node.position.y,
            width: node.width,
            height: node.height,
            id: node.getID(),
          };

          if (this.hasNodePositionChanged(node) && this.nodeIsOverlapping(nodeRectange)) {
            const nodeLastKnownPosition = this.lastKnownPositions[node.getID()];
            node.setPosition(nodeLastKnownPosition.x, nodeLastKnownPosition.y);
          }

          // Clean up
          this.lastKnownPositions = {};
        }

        if (type === 'offsetUpdated') {
          WorkflowDiagramModel.adjustGridOffset(e);
        }

        if (type === 'zoomUpdated') {
          let { zoom } = e;
          if (e.zoom < MIN_ZOOM_LEVEL) {
            this.setZoomLevel(MIN_ZOOM_LEVEL);
            zoom = MIN_ZOOM_LEVEL;
          } else if (e.zoom > MAX_ZOOM_LEVEL) {
            this.setZoomLevel(MAX_ZOOM_LEVEL);
            zoom = MAX_ZOOM_LEVEL;
          }
          this.adjustGridZoom(zoom);
        }

        if (!edge) {
          return;
        }

        edge.registerListener({
          // this intercepts the creation of an edge to inject a flow node in between 2 workBlocks
          targetPortChanged: () => this.targetPortChanged(edge),
        });
      },
    });
  };

  static adjustGridOffset = ({ offsetX, offsetY }) => {
    const [workflowContainer] = document.getElementsByClassName('workflow-container');
    workflowContainer.style.setProperty('--offset-x', `${offsetX}px`);
    workflowContainer.style.setProperty('--offset-y', `${offsetY}px`);
  };

  adjustGridZoom = (zoom) => {
    const [workflowContainer] = document.getElementsByClassName('workflow-container');
    const { gridSize } = this.getOptions();
    workflowContainer.style.setProperty('--grid-size', `${(gridSize * zoom) / 100}px`);
  };

  calculateWorkBlockAddPoint(event) {
    /**
     * short explanation -
     * This chunk of code calculates positions on a grid while keeping in mind the ratio of the current zoom level
     * * ie. if the grid is 75 and the zoom is 1, it will return 75, 150, 225, etc, depending on the nearest grid block your cursor is in
     * it also calculates visible percentage of the leftmost block of the grid and snaps the square taking this offset into account
     *
     * It returns a grid block position
     */
    const blockGridSize = this.getScaledQuadrilleSize();
    const newX = WorkflowDiagramModel.getNewPosition(event.clientX, blockGridSize, this.getOffsetX());
    const newY = WorkflowDiagramModel.getNewPosition(event.clientY, blockGridSize, this.getOffsetY());
    const newPoint = this.engine.getRelativePoint(newX, newY);
    return newPoint;
  }

  static hasMoved = (currentPosition, lastKnownPosition) =>
    currentPosition.x > lastKnownPosition.x + CLICK_MOVE_MARGIN_OF_ERROR ||
    currentPosition.y > lastKnownPosition.y + CLICK_MOVE_MARGIN_OF_ERROR ||
    currentPosition.x < lastKnownPosition.x - CLICK_MOVE_MARGIN_OF_ERROR ||
    currentPosition.y < lastKnownPosition.y - CLICK_MOVE_MARGIN_OF_ERROR;

  static getNewPosition = (mousePosition, quadrilleSize, offset) => {
    // A WorkBlock or WorkBlockAdd point is two blocks sizes from the mouse position.
    // We consider the mouse position as the center of the node)
    const adjustedPoint = mousePosition - quadrilleSize * MIDDLE_BLOCK_OFFSET;
    // To fit the grid, we need to set the point to the lower multiple of blockGridSize
    const nearestEvenPoint = Math.floor(adjustedPoint / quadrilleSize);
    // Compensate for the offset value of the grid
    const offsetValue = (offset / quadrilleSize) % 1;
    return (offsetValue + nearestEvenPoint) * quadrilleSize;
  };

  conditionallyCreateBlock = (event, lastKnownPosition) => {
    // condition to determine if the user was panning or actually clicked to create a block
    // the margin of error is defined in pixels
    if (WorkflowDiagramModel.hasMoved({ x: event.clientX, y: event.clientY }, lastKnownPosition)) {
      return null;
    }

    if (this.isCursorOverlapping(event)) {
      // condition to determine if the user is currently hovering a block or not, stops if that's the case
      return null;
    }

    // creates a temporary node
    const newNode = new WorkBlockModel({
      color: color.content.message.info,
      name: 'WorkBlock',
    });
    newNode.updateDimensions({ width: WORKBLOCK_WIDTH, height: WORKBLOCK_HEIGHT });

    const point = this.engine.getRelativeMousePoint(event);
    const nodePositionX = WorkflowDiagramModel.getNewPosition(point.x, workflowTypes.GRID_SIZE, 0);
    const nodePositionY = WorkflowDiagramModel.getNewPosition(point.y, workflowTypes.GRID_SIZE, 0);
    newNode.setPosition(new Point(nodePositionX, nodePositionY)); // create the node in the grid square where the click happened.

    this.addNode(newNode);
    this.engine.repaintCanvas();

    return newNode;
  };

  setNodeLastKnownPosition(element) {
    this.lastKnownPositions[element.getID()] = element.position;
  }

  hasNodePositionChanged(element) {
    const nodeId = element.getID();

    if (this.lastKnownPositions[nodeId]) {
      const nodeLastKnownPosition = new Point(this.lastKnownPositions[nodeId].x, this.lastKnownPositions[nodeId].y);
      return WorkflowDiagramModel.hasMoved(element.position, nodeLastKnownPosition);
    }

    return false;
  }

  deleteFlow(flowNodeId) {
    const flowNode = this.getNode(flowNodeId);
    // TODO: when deleting a flow from the WF designer, we used to have a race condition on the deleteLink
    // API call, now that this call properly removes flows from workflows, we may sometimes get situations
    // where flow references in the blocks where actually already removed from the diagram. This check is just to
    // prevent an error if the flow was already removed.
    if (flowNode) {
      const [sourceWorkBlockNodeId, targetWorkBlockNodeId] = flowNode.getSiblings();
      const sourceWorkBlock = this.getNode(sourceWorkBlockNodeId);
      const targetWorkBlock = this.getNode(targetWorkBlockNodeId);
      sourceWorkBlock.removeFlowNodeId(flowNodeId);
      targetWorkBlock.removeFlowNodeId(flowNodeId);
      const {
        ports: { FlowPort: flowPort },
      } = flowNode;
      const portLinks = flowPort.getLinks();
      Object.values(portLinks).forEach((portLink) => portLink.remove());
      this.removeNode(flowNodeId);
    }
  }

  deleteBlockOfWork(blockNodeId) {
    const block = this.getNode(blockNodeId);
    block.getFlowNodeIds().forEach((flowNodeId) => this.deleteFlow(flowNodeId));
    this.removeNode(blockNodeId);
  }

  findNodeByBlockId(blockId) {
    let node = this.getNodes().find((block) => block.getBlockId?.() === blockId);
    if (node) {
      return node;
    }

    // Needed for placeholder
    const allWorkblocks = this.getWorkBlockNodes();
    node = allWorkblocks.find((block) => blockId === block.options.id);

    return node;
  }

  findNodeByLinkId(linkId) {
    return this.getNodes().find((block) => block.getLinkId?.() === linkId);
  }

  /**
   * Reorders the node layer so that the selected node is on top
   */
  reorderNodeLayer(nodeId) {
    const activeNode = this.getActiveNodeLayer();
    const modelToReorder = activeNode.getModel(nodeId);
    activeNode.removeModel(nodeId);
    activeNode.addModel(modelToReorder);
  }

  getSiblingNodes(flowNode) {
    const nodes = this.getNodes();
    const containerNodes = nodes.filter((node) => node.flowNodeIds?.includes(flowNode.getID()));
    return containerNodes;
  }

  getWorkblockFlowIds(workblockNode) {
    const flowNodeIds = workblockNode.getFlowNodeIds();
    const flowNodes = this.getNodes().filter((flowNode) => flowNodeIds.includes(flowNode.getID()));
    return flowNodes.map((flowNode) => flowNode.getLinkId()).filter((linkId) => !!linkId); // linkId can be undefined if we have two linked blocks with no flow created between them.
  }
}
