import { useWatch } from 'react-hook-form';

import { useIsFlowDraft } from './useIsFlowDraft';

export function useIsFlowDuplicate(control = undefined) {
  const duplicateLinkId = useWatch({ name: 'duplicateLinkId', control });
  const isDraft = useIsFlowDraft(control);

  return duplicateLinkId && isDraft;
}
