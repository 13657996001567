import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { List } from 'immutable';

import { Box, Icon, Link, tokens, NewTag, Tooltip, Typography, ProviderIcon } from '@unitoio/mosaic';

import {
  getLinkContainerBySide,
  getLinkProviderNameBySide,
  getProviderByName,
  getProviderVisibleFields,
} from 'reducers';
import { SelectField } from 'components';
import { trackingTypes } from 'consts';
import { useTrackEvent, useGetItemTypes } from 'hooks';

import { PCDFieldsSelect, displayTypes } from './PCDFieldsSelect';

const GrowingBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const StretchedBox = styled(Box)`
  width: 100%;
  border: 1px solid ${tokens.colors.global.primary.light};
  border-radius: ${tokens.spacing.s3};

  &:hover {
    border-color: ${({ $hoverable }) =>
      $hoverable ? tokens.colors.content.primary.default : tokens.colors.global.primary.light};
  }
`;

// no support for traling tooltips in Mimic's Tag component
const LegacyRuleTag = styled.div`
  background: ${tokens.colors.background.neutral.grey};
  border: 1px solid ${tokens.colors.background.neutral.grey};
  font-size: ${tokens.fontSize.f7};
  margin: 0 ${tokens.spacing.s2};
  border-radius: ${tokens.spacing.s3};
  padding: ${tokens.spacing.s3};
  line-height: ${tokens.lineHeight.lh5};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RuleTag = styled(NewTag)`
  background: ${tokens.colors.background.neutral.grey};
  border: 1px solid ${tokens.colors.background.neutral.grey};
  border-radius: ${tokens.spacing.s3};
  padding: ${tokens.spacing.s3};
  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    padding: 0;
  }
`;

const RuleTagIcon = styled(LegacyRuleTag)`
  padding: ${tokens.spacing.s2} ${tokens.spacing.s3};
`;

const StyledIcon = styled(Icon)`
  margin-left: ${tokens.spacing.s3};
`;

export const ManageDuplicates = () => {
  const { linkId } = useParams();
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const { watch, setValue } = useFormContext();

  const providerNameA = useSelector((state) => getLinkProviderNameBySide(state, { containerSide: 'A', linkId }));
  const providerNameB = useSelector((state) => getLinkProviderNameBySide(state, { containerSide: 'B', linkId }));
  const providerA = useSelector((state) => getProviderByName(state, providerNameA));
  const providerB = useSelector((state) => getProviderByName(state, providerNameB));
  const containerA = useSelector((state) => getLinkContainerBySide(state, { containerSide: 'A', linkId }));
  const containerB = useSelector((state) => getLinkContainerBySide(state, { containerSide: 'B', linkId }));

  const fieldsA = useSelector((state) =>
    getProviderVisibleFields(state, providerA.get('_id'), itemTypeA, containerA?.get('id')),
  );
  const fieldsB = useSelector((state) =>
    getProviderVisibleFields(state, providerB.get('_id'), itemTypeB, containerB?.get('id')),
  );

  // fields which can be used for merging are tagged with this property
  const [fieldIdEmailA] = fieldsA.entrySeq().find(([, values]) => values.get('mergeField', false));
  const [fieldIdEmailB] = fieldsB.entrySeq().find(([, values]) => values.get('mergeField', false));

  const providerADisplayName = providerA.get('displayName');
  const providerBDisplayName = providerB.get('displayName');
  const trackEvent = useTrackEvent({ selected_tool_names: `${providerNameA}, ${providerNameB}` });

  // The fields below are where the actual merge fields values are stored in MongoDB (1 for now).
  const mergeFieldsA = watch('A.merge.fields') ?? List();
  const mergeFieldsB = watch('B.merge.fields') ?? List();

  // The watched fields below are transient and not persisted in MongoDB.
  // This is a workaround to not have to use react-hook-form's useFieldArray on merge.fields
  // since we only support one value for now in the merge fields anyways.
  const mergeFieldA = watch('A.mergeField');
  const mergeFieldB = watch('B.mergeField');

  const truthSide = watch('truthSide');

  // if the user creates a draft for the first time between two contact based providers, we need to apply
  // some defaults.
  useEffect(() => {
    async function initializeMergeFields() {
      if (!mergeFieldsA.size && !mergeFieldsB.size) {
        const now = Date.now();
        setValue('A.merge.fields', [fieldIdEmailA], { shouldDirty: true });
        setValue('A.mergeField', fieldIdEmailA, { shouldDirty: true });
        setValue('B.mergeField', fieldIdEmailB, { shouldDirty: true });
        setValue('B.merge.fields', [fieldIdEmailB], { shouldDirty: true });
        setValue('A.truthSide', false, { shouldDirty: true });
        setValue('B.truthSide', false, { shouldDirty: true });
        setValue('truthSide', truthSide, { shouldDirty: true });
        setValue('A.filtersInitializedAt', now, { shouldDirty: true });
        setValue('B.filtersInitializedAt', now, { shouldDirty: true });
      }
    }

    initializeMergeFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeFieldsA.size, mergeFieldsB.size, fieldIdEmailA, fieldIdEmailB, truthSide]);

  const containerNameA = containerA.get('displayName');
  const containerNameB = containerB.get('displayName');

  const containerIdA = containerA.get('id');
  const containerIdB = containerB.get('id');

  const truthSideSelectOptions = [
    { label: containerNameA, value: 'A', icon: { type: 'provider', name: providerNameA } },
    { label: containerNameB, value: 'B', icon: { type: 'provider', name: providerNameB } },
  ];

  // TODO remove providerIdentityId and containerId from all PCDFieldSelect instances.
  const tooltipContent = `Matching contacts in your tools may have fields with differing values.
    To ensure no duplicates are created, you will need to select
    which tool's information will be used in the event of a conflict.`;

  return (
    <GrowingBox>
      <Box
        fullWidth
        borderRadius={tokens.spacing.s4}
        borderColor={tokens.colors.content.neutral.n10}
        p={[tokens.spacing.s3, tokens.spacing.s6, tokens.spacing.s5, tokens.spacing.s6]}
        m={[tokens.spacing.s6, 0, 0, 0]}
        aria-label="Manage duplicates"
      >
        <Box p={[tokens.spacing.s3, tokens.spacing.s4, 0, tokens.spacing.s4]}>
          <Typography variant="h4">Manage duplicates *</Typography>
          <Typography color={tokens.colors.content.neutral.n30}>
            Decide which side will overwrite the other when it’s unclear which one has the most up-to-date information.
            Refer to{' '}
            <Link href="https://guide.unito.io/what-is-contact-merging#A" isExternal>
              the contact merging
            </Link>
            article for further details.
          </Typography>
        </Box>

        <StretchedBox
          flexDirection="row"
          alignItems="center"
          p={[tokens.spacing.s3, tokens.spacing.s4]}
          m={[tokens.spacing.s4, 0, 0, 0]}
        >
          <Typography variant="h4">IF</Typography>
          <Box m={[0, tokens.spacing.s3]}>
            <RuleTagIcon>
              <Box m={[0, tokens.spacing.s3, 0, 0]}>
                <ProviderIcon name={providerNameA} size="small" />
              </Box>
              {providerADisplayName}
            </RuleTagIcon>
          </Box>
          {/* Arrays such as A.merge.fields are not supported 'name's, hence the workaround */}
          <Box>
            <PCDFieldsSelect
              name="A.mergeField"
              value={mergeFieldA}
              isOptionDisabledHandler={() => false}
              containerSide="A"
              containerId={containerIdA}
              itemType={itemTypeA}
              readOnly
              placeholder="Select an anchor field *"
              providerName={providerNameA}
              displayType={displayTypes.MERGEABLE_FIELDS_ONLY}
              onChange={(newValue) => {
                // we only support one anchor field for now, so here we always override the whole
                // array with the single choice
                setValue('A.merge.fields', [newValue], { shouldDirty: true });
                setValue('A.mergeField', newValue, { shouldDirty: true });
              }}
            />
          </Box>
          <Box m={[0, tokens.spacing.s4]}>and</Box>
          <RuleTagIcon>
            <Box m={[0, tokens.spacing.s3, 0, 0]}>
              <ProviderIcon name={providerNameB} size="small" />
            </Box>
            {providerBDisplayName}
          </RuleTagIcon>
          <Box>
            <PCDFieldsSelect
              name="B.mergeField"
              value={mergeFieldB}
              isOptionDisabledHandler={() => false}
              containerSide="B"
              itemType={itemTypeB}
              containerId={containerIdB}
              readOnly
              placeholder="Select an anchor field *"
              providerName={providerNameB}
              displayType={displayTypes.MERGEABLE_FIELDS_ONLY}
              onChange={(newValue) => {
                setValue('B.merge.fields', [newValue], { shouldDirty: true });
                setValue('B.mergeField', newValue, { shouldDirty: true });
              }}
            />
          </Box>
          <Box m={[0, 0, 0, tokens.spacing.s2]}>
            <RuleTag>have the same value</RuleTag>
          </Box>
        </StretchedBox>
        <StretchedBox flexDirection="row" alignItems="center" p={[tokens.spacing.s3, tokens.spacing.s4]}>
          <Typography variant="h4">THEN</Typography>
          {/* Subject to change to a Select in the future if we want to offer flows which allow duplicates instead,
              but for now using a simple RuleTag will suffice */}
          <Box m={[0, tokens.spacing.s3]}>
            <RuleTag>Merge the duplicates</RuleTag>
          </Box>
        </StretchedBox>
        <StretchedBox flexDirection="row" alignItems="center" p={[tokens.spacing.s3, tokens.spacing.s4]}>
          <Typography variant="h4">AND</Typography>
          <Box m={[0, tokens.spacing.s2]}>
            <RuleTag>Use</RuleTag>
          </Box>
          <Box aria-label="Select a tool as source of truth">
            <SelectField
              value={truthSide}
              name="truthSide"
              placeholder="Select a tool *"
              onChange={(newValue) => {
                setValue('truthSide', newValue, { shouldDirty: true });
                setValue(`${newValue}.truthSide`, true, { shouldDirty: true });
                trackEvent(trackingTypes.EVENT_NAME.ACTION, {
                  action_name: 'selected source of truth tool to manage duplicates',
                  tool_source_of_truth: newValue === 'A' ? providerNameA : providerNameB,
                });
              }}
              options={truthSideSelectOptions}
            />
          </Box>
          <LegacyRuleTag>
            as the source of truth{' '}
            <Tooltip placement="top" content={tooltipContent}>
              <StyledIcon name="question-circle" size="sm" kind={Icon.KINDS.SOLID} />
            </Tooltip>
          </LegacyRuleTag>
        </StretchedBox>
      </Box>
    </GrowingBox>
  );
};
