import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Box, Button, tokens, Typography } from '@unitoio/mosaic';

import { useTrackEvent } from 'hooks';
import { routes, trackingTypes } from 'consts';
import { PageHeader } from '../components/PageHeader';
import { Steps } from '../components/Steps';

const InstructionsContainer = styled(Box)`
  max-width: 80%;
`;
const Instructions = styled(Typography)`
  font-weight: ${tokens.fontWeight.fw4};
`;

const ContinueButton = styled(Button)`
  margin-top: ${tokens.spacing.s4};
`;

export const SetupCompleted = ({ draft }) => {
  const trackEvent = useTrackEvent();
  const history = useHistory();

  const linkId = draft ? draft.link._id : undefined;

  const handleOnClick = () => {
    trackEvent(trackingTypes.EVENT_NAME.ACTION, {
      action_name: 'clicked on go to flow settings',
    });

    if (linkId) {
      history.push({
        pathname: `${routes.getEditFlowBuilderRoute(linkId)}`,
        state: { from: routes.BASE_PATHS.ONE_CLICK_EXPORT },
      });
    } else {
      history.push(`${routes.ABSOLUTE_PATHS.DASHBOARD}`);
    }
  };

  return (
    <>
      <Steps activeStep={3} totalStepCircles={2} />
      <PageHeader title="You're all set 👍" align="center" />
      <Box justifyContent={Box.justifyContent.CENTER}>
        <InstructionsContainer>
          <Box
            justifyContent={Box.justifyContent.CENTER}
            alignItems={Box.alignItems.CENTER}
            p={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s3, tokens.spacing.s0]}
          >
            <Instructions fontStyle="normal" variant={Typography.variants.H3}>
              Your flow has been created and you should see information syncing across tools.
            </Instructions>
          </Box>
          <Box justifyContent={Box.justifyContent.FLEX_END}>
            <ContinueButton onClick={handleOnClick}>Go to flow settings</ContinueButton>
          </Box>
        </InstructionsContainer>
      </Box>
    </>
  );
};

SetupCompleted.propTypes = {
  draft: PropTypes.objectOf.isRequired,
};
