import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getOrganizationPlanFeaturesWithUsage, getSelectedOrganizationId } from 'reducers';
import { isFeatureUnlimited } from 'utils';
import { useGetFeatureLimit } from '../../hooks/useGetFeatureLimit';

export function PlanFeature({ name, children }) {
  const organizationId = useSelector((state) => getSelectedOrganizationId(state));
  const organizationPlanFeaturesWithUsage = useSelector((state) =>
    getOrganizationPlanFeaturesWithUsage(state, organizationId),
  );

  const hasAccessToFeature = () => {
    const orgFeature = organizationPlanFeaturesWithUsage.get(name);
    const orgFeatureUsage = organizationPlanFeaturesWithUsage.getIn([name, 'usage']);

    if (!orgFeature || orgFeature.isEmpty()) {
      return false;
    }

    return orgFeature.has('limit')
      ? isFeatureUnlimited(orgFeature.get('limit', '')) || orgFeatureUsage < orgFeature.get('limit')
      : orgFeature.get('value', false);
  };

  const orgLimit = useGetFeatureLimit(name);

  if (typeof children !== 'function') {
    return null;
  }

  return children(hasAccessToFeature(), orgLimit);
}

PlanFeature.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};
