import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { tokens, ProviderIcon } from '@unitoio/mosaic';

import { Href, IconBadge } from 'components';

const BlockOfWorkWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .icon-badge {
    /* Prevents shrinking */
    flex: none;
    margin-right: ${tokens.spacing.s4};
  }
`;

export function BlockOfWork({ providerName, url, children }) {
  return (
    <Href href={url} target="_blank">
      <BlockOfWorkWrapper>
        <IconBadge className="icon-badge">
          <ProviderIcon name={providerName} size="small" />
        </IconBadge>
        {children}
      </BlockOfWorkWrapper>
    </Href>
  );
}

BlockOfWork.propTypes = {
  providerName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.node,
};
