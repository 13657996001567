import React from 'react';

import { CopyToClipboard } from 'components';

import { AuthImageWrapper } from '../../AuthImageWrapper';
import { AuthOrderedList } from '../../AuthOrderedList';
import jiraApiToken1 from './jira-server-api-token-1.png';

export function JiraServerTokenStep1() {
  return (
    <>
      <AuthImageWrapper>
        <img src={jiraApiToken1} alt="Jira API Token Step - Step 1" />
      </AuthImageWrapper>
      <AuthOrderedList data-testid="server-api-token-instructions">
        <li>
          Go to Jira's <strong>Personal Access Tokens</strong> page, which can be found in your profile
        </li>
        <li>
          Click <strong>Create Token</strong>
        </li>
        <li>
          In the dialog that appears, input the following:
          <ul>
            <li>
              Token Name: <CopyToClipboard>Unito Sync</CopyToClipboard>
            </li>
            <li>
              Disable the <strong>Automatic Expiry</strong> checkbox
            </li>
          </ul>
        </li>
        <li>
          Click <strong>Create</strong>
        </li>
      </AuthOrderedList>
    </>
  );
}
