import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Box, SidePanel, Typography, tokens } from '@unitoio/mosaic';

import { TrackingFunnel } from 'containers';
import { getLinkById } from 'reducers';
import { useTrackEvent } from 'hooks';
import { trackingTypes } from 'consts';

import { ResourceBlock } from '../../components/ResourceBlock';
import { SidePanelVideoIframe } from './SidePanelVideoIframe';

import Mapping1 from '../../images/side_panel/mapping/mapping1.svg';
import Mapping2 from '../../images/side_panel/mapping/mapping2.svg';
import Mapping3 from '../../images/side_panel/mapping/mapping3.svg';
import Mapping4 from '../../images/side_panel/mapping/mapping4.svg';
import Mapping5 from '../../images/side_panel/mapping/mapping5.svg';
import Mapping6 from '../../images/side_panel/mapping/mapping6.svg';

const MappingSidePanel = ({ isOpen, onClose, linkId }) => {
  const link = useSelector((state) => getLinkById(state, linkId));
  const trackEvent = useTrackEvent({
    selected_tool_names: `${link.getIn(['A', 'providerName'])},${link.getIn(['B', 'providerName'])}`,
  });

  useEffect(() => {
    if (isOpen) {
      trackEvent(trackingTypes.START);
    }
  }, [isOpen, trackEvent]);

  return (
    <SidePanel onClose={onClose} isOpen={isOpen} backButtonText="Close">
      <Box>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <Typography variant={Typography.variants.H4}>Need some help mapping your fields?</Typography>
        </Box>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <SidePanelVideoIframe videoId="MAqjcem5NkM" title="Flow Creation Step 4/4 - Mappings" />
        </Box>
        <Box
          alignItems={Box.alignItems.CENTER}
          flexDirection={Box.flexDirection.COLUMN}
          m={[0, 0, tokens.spacing.s7, 0]}
        >
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              In the previous step, you chose which items to sync from one tool to another. Now you can choose which
              individual fields within your work items you'd like to keep in sync.
            </Typography>
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              You can choose whether to have Unito auto-map fields automatically, or to do it manually yourself.
            </Typography>
          </Box>
          <Box m={[0, 0, tokens.spacing.s4, 0]}>
            <img alt="auto or manual mapped field" src={Mapping1} width="100%" height="100%" />
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              For example, if you have a task in one tool and you only care about the title and description, you don’t
              need to add any other fields to your flow. Or you can add as many as you can think of. It all depends on
              your use case and preferences.
            </Typography>
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              You can add a new field mapping by clicking the button at the top.
            </Typography>
          </Box>
          <Box m={[0, 0, tokens.spacing.s4, 0]}>
            <img alt="add new mapping" src={Mapping2} width="100%" height="100%" />
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              Your new mapping will then appear at the top of the list. Typically you’ll want to pair it with the same
              or a similar field in your other tool.
            </Typography>
          </Box>
          <Box m={[0, 0, tokens.spacing.s4, 0]}>
            <img alt="mapped similar field" src={Mapping3} width="100%" height="100%" />
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              If there is no obvious or compatible match for a field in another tool, our users often select the
              description footer so the data has somewhere to go that can still be accessed by a team using that other
              tool.
            </Typography>
          </Box>
          <Box m={[0, 0, tokens.spacing.s4, 0]}>
            <img alt="mapping direction" src={Mapping4} width="100%" height="100%" />
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              If you see a cog, or gear icon, beside a field mapping you can click on it to view the options associated
              with that mapping.
            </Typography>
          </Box>
          <Box m={[0, 0, tokens.spacing.s4, 0]}>
            <img alt="mapping option with gear icon" src={Mapping5} width="100%" height="100%" />
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              In the case of labels, tags, or lists, you can add or modify values to match different names between
              tools. For example, you could tell Unito that the “in progress” list in Tool A maps to the “active” list
              in Tool B.
            </Typography>
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              You can map one-to-one, or one-to-many, giving you lots of flexibility. Perhaps there are a variety of
              statuses in one tool that all equal a single status in another. For example, “To Do” and “In Progress” in
              Tool A might all equal “Incomplete” in Tool B.
            </Typography>
          </Box>
          <Box m={[0, 0, tokens.spacing.s4, 0]}>
            <img alt="one-to-one and one-to-many mapping" src={Mapping6} width="100%" height="100%" />
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              It may not be possible to map some fields with others. If that’s the case, you can submit a support
              request to find a workaround from our team or ask about enhancement.
            </Typography>
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              If you decide to add a new field to your tool while editing mappings, you’ll need to refresh the page in
              order for Unito to detect it.
            </Typography>
          </Box>
          <Box fullWidth m={[0, 0, tokens.spacing.s3, 0]}>
            <Typography variant={Typography.variants.BODY1}>
              Sub-items, such as subtasks and checklists, will follow your flow direction’s behavior at all times and
              also count as items in sync.
            </Typography>
          </Box>
        </Box>
        <Box fullWidth>
          <ResourceBlock trackEvent={trackEvent} />
        </Box>
      </Box>
    </SidePanel>
  );
};

MappingSidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  linkId: PropTypes.string.isRequired,
};

const MappingSidePanelWithTracking = (props) => (
  <TrackingFunnel contextName={trackingTypes.FUNNELS.SIDE_PANEL}>
    <MappingSidePanel {...props} />
  </TrackingFunnel>
);

export { MappingSidePanelWithTracking as MappingSidePanel };
