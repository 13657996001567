import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, tokens } from '@unitoio/mosaic';

import { Card, Href, Title } from 'components';
import { appTypes, trackingTypes } from 'consts';
import { getSignupOauthTool } from 'reducers';

const ARTICLES = [
  {
    title: 'The Basics',
    description: `Connect your favorite tools and
    create flows between blocks of work to better collaborate with clients and colleagues.`,
    url: 'https://guide.unito.io/unito-basics',
  },
  {
    title: 'Glossary & terminology',
    description: `Since Unito create flows between different tools with distinct vocabulary,
    we've created a useful glossary of terms that should help make sense of it all.`,
    url: 'https://guide.unito.io/unito-terminology-a-glossary',
  },
  {
    title: 'How to create a flow',
    description: `To discover the magic of Unito, you'll first need to create a flow.
    Here’s a step-by-step walkthrough to help you get started.`,
    url: 'https://guide.unito.io/how-to-create-your-first-flow',
  },
  {
    title: 'Use Unito with Trello to link & share cards across boards',
    description: `Did you know that you could use Unito to build a Trello Master Board,
    allowing you to see cards from multiple boards in one place?`,
    url: 'https://unito.io/blog/how-to-build-a-trello-master-board/',
  },
  {
    title: 'Cross-team collaboration on Zendesk tickets',
    description: `We wrote up a quick guide to help you setup Zendesk so that you can send tickets
    to other tools and keep other teams aware of issues that need to be resolved.`,
    url: 'https://unito.io/blog/zendesk-trello-integration/',
  },
  {
    title: 'Create your first Mirror',
    description: `Mirror your cards in 3 simple steps. First, enable the Power-Up on the origin and destination boards.
    Make sure you’re logged into an account that has access to all boards you want to connect.`,
    url: 'https://guide.unito.io/how-to-use-the-mirror-power-up-for-trello',
  },
  {
    title: 'What information is mirrored',
    description: `Mirror will update all fields two-ways. Find out what information is mirrored, what’s not, how to customize
    what fields are synced and how fields are synced between your Trello cards.`,
    url: 'https://guide.unito.io/what-information-can-be-mirrored',
  },
  {
    title: 'How fast does Mirror work?',
    description: `Updates to your mirrored card will be sent live! Work even better together with the ability to mirror
    comments, due dates, custom fields - all in real time!`,
    url: 'https://guide.unito.io/how-fast-does-mirror-sync',
  },
];

const DEFAULT_ARTICLES = [ARTICLES[0], ARTICLES[1], ARTICLES[2]];

const getUsefulLinks = ({ signupOauthTool, isEmbed, productName }) => {
  if (!signupOauthTool) {
    return DEFAULT_ARTICLES;
  }

  let option = signupOauthTool.toLowerCase();
  if (isEmbed) {
    option = `${option}.embed`;
  }

  if (productName === appTypes.PRODUCT_NAMES.TASK_SYNC) {
    option = `${option}.${productName}`;
  }

  switch (option) {
    case 'trello': {
      return [ARTICLES[0], ARTICLES[1], ARTICLES[3]];
    }
    case 'zendesk': {
      return [ARTICLES[0], ARTICLES[1], ARTICLES[4]];
    }
    case 'trello.taskSync':
    case 'trello.embed.taskSync': {
      return [ARTICLES[5], ARTICLES[6], ARTICLES[7]];
    }
    case 'asana':
    case 'basecamp':
    case 'bitbucket':
    case 'github':
    case 'gitlab':
    case 'hubspot':
    case 'jiracloud':
    case 'jiraserver':
    case 'trello.embed':
    case 'wrike.embed':
    case 'wrike':
    default: {
      return DEFAULT_ARTICLES;
    }
  }
};

export const UsefulLinks = ({ isEmbed = false, productName = appTypes.PRODUCT_NAMES.PROJECT_SYNC, trackEvent }) => {
  const signupOauthTool = useSelector((state) => getSignupOauthTool(state));

  return (
    <Box m={[tokens.spacing.s7, 0, 0]}>
      <Title type="h2">Useful Links</Title>
      <div className="row">
        {getUsefulLinks({ isEmbed, productName, signupOauthTool }).map(({ title, description, url }) => (
          <div key={title} className="col-md-4">
            <Card padding="1rem" style={{ minHeight: '16rem', position: 'relative' }}>
              <Title type="h4">{title}</Title>
              <div style={{ marginTop: '0.5rem' }}>{description}</div>
              <Box m={[tokens.spacing.s4, 0, tokens.spacing.s3]}>
                <Href
                  onClick={() =>
                    trackEvent(trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.USEFUL_LINK, {
                      articleTitle: title,
                    })
                  }
                  href={url}
                >
                  Read more
                </Href>
              </Box>
            </Card>
          </div>
        ))}
      </div>
    </Box>
  );
};

UsefulLinks.propTypes = {
  isEmbed: PropTypes.bool,
  productName: PropTypes.oneOf(Object.values(appTypes.PRODUCT_NAMES)),
  trackEvent: PropTypes.func.isRequired,
};
