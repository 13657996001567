import { fieldTypes, pcdFilterOperatorTypes } from 'consts';
import { useGetDefaultTestModeDate } from '../RulesSection/ClassicIntegrationRules/utils';

export function useAddInitialRules(
  hasCreatedDate,
  hasSubfolders,
  canCloseTasks,
  hasDeepFiltering,
  defaultDeepFilterItemFieldId,
  hasRequiredDate,
  dateRangeIsMissing,
) {
  const now = Date.now();
  const rules = [];

  const testModeDate = useGetDefaultTestModeDate();
  const testModeDateTime = testModeDate?.getTime();

  if (hasCreatedDate && testModeDateTime) {
    const operator = pcdFilterOperatorTypes.pcdFilterOperator.GT;
    const fieldId = fieldTypes.EARLIEST_CREATED_AT;
    rules.unshift({
      _id: `${fieldId}-${operator}`,
      operator,
      type: 'value',
      value: testModeDateTime,
      kind: 'options',
      fieldId,
    });
  }

  if (hasSubfolders) {
    const operator = pcdFilterOperatorTypes.pcdFilterOperator.EQ;
    const fieldId = fieldTypes.SUBFOLDERS;
    rules.unshift({
      _id: `${fieldId}-${operator}`,
      operator,
      type: 'category',
      value: 'included',
      kind: 'options',
      fieldId,
    });
  }

  if (canCloseTasks) {
    const operator = pcdFilterOperatorTypes.pcdFilterOperator.EQ;
    const fieldId = fieldTypes.INCLUDE_CLOSED_TASKS;
    rules.unshift({
      _id: `${fieldId}-${operator}`,
      operator,
      type: 'category',
      value: 'open',
      kind: 'options',
      fieldId,
    });
  }

  if (hasDeepFiltering) {
    const operator = pcdFilterOperatorTypes.pcdFilterOperator.EQ;
    const fieldId = fieldTypes.DEEP_FILTER_ITEM;
    rules.unshift({
      _id: `${fieldId}-${operator}`,
      operator,
      kind: 'options',
      fieldId,
      value: defaultDeepFilterItemFieldId,
    });
  }

  if (hasRequiredDate) {
    rules.unshift({
      _id: `${fieldTypes.PAST_DATE}-${pcdFilterOperatorTypes.pcdFilterOperator.GT}`,
      operator: pcdFilterOperatorTypes.pcdFilterOperator.GT,
      type: 'value',
      kind: 'options',
      value: '6',
      fieldId: fieldTypes.PAST_DATE,
    });

    if (dateRangeIsMissing) {
      rules.unshift({
        _id: `${fieldTypes.FUTURE_DATE}-${pcdFilterOperatorTypes.pcdFilterOperator.LT}`,
        operator: pcdFilterOperatorTypes.pcdFilterOperator.LT,
        type: 'value',
        kind: 'options',
        value: '12',
        fieldId: fieldTypes.FUTURE_DATE,
      });
    }
  }

  return { filters: rules, filtersInitializedAt: now };
}
