import { useSelector } from 'react-redux';

import { fieldTypes } from 'consts';
import {
  isProviderItemCustomFieldBased,
  getSupportedFieldsForItem,
  getSupportedCustomFieldTypesForItem,
} from 'reducers';

export function useProviderItemsCompatible({
  providerA,
  containerIdA,
  itemTypeA,
  providerB,
  containerIdB,
  itemTypeB,
  areProviderIdentitiesSelected,
}) {
  const itemsCustomFieldBased = {
    A: useSelector((state) => isProviderItemCustomFieldBased(state, providerA.get('name'), itemTypeA)),
    B: useSelector((state) => isProviderItemCustomFieldBased(state, providerB.get('name'), itemTypeB)),
  };

  const itemsFields = {
    A: useSelector((state) => getSupportedFieldsForItem(state, providerA.get('_id'), containerIdA, itemTypeA)),
    B: useSelector((state) => getSupportedFieldsForItem(state, providerB.get('_id'), containerIdB, itemTypeB)),
  };

  const itemsCustomFields = {
    A: useSelector((state) =>
      getSupportedCustomFieldTypesForItem(state, providerA.get('_id'), containerIdA, itemTypeA),
    ),
    B: useSelector((state) =>
      getSupportedCustomFieldTypesForItem(state, providerB.get('_id'), containerIdB, itemTypeB),
    ),
  };

  // early return since we use this hook in drafts, where providerName/itemType could be undefined
  if (
    providerA.isEmpty() ||
    providerB.isEmpty() ||
    !itemTypeA ||
    !itemTypeB ||
    !containerIdA ||
    !containerIdB ||
    !areProviderIdentitiesSelected
  ) {
    return true;
  }

  if (itemsCustomFieldBased.A || itemsFields.A.isEmpty() || itemsCustomFieldBased.B || itemsFields.B.isEmpty()) {
    return true;
  }

  const fieldIsDateType = (field) =>
    field.get('type') === fieldTypes.TYPES.DATE ||
    field.get('type') === fieldTypes.TYPES.DATETIME ||
    field.get('type') === fieldTypes.TYPES.ISO_DATE_STRING;

  const itemIsDateBased = (field) =>
    fieldIsDateType(field) && (field.get('requiredOnCreate') || field.get('requiredByGroup') === 'requireOne');

  const fieldIsCompatibleWithDateType = (field) => {
    const isHiddenOrUnsupportedOrReadonly =
      field.get('unsupported', false) || field.get('hidden', false) || field.get('readOnlyOnUpdate', false);

    return fieldIsDateType(field) && !isHiddenOrUnsupportedOrReadonly;
  };

  const AsideIsDateBased = itemsFields.A.some(itemIsDateBased);
  if (AsideIsDateBased) {
    if (itemsCustomFieldBased.B) {
      return true;
    }

    return itemsFields.B.some(fieldIsCompatibleWithDateType) || itemsCustomFields.B.some(fieldIsCompatibleWithDateType);
  }

  const BsideIsDateBased = itemsFields.B.some(itemIsDateBased);
  if (BsideIsDateBased) {
    if (itemsCustomFieldBased.A) {
      return true;
    }
    return itemsFields.A.some(fieldIsCompatibleWithDateType) || itemsCustomFields.A.some(fieldIsCompatibleWithDateType);
  }

  return true;
}
