import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@unitoio/mosaic';

import { Href } from 'components';
import { NoWrapHeader, TableCell } from '../components';

function Header() {
  return (
    <NoWrapHeader>
      <Tooltip as="span" placement="bottom" content="URL of the created or updated item.">
        Updated item
      </Tooltip>
    </NoWrapHeader>
  );
}

function Cell({ value: url }) {
  return (
    <TableCell>
      <div className="no-wrap wide ellipsized">
        <Href target="_blank" href={url}>
          {url}
        </Href>
      </div>
    </TableCell>
  );
}

Cell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const updatedItemColumn = {
  header: Header,
  accessor: 'targetTask.url',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
