import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { fromJS, is } from 'immutable';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { NewButton, notification } from '@unitoio/mosaic';

import { linkActions, draftActions, providerActions, websocketActions } from 'actions';
import { linkTypes, routes, websocketTypes } from 'consts';
import { getLinkById, getLinkSyncStatus, getUserId, getSelectedOrganizationId } from 'reducers';
import { useLogger } from 'hooks';

import * as formUtils from '../utils/form';
import { useGetFormData } from './useGetFormData';
import { useSetContainersErrors } from './useSetContainersErrors';

/**
 * Custom hook for auto-saving draft form data.
 * Update is triggered when the form data is dirty and in draft state.
 * The save function won't be called if the form is currently saving.
 *
 * @param {object} formMethods - The form methods object from react-hook-form.
 * @param {object} formData - The form data object.
 * @param {function} save - The save function to be called when auto-saving the draft.
 * @param {string} loadingState - The loading state of the flow builder.
 */
export function useAutoSaveDraft(formMethods, save, loadingState, setLoadingState) {
  const {
    formState: { isDirty },
    watch,
    reset,
    handleSubmit,
  } = formMethods;
  const formData = watch();
  const isDraft = formData.state === linkTypes.LINK_STATES.DRAFT;
  const isLoading = [formUtils.loadingStates.LOADING, formUtils.loadingStates.SAVING].includes(loadingState);
  const shouldTriggerAutoSave = isDraft && isDirty && !isLoading;

  useEffect(() => {
    const debouncedSubmit = debounce(
      () => {
        setLoadingState(formUtils.loadingStates.SAVING);

        // NOTE: If the form has any reported errors, the save API call will not be triggered
        const onSubmit = handleSubmit(save);

        onSubmit(formData)
          .then(() => {
            setLoadingState(formUtils.loadingStates.SAVED);
            reset(undefined, { keepValues: true, keepDirty: false, keepDirtyValues: false, keepErrors: true });
          })
          .catch(() => {
            setLoadingState(formUtils.loadingStates.ERROR);
            // Reset form to previous state before the changes to prevent an infinite loop of auto-saving
            reset(undefined, { keepValues: false, keepDirty: false, keepDirtyValues: false, keepErrors: true });
          });
      },
      500,
      { leading: false, trailing: true },
    );

    if (shouldTriggerAutoSave) {
      debouncedSubmit();
    }

    // Cleanup function to cancel the debounce on unmount
    return () => {
      debouncedSubmit.cancel();
    };
  }, [formData, handleSubmit, reset, save, setLoadingState, shouldTriggerAutoSave]);
}

/**
 * Custom hook for fetching a link.
 * Only fetch the link when the loading state is INITIAL and the link ID is defined (meaning we've just entered the flow builder).
 * It should not be called if the linkId is defined on a duplicate route
 *
 * @param {string} loadingState - The current loading state.
 * @param {string} linkId - The ID of the link to fetch.
 * @param {function} setLoadingState - The function to set the loading state.
 * @param {function} dispatch - The dispatch function from the Redux store.
 */
export async function useFetchLink({
  linkId,
  loadingState,
  setLoadingState,
  isDuplicate = false,
  reportException,
  formMethods,
}) {
  const dispatch = useDispatch();
  const { clearErrors, getValues, reset, setError } = formMethods;
  const linkHasSyncStatus = !useSelector((state) => getLinkSyncStatus(state, linkId)).isEmpty();
  const userId = useSelector((state) => getUserId(state));
  const organizationId = useSelector(getSelectedOrganizationId);
  const setContainerErrors = useSetContainersErrors(getValues(), setError, clearErrors);

  // re-subcribe to the link if the initial subscription in getLink didn't succeed, so we don't re-fetch the link
  useEffect(() => {
    if (loadingState === formUtils.loadingStates.LOADED && !linkHasSyncStatus) {
      websocketActions.subscribe({
        currentPage: websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_ACTIVITY,
        organizationId,
        userId,
        linkIds: [linkId],
      });
    }
  }, [linkHasSyncStatus, linkId, loadingState, organizationId, setLoadingState, userId]);

  useEffect(() => {
    if (loadingState !== formUtils.loadingStates.INITIAL || !linkId || isDuplicate) {
      return;
    }

    setLoadingState(formUtils.loadingStates.LOADING);
    const fetchLink = async () => {
      try {
        const response = await dispatch(linkActions.getLink(linkId, {}, { displayError: false }));
        const { link, containerErrors } = response;
        const updatedLink = formUtils.linkPayloadToFlowBuilderFormData(fromJS(link));
        setContainerErrors(containerErrors);
        reset(updatedLink, { keepValues: false, keepDirty: false, keepDirtyValues: false, keepErrors: true });
        setLoadingState(formUtils.loadingStates.LOADED);
      } catch (err) {
        setLoadingState(formUtils.loadingStates.ERROR);
        reportException(err);
      }
    };

    fetchLink();
  }, [dispatch, linkId, loadingState, reportException, setContainerErrors, setLoadingState, isDuplicate, reset]);
}

/**
 * Custom hook for fetching the duplicate link data
 *
 * We only fetch the duplicate link data when the loading state is INITIAL and the link ID is defined (meaning we've just entered the flow builder).
 * We should only fetch the duplicate link data when the user is on the duplicate route.
 * Once the duplicate link data is fetched, we update reset the form and update the loading state.
 *
 * @param {Object} options - The options for fetching and duplicating a link.
 * @param {string} options.linkId - The ID of the link to duplicate.
 * @param {string} options.loadingState - The current loading state.
 * @param {function} options.setLoadingState - The function to set the loading state.
 * @param {boolean} [options.shouldDuplicate=false] - Flag indicating whether the link should be duplicated.
 * @param {Object} options.formData - The form data.
 * @param {function} options.reset - The function to reset the form data.
 * @param {function} options.reportException - The function to report any exceptions.
 */
export function useFetchDuplicateLink({
  linkId,
  loadingState,
  setLoadingState,
  shouldDuplicate = false,
  formData,
  reset,
  reportException,
  setIsDuplicating,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!shouldDuplicate || loadingState !== formUtils.loadingStates.INITIAL || !linkId) {
      return;
    }

    setLoadingState(formUtils.loadingStates.LOADING);
    setIsDuplicating(true);
    const fetchDuplicateLink = async () => {
      try {
        const { link } = await dispatch(linkActions.duplicateSync(linkId, formData, false));
        const updatedLink = formUtils.linkPayloadToFlowBuilderFormData(fromJS({ ...link, duplicateLinkId: linkId }));
        reset(updatedLink, { keepValues: false, keepDirty: false, keepDirtyValues: false, keepErrors: true });
        setLoadingState(formUtils.loadingStates.LOADED);
      } catch (err) {
        reportException(err);
        setLoadingState(formUtils.loadingStates.ERROR);

        notification.warning({
          message: 'Duplication incomplete',
          description:
            'The duplication was interrupted and we were unable to finish setting up this flow. Click Retry duplicate below or reach out to our support team for more info if it happens again.',
          duration: 0,
          btn: (
            <NewButton onClick={() => window.location.reload()} type="primary">
              Retry duplicate
            </NewButton>
          ),
        });
      } finally {
        setIsDuplicating(false);
      }
    };

    fetchDuplicateLink();
  }, [
    dispatch,
    formData,
    linkId,
    loadingState,
    reportException,
    reset,
    setLoadingState,
    shouldDuplicate,
    setIsDuplicating,
  ]);
}

const resetFormData = (formMethods, currentLink) => {
  const { reset, getValues } = formMethods;
  const values = getValues();
  const updatedLink = formUtils.linkPayloadToFlowBuilderFormData(currentLink, values);
  // Keep the values that are not in the link payload but part of the form data as hidden fields
  reset({ ...values, ...updatedLink });
};

/**
 * Custom hook that resets the form and clears errors when the form is successfully submitted.
 * The form is updated based on the link data in the Redux store.
 */
export function useResetFormOnSuccessfulSubmit(currentLink, formMethods) {
  const {
    reset,
    formState: { isSubmitSuccessful },
    getValues,
  } = formMethods;

  // resets the form on a successful submit
  useEffect(() => {
    if (isSubmitSuccessful) {
      resetFormData(formMethods, currentLink);
    }
  }, [currentLink, formMethods, getValues, isSubmitSuccessful, reset]);
}

export function useResetFormOnLinkIdChange(linkId, currentLink, formMethods, loadingState) {
  // resets the form if the linkId match params changes
  useEffect(() => {
    if (linkId && loadingState && loadingState !== formUtils.loadingStates.INITIAL) {
      resetFormData(formMethods, currentLink);
    }
    // disabling because we do NOT want this to trigger every time the loading state changes
    // we only want it to trigger when linkId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkId]);
}

/**
 * Determines the action to dispatch
 * - When a link should be converted to a draft, we dispatch the convertDraft action
 * - When a draft should be created, meaning no linkId from the url, we dispatch the createDraft action
 * - When a link is a duplicate, we dispatch the createDraft action
 * - When a link is in draft state, we dispatch the editDraft action
 * - When a link is in any other state, we dispatch the saveLink action
 */
export function getActionToDispatch(linkId, { linkState, shouldConvert = false } = {}) {
  if (shouldConvert) {
    return draftActions.convertDraft;
  }

  if (!linkId || linkState === linkTypes.LINK_STATES.DUPLICATE) {
    return draftActions.createDraft;
  }

  if (linkState === linkTypes.LINK_STATES.DRAFT) {
    return draftActions.editDraft;
  }

  return linkActions.saveLink;
}

/**
 * A custom hook that handles the form submission in the FlowBuilder component.
 * The hook is responsible for dispatching the correct action based on the link state.
 * In addition, the hook is responsible for redirecting the user to the newly created draft.
 * Request aborting is also handled by this hook to prevent loading state flickering when multiple requests are dispatched.
 */
export function useOnSubmit({
  formMethods,
  history,
  linkId,
  loadingState,
  reportException,
  setLoadingState,
  workflowId,
}) {
  const dispatch = useDispatch();
  const { replace } = history;
  const { reset } = formMethods;

  return useCallback(
    async (formValues, { convertDraft = false, redirectPage = null } = {}) => {
      setLoadingState(formUtils.loadingStates.SAVING);
      if (loadingState === formUtils.loadingStates.SAVING) {
        return;
      }

      let link;
      try {
        const actionToDispatch = getActionToDispatch(linkId, {
          linkState: formValues.state,
          shouldConvert: convertDraft,
        });
        const response = await dispatch(actionToDispatch({ ...formValues, _id: formValues._id ?? linkId }));

        // The editDraft callback is called for every modification done to the draft flow, which means that it is possible we dispatch 2 PUT calls
        // or more to the /draft endpoint in a short period of time. These calls are async and the older call could potentially be executed after
        // the most recent call which would result in loss of information. We have a mechanism implemented in store/middleware.js to abort older
        // requests if a new one comes in. However, if a request has been aborted, we should not update the loading state untill the newest request
        // comes back. This condition is to prevent use cases such as this one:
        //
        // 1. User changes something in the flow builder
        // 2. The loading state is updated to loadingSates.SAVING
        // 3. A PUT request to the /draft endpoint is dispatched <- This is async
        // 4. User changes something else in the flow builder
        // 5. The loading state stays to loadingStates.SAVING
        // 6. A second PUT request to the /draft endpoint is dispatched, and the previous request is aborted
        // 7. The first request returns and the loading state is updated to loadingStates.SAVED <- /!\/!\/!\ This is bad, because the latest PUT request is still being processed
        if (!response.hasRequestAborted) {
          ({ link } = response);
          const updatedLink = formUtils.linkPayloadToFlowBuilderFormData(fromJS(link), formValues);
          reset(
            { ...formValues, ...updatedLink },
            { keepValues: false, keepDirty: false, keepDirtyValues: false, keepErrors: true },
          );
          setLoadingState(formUtils.loadingStates.SAVED);
          if (link) {
            const baseRedirectUrl = workflowId
              ? `${routes.ABSOLUTE_PATHS.FLOW_BUILDER_WORKFLOW}/${workflowId}/${link._id}`
              : `${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${link._id}`;

            // If there was no previous linkId it means that we just crated a draft and need do redirect to the newly created draft
            const page = !linkId ? routes.FLOW_BUILDER_PAGES.TOOL_SELECTION : redirectPage;
            if (page) {
              replace(`${baseRedirectUrl}/${page}`);
            }
          }
        }
      } catch (err) {
        reportException(err);
        setLoadingState(formUtils.loadingStates.ERROR);
        throw err;
      }
    },
    [setLoadingState, loadingState, linkId, dispatch, reset, workflowId, replace, reportException],
  );
}

/**
 * Custom hook to update provider capabilities form item, if the side information has changed.
 */
export function useUpdateProviderCapabilitiesForItem(
  { providerName, providerIdentityId, containerId, itemType },
  isSuspended,
  linkId,
) {
  const dispatch = useDispatch();

  // We might have a loop caused by suspended links whenever we load the flowbuilder
  // These are the only 2 calls we do as soon as we load the guide for a specific link
  // so we're preventing calling them for now if a link is suspended since anyways users won't
  // be able to edit the link
  useEffect(() => {
    if (providerName && providerIdentityId && !isSuspended) {
      dispatch(providerActions.getProviderCapabilities(providerName, providerIdentityId));
    }
  }, [dispatch, providerIdentityId, providerName, isSuspended]);

  useEffect(() => {
    if (providerName && providerIdentityId && containerId && itemType && !isSuspended) {
      dispatch(
        providerActions.getProviderCapabilitiesForItem(providerName, providerIdentityId, containerId, itemType, linkId),
      );
    }
  }, [containerId, dispatch, itemType, providerIdentityId, providerName, isSuspended, linkId]);
}

export function deepCloneAndOmit(obj, paths) {
  // Deep clone the object
  const clonedObj = JSON.parse(JSON.stringify(obj));

  // Iterate over each path
  paths.forEach((path) => {
    // Split the path and reduce it to get the nested object
    const pathParts = path.split('.');
    const lastKey = pathParts.pop();
    const updatedNestedObj = pathParts.reduce((nested, key) => {
      const newNested = nested[key] || {};
      return newNested;
    }, clonedObj);

    // Delete the key from the updated nested object
    delete updatedNestedObj[lastKey];
  });

  return clonedObj;
}
/**
 * Custom hook for the FlowBuilder component to handle form data and submission.
 * Initializes the form data based on the link ID and the query params.
 *
 * - Retrieves the link from the Redux store based on the link ID and populates the form with the link data after the link is fetched or updated.
 * - Handles the form submission and dispatches the correct action based on the link state (create draft, update draft, convert draft, update link).
 * - When in draft state, the form is auto-saved every time the form data is dirty.
 * - When the form is successfully submitted, the form is reset and the errors are cleared.
 * - When the form is successfully submitted a draft is created and the user is redirected to the newly created draft.
 * - Updates the provider capabilities when containers information are changed, to retrieve the new capabilities dynamically.
 * - Handles duplicating an existing flow
 * - Handles the loading state of the form data
 *
 */
export function useFlowBuilderGetForm() {
  const {
    params: { linkId, workflowId },
  } = useRouteMatch();
  const shouldDuplicate = !!useRouteMatch(routes.getDuplicateFlowBuilderRoute(linkId));
  const history = useHistory();
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [loadingState, setLoadingState] = useState(
    !linkId ? formUtils.loadingStates.NEW : formUtils.loadingStates.INITIAL,
  );
  const { reportException } = useLogger();
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const defaultValues = useGetFormData(linkId, currentLink, shouldDuplicate);
  const formMethods = useForm({ defaultValues });

  const values = formMethods.getValues();

  // We can't rely on the native formState.isDirty to determine if there are changes to be saved
  // It is working intermittently and doesn't always reset properly if you revert a field to its original value
  // (e.g.: flow name) and also doesn't consistently work when adding and removing rules.
  // see react-hook-form threads here:
  // https://github.com/react-hook-form/react-hook-form/issues/3213 (issue was clsoed by a bot but looks like the issue
  // is still happening)
  // and this thread about the issues with `remove` from `useFieldArray`:
  // https://github.com/react-hook-form/react-hook-form/issues/9932
  // Instead we're manually resorting to checking changes between the form and default values
  // except we now need to exclude certain fields from the comparison because they're only used for UI
  // purposes and not saved in the DB
  const defaultValuesWithOmmitedField = deepCloneAndOmit(defaultValues, [
    'linkSettingsLastModifiedOn',
    'lastSyncRequest',
  ]);

  const valuesWithOmmitedField = deepCloneAndOmit(values, ['linkSettingsLastModifiedOn', 'lastSyncRequest']);

  const hasChanged =
    formMethods.formState.isDirty && !is(fromJS(defaultValuesWithOmmitedField), fromJS(valuesWithOmmitedField));

  const onSubmit = useOnSubmit({
    formMethods,
    shouldDuplicate,
    loadingState,
    setLoadingState,
    linkId: currentLink?.get('_id', linkId),
    reportException,
    workflowId,
    history,
  });

  // Handle auto saving of a draft
  useAutoSaveDraft(formMethods, onSubmit, loadingState, setLoadingState);

  // Fetch the link to populate the form
  useFetchLink({ linkId, loadingState, setLoadingState, isDuplicate: shouldDuplicate, reportException, formMethods });
  useFetchDuplicateLink({
    linkId,
    loadingState,
    setLoadingState,
    shouldDuplicate,
    formData: defaultValues,
    reset: formMethods.reset,
    reportException,
    setIsDuplicating,
  });

  // Reset the form when the form is successfully submitted
  useResetFormOnSuccessfulSubmit(currentLink, formMethods);

  // Reset the form when the form is successfully submitted
  useResetFormOnLinkIdChange(linkId, currentLink, formMethods, loadingState);

  // Since the introduction of modern integrations, we need to fetch the provider capabilities when update the item type
  // As capabilities are now dynamic based on the container
  const formValues = formMethods.getValues();
  useUpdateProviderCapabilitiesForItem(formValues.A, formValues.isSuspended, linkId);
  useUpdateProviderCapabilitiesForItem(formValues.B, formValues.isSuspended, linkId);

  return {
    ...formMethods,
    formData: formMethods.getValues(),
    handleSubmit: formMethods.handleSubmit(onSubmit),
    loadingState,
    setLoadingState,
    isDuplicating,
    hasChanged,
  };
}
