import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Section, Button } from 'components';

import { AuthButtonsWrapper } from '../../AuthButtonsWrapper';
import addAccountButton from './add-account-button.png';

const GoogleButtonWrapper = styled.div`
  display: inline;

  button {
    padding: 0;
  }
`;

// TODO: Rely on PCD
const DISPLAY_NAME = {
  googlecontacts: 'Google Contacts',
  googlecalendar: 'Google Calendar',
};

export function GoogleInstructionsStep2({ match, onCancel, onSubmit }) {
  return (
    <Section>
      <p>{`In order to connect ${
        DISPLAY_NAME[match.params.providerName]
      } with Unito, you'll need to Sign in to your Google account.`}</p>
      <AuthButtonsWrapper className="clearfix">
        <Button reverse btnStyle="dark" onClick={onCancel}>
          Cancel
        </Button>
        <GoogleButtonWrapper>
          <Button btnStyle="subtleLink" pullRight type="submit" onClick={onSubmit}>
            <img src={addAccountButton} alt="Add account button" />
          </Button>
        </GoogleButtonWrapper>
      </AuthButtonsWrapper>
    </Section>
  );
}

GoogleInstructionsStep2.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      providerName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
