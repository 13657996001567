import { fieldTypes } from 'consts';

export const displayCreatedDateFieldFilter = (field, hasCreatedDate) => {
  // The "Earliest Created Date" filter is now conditionally displayed
  // if the work item has a field with the semantic createdAt
  if (!hasCreatedDate && field.value === fieldTypes.EARLIEST_CREATED_AT) {
    return null;
  }
  return field;
};
