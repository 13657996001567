import React from 'react';
import PropTypes from 'prop-types';
import { useWatch, useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Map } from 'immutable';
import { useParams } from 'react-router';

import { Box, tokens, Icon, Toggle } from '@unitoio/mosaic';

import { ProviderTermsByName } from 'components';
import { fieldTypes } from 'consts';
import { otherSide } from 'utils';

import { useGetProviders } from '../../hooks/useGetProviders';
import { useGetProviderNames } from '../../hooks/useGetProviderNames';
import { useGetCapabilitiesV3 } from '../../hooks/useGetCapabilitiesV3';
import { FieldMappingPrefixRow } from './FieldMappingPrefixRow';
import { PrefixInputField } from './PrefixInputField';

const PrefixLabel = styled((props) => <Box as="label" {...props} />)`
  font-size: ${tokens.fontSize.f7};
  display: inline;
  vertical-align: middle;
`;

const AddPrefix = styled(Box)`
  color: ${tokens.colors.content.info.default};
  margin-top: -2px; // Needed to align correctly because of some weird spacing
  cursor: pointer;

  &:hover {
    background: ${tokens.colors.background.message.info};
    border-radius: 100px;
  }
`;

function replaceTerms(otherProvider, text = '', capabilities) {
  return text
    .replace(/\${otherProviderName}/g, otherProvider.get('displayName'))
    .replace(/\${otherTermTaskSingular}/g, capabilities.getIn(['item', 'names', 'singular']));
}

const PrefixSide = ({
  capabilities,
  otherCapabilities,
  provider,
  otherProvider,
  prefixOption,
  side,
  otherItemType,
}) => {
  const { setValue, getValues } = useFormContext();
  const isPrefixEnabled = useWatch({ name: `${side}.prefix`, defaultValue: false });
  const prefixes = useFieldArray({ name: `${side}.prefixes` });
  const isCheckboxType = prefixOption.get('type') === 'checkbox';
  const defaultValues = prefixOption.get('default');
  // TODO investigate why we have undefined/empty values in prefixes.fields sometimes...
  const defaultMatch = prefixes.fields.filter((p) => !!p).findIndex((p) => p.key === 'default');
  const defaultIndex = defaultMatch === -1 ? 0 : defaultMatch;
  const defaultField = prefixes.fields[defaultIndex];
  const selectedPrefixes = prefixes.fields.map((p) => p.key);
  const label = replaceTerms(provider, prefixOption.get('label'), capabilities);
  const titleField = capabilities
    .getIn(['item', 'fields'], Map())
    .find((field) => field.get('semantic') === fieldTypes.SEMANTIC.ITEM_NAME, undefined, Map());

  const togglePrefix = () => {
    const prefixEnabled = !isPrefixEnabled;
    setValue(`${side}.prefix`, prefixEnabled, { shouldDirty: true });

    if (!isCheckboxType && prefixEnabled && prefixes.fields.length === 0) {
      const defaultPrefix = { key: 'default', value: defaultValues.get('default', '') };
      prefixes.append(defaultPrefix);
    }
  };

  const onPrefixChange = (index, valuesToUpdate) => {
    const { key, value } = { ...getValues(`${side}.prefixes.${index}`), ...valuesToUpdate };
    setValue(`${side}.prefixes.${index}`, { key, value }, { shouldDirty: true });
  };

  const onRemovePrefix = (index) => {
    prefixes.remove(index);
  };

  const handleOnAddPrefix = () => {
    const defaultPrefixValue = defaultValues.get(prefixOption.get('field'), defaultValues.get('default'));
    prefixes.append({ key: 'default2', value: defaultPrefixValue });
  };

  return (
    <>
      <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4]} flexDirection="row" alignItems="baseline">
        <Toggle value={isPrefixEnabled} onClick={togglePrefix} />

        <PrefixLabel m={[0, 0, 0, tokens.spacing.s3]}>
          {label} {titleField.getIn(['names', 'singular'], 'title')} (optional)
        </PrefixLabel>
      </Box>
      {!isCheckboxType && isPrefixEnabled && (
        <Box borderColor={tokens.colors.content.neutral.n10} borderRadius={tokens.spacing.s3} p={[tokens.spacing.s5]}>
          <Box flexDirection="row">
            <Box fullWidth>
              <PrefixInputField
                key={`default-prefix-${side}`}
                name={`${side}.prefixes.${defaultIndex}.value`}
                label={
                  <>
                    Default prefix will be applied to all{' '}
                    <ProviderTermsByName
                      providerNameA={otherProvider.get('name')}
                      termKey="task"
                      plurality="plural"
                      pcdv3
                      itemTypeA={otherItemType}
                    />
                  </>
                }
                value={defaultField?.value || defaultValues.get('default', '')}
                onChange={(newValue) => {
                  setValue(
                    `${side}.prefixes.${defaultIndex}`,
                    { value: newValue, key: 'default' },
                    { shouldDirty: true },
                  );
                }}
                id={`default-prefix-${side}`}
              />
            </Box>
            <Box p={[tokens.spacing.s6, 0, 0, tokens.spacing.s2]}>
              <AddPrefix p={[tokens.spacing.s3]} flexDirection="row" role="button" onClick={handleOnAddPrefix}>
                <Icon name="plus-circle" size="lg" />
              </AddPrefix>
            </Box>
          </Box>
          {prefixes.fields.map((field, index) => {
            if (field.key === 'default') {
              return null;
            }

            const prefixValues = getValues(`${side}.prefixes`) || [];
            const currentIndexHasKey = !!prefixValues[index]?.key;

            return (
              <FieldMappingPrefixRow
                key={field.id}
                fieldId={prefixOption.get('field')}
                field={field}
                index={index}
                keySelected={currentIndexHasKey}
                defaultValue={defaultValues.get(prefixOption.get('field'), defaultValues.get('default'))}
                onPrefixChange={onPrefixChange}
                selectedPrefixes={selectedPrefixes}
                side={otherSide(side)}
                remove={onRemovePrefix}
                placeholder={`Select your type of ${otherCapabilities.getIn(['item', 'names', 'singular'])}`}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};

PrefixSide.propTypes = {
  provider: PropTypes.instanceOf(Map).isRequired,
  otherProvider: PropTypes.instanceOf(Map).isRequired,
  prefixOption: PropTypes.instanceOf(Map).isRequired,
  otherCapabilities: PropTypes.instanceOf(Map).isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  otherItemType: PropTypes.string.isRequired,
};

export const FieldMappingPrefix = ({
  providerIdentityIdA,
  providerIdentityIdB,
  itemTypeA,
  itemTypeB,
  containerTypeA,
  containerTypeB,
}) => {
  const { linkId } = useParams();
  const [providerNameA, providerNameB] = useGetProviderNames(linkId);
  const [providerA, providerB] = useGetProviders(providerNameA, providerNameB);
  const [capabilitiesA, capabilitiesB, capabilitiesV2A, capabilitiesV2B] = useGetCapabilitiesV3(linkId);
  // TODO PCDv3 - aug 28
  const prefixOptionA = capabilitiesV2A.getIn(['options', 'taskNumberPrefix']);
  const prefixOptionB = capabilitiesV2B.getIn(['options', 'taskNumberPrefix']);

  return (
    <Box fullWidth p={[tokens.spacing.s4, 2.5]}>
      {prefixOptionB && (
        <PrefixSide
          side="A"
          itemType={itemTypeA}
          containerType={containerTypeA}
          capabilities={capabilitiesA}
          otherCapabilities={capabilitiesB}
          provider={providerA}
          otherProvider={providerB}
          prefixOption={prefixOptionB}
          otherProviderIdentityId={providerIdentityIdB}
          otherItemType={itemTypeB}
        />
      )}
      {prefixOptionA && prefixOptionB && <Box p={[tokens.spacing.s3, 0]} />}
      {prefixOptionA && (
        <PrefixSide
          side="B"
          itemType={itemTypeB}
          containerType={containerTypeB}
          capabilities={capabilitiesB}
          otherCapabilities={capabilitiesA}
          provider={providerB}
          otherProvider={providerA}
          prefixOption={prefixOptionA}
          otherProviderIdentityId={providerIdentityIdA}
          otherItemType={itemTypeA}
        />
      )}
    </Box>
  );
};

FieldMappingPrefix.propTypes = {
  providerIdentityIdA: PropTypes.string.isRequired,
  providerIdentityIdB: PropTypes.string.isRequired,
  itemTypeA: PropTypes.string.isRequired,
  itemTypeB: PropTypes.string.isRequired,
  containerTypeA: PropTypes.string.isRequired,
  containerTypeB: PropTypes.string.isRequired,
};
