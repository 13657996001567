import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Href } from 'components';
import { color } from 'theme';

const ListItem = styled.li`
  & a:hover {
    color: ${color.dark.primary};
  }
`;

export const NavbarItem = ({ children, href, onClick, to, ...rest }) => {
  const needsHref = to || href || onClick;

  if (!needsHref) {
    return <li>{children}</li>;
  }

  return (
    <ListItem className="navbar-item">
      <Href {...rest} to={to} href={href} data-test={rest['data-test']} onClick={onClick}>
        {children}
      </Href>
    </ListItem>
  );
};

NavbarItem.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
};
