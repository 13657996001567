import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { FlowBuilderErrorContext } from 'contexts';
import { fieldTypes } from '../../../consts';

export function useGetMissingDeepFilterItemNames(enableAlert = true) {
  const { setError, clearErrors, watch } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);

  const filtersA = watch('A.filters') || [];
  const filtersB = watch('B.filters') || [];

  const missingDeepFilterFieldNameA =
    filtersA.find((filter) => filter.fieldId === fieldTypes.DEEP_FILTER_ITEM)?.value ?? [];
  const missingDeepFilterFieldNameB =
    filtersB.find((filter) => filter.fieldId === fieldTypes.DEEP_FILTER_ITEM)?.value ?? [];

  useEffect(() => {
    if (enableAlert) {
      if (missingDeepFilterFieldNameA.length > 0) {
        setError(`${pageName}.missingDeepFilterFieldNames`, { type: 'manual' });
      } else if (missingDeepFilterFieldNameB.length > 0) {
        setError(`${pageName}.missingDeepFilterFieldNames`, { type: 'manual' });
      } else {
        clearErrors(`${pageName}.missingDeepFilterFieldNames`);
      }
    }
  }, [
    setError,
    clearErrors,
    pageName,
    enableAlert,
    missingDeepFilterFieldNameA.length,
    missingDeepFilterFieldNameB.length,
  ]);

  return [missingDeepFilterFieldNameA, missingDeepFilterFieldNameB];
}
