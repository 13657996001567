import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { List } from 'immutable';
import styled from 'styled-components';

import { Avatar } from 'components';
import { fontSize, fontWeight } from 'theme';

const StyledUserDisplay = styled.div`
  align-items: center;
  display: flex;
  cursor: ${(props) => props.onClick && 'pointer'};

  .avatar {
    margin-right: 1rem;
  }
`;

const DisplayName = styled.div`
  font-weight: ${fontWeight.medium};
  line-height: 1.25;
`;

const Email = styled.div`
  font-size: ${fontSize.small};
`;

export const UserDisplay = ({ avatar, className, displayName = '', emails = List(), onClick, size = 32 }) => (
  <StyledUserDisplay className={classnames('user-display', className)} onClick={onClick} data-dd-privacy="mask">
    <Avatar size={size} src={avatar} />
    <div>
      <DisplayName>{displayName}</DisplayName>
      {emails.first() && <Email>{emails.first()}</Email>}
    </div>
  </StyledUserDisplay>
);

UserDisplay.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  displayName: PropTypes.string,
  emails: PropTypes.instanceOf(List),
  onClick: PropTypes.func,
  size: PropTypes.number,
};
