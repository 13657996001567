import React from 'react';
import PropTypes from 'prop-types';

import { Button, Section, Title } from 'components';
import { useQueryParams } from 'hooks';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../../AuthTitleWrapper';
import { AuthOrderedList } from '../../AuthOrderedList';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import stepImage from './jira-oauth2-2.png';

export function AuthJiraOAuth2Step2({ history, onCancel, trackEvent }) {
  const { windowOpenerId } = useQueryParams();
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (OAuth2 - Step 2 of 4)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <AuthImageWrapper className="clearfix">
          <img src={stepImage} alt="Jira OAuth2 Step - Step 2" />
        </AuthImageWrapper>
        <p>In the dialog that appears, input the following:</p>
        <AuthOrderedList>
          <li>
            Application type: <strong>External application</strong>
          </li>
          <li>
            Direction: <strong>Incoming</strong>
          </li>
        </AuthOrderedList>
        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            to={`step3?windowOpenerId=${windowOpenerId}`}
            onClick={() => trackEvent('oauth2 onboarding 2 (of 4)')}
            type="href"
            pullRight
          >
            Continue
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthJiraOAuth2Step2.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
