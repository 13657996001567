import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { NewAlert, NewAlertLevel } from '@unitoio/mosaic';
import { useHasMissingMergeSettings } from '../../../hooks/useHasMissingMergeSettings';

export const MissingMergeSettingsAlert = () => {
  const match = useRouteMatch();

  const isMissingMergeSettings = useHasMissingMergeSettings();

  if (!isMissingMergeSettings) {
    return null;
  }

  return (
    <NewAlert
      level={NewAlertLevel.ERROR}
      message={
        <>
          Please identify a source of truth tool to prevent contact duplicates. Fill in the missing information in the
          <Link to={`${match.url}/rules`}> Manage Duplicates section</Link>.
        </>
      }
    />
  );
};
