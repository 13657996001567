import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { LoadingPlaceholder, tokens } from '@unitoio/mosaic';

import { featureTypes, trackingTypes, routes } from 'consts';
import { useTrackEvent, useGetOrganizationActivities } from 'hooks';
import { getSyncsWithItemsKeptInSyncByLinkId } from 'reducers';
import { FeatureFlag, FeatureFlagVariant, Href } from 'components';
import { formatNumberWithComma } from 'utils';
import { useIsFlowDraft } from '../../hooks/useIsFlowDraft';

const StyledLoadingPlaceholder = styled(LoadingPlaceholder)`
  display: inline-block;
  margin-left: ${tokens.spacing.s3};
`;

export const GuideItemsInSyncCount = ({ organizationId }) => {
  const { linkId } = useParams();
  const trackEvent = useTrackEvent();
  const isDraft = useIsFlowDraft();
  const syncWithItemsKeptInSync = useSelector((state) =>
    getSyncsWithItemsKeptInSyncByLinkId(state, organizationId, linkId),
  );

  const { isActivitiesLoading: isActivityLoading } = useGetOrganizationActivities(organizationId, isDraft);

  return (
    <FeatureFlag name={featureTypes.FEATURES.ITEMS_KEPT_IN_SYNC_PAGE}>
      <FeatureFlagVariant value={true}>
        {isActivityLoading ? (
          <StyledLoadingPlaceholder width={tokens.spacing.s9} height={tokens.spacing.s3} />
        ) : (
          syncWithItemsKeptInSync &&
          syncWithItemsKeptInSync.get('itemsCount') !== null && (
            <>
              {' '}
              |{' '}
              <Href
                onClick={() =>
                  trackEvent(trackingTypes.ACTION, {
                    action_name: 'clicked on # of items in sync',
                  })
                }
                to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/items-kept-in-sync`}
              >
                {`${formatNumberWithComma(syncWithItemsKeptInSync.get('itemsCount'))} items in sync`}
              </Href>
            </>
          )
        )}
      </FeatureFlagVariant>
    </FeatureFlag>
  );
};

GuideItemsInSyncCount.propTypes = {
  organizationId: PropTypes.string.isRequired,
};
