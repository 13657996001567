import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import debounce from 'lodash.debounce';

import { Select, Tooltip } from '@unitoio/mosaic';

import { containerActions } from 'actions';
import { containerTypes } from 'consts';
import {
  getContainersByProviderIdentityId,
  getProviderCapabilitiesByProviderIdentityId,
  getProviderCapabilitiesByProviderIdentityIdV3,
  isLoadingContainers,
} from 'reducers';
import { capitalize } from 'utils';

import { NewProjectModalRevamp } from './NewProjectModalRevamp';

// export for testing
export const getOptions = (containers, valuesToDisable) => {
  const filteredOptionMimicSelect = [];

  const options = containers
    .map((container) => {
      // FIXME: Solution works for now because we don't have any provider that needs the 4th level to be clickable
      // The real solution would be this: app.asana.com/0/847713977592024/1183540666181332/f
      const depth = container.get('depth');
      const hasChildren = container.has('children');
      const isTopLevelWithChildren = depth === 1 && hasChildren;
      const isConflicting = container.get('conflicting');
      const disabledValue = valuesToDisable.find((value) => value.get('id') === container.get('id'));
      const hasErrors = container.get('errors') && container.get('errors').size > 0;

      let details = container.getIn(['warnings', 0], '');

      if (hasErrors) {
        details = container.getIn(['errors', 0], details);
      }

      return {
        container,
        depth,
        details: disabledValue ? disabledValue.get('disabledText') : details,
        disabled: isTopLevelWithChildren || isConflicting || !!disabledValue || hasErrors,
        isWorkspace: isTopLevelWithChildren,
        label: container.get('displayName'),
        value: container.get('id'),
        children: [],
        parent: null,
      };
    })
    .toArray();

  const optionsByContainerId = options.reduce((acc, option) => {
    acc[option.value] = option;
    return acc;
  }, {});

  // eslint-disable-next-line no-restricted-syntax
  for (const option of options) {
    const parentId = option.container.get('parentId');
    if (parentId) {
      optionsByContainerId[parentId].children.push(option);
      option.parent = optionsByContainerId[parentId];
    }
  }

  const recursivelyCleanupChildOption = (option) =>
    option.children.reduce((acc, child) => {
      if (!child.children || child.children.length === 0) {
        return [
          ...acc,
          {
            label: child.label,
            value: child.value,
            disabled: child.disabled,
          },
        ];
      }

      const withChildrens = {
        label: child.label,
        children: recursivelyCleanupChildOption(child),
      };
      return [...acc, withChildrens];
    }, []);

  options.forEach((option) => {
    if (option.depth === 1) {
      // first level children are the workspace
      if (option.children.length === 0) {
        filteredOptionMimicSelect.push({
          label: option.label,
          disabled: option.disabled,
          value: option.value,
        });
      } else {
        // else start looping recursively in the childrens to build the rest of the tree
        filteredOptionMimicSelect.push({
          label: option.label,
          disabled: option.disabled,
          value: option.value,
          options: recursivelyCleanupChildOption(option),
        });
      }
    }
  });

  return filteredOptionMimicSelect;
};

const useFetchContainers = ({
  providerIdentityId,
  containerType,
  isSearchable,
  searchValue,
  trackActionEvent,
  trackBlockedEvent,
  itemType,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchContainers() {
      try {
        if (!providerIdentityId) {
          return;
        }

        if (isSearchable && searchValue) {
          trackActionEvent({ action_name: containerTypes.TRACKING.ACTION_NAMES.SEARCHING });
        }

        if (((isSearchable && searchValue) || !isSearchable) && containerType) {
          const { containers } = await dispatch(
            containerActions.getContainers({ providerIdentityId, containerType, searchValue, itemType }),
          );
          if (containers?.length === 0) {
            trackBlockedEvent({ reason: containerTypes.TRACKING.ERROR_REASONS.NO_PROJECTS });
          }
        }
      } catch (err) {
        trackBlockedEvent({ reason: err.message });
      } finally {
        setIsLoading(false);
      }
    }

    fetchContainers();
  }, [
    dispatch,
    isSearchable,
    providerIdentityId,
    containerType,
    itemType,
    searchValue,
    trackBlockedEvent,
    trackActionEvent,
  ]);

  return isLoading;
};

const useGetOptions = ({
  providerIdentityId,
  isSearchable,
  valuesToDisable,
  searchValue,
  trackActionEvent,
  trackBlockedEvent,
  containerType,
  itemType,
}) => {
  useFetchContainers({
    providerIdentityId,
    containerType,
    isSearchable,
    searchValue,
    trackActionEvent,
    trackBlockedEvent,
    itemType,
  });

  const containers = useSelector((state) => getContainersByProviderIdentityId(state, providerIdentityId));
  const options = getOptions(containers, valuesToDisable);
  const isLoading = useSelector((state) => isLoadingContainers(state, providerIdentityId));
  return [isLoading, options];
};

// exported for testing purposes
export function filterOptions(options, filterValue) {
  // for aaaba, returns: [a, aa, aaa, aab]
  function getAncestorsChain(option) {
    const ancestors = option.parent ? [...getAncestorsChain(option.parent), option.parent] : [];
    return ancestors;
  }

  // for the same chain above, for a it returns [aa, aaa, aab, aaba]
  function getDescendantsChain(option) {
    const children = option.children.flatMap((child) => [child, ...getDescendantsChain(child)]);
    return children;
  }

  if (!filterValue) {
    return options;
  }

  const matchedOptions = options
    .filter((option) => option.label.toLowerCase().includes(filterValue.toLowerCase()))
    .reduce((acc, option) => {
      // Without this check we'd end up with the same parent many times when processing siblings, and do needless work as well.
      // if acc already has option.parent, then it means we're processing a sibling of the previous reduce entry
      const ancestors = acc.has(option.parent) ? [] : getAncestorsChain(option);
      ancestors.forEach((ancestor) => acc.add(ancestor));
      acc.add(option);
      getDescendantsChain(option).forEach((descendent) => acc.add(descendent));
      return acc;
    }, new Set());

  return Array.from(matchedOptions);
}

const useGetNoResultsText = (providerIdentityId, capabilitiesV3, containerType, searchValue) => {
  const isLoading = useSelector((state) => isLoadingContainers(state, providerIdentityId));
  const containerTerm = capabilitiesV3.getIn(['containers', containerType, 'names', 'singular'], 'project');
  const containerPluralTerm = capabilitiesV3.getIn(['containers', containerType, 'names', 'plural'], 'projects');

  if (!searchValue) return getPlaceholderText(capabilitiesV3, containerType);
  return isLoading ? `Loading your ${containerPluralTerm} ...` : `No ${containerTerm} found`;
};

const getPlaceholderText = (capabilitiesV3, containerType) => {
  const searchablePlaceholder = capitalize(
    capabilitiesV3.getIn(['containers', containerType, 'searchable', 'placeholder']),
  );
  const searchableTerm = capabilitiesV3.getIn(['containers', containerType, 'names', 'native']);
  return searchablePlaceholder || `Search by name of your ${searchableTerm}`;
};

export const ContainersSelectRevamp = ({
  closeNewProjectModal,
  control,
  disabled = false,
  errors = undefined,
  getProviderIdentityId,
  isNewProjectModalOpen,
  name,
  provider,
  itemType,
  containerType,
  trackActionEvent,
  trackBlockedEvent,
  valuesToDisable = List(),
  controlForm,
}) => {
  const providerIdentityId = getProviderIdentityId();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(null);
  const [containerErrors, setContainerErrors] = useState(null);
  const capabilitiesV2 = useSelector((state) =>
    getProviderCapabilitiesByProviderIdentityId(state, { providerIdentityId }),
  );
  const capabilitiesV3 = useSelector((state) =>
    getProviderCapabilitiesByProviderIdentityIdV3(state, { providerIdentityId, itemType }),
  );

  const isSearchable = capabilitiesV3.getIn(['containers', containerType, 'searchable', 'isSearchable']);
  const [isLoading, options] = useGetOptions({
    providerIdentityId,
    isSearchable,
    valuesToDisable,
    searchValue,
    trackActionEvent,
    trackBlockedEvent,
    containerType,
    itemType,
  });

  const containerTerm = containerType ?? 'project';
  // We rely on capabilities v2 for the workspace because we don't have
  // hierarchy yet. Without that, create a container with mandatory workspace
  // are broken. "workspace" doesn't exist in capabilities v3.
  const workspaceTerm =
    capabilitiesV3.getIn(['workspaces', containerType, 'names', 'singular'], '') ||
    capabilitiesV2.getIn(['terms', 'workspace', 'singular'], '');
  const searchPlaceholder = getPlaceholderText(capabilitiesV3, containerType);
  const noResultsText = useGetNoResultsText(providerIdentityId, capabilitiesV3, containerType, searchValue);

  const controllerRef = useRef('controllerRef');

  function getOnInputChange() {
    if (!isSearchable) {
      return () => {};
    }

    return debounce((value) => {
      const valueToSet = value.trim().length === 0 ? null : value;
      setSearchValue(valueToSet);
    }, 750);
  }

  async function validate(option) {
    if (!option) {
      return true;
    }
    const { container } = await dispatch(
      containerActions.getContainerById({
        providerIdentityId: getProviderIdentityId(),
        containerId: option,
        containerType,
        itemType,
      }),
    );
    if (container.errors?.length) {
      setContainerErrors(container.errors[0]);
      return false;
    }
    setContainerErrors(null);
    return true;
  }

  function handleOnChange(containerId) {
    controlForm.setValue(name, containerId, { shouldDirty: true });
    controlForm.trigger();
    trackActionEvent({ action_name: containerTypes.TRACKING.ACTION_NAMES.SELECTED_PROJECT });
  }

  return (
    <>
      <NewProjectModalRevamp
        isOpen={isNewProjectModalOpen}
        closeModal={closeNewProjectModal}
        onRequestClose={closeNewProjectModal}
        handleOnChangeNewContainer={handleOnChange} // eslint-disable-line react/jsx-no-bind
        providerDisplayName={provider.get('displayName')}
        providerIdentityId={providerIdentityId}
        containerTerm={containerTerm}
        workspaceTerm={workspaceTerm}
        containerType={containerType}
        itemType={itemType}
      />
      <div ref={controllerRef}>
        <Controller
          render={({ field: { value, onChange } }) => (
            <Select
              value={value}
              isLoading={isLoading}
              onChange={(val) => {
                onChange(val);
                trackActionEvent({ action_name: containerTypes.TRACKING.ACTION_NAMES.SELECTED_PROJECT });
              }}
              options={options}
              placeholder={getPlaceholderText(capabilitiesV3, containerType)}
              searchable
              onSearch={getOnInputChange()}
              disabled={disabled}
              onOpen={() => trackActionEvent({ action_name: containerTypes.TRACKING.ACTION_NAMES.OPEN_DROPDOWN })}
              searchPlaceholder={searchPlaceholder}
              noResultsText={noResultsText}
            />
          )}
          control={control}
          name={name}
          rules={{ validate }}
        />
      </div>
      {errors && <Tooltip content={containerErrors} forceShow={!!errors} />}
    </>
  );
};

ContainersSelectRevamp.propTypes = {
  closeNewProjectModal: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  controlForm: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
    trigger: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  getProviderIdentityId: PropTypes.func.isRequired,
  trackActionEvent: PropTypes.func.isRequired,
  trackBlockedEvent: PropTypes.func.isRequired,
  isNewProjectModalOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  provider: PropTypes.instanceOf(Map).isRequired,
  itemType: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
  valuesToDisable: PropTypes.instanceOf(List),
};
