import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Typography, Box, ProviderIcon, tokens, LoadingPlaceholder, Tooltip } from '@unitoio/mosaic';

import { getLinkContainerBySide, getLinkProviderNameBySide } from 'reducers';
import { Href } from 'components';
import { useContainerLinkTrackEvent } from '../hooks/useContainerLinkTrackEvent';

const sideToMargin = {
  A: [0, 0, 0, tokens.spacing.s4],
  B: [0, tokens.spacing.s4, 0, 0],
};

const TypographyBoosted = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
`;

const TypographyBoostedGrey = styled(TypographyBoosted)`
  color: ${tokens.colors.content.neutral.n30};
`;

export const FlowContainerInfo = ({ containerSide, linkId, subtitle, isLoading = false }) => {
  const container = useSelector((state) => getLinkContainerBySide(state, { containerSide, linkId }));
  const containerName = container.get('displayName');
  const providerName = useSelector((state) => getLinkProviderNameBySide(state, { containerSide, linkId }));
  const trackEvent = useContainerLinkTrackEvent();

  return (
    <Box flexDirection="row" alignItems="center" justifyContent={containerSide === 'B' ? 'flex-end' : undefined}>
      {isLoading && !containerName ? (
        <LoadingPlaceholder width="4rem" height="4rem" />
      ) : (
        <Box alignItems="center">
          {containerSide === 'A' && (
            <Tooltip content={`Open ${containerName} in a new tab`} placement="top">
              <Box onClick={() => trackEvent(providerName)} href={container.get('url')} as={Href} alignItems="center">
                <ProviderIcon size="xlarge" name={providerName} />
              </Box>
            </Tooltip>
          )}
          <Box m={sideToMargin[containerSide]}>
            <Tooltip content={`Open ${containerName} in a new tab`} placement="top">
              <Box onClick={() => trackEvent(providerName)} href={container.get('url')} as={Href} alignItems="center">
                <TypographyBoosted
                  variant="h4"
                  align={containerSide === 'B' ? 'right' : 'left'}
                  title={container.get('displayName')}
                >
                  {container.get('displayName')}
                </TypographyBoosted>
              </Box>
            </Tooltip>
            {subtitle && (
              <TypographyBoostedGrey variant="body2" title={subtitle}>
                {subtitle}
              </TypographyBoostedGrey>
            )}
          </Box>
          {containerSide === 'B' && (
            <Tooltip content={`Open ${containerName} in a new tab`} placement="top">
              <Box onClick={() => trackEvent(providerName)} href={container.get('url')} as={Href} alignItems="center">
                <ProviderIcon size="xlarge" name={providerName} />
              </Box>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

FlowContainerInfo.propTypes = {
  containerSide: PropTypes.oneOf(['A', 'B']).isRequired,
  linkId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  subtitle: PropTypes.string,
};
