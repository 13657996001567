import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@unitoio/mosaic';

import { Href } from 'components';

import { NoWrapHeader, TableCell } from '../components';

const Cell = ({ value: url }) => (
  <TableCell>
    <div className="no-wrap wide ellipsized">
      <Href target="_blank" href={url}>
        {url}
      </Href>
    </div>
  </TableCell>
);

Cell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const originalItemColumn = {
  header: () => (
    <NoWrapHeader>
      <Tooltip as="span" placement="bottom" content="URL of the item that is at the origin of the sync.">
        Original item
      </Tooltip>
    </NoWrapHeader>
  ),
  accessor: 'sourceTask.url',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
