import { NodeModel } from '@projectstorm/react-diagrams';
import { workflowTypes } from 'consts';
import { WorkBlockPortModel } from './WorkBlockPortModel';

export const WORKBLOCK_WIDTH = 160;
export const WORKBLOCK_HEIGHT = 160;

export class WorkBlockModel extends NodeModel {
  constructor(options = {}) {
    super({
      ...options,
      type: 'WorkBlock',
    });
    this.flowNodeIds = [];
    this.containerId = null;
    this.providerIdentityId = null;
    this.containerName = null;
    this.containerType = null;
    this.itemType = null;
    this.toolName = null;
  }

  serialize() {
    return {
      ...super.serialize(),
      itemType: this.itemType,
      containerId: this.containerId,
      containerType: this.containerType,
      providerIdentityId: this.providerIdentityId,
      height: WORKBLOCK_WIDTH,
      width: WORKBLOCK_HEIGHT,
      blockId: this.blockId,
      providerContainerId: this.providerContainerId,
      flowNodeIds: this.flowNodeIds,
      toolName: this.toolName,
      name: this.name,
    };
  }

  deserialize(event, engine) {
    super.deserialize(event, engine);
    this.providerIdentityId = event.data.providerIdentityId;
    this.itemType = event.data.itemType;
    this.containerType = event.data.containerType;
    this.containerId = event.data.containerId;
    this.blockId = event.data.blockId;
    this.providerContainerId = event.data.providerContainerId;
    this.flowNodeIds = event.data.flowNodeIds || [];
    this.toolName = event.data.toolName;
    this.name = event.data.name;
    this.updateDimensions({ width: event.data.width, height: event.data.height });

    // to prevent legacy blocks from breaking, add missing center port on deserialization
    if (event.data.ports.length < Object.keys(workflowTypes.PORT_POSITIONS).length) {
      const centerPort = this.addPort(new WorkBlockPortModel(workflowTypes.PORT_POSITIONS.CENTER));
      centerPort.options.locked = true;
    }
  }

  addPorts() {
    this.addPort(new WorkBlockPortModel(workflowTypes.PORT_POSITIONS.TOP));
    this.addPort(new WorkBlockPortModel(workflowTypes.PORT_POSITIONS.LEFT));
    this.addPort(new WorkBlockPortModel(workflowTypes.PORT_POSITIONS.BOTTOM));
    this.addPort(new WorkBlockPortModel(workflowTypes.PORT_POSITIONS.RIGHT));
    // adds center port and locks it so you don't accidentally drag from it
    const centerPort = this.addPort(new WorkBlockPortModel(workflowTypes.PORT_POSITIONS.CENTER));
    centerPort.options.locked = true;
  }

  initialize({
    name: displayName,
    toolName,
    providerIdentityId,
    containerId,
    blockId,
    providerContainerId,
    containerType,
    itemType,
  }) {
    this.setName(displayName);
    this.setProviderIdentityId(providerIdentityId);
    this.setItemType(itemType);
    this.setContainerId(containerId);
    this.setContainerType(containerType);
    this.setBlockId(blockId);
    this.setProviderContainerId(providerContainerId);
    this.setToolName(toolName);
    this.addPorts();
  }

  // TODO it would be good to include itemType & containerType here too
  // but we can't yet because of legacy blocks which don't have this info
  isSetupCompleted() {
    return !!(this.containerId && this.providerIdentityId && this.blockId) || this.isPlaceholder();
  }

  isPlaceholder() {
    return !!this.toolName && !this.containerName && !this.providerContainerId && !this.providerIdentityId;
  }

  setName(name) {
    this.name = name;
  }

  getName() {
    return this.name;
  }

  setItemType(itemType) {
    this.itemType = itemType;
  }

  getItemType() {
    return this.itemType ?? this.inMemoryItemType;
  }

  setContainerId(containerId) {
    this.containerId = containerId;
  }

  getContainerId() {
    return this.containerId;
  }

  setProviderIdentityId(providerIdentityId) {
    this.providerIdentityId = providerIdentityId;
  }

  getProviderIdentityId() {
    return this.providerIdentityId;
  }

  getBlockId() {
    return this.blockId;
  }

  setBlockId(blockId) {
    this.blockId = blockId;
  }

  addFlowNodeId(flowNodeId) {
    this.flowNodeIds.push(flowNodeId);
  }

  removeFlowNodeId(flowNodeId) {
    this.flowNodeIds = this.flowNodeIds.filter((nodeId) => nodeId !== flowNodeId);
  }

  getFlowNodeIds() {
    return this.flowNodeIds;
  }

  getProviderContainerId() {
    return this.providerContainerId;
  }

  setProviderContainerId(providerContainerId) {
    this.providerContainerId = providerContainerId;
  }

  setContainerName(containerName) {
    this.containerName = containerName;
  }

  getContainerName() {
    return this.containerName;
  }

  setContainerType(containerType) {
    this.containerType = containerType;
  }

  getContainerType() {
    return this.containerType ?? this.inMemoryContainerType;
  }

  getToolName() {
    return this.toolName;
  }

  setToolName(toolName) {
    this.toolName = toolName;
  }

  // This is for legacy blocks that were created before itemType / containerType
  // Those blocks won't have itemType / containerType stored in the `blocks` array of their workflow and so in order to not have a drift
  // between what is in the `diagramRepresentation` and the `blocks` array we will keep this info in memory and not persist it.
  // Ideally we should either migrate the legacy blocks or do a lazy migration by allowing the workflow patch endpoint to update the `blocks` array.
  getInMemoryContainerType() {
    return this.inMemoryContainerType;
  }

  setInMemoryContainerType(containerType) {
    this.inMemoryContainerType = containerType;
  }

  getInMemoryItemType() {
    return this.inMemoryItemType;
  }

  setInMemoryItemType(itemType) {
    this.inMemoryItemType = itemType;
  }
}
