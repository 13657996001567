import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Button, Modal, Typography, TypographyVariants, notification } from '@unitoio/mosaic';

import { billingActions } from 'actions';
import { trackingTypes } from 'consts';
import { useTrackEvent } from 'hooks';

export const ResumeSubscriptionButton = ({ children, organizationId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch();

  const unpauseSubscription = async () => {
    await dispatch(billingActions.unpauseSubscription(organizationId));
    notification.info({
      message: 'Subscription resumed',
      description: 'Your Unito subscription will no longer be paused',
      placement: 'topRight',
    });
  };

  const handleClick = () => {
    setIsModalOpen(true);
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on resume subscription (soon to be paused)',
    });
  };

  const handleSubmit = () => {
    setIsModalOpen(false);
    unpauseSubscription();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick}>{children}</Button>
      <Modal
        isOpen={isModalOpen}
        onCancel={handleModalClose}
        onConfirm={handleSubmit}
        confirmLabel="Resume subscription"
        title="Welcome back!"
        size={Modal.sizes.LG}
        onRequestClose={handleModalClose}
      >
        <Typography variant={TypographyVariants.BODY1}>
          If you resume your subscription, you will return to your current plan and billing cycle.
        </Typography>
      </Modal>
    </>
  );
};

ResumeSubscriptionButton.propTypes = {
  children: PropTypes.node.isRequired,
  organizationId: PropTypes.string.isRequired,
};
