import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { inviteActions } from 'actions';

import { useLogger } from './useLogger';

/**
 * Hooks to fetch the workspace name based on the invitationId stored in the cookie
 * @returns {workspaceName, isloading, hasFailed}
 */
export const useGetWorkspaceNameFromInvite = () => {
  const wasUserInvited = !!Cookies.get('invitationId');
  const [isLoading, setIsLoading] = useState(wasUserInvited);
  const [workspaceName, setWorkspaceName] = useState();
  const [hasFailed, setHasFailed] = useState(false);
  const { reportWarning } = useLogger();
  const dispatch = useDispatch();

  useEffect(() => {
    const getWorkspaceName = async () => {
      try {
        const { organizationName } = await dispatch(inviteActions.getInviteWorkspaceName());
        setWorkspaceName(organizationName);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setHasFailed(true);
        reportWarning(err, {
          identifier: 'useGetWorkspaceNameFromInvite getInviteWorkspaceName failedGetInviteWorkspaceName',
        });
      }
    };

    if (wasUserInvited) {
      getWorkspaceName();
    }
  }, [wasUserInvited, dispatch, reportWarning]);

  return { isLoading, hasFailed, workspaceName };
};
