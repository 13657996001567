import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Color from 'color';

import { Box, ALLOWED_NON_NUMERICAL_MARGIN_VALUES } from 'components';
import { color as themeColor, borderRadius as themeBorderRadius } from 'theme';

const borderPropToCss = {
  dashed: `border: 4px dashed ${themeColor.content.neutral.border}`,
  solid: `border: 1px solid ${themeColor.content.neutral.border};,`,
};

const darkenColor = (color) => Color(color).darken(0.15).string();

const StyledCard = styled(Box)`
  background-color: ${(props) => props.$color || 'transparent'};

  ${(props) => {
    if (props.$borderless) {
      return undefined;
    }

    if (props.$border) {
      return borderPropToCss[props.$border];
    }

    return `border: 1px solid ${props.$borderColor || darkenColor(props.$color || themeColor.dark.whisper)}`;
  }};

  border-radius: ${(props) => props.$borderRadius || themeBorderRadius.double};

  ${(props) => props.$boxShadow && `box-shadow: 0px 0px 12px 0px ${themeColor.dark.whisper}`};
  ${(props) => props.$disabled && `opacity: 0.7; pointer-events: none;`};
  ${(props) => {
    if (!props.onClick) {
      return null;
    }

    return `
      &:hover {
        box-shadow: 0px 0px 12px 0px ${themeColor.dark.whisper};
        cursor: pointer;
      }
    `;
  }};

  ${(props) => {
    if (props.$p.length) {
      return null;
    }

    // handles legacy padding css
    if (props.$padding) {
      return `padding: ${props.$padding}`;
    }

    return 'padding: 2em;';
  }};

  ${(props) => {
    if (props.$m.length) {
      return null;
    }

    // handles legacy margin css
    if (props.$margin) {
      return `margin: ${props.$margin}`;
    }

    return 'margin: 0em';
  }};
`;

export const Card = ({
  children = null,
  border = '',
  boxShadow = false,
  disabled = false,
  borderless = false,
  borderRadius = '',
  m = [],
  p = [],
  padding = '',
  margin = '',
  color = '',
  className = '',
  onClick,
  onMouseOver = () => {},
  onFocus = () => {},
  onMouseLeave = () => {},
}) => (
  <StyledCard
    $color={color}
    $border={border}
    $borderless={borderless}
    $boxShadow={boxShadow}
    $borderRadius={borderRadius}
    $m={m}
    $p={p}
    // TODO: use the Box padding/margin on all cards
    $padding={padding} // for the legacy cards, pre-Box component
    $margin={margin} // for the legacy cards, pre-Box component
    onClick={!disabled ? onClick : undefined}
    onFocus={onFocus}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    $disabled={disabled}
    className={className}
  >
    {children}
  </StyledCard>
);

Card.propTypes = {
  children: PropTypes.node,
  border: PropTypes.string,
  disabled: PropTypes.bool,
  borderless: PropTypes.bool,
  borderRadius: PropTypes.string,
  boxShadow: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseLeave: PropTypes.func,
  m: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(ALLOWED_NON_NUMERICAL_MARGIN_VALUES)])),
  p: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(ALLOWED_NON_NUMERICAL_MARGIN_VALUES)])),
  padding: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
};
