import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Title } from 'components';

import ActivityStreamInitialSyncImg from './images/ActivityStreamInitialSync.svg';

const ActivityStreamInitialSyncWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 60%;
`;

export function ActivityStreamInitialSync({ containerTerm, taskTerm }) {
  return (
    <ActivityStreamInitialSyncWrapper>
      <Box $m={[1, 0, 0, 0]}>
        <img src={ActivityStreamInitialSyncImg} alt="initial sync in progress" />
      </Box>
      <Box $m={[1, 0]}>
        <Title type="h3">Initial sync is in progress</Title>
      </Box>
      <Box $m={[0, 0, 1, 0]}>
        This flow is currently syncing. Unito is reviewing all {taskTerm} between your {containerTerm}. This might take
        a while.
      </Box>
    </ActivityStreamInitialSyncWrapper>
  );
}

ActivityStreamInitialSync.propTypes = {
  containerTerm: PropTypes.node.isRequired,
  taskTerm: PropTypes.element.isRequired,
};
