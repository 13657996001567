import { useSelector } from 'react-redux';

import { getProviderCapabilitiesByProviderNameV3, getProviderCapabilitiesByProviderName } from 'reducers';
import { useGetItemTypes } from 'hooks';

import { useGetProviderNames } from './useGetProviderNames';

export function useGetCapabilitiesV3(linkId) {
  const [providerNameA, providerNameB] = useGetProviderNames(linkId);
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const capabilitiesA = useSelector((state) =>
    getProviderCapabilitiesByProviderNameV3(state, { providerName: providerNameA, itemType: itemTypeA }),
  );
  const capabilitiesB = useSelector((state) =>
    getProviderCapabilitiesByProviderNameV3(state, { providerName: providerNameB, itemType: itemTypeB }),
  );
  const capabilitiesAv2 = useSelector((state) => getProviderCapabilitiesByProviderName(state, providerNameA));
  const capabilitiesBv2 = useSelector((state) => getProviderCapabilitiesByProviderName(state, providerNameB));
  return [capabilitiesA, capabilitiesB, capabilitiesAv2, capabilitiesBv2];
}
