import { billingTypes } from 'consts';

export const getTierFromPlan = (plan) => {
  if (!plan) {
    return undefined;
  }
  const { PERSONAL, TEAM, COMPANY, BASIC, PRO, BUSINESS } = billingTypes.PLAN_TYPES;
  const possibleTiers = [PERSONAL, TEAM, COMPANY, BASIC, PRO, BUSINESS];
  return possibleTiers.find((tier) => plan.get('id').includes(tier));
};

export const getNextPlanTier = (currentPlanTier) => {
  const nextTier = new Map([
    ['personal', 'team'],
    ['team', 'company'],
    ['company', 'enterprise'],
    ['basic', 'pro'],
    ['pro', 'business'],
    ['business', 'enterprise'],
    ['enterprise', undefined],
  ]);

  return nextTier.get(currentPlanTier);
};
