import PropTypes from 'prop-types';
import React from 'react';

import { Typography, TypographyVariants } from '@unitoio/mosaic';

import { ProgressBar, IconHoverTooltip } from 'components';
import { isFeatureUnlimited } from 'utils';
import { featureTypes } from 'consts';

export const LimitProgressBar = ({ helpText, limitName, limitValue, currentValue }) => {
  const limitPercentage = Number.parseInt((currentValue / limitValue) * 100, 10) || 0;
  const NaNOrUnlimited = Number.isNaN(limitValue) || isFeatureUnlimited(limitValue);

  // TODO For organizations with unlimited mirrors, we don't display the usage count anymore, until
  // TODO this https://app.asana.com/0/847713977592024/1198296450340406/f is resolved.
  const displayedLimitValue = !NaNOrUnlimited
    ? `(${currentValue}${NaNOrUnlimited ? '' : `/${limitValue}`})`
    : `(${featureTypes.UNLIMITED})`;

  return (
    <div>
      <Typography variant={TypographyVariants.BODY1}>
        {`${limitName} ${displayedLimitValue} `}
        {helpText && <IconHoverTooltip placement="top">{helpText}</IconHoverTooltip>}
      </Typography>

      {!NaNOrUnlimited && (
        <ProgressBar
          type="horizontal"
          progression={limitPercentage}
          dangerZone={limitPercentage >= 80}
          animateTransition
        />
      )}
    </div>
  );
};

LimitProgressBar.propTypes = {
  helpText: PropTypes.string,
  limitName: PropTypes.string.isRequired,
  limitValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currentValue: PropTypes.number,
};
