import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-modal';
import classnames from 'classnames';
import styled from 'styled-components';

import { Button as MimicsButton, Icon } from '@unitoio/mosaic';

import { Box, Button, Title } from 'components';
import { color } from 'theme';

const CloseButton = styled(Icon)`
  position: absolute;
  top: 20px;
  right: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const modalSizes = {
  sm: '400px',
  md: '500px',
  lg: '600px',
  full: 'none',
};

const Content = styled.div`
  width: ${(props) => modalSizes[props.$size]};
`;

const ButtonBar = styled.div`
  margin-top: 2rem;
`;

const PullRightMimicsButton = styled(MimicsButton)`
  float: right;
`;

const defaultCustomStyles = (overflow) => ({
  overlay: {
    backgroundColor: `${color.content.neutral.text}E6`,
  },
  content: {
    position: 'absolute',
    border: `1px solid ${color.content.neutral.border}`,
    background: color.content.neutral.white,
    overflow,
    borderRadius: '4px',
    outline: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2em',
    maxHeight: '90vh',
  },
});

const customStylesByPosition = (overflow) => ({
  bottomLeft: {
    overlay: {
      backgroundColor: 'transparent',
    },
    content: {
      ...defaultCustomStyles(overflow).content,
      top: 'auto',
      left: '1rem',
      bottom: '1rem',
      transform: 'none',
      marginRight: 'initial',
    },
  },
});

const modalTypes = {
  INFORMATIVE: 'informativeModal',
  PLAIN: 'plainModal',
  CONFIRMATION: 'confirmationModal',
};

const onConfirmWrapper = async (onConfirm, setIsConfirming) => {
  if (!onConfirm) {
    return;
  }

  setIsConfirming(true);
  try {
    await onConfirm();
  } catch {
    setIsConfirming(false);
  }
};

const UnitoModal = ({
  cancelLabel,
  children,
  className,
  confirmLabel = 'Confirm',
  contentLabel = 'Modal',
  disabled = false,
  displayCloseButton = false,
  isOpen,
  onCancel,
  onConfirm,
  onRequestClose,
  overflow = 'auto',
  position,
  size = 'full',
  title,
  type = modalTypes.CONFIRMATION,
  useNewButton = false,
  danger = false,
}) => {
  const modalClasses = classnames('modal-dialog', className);
  const cancelText = cancelLabel || (type === modalTypes.INFORMATIVE ? 'Close' : 'Cancel');
  const [isConfirming, setIsConfirming] = useState(false);
  const style = customStylesByPosition(overflow)[position] || defaultCustomStyles(overflow);

  return (
    <Modal
      ariaHideApp={false}
      className={modalClasses}
      contentLabel={contentLabel}
      isOpen={isOpen}
      onRequestClose={onRequestClose || onCancel}
      style={style}
    >
      {displayCloseButton && (
        <CloseButton
          data-testid={`${contentLabel}-close`}
          name="remove"
          onClick={onRequestClose || onCancel}
          title="close"
          kind={Icon.KINDS.SOLID}
        />
      )}

      {title && (
        <Box $m={[0, 0, 1]}>
          <Title type="h3">{title}</Title>
        </Box>
      )}

      <Content $size={size}>{children}</Content>

      {type !== modalTypes.PLAIN && (
        <ButtonBar className="clearfix">
          {type === modalTypes.CONFIRMATION && useNewButton ? (
            <PullRightMimicsButton
              onClick={() => {
                onConfirmWrapper(onConfirm, setIsConfirming);
                setIsConfirming(false);
              }}
              variant={danger ? 'destructive' : 'primary'}
              disabled={isConfirming || disabled}
            >
              {confirmLabel}
            </PullRightMimicsButton>
          ) : (
            <Button
              onClick={() => {
                onConfirmWrapper(onConfirm, setIsConfirming);
                setIsConfirming(false);
              }}
              btnStyle={danger ? 'error' : 'primary'}
              pullRight
              disabled={isConfirming || disabled}
            >
              {confirmLabel}
            </Button>
          )}
          <Button btnStyle="link" pullRight onClick={onCancel || onRequestClose}>
            {cancelText}
          </Button>
        </ButtonBar>
      )}
    </Modal>
  );
};

UnitoModal.propTypes = {
  cancelLabel: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  confirmLabel: PropTypes.node,
  contentLabel: PropTypes.string,
  disabled: PropTypes.bool,
  displayCloseButton: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onRequestClose: PropTypes.func,
  danger: PropTypes.bool,
  overflow: PropTypes.oneOf(['auto', 'visible']),
  position: PropTypes.oneOf(['bottomLeft']),
  size: PropTypes.oneOf(Object.keys(modalSizes)),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  useNewButton: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(modalTypes)),
};

export { UnitoModal as Modal };
