import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Href, UnitoLogo } from 'components';
import { routes } from 'consts';
import { color } from 'theme';

const Navbar = styled.nav`
  align-items: center;
  background: ${`${color.background.global.gray} !important;`};
  display: flex;
  max-height: 70px;

  ${`.nav-tabs {border-bottom: 4px solid ${color.background.global.gray}!important;}`};

  .nav-tabs > li > a {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
`;

const RightNavbar = styled(Box)`
  align-items: center;
  display: flex;
`;

export const LeftHeader = ({ children }) => <ul className="nav navbar-nav">{children}</ul>;

LeftHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export const RightHeader = ({ children }) => (
  <RightNavbar $m={[0, 1, 0, 'auto']} as="ul" className="nav">
    {children}
  </RightNavbar>
);

RightHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export function Header({ children, logoRedirectUrl = routes.ABSOLUTE_PATHS.DASHBOARD }) {
  return (
    <Navbar>
      <Box $m={[0, 1]} className="pull-left">
        <Href to={logoRedirectUrl}>
          <UnitoLogo kind="horizontal" color="color" width="132px" />
        </Href>
      </Box>
      {children}
    </Navbar>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  logoRedirectUrl: PropTypes.string,
};
