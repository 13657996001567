import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Map } from 'immutable';

import { inviteActions, trackingActions } from 'actions';
import { Modal, Typography } from '@unitoio/mosaic';

import './UserOrgInviteModal.scss';

export const UserOrgInviteModal = ({ invite }) => {
  const [isOpen, setIsOpen] = useState(!invite.isEmpty());
  const dispatch = useDispatch();
  const trackEvent = useCallback(
    (actionName, data) => dispatch(trackingActions.trackEvent(actionName, data)),
    [dispatch],
  );

  useEffect(() => {
    if (isOpen) {
      trackEvent('CHOOSE-UNITO-ORG_START');
    }
  }, [isOpen, trackEvent]);

  const acceptInvite = () => {
    trackEvent('CHOOSE-UNITO-ORG_SUBMIT', { settings: 'add to an existing Unito organization' });
    dispatch(inviteActions.acceptInvite(invite.get('id')));
    setIsOpen(false);
  };
  const declineInvite = (ignoredModal = false) => {
    trackEvent('CHOOSE-UNITO-ORG_SUBMIT', {
      settings: `create a new Unito organization${ignoredModal ? ' by ignoring modal' : ''}`,
    });
    dispatch(inviteActions.declineInvite(invite.get('id')));
    setIsOpen(false);
  };

  const invitedBy = invite.getIn(['invitedBy', 'fullName']);

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      displayCloseButton
      confirmLabel="Join workspace"
      cancelLabel="Decline invitation"
      onConfirm={acceptInvite}
      onCancel={() => declineInvite()}
      onRequestClose={() => declineInvite(true)}
      title="You'll discover a lot by working together!"
    >
      <Typography variant="body1">
        You've been invited to join <span className="user-org-invite-modal__invited-by">{invitedBy}'s workspace.</span>{' '}
        If you accept, you'll be able to create and edit flows.
      </Typography>
      <br />
      <Typography variant="body1">
        If you think you've received this by mistake, you can just decline the invitation and no changes will be made to
        your account.
      </Typography>
    </Modal>
  );
};

UserOrgInviteModal.propTypes = {
  invite: PropTypes.instanceOf(Map).isRequired,
};
