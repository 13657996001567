import { Map, List, OrderedMap, fromJS } from 'immutable';
import { actionTypes } from 'redux-form';

import { containerTypes, providerIdentityTypes } from 'consts';
import { containerGenerator } from 'utils';

export const initialState = Map({
  allowedProviderIdentityIds: Map(),
  containers: Map({
    A: Map(),
    B: Map(),
  }),
  createdContainersIds: List(),
  workspaces: Map(),
  loading: Map(),
  loadingByContainerId: Map(),
  activeContainers: Map(),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case containerTypes.GET_CONTAINER_PLUGINS_SUCCESS: {
      const {
        meta: { containerId },
        payload: plugins,
      } = action;

      return state.setIn(['pluginsByContainerId', containerId], fromJS(plugins));
    }

    case containerTypes.GET_WORKSPACES_SUCCESS: {
      const {
        meta: { providerIdentityId },
        payload: { workspaces },
      } = action;

      const workspacesTree = Array.from(containerGenerator(workspaces));
      let workspacesById = OrderedMap();
      workspacesTree.forEach((workspace) => {
        workspacesById = workspacesById.set(
          workspace.id,
          fromJS({
            ...workspace,
          }),
        );
      });

      return state.setIn(['workspaces', providerIdentityId], workspacesById);
    }

    case actionTypes.DESTROY: {
      const forms = action.meta.form || [];
      if (forms.includes('syncForm')) {
        return state.set('containers', initialState.get('containers'));
      }

      return state;
    }

    case providerIdentityTypes.GET_ALLOWED_PROVIDER_IDENTITIES_SUCCESS: {
      const { providerIdentityIds } = action.payload;
      const { containerId, providerIdentityId } = action.meta;
      if (!providerIdentityIds.find((pi) => pi === providerIdentityId)) {
        providerIdentityIds.push(providerIdentityId);
      }
      return state.setIn(['allowedProviderIdentityIds', containerId], fromJS(providerIdentityIds));
    }

    case containerTypes.GET_CONTAINERS_REQUEST: {
      const { providerIdentityId } = action.meta;
      return state.setIn(['loading', providerIdentityId], true);
    }

    case containerTypes.GET_CONTAINERS_SUCCESS:
    case containerTypes.GET_CONTAINERS_FAILURE: {
      const { providerIdentityId } = action.meta;
      return state.setIn(['loading', providerIdentityId], false);
    }

    case containerTypes.GET_CONTAINER_REQUEST: {
      const { containerId } = action.meta;
      return state.setIn(['loadingByContainerId', containerId], true);
    }

    case containerTypes.GET_CONTAINER_SUCCESS:
    case containerTypes.GET_CONTAINER_FAILURE: {
      const { containerId } = action.meta;
      return state.setIn(['loadingByContainerId', containerId], false);
    }

    case containerTypes.GET_ACTIVE_CONTAINERS_SUCCESS: {
      return state.setIn(['activeContainers'], fromJS(action.payload));
    }

    default:
      return state;
  }
};

/**
 * SELECTORS
 */

export const getWorkspaces = (state, providerIdentityId) => state.getIn(['workspaces', providerIdentityId], Map());

export const getWorkspaceById = (state, providerIdentityId, containerId) =>
  state.getIn(['workspaces', providerIdentityId, containerId], Map());

export const getContainers = (state, containerSide) => state.getIn(['containers', containerSide], Map());

export const getContainerById = (state, containerSide, containerId) =>
  state.getIn(['containers', containerSide, containerId], Map());

export const getContainerPlugins = (state, containerId) => state.getIn(['pluginsByContainerId', containerId], List());

export const getCreatedContainersIds = (state) => state.get('createdContainersIds', List());

export const getAllowedProviderIdentitiesByContainerId = (state, containerId) =>
  state.getIn(['allowedProviderIdentityIds', containerId], List());

export const isLoading = (state, providerIdentityId) => state.getIn(['loading', providerIdentityId], false);

export const isContainerLoading = (state, containerId) => state.getIn(['loadingByContainerId', containerId], false);

export const getActiveContainers = (state) => state.get('activeContainers').sortBy((v) => -v.get('itemsCount'));
