import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { itemActions } from 'actions';
import { useLogger } from 'hooks';

export const useFetchItemInfo = (itemId) => {
  const dispatch = useDispatch();
  const { reportWarning } = useLogger();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [item, setItem] = useState(undefined);
  const [blockFlows, setBlockFlows] = useState(undefined);
  const [sisterItems, setSisterItems] = useState(undefined);
  const fetchItemInfo = useCallback(async () => {
    try {
      setError(null);
      setIsLoading(true);

      const response = await dispatch(itemActions.getItemInfo(itemId));

      if (!response.item) {
        setError('no item found');
      } else {
        setItem(response.item);
        setBlockFlows(response.blockFlows);
        setSisterItems(response.itemsSisters);
      }
    } catch (err) {
      setError(err);
      reportWarning(err, { itemId });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, itemId, reportWarning]);

  useEffect(() => {
    fetchItemInfo();
  }, [fetchItemInfo]);

  return { isLoading, item, blockFlows, sisterItems, error, fetchItemInfo };
};
