import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { useFormContext, useFormState } from 'react-hook-form';

import { FlowBuilderErrorContext } from 'contexts';
import { getLinkById } from 'reducers';
import { ProviderTermsByName } from 'components';
import { linkTypes, trackingTypes, routes } from 'consts';
import { useGetItemTypes } from 'hooks';

import { getLastAssociation } from 'containers/FlowBuilder/utils/getLastAssociation';
import { useWatchFieldArray } from '../../hooks';
import { getIsWorkItemsPageCompleted } from '../../utils/form';
import { GuideStepTitleWithTime } from './GuideStepTitleWithTime';
import { GuideStepTitle } from './GuideStepTitle';
import { GuideStep } from './GuideStep';
import { useGetLabelAndStatusFromAnomalies, PAGES } from '../../hooks/useGetAnomalies';
import MappedFieldsIcon from '../../images/steps_icons/mapped_fields.svg';

export const GuideStepMapping = ({ linkState }) => {
  const { linkId } = useParams();
  const { getValues } = useFormContext();
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const pageName = useContext(FlowBuilderErrorContext);
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const filtersInitializedAtA = currentLink.getIn(['syncSettings', 'A', 'filtersInitializedAt']);
  const filtersInitializedAtB = currentLink.getIn(['syncSettings', 'B', 'filtersInitializedAt']);

  const { fields: fieldAssociations, remove } = useWatchFieldArray('associations');

  const { errors } = useFormState();
  const providerIdentityAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.providerIdentityId;
  const providerIdentityBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.providerIdentityId;
  const containerAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.containerId;
  const containerBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.containerId;
  const hasContainerErrors = providerIdentityAError || providerIdentityBError || containerAError || containerBError;
  const hasMappingErrors = !!errors[pageName];
  // filtersInitializedAt is the new confirmation method
  // please keep the filters length condition for backward compatibility sake
  const hasFilters =
    (getValues('A.filters')?.length && getValues('A.filters')?.length) ||
    (filtersInitializedAtA && filtersInitializedAtB);
  const hasAssociations = !!fieldAssociations?.length;
  const [labelFromAnomalies, statusFromAnomalies] = useGetLabelAndStatusFromAnomalies(linkId, PAGES.MAPPED_FIELDS);
  const linkKind = useSelector((state) => getLinkById(state, linkId)).get('kind');
  const isWorkItemStepCompleted = getIsWorkItemsPageCompleted(currentLink);
  const [lastAssociation, lastAssociationIndex] = getLastAssociation(fieldAssociations);

  const canDeleteLastAssociation = lastAssociation?.A?.field === null || lastAssociation?.B?.field === null;

  useEffect(() => {
    if (canDeleteLastAssociation) {
      remove(lastAssociationIndex);
    }
  }, [lastAssociationIndex, canDeleteLastAssociation, lastAssociation?.A, lastAssociation?.B, remove]);

  const isLiteGuide = linkTypes.GUIDE_VARIANTS[linkKind] === linkTypes.GUIDE_VARIANT_TYPE.LITE;
  if (!hasMappingErrors && hasContainerErrors) {
    return hasAssociations ? (
      <GuideStep status="inaccessible" statusLabel="Flow mapping status">
        <GuideStepTitle title="Fields mapped" number={fieldAssociations.length} />
      </GuideStep>
    ) : (
      <GuideStep status="inaccessible" statusLabel="Flow mapping status">
        <GuideStepTitleWithTime
          title={
            <>
              4. Specify what information flows between{' '}
              <ProviderTermsByName
                providerNameA={currentLink.getIn(['A', 'providerName'])}
                providerNameB={currentLink.getIn(['B', 'providerName'])}
                termKey="task"
                plurality="plural"
                pcdv3
                itemTypeA={itemTypeA}
                itemTypeB={itemTypeB}
              />
            </>
          }
          icon={MappedFieldsIcon}
          time={3}
        />
      </GuideStep>
    );
  }

  if (hasMappingErrors) {
    return (
      <GuideStep status="review" actionSlug={routes.FLOW_BUILDER_PAGES.MAPPINGS} statusLabel="Flow mapping status">
        <GuideStepTitle title="Fields mapped" number={fieldAssociations.length} />
      </GuideStep>
    );
  }

  if (statusFromAnomalies) {
    return (
      <GuideStep
        status={statusFromAnomalies}
        actionLabel={labelFromAnomalies}
        actionSlug={routes.FLOW_BUILDER_PAGES.MAPPINGS}
        statusLabel="Flow mapping status"
      >
        <GuideStepTitle title="Fields mapped" number={fieldAssociations.length} />
      </GuideStep>
    );
  }

  if (isWorkItemStepCompleted && hasAssociations) {
    let hasAssociationsStatus = 'edit';
    if (!hasAssociations) {
      hasAssociationsStatus = 'review';
    } else if (linkState === linkTypes.LINK_STATES.DRAFT) {
      hasAssociationsStatus = 'done';
    }

    const isStepLocked = isLiteGuide && hasAssociationsStatus !== 'edit';

    if (isStepLocked) {
      hasAssociationsStatus = 'locked';
    }

    return (
      <GuideStep
        status={hasAssociationsStatus}
        actionSlug={routes.FLOW_BUILDER_PAGES.MAPPINGS}
        actionLabel="Edit mapped fields"
        statusLabel="Flow mapping status"
      >
        <GuideStepTitle locked={isStepLocked} title="Fields mapped" number={fieldAssociations.length} />
      </GuideStep>
    );
  }

  const stepStatus = isWorkItemStepCompleted && hasFilters ? 'current' : 'default';
  return (
    <GuideStep
      status={stepStatus}
      actionLabel={stepStatus === 'current' ? 'Continue' : null}
      actionSlug={routes.FLOW_BUILDER_PAGES.MAPPINGS}
      statusLabel="Flow mapping status"
    >
      <GuideStepTitleWithTime
        title={
          <>
            4. Specify what information flows between{' '}
            <ProviderTermsByName
              providerNameA={currentLink.getIn(['A', 'providerName'])}
              providerNameB={currentLink.getIn(['B', 'providerName'])}
              termKey="task"
              plurality="plural"
              pcdv3
              itemTypeA={itemTypeA}
              itemTypeB={itemTypeB}
            />
          </>
        }
        icon={MappedFieldsIcon}
        time={3}
      />
    </GuideStep>
  );
};

GuideStepMapping.propTypes = {
  linkState: PropTypes.oneOf(Object.values(linkTypes.LINK_STATES)).isRequired,
};
