import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { childrenOfType } from 'airbnb-prop-types';

import { Icon, tokens } from '@unitoio/mosaic';

import { Title } from 'components';

const ItemWrapper = styled.div`
  border-top: ${(props) => props.$index && `1px solid ${tokens.colors.content.neutral.n30}`};
  padding: ${tokens.spacing.s4} 0;
  cursor: pointer;
`;

const Content = styled.div`
  width: 28rem;
`;

const StyledIcon = styled(Icon)`
  margin-top: -${tokens.spacing.s6};
`;

export const AccordionItem = ({ children, index = undefined, onClick = () => {}, title, isActive = false }) => (
  <ItemWrapper $index={index} onClick={onClick}>
    <Title type="h3">{title}</Title>
    <StyledIcon
      kind={Icon.KINDS.SOLID}
      className="pull-right"
      name={isActive ? 'chevron-up' : 'chevron-down'}
      color={tokens.colors.content.neutral.n30}
    />
    {isActive && <Content>{children}</Content>}
  </ItemWrapper>
);

AccordionItem.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export class Accordion extends Component {
  static propTypes = {
    children: childrenOfType(AccordionItem).isRequired,
    defaultItem: PropTypes.number,
  };

  static defaultProps = {
    defaultItem: null,
  };

  state = {
    activeItem: null,
  };

  componentDidMount() {
    const { defaultItem } = this.props;
    if (!Number.isNaN(defaultItem)) {
      this.setState({ activeItem: defaultItem });
    }
  }

  showItem = (item) => {
    this.setState({ activeItem: item });
  };

  hideItem = () => {
    this.setState({ activeItem: null });
  };

  renderChildren = () => {
    const { children } = this.props;
    const { activeItem } = this.state;

    const childrenArray = React.Children.toArray(children).filter((child) => child.type === AccordionItem);

    return childrenArray.map((child, index) => {
      const isActive = activeItem === index;
      const onClick = isActive ? this.hideItem : this.showItem;
      return React.cloneElement(child, {
        index,
        isActive,
        onClick: () => onClick(index),
      });
    });
  };

  render() {
    return <div>{this.renderChildren()}</div>;
  }
}
