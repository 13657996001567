import React from 'react';
import PropTypes from 'prop-types';

import { Button, Section } from 'components';
import { useQueryParams } from 'hooks';

import { AuthButtonsWrapper } from '../../AuthButtonsWrapper';

export function AuthGitHubAppInstallation({ onCancel, onSubmit, children: githubAppInstallations, value }) {
  const { windowOpenerId } = useQueryParams;
  return (
    <div className="auth-instruction-step">
      <Section>
        <p>
          We’ve noticed an administrator has already installed Unito for your GitHub organization. You can choose to{' '}
          <strong>use the current installation</strong> or <strong>install it on another GitHub account</strong>.
        </p>
        {githubAppInstallations}
        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            to={`step2?windowOpenerId=${windowOpenerId}`}
            onClick={onSubmit}
            type="submit"
            pullRight
            disabled={!value}
          >
            Continue
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthGitHubAppInstallation.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  value: PropTypes.string,
};
