import React from 'react';
import PropTypes from 'prop-types';
import { appcuesTypes } from 'consts';

import { Box, Link, NewAlert as Alert, NewAlertLevel } from '@unitoio/mosaic';

import { Href, AppcuesLink } from 'components';

export function ContainerInfo({ providerName }) {
  // This component is not really flexible. TODO make it more flexible (pcd driven ?)
  const PROVIDER_INFO = {
    favro: (
      <Box>
        In order for tasks to sync properly with Unito,{' '}
        <Href href="https://www.favro.com/pricing">Favro requires you to have, at least, a Standard plan</Href>. Trial
        or Lite accounts won’t support the integration.
      </Box>
    ),
    miro: (
      <Box>
        Don’t see the board you’re looking for? Make sure you have
        <AppcuesLink ctaId={appcuesTypes.CTA_IDS.MIRO_INSTRUCTIONS} asHref>
          {' '}
          edit rights
        </AppcuesLink>{' '}
        before connecting it.
      </Box>
    ),
    notion: (
      <Box>
        Can't find the database you're looking for? Try{' '}
        <AppcuesLink ctaId={appcuesTypes.CTA_IDS.NOTION_BOT_INSTRUCTIONS} asHref>
          following these steps
        </AppcuesLink>
        .
      </Box>
    ),
    snowflake: (
      <Box>
        Can't find the table you're looking for? Try{' '}
        <Link href="https://guide.unito.io/snowflake-overview#snowflake-limitations" target="_blank">
          reading these instructions
        </Link>
        .
      </Box>
    ),
    slack: (
      <Box>
        Don't see the channel you're looking for? Try{' '}
        <AppcuesLink ctaId={appcuesTypes.CTA_IDS.SLACK_BOT_INSTRUCTIONS} asHref>
          following these steps
        </AppcuesLink>
        .
      </Box>
    ),
    excel: (
      <Box>
        Don't see the sheet you're looking for? Try again in a few minutes as it may take Microsoft's API a little bit
        of time before it is accessible to Unito
      </Box>
    ),
  };

  if (!PROVIDER_INFO[providerName]) {
    return null;
  }

  return (
    <Box m={[1, 0, 1, 0]}>
      <Alert level={NewAlertLevel.INFO} message={PROVIDER_INFO[providerName]} />
    </Box>
  );
}

ContainerInfo.propTypes = {
  providerName: PropTypes.string,
};
