import { useCallback } from 'react';

import { featureTypes, fieldTypes, pcdFilterOperatorTypes } from 'consts';
import { getFeatureFlagValue } from 'reducers';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { useGetDefaultTestModeDate } from './useGetDefaultTestModeDate';

function addNewRule({
  newFieldId,
  append,
  groupedFields,
  field,
  parentFieldId,
  defaultTestModeDate,
  ffTestModeVariant,
  setValue,
}) {
  if (newFieldId === fieldTypes.EARLIEST_CREATED_AT) {
    const testModeDateTimeValue = defaultTestModeDate?.getTime();
    const operator = pcdFilterOperatorTypes.pcdFilterOperator.GT;
    const filter = {
      _id: `${newFieldId}-${operator}`,
      fieldId: newFieldId,
      operator,
      kind: 'options',
      value: testModeDateTimeValue,
    };
    append(filter);
    // Patch - Explicitly set the testModeVariant to last month or now if the user adds it manually
    // in the UI. Instead of falling back to the FF value otherwise the backend will reset the test mode value
    // if we send 'none' or 'null' and the user added the date. We can remove this patch when we send `earliestCreatedAt`
    // from the UI instead.
    const flowTestModeVariant =
      ffTestModeVariant === featureTypes.TEST_MODE_VARIANT.LAST_MONTH
        ? featureTypes.TEST_MODE_VARIANT.LAST_MONTH
        : featureTypes.TEST_MODE_VARIANT.NOW;
    setValue('testModeVariant', flowTestModeVariant);
    return;
  }

  const operator = groupedFields[newFieldId]?.existingOperators?.includes(pcdFilterOperatorTypes.pcdFilterOperator.IN)
    ? pcdFilterOperatorTypes.pcdFilterOperator.NIN
    : pcdFilterOperatorTypes.pcdFilterOperator.IN;

  append({
    _id: `${newFieldId}-${operator}`,
    fieldId: newFieldId,
    parentFieldId,
    operator,
    kind: parentFieldId ? fieldTypes.KINDS.PCD_TYPED_ITEM_FIELD : field.kind,
    type: field.type,
    value: [],
  });
}

export const useGetAddNewRule = () => {
  const { setValue } = useFormContext();
  const ffTestModeVariant = useSelector((state) => getFeatureFlagValue(state, featureTypes.FEATURES.NEW_TEST_MODE));
  const defaultTestModeDate = useGetDefaultTestModeDate();
  return useCallback(
    ({ newFieldId, append, groupedFields, field, parentFieldId }) =>
      addNewRule({
        newFieldId,
        append,
        groupedFields,
        field,
        parentFieldId,
        defaultTestModeDate,
        ffTestModeVariant,
        setValue,
      }),
    [ffTestModeVariant, defaultTestModeDate, setValue],
  );
};
