import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';

import { Button, ProviderButton } from 'components';
import { LegacyEmbedOAuthPopupHoC } from '../LegacyEmbedOAuthPopupHoC/LegacyEmbedOAuthPopupHoC';

function AuthorizeProviderButtonComponent({ includeProviderIcon = false, provider, children, onClick, ...rest }) {
  const ButtonElement = includeProviderIcon ? ProviderButton : Button;

  const { btnProps = {} } = rest;
  if (includeProviderIcon) {
    btnProps.provider = provider;
  }

  return (
    <ButtonElement onClick={onClick} {...btnProps} data-test="embed__btn--authorize">
      {children || `Connect ${provider.get('displayName')}`}
    </ButtonElement>
  );
}

AuthorizeProviderButtonComponent.propTypes = {
  btnProps: PropTypes.shape({}),
  children: PropTypes.node,
  includeProviderIcon: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  provider: PropTypes.instanceOf(Map).isRequired,
};

export const AuthorizeProviderButton = LegacyEmbedOAuthPopupHoC(AuthorizeProviderButtonComponent);
