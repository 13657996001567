import { featureTypes } from 'consts';
import { useSelector } from 'react-redux';

import { getFeatureFlagValue } from 'reducers';

// we currently have to deal with 2 FFs, hence the logic below might seem funky
// but this was vetted with PM, until we can get rid of the old hasTestModeDefault flag.
const getTestModeDate = (hasTestModeDefault, testModeVariant) => {
  if (testModeVariant === featureTypes.TEST_MODE_VARIANT.LAST_MONTH) {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  if (hasTestModeDefault || testModeVariant === featureTypes.TEST_MODE_VARIANT.NOW) {
    return new Date();
  }

  return null;
};

export const useGetDefaultTestModeDate = (dateString) => {
  const hasTestModeDefault = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.TEST_MODE_DEFAULT),
  );
  const testModeVariant = useSelector((state) => getFeatureFlagValue(state, featureTypes.FEATURES.NEW_TEST_MODE));
  if (dateString) {
    return new Date(dateString);
  }
  return getTestModeDate(hasTestModeDefault, testModeVariant);
};
