import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@unitoio/mosaic';

import { Box } from 'components';
import { containerTypes } from 'consts';

import { WorkBlockStyled } from './WorkBlockStyled';

export const WorkBlockAdd = ({ onClick = () => {}, scale }) => (
  <WorkBlockStyled
    $containerStatus={containerTypes.CONTAINER_STATUSES.NEW}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
  >
    <div style={{ width: `${100 / scale}%`, transformOrigin: 'top' }}>
      <Box $m={[3, 0, 0, 0]}>
        <Icon name="plus" size="2x" kind={Icon.KINDS.SOLID} />
      </Box>
      <Box $m={[0.5, 1]}>Add a block</Box>
    </div>
  </WorkBlockStyled>
);

WorkBlockAdd.propTypes = {
  onClick: PropTypes.func,
  scale: PropTypes.number.isRequired,
};
