import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Map } from 'immutable';

import { Button, Modal, RadioButton, RadioButtonGroup, Section, Title } from 'components';
import { AuthWindowOpener } from 'containers';
import { getProviderIdentitiesToMigrate, getProviderByName } from 'reducers';

import { color } from 'theme';

const MigrationBannersList = styled.ul`
  text-align: center;
  list-style-type: none;
  padding-inline-start: 0px;
  margin-bottom: 0;
`;

const MigrationBanner = styled.li`
  background-color: ${color.brand.lighterBlue};
  transition: background-color 0.4s cubic-bezier(0.61, 1, 0.88, 1);
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;

  > .btn {
    margin-left: 1rem;
  }
`;

class GithubMigrationBannerComponent extends Component {
  static propTypes = {
    githubProviderIdentities: PropTypes.instanceOf(Map).isRequired,
    githubAppProvider: PropTypes.instanceOf(Map).isRequired,
  };

  state = {
    selectedAccountDataId: null,
    selectedProviderIdentityId: null,
  };

  render() {
    const { githubProviderIdentities, githubAppProvider } = this.props;
    const { selectedAccountDataId, selectedProviderIdentityId } = this.state;

    const selectedProviderIdentity = githubProviderIdentities.get(selectedProviderIdentityId, Map());
    const accountsToMigrate = selectedProviderIdentity.get('accountsToMigrate', Map());
    const accountToMigrate =
      accountsToMigrate.size === 1
        ? accountsToMigrate.first()
        : accountsToMigrate.find((account) => account.get('workspaceId') === selectedAccountDataId, null, Map());

    if (githubProviderIdentities.isEmpty()) {
      return null;
    }

    return (
      <Fragment>
        <AuthWindowOpener
          onSuccess={() => this.setState({ selectedProviderIdentityId: null })}
          providerId={githubAppProvider.get('_id')}
          extraAuthenticationOptions={{
            accountToMigrate: accountToMigrate.merge({ providerIdentityId: selectedProviderIdentityId }),
            includesPreAuthentication: true,
          }}
        >
          {(openPopupWindow) => (
            <Modal
              isOpen={!!selectedProviderIdentityId}
              onCancel={() => this.setState({ selectedProviderIdentityId: null })}
              onConfirm={() => {
                openPopupWindow();
                this.setState({ selectedProviderIdentityId: null });
              }}
              size="md"
            >
              <Section>
                <Title type="h3">Unito is now a GitHub App!</Title>
              </Section>

              <p>
                By migrating <strong>{selectedProviderIdentity.get('profileDisplayName')}</strong> to GitHub apps, you
                can <strong>set more specific permissions</strong>, allowing Unito to only{' '}
                <strong>access specific repositories</strong> and their contents. Also, connecting a new GitHub account
                is now easier than ever and{' '}
                <strong>Unito can even be installed for the entire GitHub organization</strong> giving access to every
                member.
              </p>
              <p>
                <strong>Note</strong>: You will need to repeat this step if you have more than one GitHub account.
              </p>
              {accountsToMigrate.size > 1 && (
                <Fragment>
                  <strong>
                    {selectedProviderIdentity.get('profileDisplayName')} is part of an organization. Would you like to
                    migrate your personal account or the entire organization?
                  </strong>
                  <RadioButtonGroup
                    name="githubAccount"
                    onChange={(accountDataId) => this.setState({ selectedAccountDataId: accountDataId })}
                    value={selectedAccountDataId}
                  >
                    {accountsToMigrate
                      .map((account) => (
                        <RadioButton
                          key={account.get('workspaceId')}
                          value={account.get('workspaceId')}
                          label={
                            <Fragment>
                              Migrate my <strong>{account.get('displayName')}</strong> account
                            </Fragment>
                          }
                        />
                      ))
                      .toArray()}
                  </RadioButtonGroup>
                </Fragment>
              )}
            </Modal>
          )}
        </AuthWindowOpener>
        <MigrationBannersList>
          {githubProviderIdentities
            .map((providerIdentity) => (
              <MigrationBanner key={providerIdentity.get('_id')}>
                We’ve changed the way we connect to GitHub to make it easier and more secure{' '}
                <Button
                  btnStyle="dark"
                  className="migrate-github-account"
                  size="xs"
                  reverse
                  transparent
                  type="button"
                  onClick={() => this.setState({ selectedProviderIdentityId: providerIdentity.get('_id') })}
                >
                  Migrate {providerIdentity.get('profileDisplayName')} to Github Apps!
                </Button>
              </MigrationBanner>
            ))
            .toArray()}
        </MigrationBannersList>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  githubProviderIdentities: getProviderIdentitiesToMigrate(state),
  githubAppProvider: getProviderByName(state, 'githubappinstallation'),
});

export const GithubMigrationBanner = connect(mapStateToProps)(GithubMigrationBannerComponent);
