import { Map, fromJS, List } from 'immutable';
import { Ability } from '@casl/ability';

import { authTypes } from 'consts';
import { expandPermissions } from 'utils';

export const initialState = Map({
  _id: null,
  plan: null,
  token: null,
  email: null,
  fullName: null,
  roles: List(),
  hasVerifiedEmail: null,
  isAuthenticated: false,
  isAuthenticating: false,
  keepInformed: null,
  // Timestamps of JWT token
  iat: null, // create at
  exp: null, // expiration
  signupIntentA: null,
  signupIntentB: null,
  signupOauthTool: null,
  createdAt: null,
  abilitiesByOrganizationId: Map(),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.REHYDRATE_AUTH_STATE_REQUEST:
    case authTypes.LOGIN_USER_REQUEST: {
      return state.merge({
        isAuthenticating: true,
      });
    }

    case authTypes.REHYDRATE_AUTH_TOKEN_SUCCESS: {
      const { token, permissions, ...decodedToken } = action.payload;
      const abilities = Object.entries(expandPermissions(permissions)).reduce(
        (acc, [organizationId, permissionsForOrg]) => ({
          ...acc,
          [organizationId]: new Ability(permissionsForOrg),
        }),
        {},
      );
      const returnedValue = state.merge({
        isAuthenticating: false,
        isAuthenticated: true,
        token,
        ...decodedToken,
        abilitiesByOrganizationId: Map(abilities),
      });
      return returnedValue;
    }

    case authTypes.REHYDRATE_AUTH_STATE_FAILURE:
    case authTypes.LOGIN_USER_FAILURE: {
      return state.merge(initialState, {
        isAuthenticated: false,
      });
    }

    case authTypes.UPDATE_USER_SUCCESS: {
      const { payload } = action;
      return state.merge(fromJS(payload.user));
    }

    case authTypes.SET_AUTH_EMBED_DATA: {
      const { payload } = action;
      return state.set('token', payload.data.token);
    }

    default:
      return state;
  }
};

export const isSiteAdmin = (state) => state.get('roles', List()).includes('siteadmin');

export const getUserId = (state) => state.get('_id');

export const getUserAvatarUrl = (state) => state.get('avatarUrl');

export const getUserFullName = (state) => state.get('fullName');

export const getUserEmail = (state) => state.get('email');

export const getKeepInformed = (state) => state.get('keepInformed');

export const getToken = (state) => state.get('token');

export const getIsAuthenticated = (state) => state.get('isAuthenticated');

export const getSignupIntentBySide = (state, containerSide) => state.get(`signupIntent${containerSide}`, '');

export const getSignupIntentProduct = (state) => state.get('signupIntentProduct');

export const getSignupOauthTool = (state) => state.get('signupOauthTool');

export const getSignupSource = (state) => state.get('signupSource');

export const getUserAccountCreationDate = (state) => state.get('createdAt');

export const getLoginProviderIdentity = (state) => state.getIn(['loginProviderIdentity', '_id']);

export const getAbilitiesByOrganizationId = (state, organizationId) =>
  state.getIn(['abilitiesByOrganizationId', organizationId], {});
