import { fromJS } from 'immutable';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';

import { message } from '@unitoio/mosaic';

import { fieldTypes } from 'consts';
import { getFields } from 'reducers';
import { fieldActions } from 'actions';
import { useLogger } from 'hooks';

import * as formUtils from '../utils/form';

export function useAutoMapFieldValues({
  containerIdA,
  containerIdB,
  providerIdentityIdA,
  providerIdentityIdB,
  providerNameA,
  providerNameB,
  linkId,
  isLoading,
  itemTypeA,
  itemTypeB,
}) {
  const dispatch = useDispatch();
  const [isAutomapping, setIsAutomapping] = useState(false);
  const { reportException } = useLogger();
  const { setValue } = useFormContext();
  const fieldAssociations = useWatch({ name: 'associations' });

  const providerIdentityIds = {
    A: providerIdentityIdA,
    B: providerIdentityIdB,
  };

  const providerNames = {
    A: providerNameA,
    B: providerNameB,
  };

  const itemTypes = {
    A: itemTypeA,
    B: itemTypeB,
  };

  const containerIds = {
    A: containerIdA,
    B: containerIdB,
  };

  const fieldAssociationWithFields = useSelector((state) =>
    getFields(state, fieldAssociations, providerIdentityIds, providerNames, itemTypes, containerIds),
  );

  useEffect(() => {
    const fieldAssociationsWithMappingValues = fieldAssociationWithFields.filter(
      (associationWithField) =>
        formUtils.hasFieldValues(associationWithField.fieldA) && formUtils.hasFieldValues(associationWithField.fieldB),
    );

    const needsMappingList = fieldAssociationsWithMappingValues.filter((fieldAssociation) => {
      const isMissingMapping =
        fieldAssociation.association.A?.field &&
        !fieldAssociation.association.A?.mapping &&
        !fieldAssociation.association.A?.hasMapping &&
        !fieldAssociation.association.A?.mappingCategory &&
        fieldAssociation.association.B?.field &&
        !fieldAssociation.association.B?.mapping &&
        !fieldAssociation.association.B?.hasMapping &&
        !fieldAssociation.association.B?.mappingCategory;

      const isFieldASearchable = fieldAssociation.fieldA.get('searchable', false);
      const isFieldBSearchable = fieldAssociation.fieldB.get('searchable', false);

      return isMissingMapping && !isFieldASearchable && !isFieldBSearchable;
    });

    async function autoMap() {
      try {
        setIsAutomapping(true);
        const fieldAname =
          needsMappingList[0].fieldA.get('kind') === fieldTypes.KINDS.CUSTOM_FIELD
            ? needsMappingList[0].fieldA.get('name')
            : needsMappingList[0].fieldA.getIn(['names', 'singular']);

        const fieldBname =
          needsMappingList[0].fieldB.get('kind') === fieldTypes.KINDS.CUSTOM_FIELD
            ? needsMappingList[0].fieldB.get('name')
            : needsMappingList[0].fieldB.getIn(['names', 'singular']);

        const messageContent =
          needsMappingList.length > 1 ? (
            <>
              Please be patient while we map your fields that contain multiple values.
              <br />
              This may take a while depending on how many are available.
            </>
          ) : (
            <>
              Please be patient while we map your {fieldAname} and {fieldBname}.
              <br />
              This may take a while depending on how many are available.
            </>
          );

        message.info({
          content: messageContent,
          duration: 8,
        });

        await Promise.all(
          needsMappingList.map(async (fieldAssociation) => {
            const resp = await dispatch(
              fieldActions.automapFieldValuesMapping({
                containerIdA,
                containerIdB,
                providerIdentityIdA,
                providerIdentityIdB,
                itemTypeA,
                itemTypeB,
                linkId,
                fieldAssociation: fromJS(fieldAssociation.association),
              }),
            );

            const mappingValuesA = resp?.A?.mappingIds?.map((values) => ({
              values: values.map((value) => ({ value })),
            }));
            const mappingValuesB = resp?.B?.mappingIds?.map((values) => ({
              values: values.map((value) => ({ value })),
            }));

            const associationIndex = fieldAssociations.findIndex(
              (fa) =>
                fa.A.field === fieldAssociation.association.A.field &&
                fa.B.field === fieldAssociation.association.B.field,
            );

            setValue(`associations.${associationIndex}.A.mapping`, mappingValuesA);
            setValue(`associations.${associationIndex}.A.hasMapping`, true);
            setValue(`associations.${associationIndex}.B.mapping`, mappingValuesB);
            setValue(`associations.${associationIndex}.B.hasMapping`, true);
          }),
        );
      } catch (err) {
        reportException(err);
      } finally {
        setIsAutomapping(false);
      }
    }

    if (needsMappingList.length && !isAutomapping && !isLoading) {
      autoMap();
    }
    // This useEffect is only used for autoMapping don't we don't need to re-trigger it
  }, [fieldAssociationWithFields]); // eslint-disable-line react-hooks/exhaustive-deps

  return isAutomapping;
}
