import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Range } from 'immutable';
import styled from 'styled-components';

import { Icon } from '@unitoio/mosaic';

import { Button } from 'components';
import { color } from 'theme';

const StyledPaginator = styled.div`
  margin-top: 1em;
  visibility: ${(props) => !props.$isVisible && 'hidden'};

  .paginator__btn {
    width: 100%;
    color: ${color.dark.primary};
    padding: 0;

    &.disabled {
      opacity: 0.25;
    }
  }

  .paginator__page {
    border: 1px solid ${color.dark.whisper};
    padding: 0;
  }
`;

export class Paginator extends Component {
  static propTypes = {
    numPages: PropTypes.number.isRequired,
    maxPages: PropTypes.number,
    currentPage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    maxPages: 5,
  };

  state = {
    paginatorIndex: 0,
  };

  onArrowClick = (paginatorIndex) => () => {
    const { maxPages, onChangePage } = this.props;
    const firstPageOfPaginator = maxPages * paginatorIndex + 1;

    this.setState({ paginatorIndex });
    onChangePage(firstPageOfPaginator);
  };

  getPageRange = () => {
    const { numPages, maxPages } = this.props;
    const { paginatorIndex } = this.state;

    if (numPages < maxPages) {
      return Range(1, numPages + 1).toArray();
    }

    const start = paginatorIndex * maxPages + 1;
    const end = (paginatorIndex + 1) * maxPages + 1;

    return Range(start, Math.min(end, numPages + 1)).toArray();
  };

  render() {
    const { maxPages, numPages, onChangePage, currentPage } = this.props;
    const { paginatorIndex } = this.state;
    const indexes = this.getPageRange();

    const isLastPaginatorPage = numPages - maxPages * (paginatorIndex + 1) < 1;

    return (
      <StyledPaginator $isVisible={numPages > 1} className="paginator text-center">
        <Button
          btnStyle="subtleLink"
          disabled={paginatorIndex === 0}
          onClick={this.onArrowClick(paginatorIndex - 1)}
          size="sm"
          name="last page"
        >
          <Icon name="chevron-left" kind={Icon.KINDS.SOLID} />
        </Button>
        {indexes.map((index) => (
          <Button
            key={index}
            btnStyle={currentPage === index ? 'secondary' : 'subtleLink'}
            onClick={() => onChangePage(index)}
            size="sm"
          >
            {index}
          </Button>
        ))}
        <Button
          btnStyle="subtleLink"
          disabled={isLastPaginatorPage}
          onClick={this.onArrowClick(paginatorIndex + 1)}
          size="sm"
          name="next page"
        >
          <Icon name="chevron-right" kind={Icon.KINDS.SOLID} />
        </Button>
      </StyledPaginator>
    );
  }
}
