import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { providerIdentityActions, authActions } from 'actions';
import { appTypes, authTypes } from 'consts';
import { useQueryParams } from 'hooks';
import { AuthGitHubAppInstallation } from './AuthGithubAppInstallation';

export function AuthGitHubAppInstructions({ children, history, match, onCancel, trackEvent }) {
  const dispatch = useDispatch();
  const formSelector = formValueSelector('authentication');
  const installation = useSelector((state) => formSelector(state, 'installation'));
  const { windowOpenerId } = useQueryParams();

  const onSubmit = async (formValue) => {
    if (formValue === 'newInstallation') {
      const authenticateParams = {
        authenticationAction: authTypes.AUTH_ACTIONS.CONNECT,
        authenticationTool: 'githubappinstallation',
        authenticationOptions: {},
        authenticationUserProfile: {
          productName: appTypes.PRODUCT_NAMES.PROJECT_SYNC,
        },
        // This is a specific component used only for GitHub App Installation
        // GitHub only supports oauth2.
        authorizationMethod: 'oauth2',
      };

      try {
        const { authenticationUrl } = await dispatch(authActions.authenticate(authenticateParams));
        global.window.location.assign(authenticationUrl);
      } catch (err) {
        history.push('/connect/githubappinstallation/failure');
      }
      return;
    }

    try {
      const { providerIdentity } = await dispatch(providerIdentityActions.connectProviderIdentity(formValue));
      const { origin } = new URL(window.location);
      window.opener &&
        window.opener.postMessage(
          {
            type: authTypes.POST_MESSAGE_TYPES.PROVIDER_IDENTITY,
            providerIdentityId: providerIdentity._id,
            windowOpenerId,
          },
          origin,
        );

      history.push('/connect/githubappinstallation/success');
    } catch (err) {
      history.push('/connect/githubappinstallation/failure');
    }
  };

  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/step1?windowOpenerId=${windowOpenerId}`} />
      <Route
        exact
        path={`${match.path}/step1`}
        render={(routeProps) => (
          <AuthGitHubAppInstallation
            {...routeProps}
            onCancel={onCancel}
            trackEvent={trackEvent}
            onSubmit={() => onSubmit(installation)}
            value={installation}
          >
            {children}
          </AuthGitHubAppInstallation>
        )}
      />
    </Switch>
  );
}

AuthGitHubAppInstructions.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
