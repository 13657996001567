import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextInput } from 'components';
import { formTypes } from 'consts';

const inputTypes = {
  TEXT: 'text',
  PASSWORD: 'password',
};

export const PasswordInput = ({
  label,
  maxLength = 256, // set a max length to avoid Long password denial of service (https://www.acunetix.com/vulnerabilities/web/long-password-denial-of-service/)
  minLength = 1,
  pattern,
  input,
  ...rest
}) => {
  const [type, setType] = useState(inputTypes.PASSWORD);

  const getEyeIconProps = () => {
    if (type === inputTypes.PASSWORD) {
      return {
        name: 'eye',
        onClick: () => setType(inputTypes.TEXT),
      };
    }

    return {
      name: 'eye-slash',
      onClick: () => setType(inputTypes.PASSWORD),
    };
  };

  return (
    <TextInput
      {...rest}
      input={input}
      type={type}
      label={label}
      maxLength={maxLength}
      minLength={minLength}
      pattern={pattern}
      iconProps={getEyeIconProps()}
      iconPosition="right"
    />
  );
};

PasswordInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  pattern: PropTypes.string,
  input: PropTypes.shape(formTypes.fieldInputPropTypes).isRequired,
};
