import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography, Button, Box, Link, tokens } from '@unitoio/mosaic';

import { Section } from 'components';
import { trackingTypes } from 'consts';
import { useTrackEvent } from 'hooks';

import googleSheetsAuthInstructions from './GoogleSheetsAuthInstructions.png';
import Illustration from './illustration.svg';

const ImageWrapper = styled.div`
  img {
    width: 600px;
    max-width: 100%;
    height: 100%;
  }
`;

export function GoogleSheetsInstructions({ onCancel, onSubmit }) {
  const trackEvent = useTrackEvent();

  const articleUrl = 'https://guide.unito.io/how-secure-is-unito';

  const handleOnClickKbArticle = () => {
    trackEvent(trackingTypes.ACTION, {
      selected_tool_name: 'googlesheets',
      action_name: 'clicked on an article',
      article_url: articleUrl,
    });
  };

  return (
    <Section>
      <Box m={[tokens.spacing.s4, 0, tokens.spacing.s5, 0]}>
        <Typography variant="body1" align="center">
          On the next screen, you’ll be asked to provide Unito access to some information in your Google account.
          <b> Please check all the listed options</b> in order to have the correct permissions to build your flow and
          sync information properly.
        </Typography>
      </Box>
      <ImageWrapper>
        <img src={googleSheetsAuthInstructions} alt="Google Sheets Permissions Helper" />
      </ImageWrapper>
      <Box
        p={[tokens.spacing.s4]}
        m={[tokens.spacing.s4, 0, 0, 0]}
        alignItems="center"
        backgroundColor={tokens.colors.background.message.info}
        borderRadius={tokens.spacing.s3}
      >
        <Box m={[0, tokens.spacing.s4, 0, 0]}>
          <img src={Illustration} alt="hey-there" />
        </Box>
        <Typography variant="body1">
          Any questions regarding Unito's security policy? Read our article on{' '}
          <Link onClick={handleOnClickKbArticle} isExternalLink href={articleUrl} target="_blank" rel="noreferrer">
            how secure Unito is.
          </Link>
        </Typography>
      </Box>
      <Box m={[tokens.spacing.s5, 0, 0, 0]} justifyContent="flex-end">
        <Button variant="subtle" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="md" type="submit" onClick={onSubmit}>
          Connect with Google Sheets
        </Button>
      </Box>
    </Section>
  );
}

GoogleSheetsInstructions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
