import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormState, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Alert, Box, Icon, Link, tokens } from '@unitoio/mosaic';

import { ExportAndSyncErrorContext } from 'contexts';
import { loadingStates } from '../../FlowBuilder/utils/form';

const Arrow = styled(Icon)`
  margin-right: ${tokens.spacing.s3};
`;
const Return = styled(Link)`
  margin-top: ${tokens.spacing.s6};
`;

export const ErrorPage = ({ setLoadingState }) => {
  const { clearErrors } = useFormContext();
  const { errors } = useFormState();
  const pageName = useContext(ExportAndSyncErrorContext);

  const handleRemoveErrors = () => {
    setLoadingState(loadingStates.INITIAL);
    clearErrors();
  };

  return (
    <>
      <Box m={[tokens.spacing.s6, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
        <Alert level="error">We were unable to create a new Google Sheet. {errors[pageName].error}</Alert>
      </Box>
      <Return onClick={handleRemoveErrors}>
        <Arrow name="arrow-left-long" />
        Return to previous setup
      </Return>
    </>
  );
};

ErrorPage.propTypes = {
  setLoadingState: PropTypes.func.isRequired,
};
