import React from 'react';
import PropTypes from 'prop-types';

import { Button, CopyToClipboard, Section, Title } from 'components';

import { AuthButtonsWrapper } from '../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../AuthTitleWrapper';
import { AuthImageWrapper } from '../AuthImageWrapper';
import { AuthOrderedList } from '../AuthOrderedList';
import zendeskApiToken2 from './zendesk-api-token-2.png';

export function AuthZendeskApiTokenStep2({ children: instructionFields, history, onCancel, onSubmit }) {
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          <strong>(API Token - Step 2 of 2)</strong>
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>From the new API Token entry</p>
        <AuthImageWrapper>
          <img src={zendeskApiToken2} alt="Zendesk API Token Step - Step 2" />
        </AuthImageWrapper>
        <AuthOrderedList>
          <li>
            Fill the form with:
            <ul>
              <li>
                API Token Description: <CopyToClipboard>Unito Sync</CopyToClipboard>
              </li>
            </ul>
          </li>
          <li>
            Copy the <strong>API token</strong> to paste it below.
          </li>
          <li>
            Click <strong>Save</strong>.
          </li>
        </AuthOrderedList>
        {instructionFields}
        <AuthButtonsWrapper className="clearfix">
          <Button btnStyle="dark" onClick={onSubmit} type="href" pullRight>
            Authorize
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthZendeskApiTokenStep2.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
