import React from 'react';
import PropTypes from 'prop-types';

import { Box, tokens, Typography } from '@unitoio/mosaic';

import { pcdFilterOperatorTypes } from 'consts';
import { RulesRow } from '../../../../components/RulesRow';
import { PCDFieldsSelect, displayTypes } from '../../../../components/PCDFieldsSelect';

export const AppendNewDefaultFieldValueFromFilter = ({
  otherContainerSide,
  otherContainerSection,
  itemType,
  containerType,
  isDisabledOptionHandler,
  otherItemType,
  addNewRule,
}) => {
  const handleOnChange = (newFieldId, field) => {
    addNewRule({
      newFieldId,
      append: otherContainerSection.append,
      groupedFields: otherContainerSection.groupedFields,
      field,
    });
  };

  return (
    <RulesRow hoverable={false} itemType={itemType} containerType={containerType}>
      <Box m={[0, tokens.spacing.s3, 0, 0]}>
        <Typography variant="h4">AND</Typography>
      </Box>
      <PCDFieldsSelect
        isOptionDisabledHandler={(newFieldId) =>
          isDisabledOptionHandler(newFieldId, otherContainerSection.groupedActionFields, true)
        }
        itemType={otherItemType}
        containerSide={otherContainerSide}
        placeholder="Add a new action"
        searchPlaceholder="Search for an action"
        displayType={displayTypes.FILTERS_ONLY}
        providerIdentityId={otherContainerSection.providerIdentityId}
        providerName={otherContainerSection.providerName}
        containerId={otherContainerSection.containerId}
        labelPrefix="Set default"
        labelSuffix="to"
        onChange={handleOnChange}
        value={null}
      />
    </RulesRow>
  );
};

const fieldShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
});

const filterValueType = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
  PropTypes.bool.isRequired,
]);
const groupedFieldShape = PropTypes.shape({
  canBeAllowed: PropTypes.bool.isRequired,
  canBeDenied: PropTypes.bool.isRequired,
  existingOperators: PropTypes.oneOf(Object.values(pcdFilterOperatorTypes.pcdFilterOperator)).isRequired,
  nbRulesForField: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(filterValueType),
});

AppendNewDefaultFieldValueFromFilter.propTypes = {
  otherContainerSide: PropTypes.string.isRequired,
  otherContainerSection: PropTypes.shape({
    groupedActionFields: PropTypes.shape({
      [PropTypes.string]: groupedFieldShape,
    }).isRequired,
    fields: PropTypes.arrayOf(fieldShape).isRequired,
    groupedFields: PropTypes.shape({
      [PropTypes.string]: groupedFieldShape,
    }).isRequired,
    append: PropTypes.func.isRequired,
    providerIdentityId: PropTypes.string.isRequired,
    providerName: PropTypes.string.isRequired,
    containerId: PropTypes.string.isRequired,
  }).isRequired,
  itemType: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
  isDisabledOptionHandler: PropTypes.func.isRequired,
  otherItemType: PropTypes.string.isRequired,
  addNewRule: PropTypes.func.isRequired,
};
