import React from 'react';
import PropTypes from 'prop-types';
import { ChatBubble, Header } from 'components';
import { NewButton, Result } from '@unitoio/mosaic';
import { routes } from 'consts';

/**
 * Generic top level error encapsulator.
 * */
export function AppError({
  error,
  errorInfo = {
    componentStack: '',
  },
  onDismissError = () => {},
}) {
  const errorAsString = error?.toString() || error;
  const chatMessage = `
    Hey Unito! I'm getting this error on your app.

    ${errorAsString}
    ${errorInfo.componentStack}
  `;

  const handleDismissError = () => {
    onDismissError();
    window.location.assign(routes.ABSOLUTE_PATHS.DASHBOARD);
  };

  return (
    <div className="content">
      <Header />
      <Result
        status="500"
        title="An error occurred"
        subTitle={errorInfo?.componentStack || errorAsString || 'Something went wrong, please try again later.'}
        extra={[
          <NewButton onClick={handleDismissError} type="primary">
            Back to dashboard
          </NewButton>,
          <ChatBubble type="default" message={chatMessage}>
            Get in touch with our team
          </ChatBubble>,
        ]}
      />
    </div>
  );
}

AppError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Error)]).isRequired,
  errorInfo: PropTypes.shape({
    componentStack: PropTypes.string,
  }),
  onDismissError: PropTypes.func,
};
