import { useSelector } from 'react-redux';

import { featureTypes, fieldTypes } from 'consts';
import { getFeatureFlagValue, getOrganizationPlanFeaturesWithUsage, getSelectedOrganizationId } from 'reducers';
import { isFeatureUnlimited } from 'utils';

export function countCompleteCustomFieldAssociations(associations) {
  return associations.reduce((acc, fieldAssociation) => {
    const fieldAssociationIsComplete = fieldAssociation.A?.field && fieldAssociation.B?.field;
    return fieldAssociationIsComplete &&
      [fieldAssociation.A?.kind, fieldAssociation.B?.kind].includes(fieldTypes.KINDS.CUSTOM_FIELD)
      ? acc + 1
      : acc;
  }, 0);
}

export function useIsCustomFieldLimitReached({ associations, isItemACustomFieldBased, isItemBCustomFieldBased }) {
  const organizationId = useSelector((state) => getSelectedOrganizationId(state));

  const organizationCustomFieldsLimit = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.CUSTOM_FIELD),
  );
  const organizationPlanFeaturesWithUsage = useSelector((state) =>
    getOrganizationPlanFeaturesWithUsage(state, organizationId),
  );

  if (isItemACustomFieldBased || isItemBCustomFieldBased) {
    return false;
  }
  const limit = organizationPlanFeaturesWithUsage.getIn([featureTypes.FEATURES.CUSTOM_FIELD, 'limit'], null);
  const orgLimit = limit || organizationCustomFieldsLimit;
  if (isFeatureUnlimited(orgLimit)) {
    return false;
  }

  const nbCustomFieldsAssociations = countCompleteCustomFieldAssociations(associations);

  return nbCustomFieldsAssociations >= orgLimit;
}
