import { containerTypes, routes } from 'consts';
import { getSelectedOrganizationId } from 'reducers';

export const getContainerById = ({
  providerIdentityId,
  containerId,
  containerType,
  itemType,
  linkId = '',
  getUncached = false,
  options = {},
}) => ({
  types: [
    containerTypes.GET_CONTAINER_REQUEST,
    containerTypes.GET_CONTAINER_SUCCESS,
    containerTypes.GET_CONTAINER_FAILURE,
  ],
  meta: {
    providerIdentityId,
    containerId,
  },
  url: routes.API_PATHS.GET_CONTAINER(providerIdentityId, containerId, containerType, itemType, linkId, getUncached),
  displayError: options.displayError,
});

export const getContainers =
  ({ providerIdentityId, containerType, searchValue, itemType, displayError = true }) =>
  (dispatch, getState) => {
    const selectedOrganizationId = getSelectedOrganizationId(getState());

    return dispatch({
      types: [
        containerTypes.GET_CONTAINERS_REQUEST,
        containerTypes.GET_CONTAINERS_SUCCESS,
        containerTypes.GET_CONTAINERS_FAILURE,
      ],
      meta: {
        providerIdentityId,
        searchValue,
      },
      url: routes.API_PATHS.GET_CONTAINERS(
        selectedOrganizationId,
        providerIdentityId,
        searchValue,
        containerType,
        itemType,
      ),
      shouldCallAPI: () => ([undefined, null].includes(searchValue) ? true : searchValue.trim().length >= 1),
      displayError,
    });
  };

export const getWorkspaces = (providerIdentityId, containerSide) => ({
  types: [
    containerTypes.GET_WORKSPACES_REQUEST,
    containerTypes.GET_WORKSPACES_SUCCESS,
    containerTypes.GET_WORKSPACES_FAILURE,
  ],
  meta: {
    providerIdentityId,
    containerSide,
  },
  url: routes.API_PATHS.GET_WORKSPACES(providerIdentityId),
});

export const getContainerPlugins = ({ containerId, providerIdentityId }) => ({
  types: [
    containerTypes.GET_CONTAINER_PLUGINS_REQUEST,
    containerTypes.GET_CONTAINER_PLUGINS_SUCCESS,
    containerTypes.GET_CONTAINER_PLUGINS_FAILURE,
  ],
  meta: {
    containerId,
  },
  displayError: false,
  url: routes.API_PATHS.GET_CONTAINER_PLUGINS(providerIdentityId, containerId),
});

// TODO: refactor createContainer action creator to not rely on the containerSide... 🤮
export const createContainer =
  (displayName, workspaceId, providerIdentityId, containerType, itemType) => async (dispatch) => {
    const response = await dispatch({
      types: [
        containerTypes.CREATE_CONTAINER_REQUEST,
        containerTypes.CREATE_CONTAINER_SUCCESS,
        containerTypes.CREATE_CONTAINER_FAILURE,
      ],
      method: routes.METHODS.POST,
      payload: {
        providerIdentityId,
        container: {
          displayName,
          workspaceId,
          containerType,
          itemType,
        },
      },
      url: routes.API_PATHS.CREATE_CONTAINER,
    });

    return response;
  };

export const getActiveContainers = (orgId) => (dispatch, getState) => {
  const selectedOrganizationId = orgId || getSelectedOrganizationId(getState());

  return dispatch({
    types: [
      containerTypes.GET_ACTIVE_CONTAINERS_REQUEST,
      containerTypes.GET_ACTIVE_CONTAINERS_SUCCESS,
      containerTypes.GET_ACTIVE_CONTAINERS_FAILURE,
    ],
    url: routes.API_PATHS.GET_ACTIVE_CONTAINERS(selectedOrganizationId),
  });
};
