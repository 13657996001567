import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { tokens, Box, Flex } from '@unitoio/mosaic';

import { useMediaQuery } from 'hooks';
import { TrackingFunnelContext } from 'contexts';
import { Href, RoutedNavTabs, CreateWorkflowButton, CardsDashboard } from 'components';
import {
  getEmbedName,
  isInProviderEmbed,
  isMirrorPowerUpShown,
  getSelectedOrganizationId,
  getOrganizationHasFlows,
} from 'reducers';
import { routes, trackingTypes } from 'consts';

import { CreateSyncButton } from './CreateSyncButton';
import { appTypes } from '../../consts';

export const SyncsTabNav = ({ trackDashboardAction, match }) => {
  const isLargeScreen = useMediaQuery(['(min-width: 1350px)'], [true], false);
  const embedName = useSelector((state) => getEmbedName(state));
  const isEmbed = !!embedName;
  const isEmbedTrello = useSelector((state) => isInProviderEmbed(state, appTypes.EMBED.TRELLO));

  const showMirrorPowerUp = useSelector((state) => isMirrorPowerUpShown(state, embedName));
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const hasFlows = useSelector((state) => getOrganizationHasFlows(state, selectedOrganizationId));

  const getTabRoutes = () => {
    let tabRoutes = [
      {
        path: routes.ABSOLUTE_PATHS.PROJECT_SYNC,
        tab: 'All',
      },
    ];

    if (!isEmbed) {
      tabRoutes = [
        ...tabRoutes,
        {
          path: routes.ABSOLUTE_PATHS.WORKFLOWS,
          tab: 'From workflows',
        },
      ];
    }

    if (showMirrorPowerUp || isEmbedTrello) {
      tabRoutes = [
        ...tabRoutes,
        {
          path: routes.ABSOLUTE_PATHS.TASK_SYNC,
          tab: 'From Mirror',
        },
      ];
    }

    return tabRoutes;
  };

  const tabRoutes = getTabRoutes();
  const tabRoute = tabRoutes.find((tab) => tab.tab === 'All');

  const location = useLocation();

  const trackingValue = useMemo(() => ({ name: trackingTypes.FUNNELS.DASHBOARD }), []);

  const routedNavTabs = (
    <RoutedNavTabs
      match={match}
      className="syncs-tab__navigation"
      isJustified={false}
      routes={getTabRoutes()}
      tabStyle="underline"
      location={location}
      callToAction={
        isEmbed ? (
          <CreateSyncButton
            trackDashboardAction={trackDashboardAction}
            selectedOrganizationId={selectedOrganizationId}
          />
        ) : (
          <Switch>
            <Route exact path={tabRoute.path}>
              {hasFlows && (
                <CreateSyncButton
                  trackDashboardAction={trackDashboardAction}
                  selectedOrganizationId={selectedOrganizationId}
                />
              )}
            </Route>
            <Route exact path={routes.ABSOLUTE_PATHS.WORKFLOWS}>
              <CreateWorkflowButton />
            </Route>
          </Switch>
        )
      }
    />
  );

  return (
    <Flex vertical gap="large">
      {isLargeScreen && (
        <TrackingFunnelContext.Provider value={trackingValue}>
          <CardsDashboard organizationId={selectedOrganizationId} />
        </TrackingFunnelContext.Provider>
      )}

      {isEmbed ? (
        <Box m={[tokens.spacing.s6, 0, 0]}>
          <Box m={[tokens.spacing.s6, 0, 0]}>
            {routedNavTabs}
            <Box m={[tokens.spacing.s4, 0, 0, 0]}>
              Manage your flows here, or all your workflows from the{' '}
              <Href
                href={process.env.REACT_APP_PUBLIC_URL}
                onClick={() => trackDashboardAction(trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.WEB_APP)}
              >
                Unito web app
              </Href>
              .
            </Box>
          </Box>
        </Box>
      ) : (
        routedNavTabs
      )}
    </Flex>
  );
};

SyncsTabNav.propTypes = {
  match: PropTypes.shape({}).isRequired,
  trackDashboardAction: PropTypes.func.isRequired,
};
