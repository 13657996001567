import React from 'react';
import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';

import { Flex, Space } from '@unitoio/mosaic';

import { getContainerById } from 'reducers';
import { useGetExtraStepCopyToUse } from 'containers/FlowBuilder/hooks/useGetExtraStepCopyToUse';
import { useGetProviderExtraStep } from '../../hooks/useGetProviderExtraStep';
import { useIsWorkItemStepMissingConfig } from '../../hooks/useIsWorkItemStepMissingConfig';
import { useHasContainerErrors } from '../../hooks/useHasContainerErrors';
import { useAreFieldAssociationsCompleted } from '../../hooks/useAreFieldAssociationsCompleted';
import { AddOnStepCompleted } from './AddOnStepCompleted';
import { GuideStep } from './GuideStep';
import { GuideStepTitleWithTime } from './GuideStepTitleWithTime';
import { Step } from '../ExtraUserStep/Step';
import AddOnConfigurationIcon from '../../images/steps_icons/add_on_configuration.svg';

export const GuideStepAddOnConfiguration = () => {
  const formData = useWatch();
  const providerNameA = formData.A.providerName;
  const providerNameB = formData.B.providerName;
  const containerA = useSelector((state) =>
    getContainerById(state, formData.A.providerIdentityId, formData.A.containerId),
  );
  const containerB = useSelector((state) =>
    getContainerById(state, formData.B.providerIdentityId, formData.B.containerId),
  );

  const providerAExtraStep = useGetProviderExtraStep(providerNameA);
  const providerBExtraStep = useGetProviderExtraStep(providerNameB);
  const extraStepCopyToUse = useGetExtraStepCopyToUse(providerAExtraStep, providerBExtraStep);

  const areFieldAssociationsCompleted = useAreFieldAssociationsCompleted();
  const hasContainerErrors = useHasContainerErrors();
  const isWorkItemStepMissingConfig = useIsWorkItemStepMissingConfig();

  const numberOfProvidersNeedExtraStep = [providerAExtraStep, providerBExtraStep].filter(Boolean).length;

  if (hasContainerErrors) {
    return (
      <GuideStep status="inaccessible" statusLabel="Add-on configuration status">
        <GuideStepTitleWithTime
          title={`5. Install the Unito ${extraStepCopyToUse?.lowerCaseName}`}
          icon={AddOnConfigurationIcon}
          time={numberOfProvidersNeedExtraStep}
        />
      </GuideStep>
    );
  }

  if (areFieldAssociationsCompleted && !isWorkItemStepMissingConfig) {
    return (
      <GuideStep status="locked" actionSlug="guide" actionLabel="Add-on configuration">
        <Space direction="vertical">
          {providerAExtraStep && (
            <AddOnStepCompleted
              providerName={providerNameA}
              containerDisplayName={containerA.get('displayName', '')}
              providerExtraStep={providerAExtraStep}
            />
          )}
          {providerBExtraStep && (
            <AddOnStepCompleted
              providerName={providerNameB}
              containerDisplayName={containerB.get('displayName', '')}
              providerExtraStep={providerBExtraStep}
            />
          )}
        </Space>
      </GuideStep>
    );
  }

  return (
    <GuideStep status={areFieldAssociationsCompleted ? 'current' : 'default'}>
      <Space style={{ width: '100%', display: 'block' }}>
        <Flex vertical>
          <Flex>
            <GuideStepTitleWithTime
              title={`5. Install the Unito ${extraStepCopyToUse?.lowerCaseName}`}
              icon={AddOnConfigurationIcon}
              time={numberOfProvidersNeedExtraStep}
            />
          </Flex>
          {providerAExtraStep && (
            <Step
              providerName={providerNameA}
              containerName={containerA.get('displayName', '')}
              providerIdentityId={formData.A?.providerIdentityId}
              containerId={formData.A?.containerId}
              containerType={formData.A?.containerType}
              itemType={formData.A?.itemType}
            />
          )}
          {providerBExtraStep && (
            <Step
              providerName={providerNameB}
              containerName={containerB.get('displayName', '')}
              providerIdentityId={formData.B?.providerIdentityId}
              containerId={formData.B?.containerId}
              containerType={formData.B?.containerType}
              itemType={formData.B?.itemType}
            />
          )}
        </Flex>
      </Space>
    </GuideStep>
  );
};
