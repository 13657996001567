import PropTypes from 'prop-types';
import React from 'react';

import { Icon, tokens } from '@unitoio/mosaic';

import { Card } from 'components';

export function CouponInfo({ couponName }) {
  return (
    <div>
      {couponName && (
        <Card padding={`${tokens.spacing.s4} ${tokens.spacing.s6}`} color={tokens.colors.background.message.positive}>
          {couponName && (
            <div>
              <Icon name="info-circle" kind={Icon.KINDS.SOLID} /> Coupon <strong>{couponName}</strong> has been added
              and will be applied to your subscription.
            </div>
          )}
        </Card>
      )}
    </div>
  );
}

CouponInfo.propTypes = {
  couponName: PropTypes.string,
};
