import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './theme/bootstrap.overrides.scss';

import React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import { Root } from 'containers';
import { appHistory } from 'app-history';
import { store } from './store';

const target = document.getElementById('root');

const root = ReactDOMClient.createRoot(target);

const node = (
  <Root // eslint-disable-line
    history={appHistory}
    store={store}
    debug={process.env.REACT_APP_DEBUG === 'true'}
  />
);

root.render(node);
