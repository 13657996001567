import { Map } from 'immutable';
import { useContext, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router';

import { featureTypes, fieldTypes } from '../../../consts';
import { FlowBuilderErrorContext } from '../../../contexts';
import { useGetFeatureLimit } from '../../../hooks/useGetFeatureLimit';

import { isFeatureUnlimited } from '../../../utils';
import { useGetProvidersFields } from './useGetProvidersFields';

export const useIsOverFieldMappingsLimit = (linkId, enableAlert = true) => {
  const limit = useGetFeatureLimit(featureTypes.FEATURES.MAPPED_FIELDS);
  const fieldAssociations = useWatch({ name: 'associations' });
  const [providerFieldsA, providerFieldsB] = useGetProvidersFields(linkId);

  const { pageName } = useParams();
  const isMappedFieldsStep = pageName === 'mappings';

  const { setError, clearErrors } = useFormContext();
  const errorContextPage = useContext(FlowBuilderErrorContext);

  const currentFieldsInLimit = useMemo(() => {
    if (isFeatureUnlimited(limit) || !linkId) {
      return false;
    }

    const fieldsInLimit = fieldAssociations.filter((fa) => {
      const isAssociationComplete = fa.A?.field && fa.B?.field;
      const fieldA = providerFieldsA.get(fa?.A?.field, Map());
      const fieldB = providerFieldsB.get(fa?.B?.field, Map());
      return (
        !fieldA.get('semantic', '')?.startsWith(fieldTypes.SEMANTIC.WORKFLOW) &&
        !fieldB.get('semantic', '')?.startsWith(fieldTypes.SEMANTIC.WORKFLOW) &&
        isAssociationComplete
      );
    });

    return fieldsInLimit.length;
  }, [fieldAssociations, providerFieldsA, providerFieldsB, limit, linkId]);

  const mappedFieldsLimit = currentFieldsInLimit >= limit;
  const launchFlowLimit = currentFieldsInLimit > limit;

  useEffect(() => {
    if (enableAlert) {
      if (launchFlowLimit) {
        setError(`${errorContextPage}.fieldMappingLimitReached`, { type: 'manual' });
      } else {
        clearErrors(`${errorContextPage}.fieldMappingLimitReached`);
      }
    }
  }, [enableAlert, setError, clearErrors, currentFieldsInLimit, errorContextPage, limit, launchFlowLimit]);

  return isMappedFieldsStep ? mappedFieldsLimit : launchFlowLimit;
};
