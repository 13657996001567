import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackingActions } from 'actions';
import { TrackingFunnelContext } from 'contexts';
import { getFeatureFlagValue } from 'reducers';

export function useTrackEvent(initialProps = {}, useFunnelContext = true) {
  const dispatch = useDispatch();
  const flagValue = useSelector((state) => getFeatureFlagValue(state, 'tracking-v2'));
  const { name, parents = [], sharedProperties: sharedProps = {} } = useContext(TrackingFunnelContext);

  const [, ...grandParents] = parents;
  const parentProps = flagValue
    ? Object.fromEntries(grandParents.map((p, index) => [`parent${grandParents.length - index}`, p]))
    : {};

  const trackingProps = {
    ...parentProps,
    ...sharedProps,
    ...initialProps,
  };

  return useCallback(
    (action, extraTrackingData) => {
      const eventName = name && useFunnelContext ? `${name}_${action}` : action;

      return dispatch(trackingActions.trackEvent(eventName, { ...trackingProps, ...extraTrackingData }));
    },
    // WARNING: Do not use add the sharedProperties to the arrays of dependency, as it will cause useCallback not to work or loop indefinitely.
    // This is because the dependency array doesn't do a deep equality check but a reference equality check.
    // Every time this hook is called the trackingProps would be a different "object" and thus fail the comparison.
    // Using Option 3 as described in thread https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, name, JSON.stringify(trackingProps)],
  );
}
