import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, useFormState } from 'react-hook-form';

import { Box, LoadingPlaceholder, tokens } from '@unitoio/mosaic';

import { FlowBuilderErrorContext } from 'contexts';
import { useTrackEvent } from 'hooks';
import { getProviderByName, getUserProviderIdentities, getEmbedName, getProviderCapabilitiesV3 } from 'reducers';
import { containerActions } from 'actions';
import * as utils from 'utils';
import { trackingTypes, appTypes, containerTypes } from 'consts';
import { ContainerInfo } from 'containers';
import { loadingStates } from '../../containers/FlowBuilder/utils/form';

import { ProvidersSelect } from './ProvidersSelect';
import { ProviderIdentitiesSelect } from './ProviderIdentitiesSelect';
import { ContainerSelectWrapper } from './ContainerSelectWrapper';
import { ItemTypeSelect } from './ItemTypeSelect';
import { useProviderItemSupportsMerging } from '../../containers/FlowBuilder/hooks/useProviderItemSupportsMerging';
import { MergeBasedContainerSelect } from './MergeBasedContainerSelect';
import { useIsFlowDuplicate } from '../../containers/FlowBuilder/hooks';

// forcing a min-height here to prevent the Box from shrinking
// when the loader is removed and the ContainerSelect is displayed
const StyledBox = styled(Box)`
  min-height: ${tokens.spacing.s5};
`;

export function WorkItemSelector({
  isEdit = false,
  side,
  loadingState,
  disableContainerSelection = false,
  isDuplicating,
}) {
  const { watch, setValue, clearErrors, setError } = useFormContext();
  const dispatch = useDispatch();
  const { errors } = useFormState();
  const otherSide = utils.otherSide(side);
  const [autoSelectContainersLoading, setAutoSelectContainersLoading] = useState(loadingStates.LOADED);
  const [autoSelectedContainerId, setAutoSelectedContainerId] = useState();
  const selectedProviderName = watch(`${side}.providerName`);
  const otherProviderName = watch(`${otherSide}.providerName`);
  const selectedItemType = watch(`${side}.itemType`);
  const selectedContainerType = watch(`${side}.containerType`);
  const duplicateLinkId = watch('duplicateLinkId');
  const selectedProviderIdentityId = watch(`${side}.providerIdentityId`);
  const otherContainerId = watch(`${otherSide}.containerId`);
  const itemSupportsMerge = useProviderItemSupportsMerging(selectedProviderName, selectedItemType);
  const provider = useSelector((state) => getProviderByName(state, selectedProviderName));
  const userProviderIdentities = useSelector((state) => getUserProviderIdentities(state, false)); // false because we don't want providers that are "onlyAuthenticate" (githubappuser)
  const providerIdentities = userProviderIdentities.filter((pi) => pi.get('providerName') === selectedProviderName);

  const capabilitiesV3 = useSelector((state) =>
    getProviderCapabilitiesV3(state, {
      providerIdentityId: selectedProviderIdentityId,
      providerName: selectedProviderName,
      itemType: selectedItemType,
    }),
  );

  const trackEvent = useTrackEvent();
  const embedName = useSelector((state) => getEmbedName(state));
  const lockedWrikeEmbed =
    embedName === appTypes.EMBED.WRIKE &&
    otherProviderName !== appTypes.EMBED.WRIKE &&
    selectedProviderName === appTypes.EMBED.WRIKE;
  const pageName = useContext(FlowBuilderErrorContext);
  const isDuplicate = useIsFlowDuplicate();
  const isSearchable = capabilitiesV3?.getIn(['containers', selectedContainerType, 'searchable', 'isSearchable']);

  const fetchContainersByProviderIdentity = async (providerIdentityId, containerType) => {
    if (!isSearchable && selectedContainerType) {
      setAutoSelectContainersLoading(loadingStates.LOADING);
      try {
        const response = await dispatch(
          containerActions.getContainers({
            providerIdentityId,
            displayError: false,
            containerType,
            itemType: selectedItemType,
          }),
        );
        setAutoSelectContainersLoading(loadingStates.LOADED);
        if (response.containers?.length === 0) {
          trackEvent(trackingTypes.EVENT_NAME.BLOCKED, {
            reason: containerTypes.TRACKING.ERROR_REASONS.NO_PROJECTS,
          });
        }
        return response.containers;
      } catch (err) {
        setError(`${pageName}.${side}.containerId`, { type: 'manual', error: err.message });
        setAutoSelectContainersLoading(loadingStates.ERROR);
        trackEvent(trackingTypes.EVENT_NAME.BLOCKED, { reason: err.message });
      }
    }
    return null;
  };

  if ([loadingStates.LOADING, loadingStates.INITIAL].includes(loadingState) || isDuplicating) {
    return (
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <LoadingPlaceholder width={tokens.spacing.s9} height={tokens.spacing.s3} borderRadius={tokens.spacing.s4} />
        <LoadingPlaceholder width={tokens.spacing.s9} height={tokens.spacing.s6} borderRadius={tokens.spacing.s4} />
      </Box>
    );
  }

  const excludedContainers = otherContainerId ? [otherContainerId] : [];

  const handleProviderChange = async (newProvider) => {
    // this needs to be set here for the very first tool selection
    const providerIdentity = userProviderIdentities.filter((pi) => pi.get('providerName') === newProvider);
    const isAutoSelectProviderIdentityId = providerIdentity.size === 1;
    const defaultProviderIdentityId = isAutoSelectProviderIdentityId ? providerIdentity?.keySeq().first() : null;
    setValue(`${side}.providerIdentityId`, defaultProviderIdentityId, { shouldDirty: true });
    setValue(`${side}.containerId`, null, { shouldDirty: true });
    setValue(`${side}.itemType`, null, { shouldDirty: true });
    setValue(`${side}.containerType`, null, { shouldDirty: true });
    setAutoSelectedContainerId(undefined);
    clearErrors([`${pageName}.${side}.providerIdentityId`, `${pageName}.${side}.containerId`]);
    trackEvent(trackingTypes.ACTION, {
      action_name: 'selected a tool',
      selected_tool_name: newProvider,
    });
  };

  const handleProviderIdentityChange = async (newProviderIdentityId) => {
    setAutoSelectedContainerId(undefined);
    trackEvent(trackingTypes.ACTION, {
      action_name: 'selected existing account',
      selected_tool_name: selectedProviderName,
      connected_account_qty: providerIdentities.size,
      connected_account: providerIdentities.isEmpty() ? 'new' : 'existing',
    });

    clearErrors([`${pageName}.${side}.providerIdentityId`, `${pageName}.${side}.containerId`]);

    // Automatically set container id for item types that support merging
    // We shouldn't reset the container for an active flow
    if (itemSupportsMerge && !isEdit) {
      const containers = await fetchContainersByProviderIdentity(newProviderIdentityId, selectedContainerType);
      const filteredContainers =
        containers?.filter(({ id }) => !excludedContainers.some((disabledId) => disabledId === id)) || [];

      if (filteredContainers.length <= 1) {
        const [autoSelectedContainer] = filteredContainers;
        const containerId = autoSelectedContainer?.id;
        // set this value to prevent ContainerSelect form needlessly performing a duplicate call with the same containerId
        setAutoSelectedContainerId(containerId);
        setValue(`${side}.containerId`, containerId);
      }
    }
  };

  const handleItemTypeChange = (newItemType) => {
    setAutoSelectedContainerId(undefined);
    setValue(`${side}.itemType`, newItemType, { shouldDirty: true });
    setValue(`${side}.containerId`, null, { shouldDirty: true });
    setValue(`${side}.containerType`, null, { shouldDirty: true });
    clearErrors(`${side}.containerId`);
  };

  const handleContainerTypeChange = (newContainerType) => {
    setAutoSelectedContainerId(undefined);
    setValue(`${side}.containerType`, newContainerType, { shouldDirty: true });
    setValue(`${side}.containerId`, null, { shouldDirty: true });
    clearErrors(`${side}.containerId`);
  };

  const handleContainerChange = async (newContainer, newContainerType) => {
    setAutoSelectedContainerId(undefined);
    setValue(`${side}.containerId`, newContainer, { shouldDirty: true });
    setValue(`${side}.containerType`, newContainerType, { shouldDirty: true });
    clearErrors(`${pageName}.${side}.containerId`);
  };

  const providerIdentityError = errors[side]?.providerIdentityId?.error;
  const containerError = errors[side]?.containerId?.error;

  const hasErrors = containerError || providerIdentityError;
  const readOnly = isEdit && !hasErrors;

  const isLoading =
    loadingState === loadingState.SAVING ||
    loadingState === loadingStates.LOADING ||
    autoSelectContainersLoading === loadingStates.LOADING;
  const hasSelectedProviderIdentityAndItemType = !!selectedProviderIdentityId && !!selectedItemType;
  const isContainerSelectLoading = !disableContainerSelection && isLoading && hasSelectedProviderIdentityAndItemType;

  const canDisplayContainerRow =
    !disableContainerSelection &&
    (autoSelectContainersLoading === loadingStates.LOADED || autoSelectContainersLoading === loadingStates.ERROR) &&
    selectedProviderIdentityId &&
    selectedItemType;

  const isAutoSaving = loadingState !== loadingStates.SAVED && loadingState !== loadingStates.LOADED;
  return (
    <>
      <ProvidersSelect
        onChange={handleProviderChange}
        side={side}
        readOnly={!!(readOnly || isDuplicate || lockedWrikeEmbed)}
      />
      {selectedProviderName && (
        <ProviderIdentitiesSelect
          provider={provider}
          onChange={handleProviderIdentityChange}
          side={side}
          readOnly={!!isDuplicate}
        />
      )}
      {selectedProviderIdentityId && (
        <ItemTypeSelect
          side={side}
          onChange={handleItemTypeChange}
          readOnly={!!(readOnly || duplicateLinkId || isAutoSaving)}
        />
      )}
      <StyledBox m={[tokens.spacing.s4, 0, 0, 0]}>
        {canDisplayContainerRow &&
          (itemSupportsMerge ? (
            <MergeBasedContainerSelect
              side={side}
              provider={provider}
              onChange={handleContainerChange}
              valuesToDisable={excludedContainers}
              readOnly={isEdit || !!autoSelectedContainerId}
              isLoading={isContainerSelectLoading}
            />
          ) : (
            <ContainerSelectWrapper
              // Props for ContainerSelect
              valuesToDisable={excludedContainers}
              provider={provider}
              side={side}
              readOnly={isEdit}
              onChange={handleContainerChange}
              onChangeContainerType={handleContainerTypeChange}
              isLoading={isContainerSelectLoading}
              // Props for MosaicContainerSelect
              providerIdentityId={selectedProviderIdentityId}
              requiresTypeAhead={isSearchable}
            />
          ))}
      </StyledBox>
      {selectedProviderName && selectedProviderIdentityId && <ContainerInfo providerName={selectedProviderName} />}
    </>
  );
}

WorkItemSelector.propTypes = {
  isEdit: PropTypes.bool,
  loadingState: PropTypes.string.isRequired,
  disableContainerSelection: PropTypes.bool,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  isDuplicating: PropTypes.bool.isRequired,
};
