import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { Title } from 'components';
import { ConnectorList } from 'containers';
import { trackingActions } from 'actions';
import { color } from 'theme';

const Divider = styled.hr`
  color: ${color.dark.midGray};
  margin: 1rem 0;
`;

function ProfileContainerComponent({ match }) {
  return (
    <div className="profile-container">
      <Title type="h2">Integrations</Title>
      <Divider />
      <section>
        <Switch>
          <Route component={ConnectorList} exact path={`${match.path}/connectors`} />
          <Redirect from={`${match.url}/`} to={`${match.url}/connectors`} />
        </Switch>
      </section>
    </div>
  );
}

ProfileContainerComponent.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  trackEvent: (...params) => dispatch(trackingActions.trackEvent(...params)),
});

export const ProfileContainer = connect(null, mapDispatchToProps)(ProfileContainerComponent);
