import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

import { Pagination } from '@unitoio/mosaic';

import { LinkList, ProjectSyncPromotionalState } from 'components';
import { getFeatureFlagValue } from 'reducers';
import { featureTypes, linkTypes } from 'consts';

import { SyncListSearchInput } from './SyncListSearchInput';
import { LinkItemsPlaceholder } from './LinkItemsPlaceholder';
import { ProjectSyncEmptyState } from './ProjectSyncEmptyState';
import { SyncListSearchInputRevamp } from './SyncListSearchInputRevamp';
import { NO_PAGINATION_TOTAL } from './hooks/useGetLinks';

export const ProjectSyncList = ({
  embedName,
  linkList,
  isLoading,
  isCurrentPlanTaskSync,
  multisyncList = List(),
  selectedOrganizationId,
  trackDashboardAction,
  pagination = {
    page: 0,
    pageSize: 0,
    total: -1,
  },
  onChangePage,
  onSearch: onSearchCallback,
  onSetKinds,
}) => {
  const hasPaginatedFlowList = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.PAGINATED_FLOW_LIST),
  );

  // TODO: remove local searchString once we get rid of non paginated version of this component
  // right now we need it to do frontend filtering if FF is false
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    onSetKinds([linkTypes.KIND.MIRROR_SYNC, linkTypes.KIND.MULTI_SYNC, linkTypes.KIND.REPORT_SYNC]);
  }, [onSetKinds]);

  // paginated list with backend search
  const renderPaginatedListWithSearch = (projectSyncLinkList) => {
    const onPaginationChange = async (page, pageSize) => {
      // -1 because AntD pages are 1 based.
      onChangePage({ page: page - 1, pageSize });
    };

    const onSearch = async (value) => {
      setSearchString(value);
      onSearchCallback(value);
    };

    const { page, pageSize, total } = pagination;

    const paginatedLinksContent = projectSyncLinkList.isEmpty() ? (
      <ProjectSyncEmptyState
        {...{
          embedName,
          searchString,
          selectedOrganizationId,
          trackDashboardAction,
        }}
      />
    ) : (
      <>
        <LinkList linkList={projectSyncLinkList} />
        {total !== NO_PAGINATION_TOTAL && (
          <Pagination
            current={page + 1}
            onChange={onPaginationChange}
            pageSize={pageSize}
            showTotal={(totalFlows, range) => `${range[0]}-${range[1]} of ${totalFlows} flows`}
            total={total}
          />
        )}
      </>
    );

    return (
      <>
        <SyncListSearchInputRevamp isLoading={isLoading} searchString={searchString} onSearch={onSearch} />
        {isLoading ? <LinkItemsPlaceholder /> : paginatedLinksContent}
      </>
    );
  };

  // front end search only
  const renderListWithFESearch = (projectSyncLinkList) => {
    const filteredList = projectSyncLinkList.filter((link) =>
      link.get('name', '').toLowerCase().includes(searchString.toLowerCase()),
    );

    if (filteredList.isEmpty()) {
      return (
        <>
          <SyncListSearchInput searchString={searchString} onChange={setSearchString} />
          <ProjectSyncEmptyState {...{ embedName, searchString, selectedOrganizationId, trackDashboardAction }} />
        </>
      );
    }

    return (
      <>
        <SyncListSearchInput searchString={searchString} onChange={setSearchString} />
        <LinkList linkList={filteredList} />
      </>
    );
  };

  // loader will show under the search bar when search is in progress
  if (!searchString && isLoading) {
    return <LinkItemsPlaceholder />;
  }

  if (!searchString && linkList?.isEmpty() && multisyncList?.isEmpty() && isCurrentPlanTaskSync) {
    return <ProjectSyncPromotionalState organizationId={selectedOrganizationId} trackEvent={trackDashboardAction} />;
  }

  // don't show search input if there are no links and no search in progress
  if (!searchString && linkList.isEmpty()) {
    return <ProjectSyncEmptyState {...{ embedName, selectedOrganizationId, trackDashboardAction }} />;
  }

  return hasPaginatedFlowList ? renderPaginatedListWithSearch(linkList) : renderListWithFESearch(linkList);
};

ProjectSyncList.propTypes = {
  embedName: PropTypes.string.isRequired,
  trackDashboardAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCurrentPlanTaskSync: PropTypes.bool.isRequired,
  linkList: PropTypes.instanceOf(List).isRequired,
  multisyncList: PropTypes.instanceOf(List),
  selectedOrganizationId: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
  onChangePage: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSetKinds: PropTypes.func.isRequired,
};
