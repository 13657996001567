import React from 'react';
import { Box } from '@unitoio/mosaic';

import { LinkItemLoading } from 'components';

export const LinkItemsPlaceholder = () => (
  <Box data-testid="link-items-placeholder">
    <LinkItemLoading />
    <LinkItemLoading />
    <LinkItemLoading />
    <LinkItemLoading />
  </Box>
);
