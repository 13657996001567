import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { NotFound, Can } from 'components';
import {
  BillingContainer,
  ItemsKeptInSync,
  PeopleContainer,
  PricingContainer,
  ProfileContainer,
  ProfileSettingsMenu,
  RewardsContainer,
  WorkspaceSettingsMenu,
  WorkspaceSettings,
  ProfileReportsMenu,
  LookerContainer,
  TrackingFunnel,
} from 'containers';
import { routes, appTypes, trackingTypes } from 'consts';
import { isOnMirrorEmbedContext } from 'reducers';
import { color } from 'theme';

/* eslint-disable */
const GlobalStyle = createGlobalStyle`
  &.dashboard-container {
    background: ${color.light.primary} !important;
  }
`;
/* eslint-enable */

const Sticky = styled.div`
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 6;
`;

function renderIfBillingAccess(container) {
  return (
    <>
      <Can I="update" a="billing">
        {container}
      </Can>
      <Can not I="update" a="billing">
        <NotFound goBackLink={routes.ABSOLUTE_PATHS.DASHBOARD} goBackText="Back to dashboard" />
      </Can>
    </>
  );
}

function ProfileSettings({ match, organizationId, isMirrorEmbedContext = false }) {
  const isLookerReportPage = useRouteMatch(`${match.path}/:organizationId/reports/:id`);
  const productNameRedirect = isMirrorEmbedContext
    ? appTypes.PRODUCT_NAMES_PARAM.TASK_SYNC
    : appTypes.PRODUCT_NAMES_PARAM.PROJECT_SYNC;
  return (
    <div className={isLookerReportPage && 'container-fluid'}>
      <GlobalStyle />
      <div className="row">
        <Sticky className="col-sm-3">
          <ProfileSettingsMenu />
          <WorkspaceSettingsMenu parentMatch={match} organizationId={organizationId} />
          <Can I="update" a="billing">
            <ProfileReportsMenu />
          </Can>
        </Sticky>
        <div className="col-sm-9">
          <Switch>
            <Redirect strict from={`${match.path}/workspace`} to={`${match.path}/${organizationId}/workspace`} />
            <Redirect strict from={`${match.path}/usage`} to={`${match.path}/${organizationId}/usage`} />
            <Redirect strict from={`${match.path}/people`} to={`${match.path}/${organizationId}/people`} />
            <Redirect strict from={`${match.path}/billing`} to={`${match.path}/${organizationId}/billing`} />
            <Redirect strict from={`${match.path}/pricing`} to={`${match.path}/${organizationId}/pricing`} />
            <Redirect strict from={`${match.path}/rewards`} to={`${match.path}/${organizationId}/rewards`} />
            <Route path="/dashboard/profile" component={ProfileContainer} />
            <Route path={`${match.path}/:organizationId/people`} component={PeopleContainer} />
            <Route path={`${match.path}/:organizationId/rewards`} component={RewardsContainer} />
            <Route
              path={`${match.path}/:organizationId/workspace`}
              render={(props) =>
                renderIfBillingAccess(
                  <TrackingFunnel contextName={trackingTypes.SUB_PAGE.SETTINGS_WORKSPACE}>
                    <WorkspaceSettings {...props} />
                  </TrackingFunnel>,
                )
              }
            />

            <Redirect
              from={`${match.path}/:organizationId/usage`}
              to={`${match.path}/:organizationId/billing/project-sync/overview`}
            />

            <Route
              path={`${match.path}/:organizationId/items-kept-in-sync`}
              render={(props) => renderIfBillingAccess(<ItemsKeptInSync {...props} />)}
            />

            <Route
              path={`${match.path}/:organizationId/billing/:productName`}
              render={(props) => renderIfBillingAccess(<BillingContainer {...props} />)}
            />
            <Route
              path={`${match.path}/:organizationId/pricing/:productName`}
              render={(props) => (
                <>
                  <Can I="update" a="billing">
                    <PricingContainer {...props} />
                  </Can>
                  <Can not I="update" a="billing">
                    <NotFound goBackLink={routes.ABSOLUTE_PATHS.DASHBOARD} goBackText="Back to dashboard" />
                  </Can>
                </>
              )}
            />
            <Route
              path={`${match.path}/:organizationId/reports/:reportId`}
              render={(props) => renderIfBillingAccess(<LookerContainer {...props} />)}
            />
            <Redirect
              strict
              exact
              from={`${match.path}/${organizationId}/pricing`}
              to={`${match.path}/${organizationId}/pricing/${productNameRedirect}`}
            />
            <Redirect
              strict
              exact
              from={`${match.path}/${organizationId}/billing`}
              to={`${match.path}/${organizationId}/billing/${productNameRedirect}`}
            />
            <Redirect strict exact from={match.path} to={`${match.path}/${organizationId}/usage`} />
            <Redirect
              strict
              exact
              from={`${match.path}/${organizationId}`}
              to={`${match.path}/${organizationId}/usage`}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

ProfileSettings.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  organizationId: PropTypes.string.isRequired,
  isMirrorEmbedContext: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isMirrorEmbedContext: isOnMirrorEmbedContext(state),
});

export const ProfileSettingsContainer = connect(mapStateToProps)(ProfileSettings);
