import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import { Tooltip } from '@unitoio/mosaic';

import { Select, IconHoverTooltip } from 'components';
import { formUtils } from 'utils';

export class SelectInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    defaultOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    helpText: PropTypes.string,
    input: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    label: PropTypes.string,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.node,
      asyncError: PropTypes.node,
    }),
    options: PropTypes.arrayOf(PropTypes.shape({})),
    showError: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    defaultOption: undefined,
    helpText: undefined,
    label: undefined,
    meta: {},
    options: [],
    showError: true,
  };

  componentDidMount() {
    const { defaultOption, input } = this.props;

    if (defaultOption && formUtils.isEmpty(input.value)) {
      input.onChange(defaultOption);
    }
  }

  render() {
    const { className, helpText, input, label, meta, showError, ...rest } = this.props;
    const error = meta.touched && (meta.error || meta.asyncError);
    const classNames = classnames({ 'has-error': error }, className);

    return (
      <div className="select-input">
        {label && <label htmlFor={input.name}>{label}</label>}{' '}
        {helpText && <IconHoverTooltip placement="top">{helpText}</IconHoverTooltip>}
        <Select {...input} {...rest} {...this.props} className={classNames} />
        {showError && error && <Tooltip forceShow={!!error} content={meta.error} />}
      </div>
    );
  }
}
