import React from 'react';
import PropTypes from 'prop-types';
import { createContextualCan } from '@casl/react';
import { AbilityContext } from 'contexts';

import { PlanFeature } from 'components';
import { featureTypes } from 'consts';

const { FEATURES } = featureTypes;

export const Can = ({ children, ...rest }) => (
  <PlanFeature name={FEATURES.PERMISSIONS}>
    {(hasAccess) => {
      // Don't handle <Can not> components, as they imply restrictions based on permissions
      // But if an organization has no permission feature, we shouldn't apply any restrictions
      if (!hasAccess && rest.not) {
        return null;
      }

      return hasAccess ? createContextualCan(AbilityContext.Consumer)({ ...rest, children }) : children;
    }}
  </PlanFeature>
);

Can.propTypes = {
  children: PropTypes.node.isRequired,
};
