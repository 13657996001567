import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

import { Icon } from '@unitoio/mosaic';

import { color } from 'theme';
import { Button } from 'components';
import { useInterval } from 'hooks';

const textColor = color.content.neutral.placeholder;

const StreamingSelectorWrapper = styled.div`
  color: ${textColor};
`;

const LastUpdated = styled.span`
  margin-right: 1rem;
`;

export function StreamingSelector({ isStreaming, lastUpdateTimestamp = null, onStreamingChanged }) {
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState(0);

  useInterval(() => {
    if (!lastUpdateTimestamp) {
      return;
    }

    setTimeSinceLastUpdate(lastUpdateTimestamp - Date.now());
  }, 1000);

  const lastUpdateString = moment.duration(timeSinceLastUpdate).humanize(true);
  return (
    <StreamingSelectorWrapper>
      {lastUpdateTimestamp && (
        <LastUpdated>
          <Icon name="history" kind={Icon.KINDS.SOLID} /> Last updated: {lastUpdateString}
        </LastUpdated>
      )}

      <Button
        btnStyle="dark"
        reverse
        size="md"
        transparent
        borderRadius="4px"
        border
        color={isStreaming ? color.brand.red : null}
        onClick={() => onStreamingChanged(!isStreaming)}
      >
        {isStreaming ? 'Live stream on' : 'Live stream off'}
      </Button>
    </StreamingSelectorWrapper>
  );
}

StreamingSelector.propTypes = {
  isStreaming: PropTypes.bool.isRequired,
  lastUpdateTimestamp: PropTypes.number,
  onStreamingChanged: PropTypes.func.isRequired,
};
