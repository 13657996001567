import { draftTypes, routes, linkTypes } from 'consts';
import { formDataToLinkPayload, getDefaultDraftName, getSortedLinks } from 'reducers';
import { getLastAssociation } from 'containers/FlowBuilder/utils/getLastAssociation';

export const createDraft = (formData) => async (dispatch, getState) => {
  const state = getState();

  const payload = formDataToLinkPayload(
    state,
    {
      ...formData,
      name: getDefaultDraftName(state, formData),
    },
    true,
  );

  const currentLinks = getSortedLinks(state);
  const isFirstFlow = currentLinks.size === 0;

  return dispatch({
    types: [draftTypes.CREATE_DRAFT_REQUEST, draftTypes.CREATE_DRAFT_SUCCESS, draftTypes.CREATE_DRAFT_FAILURE],
    method: routes.METHODS.POST,
    url: routes.API_PATHS.DRAFT,
    payload,
    meta: {
      isFirstFlow,
    },
  });
};

export const editDraft = (formData) => async (dispatch, getState) => {
  const state = getState();

  // TODO convert syncSettings into side settings instead
  // for rules page
  const payload = formDataToLinkPayload(state, { ...formData, name: getDefaultDraftName(state, formData) }, true);

  return dispatch({
    types: [draftTypes.EDIT_DRAFT_REQUEST, draftTypes.EDIT_DRAFT_SUCCESS, draftTypes.EDIT_DRAFT_FAILURE],
    method: routes.METHODS.PUT,
    url: routes.API_PATHS.UPDATE_DRAFT(formData._id),
    cancelOnActions: [draftTypes.EDIT_DRAFT_REQUEST],
    payload,
  });
};

export const convertDraft = (formData) => async (dispatch, getState) => {
  const state = getState();

  const fieldAssociations = formData.associations;
  const [lastAssociation] = getLastAssociation(fieldAssociations);
  if (lastAssociation?.A?.field === null || lastAssociation?.B?.field === null) {
    fieldAssociations.pop();
  }

  // TODO convert syncSettings into side settings instead
  // for rules page
  const payload = formDataToLinkPayload(state, { ...formData, associations: fieldAssociations }, true);

  return dispatch({
    types: [draftTypes.EDIT_DRAFT_REQUEST, draftTypes.EDIT_DRAFT_SUCCESS, draftTypes.EDIT_DRAFT_FAILURE],
    method: routes.METHODS.POST,
    url: routes.API_PATHS.UPDATE_DRAFT(formData._id),
    displayError: false,
    payload,
    meta: {
      isConvertedDraft: true,
    },
  });
};

export const createOneClick = (formData) => async (dispatch, getState) => {
  const state = getState();

  const payload = formDataToLinkPayload(
    state,
    {
      ...formData,
      kind: linkTypes.KIND.REPORT_SYNC,
      name: getDefaultDraftName(state, formData),
    },
    true,
  );

  return dispatch({
    types: [
      draftTypes.CREATE_ONE_CLICK_REQUEST,
      draftTypes.CREATE_ONE_CLICK_SUCCESS,
      draftTypes.CREATE_ONE_CLICK_FAILURE,
    ],
    method: routes.METHODS.POST,
    url: routes.API_PATHS.ONE_CLICK,
    payload,
    displayError: false,
  });
};

export const activateOneClick = (jwtToken) => async (dispatch) =>
  dispatch({
    types: [
      draftTypes.ACTIVATE_ONE_CLICK_REQUEST,
      draftTypes.ACTIVATE_ONE_CLICK_SUCCESS,
      draftTypes.ACTIVATE_ONE_CLICK_FAILURE,
    ],
    method: routes.METHODS.POST,
    url: routes.API_PATHS.ONE_CLICK_ACTIVATE,
    payload: { jwtToken },
  });
