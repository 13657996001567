import React from 'react';
import PropTypes from 'prop-types';

import { Button, Section, Title } from 'components';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../../AuthTitleWrapper';
import { AuthImageWrapper } from '../../AuthImageWrapper';
import stepImage from './jira-oauth2-4.png';

export function AuthJiraOAuth2Step4({ children: instructionFields, history, onCancel, onSubmit }) {
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (OAuth2 - Step 4 of 4)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <AuthImageWrapper className="clearfix">
          <img src={stepImage} alt="Jira OAuth2 Step - Step 4" />
        </AuthImageWrapper>
        <p>
          Jira will provide <strong>Client ID</strong> and <strong>Client secret</strong> credentials that you will need
          to copy and paste below
        </p>
        {instructionFields}
        <AuthButtonsWrapper className="clearfix">
          <Button btnStyle="dark" onClick={onSubmit} type="href" pullRight>
            Authorize
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthJiraOAuth2Step4.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
