import { fieldTypes, linkTypes, pcdFilterOperatorTypes } from 'consts';
import { Map, List } from 'immutable';
import { capitalize } from 'utils';

// form loading states
export const loadingStates = {
  /** Initial state when the flow builder is first rendered on add */
  NEW: 'new',
  /** Initial state when the flow builder is first rendered on edit */
  INITIAL: 'initial',
  /** Edit: The link is being fetched for the first time */
  LOADING: 'loading',
  /** Edit: The link was fully loaded after initial render */
  LOADED: 'loaded',
  /** In draft mode, when the draft is being saved */
  SAVING: 'autosaving',
  /** The draft was succesfully saved */
  SAVED: 'autosaved',
  /** An issue occured during either create, edit */
  ERROR: 'error',
};

/**
 * The link entity has multiple fields that are updated on link update but shouldn't be interpreted as a different field for the form
 * For now we only check that the A, B and syncSettings are the same to consider the link retrieved from the store as different, and avoid triggering useEffects and renders
 */
export function isCurrentLinkEqual(prevLink, nextLink) {
  if (prevLink && prevLink.equals(nextLink)) {
    return true;
  }
  // a prevLink could be potentially undefined here, if it is deleted from the Guide (when coming from WF designer)
  const sameName = prevLink?.get('name') === nextLink.get('name');
  const sameIsAutoSync = prevLink?.get('isAutoSync') === nextLink.get('isAutoSync');
  const sameA = prevLink?.get('A')?.equals(nextLink.get('A'));
  const sameB = prevLink?.get('B')?.equals(nextLink.get('B'));
  const sameSettings = prevLink?.get('syncSettings')?.equals(nextLink.get('syncSettings'));
  const sameRules = prevLink?.get('rules')?.equals(nextLink.get('rules'));
  return sameName && sameIsAutoSync && sameA && sameB && sameSettings && sameRules;
}

export function buildFilter(fieldId, setting, filterType, parentFieldId) {
  /* Custom field */
  if (setting.get('kind') === fieldTypes.KINDS.CUSTOM_FIELD) {
    if (setting.get('fieldValueType') === 'boolean' || List.isList(setting.get('whiteList'))) {
      const operator = pcdFilterOperatorTypes.pcdFilterOperator.IN;
      return {
        _id: `${fieldId}-${operator}`,
        operator,
        value: setting.get('whiteList').toJS(),
        kind: setting.get('kind'),
        fieldId,
        type: '',
      };
    }

    const operator =
      setting.getIn(['whiteList', 0]) === false
        ? pcdFilterOperatorTypes.pcdFilterOperator.NOT_EXISTS
        : pcdFilterOperatorTypes.pcdFilterOperator.EXISTS;

    return {
      _id: `${fieldId}-${operator}`,
      operator,
      type: 'value',
      kind: setting.get('kind'),
      fieldId,
    };
  }

  // Rest of other field kinds (fieldTypes.KINDS.PCD_FIELD, fieldTypes.KINDS.PCD_TYPED_FIELD, fieldTypes.PCD_TYPED_ITEM_FIELD)
  // Multisync discriminant is to support legacy syncs created via the multisync wizard
  const multisyncDiscriminant = setting.get('multisyncDiscriminant');
  const values = !multisyncDiscriminant ? setting.get(filterType) : setting.get(filterType).push(multisyncDiscriminant);

  const operator =
    filterType === 'whiteList'
      ? pcdFilterOperatorTypes.pcdFilterOperator.IN
      : pcdFilterOperatorTypes.pcdFilterOperator.NIN;
  return {
    _id: parentFieldId ? `${parentFieldId}-${fieldId}-${operator}` : `${fieldId}-${operator}`,
    operator,
    type: setting.get('fieldValueType', 'id'),
    value: values.toJS(),
    kind: setting.get('kind', fieldTypes.KINDS.PCD_TYPED_FIELD),
    parentFieldId,
    fieldId,
  };
}

export function getDeepRulesBySide(link, containerSide) {
  const sideSettings = link.getIn(['syncSettings', containerSide], Map());
  const rules = [];

  const deepFiltering = sideSettings.get('itemFieldAssociations', null);
  if (deepFiltering) {
    const objectFieldId = deepFiltering.keySeq().first();

    const deepFilters = sideSettings.get(objectFieldId, null);
    if (deepFilters) {
      deepFilters.forEach((setting, fieldId) => {
        ['whiteList', 'blackList'].forEach((filterType) => {
          if (Map.isMap(setting) && setting.has(filterType)) {
            rules.push(buildFilter(fieldId, setting, filterType, objectFieldId));
          }
        });
      });
    }
  }

  return rules;
}

export function getRulesBySide(link, containerSide, currentFormSideFilters = []) {
  const sideSettings = link.getIn(['syncSettings', containerSide], Map());
  const rules = [];

  // TODO PCDv3 - aug 28
  const closedTasks = sideSettings.get(fieldTypes.CLOSED_TASKS, null);
  if (closedTasks !== null) {
    const operator = pcdFilterOperatorTypes.pcdFilterOperator.EQ;
    const fieldId = fieldTypes.INCLUDE_CLOSED_TASKS;
    rules.push({
      _id: `${fieldId}-${operator}`,
      operator,
      type: 'category',
      value: closedTasks ? 'all' : 'open',
      kind: 'options',
      fieldId,
    });
  }

  const subfolders = sideSettings.get('subfolders', null);
  if (subfolders !== null) {
    const operator = pcdFilterOperatorTypes.pcdFilterOperator.EQ;
    const fieldId = fieldTypes.SUBFOLDERS;
    rules.push({
      _id: `${fieldId}-${operator}`,
      operator,
      type: 'value',
      value: subfolders ? 'included' : 'excluded',
      kind: 'options',
      fieldId,
    });
  }

  if (link.getIn(['syncSettings', fieldTypes.EARLIEST_CREATED_AT])) {
    const fieldId = fieldTypes.EARLIEST_CREATED_AT;
    const operator = pcdFilterOperatorTypes.pcdFilterOperator.GT;
    rules.push({
      _id: `${fieldId}-${operator}`,
      operator,
      type: 'value',
      value: link.get('syncSettings').get(fieldTypes.EARLIEST_CREATED_AT),
      kind: 'options',
      fieldId,
    });
  }

  if (sideSettings.get(fieldTypes.PAST_DATE)) {
    rules.push({
      _id: `${pcdFilterOperatorTypes.pcdFilterOperator.GT}-${fieldTypes.PAST_DATE}`,
      operator: pcdFilterOperatorTypes.pcdFilterOperator.GT,
      type: 'value',
      kind: 'options',
      value: '6',
      fieldId: fieldTypes.PAST_DATE,
    });

    if (sideSettings.get(fieldTypes.FUTURE_DATE)) {
      rules.push({
        _id: `${pcdFilterOperatorTypes.pcdFilterOperator.LT}-${fieldTypes.FUTURE_DATE}`,
        operator: pcdFilterOperatorTypes.pcdFilterOperator.LT,
        type: 'value',
        kind: 'options',
        value: '12',
        fieldId: fieldTypes.FUTURE_DATE,
      });
    }
  }

  const deepFiltering = sideSettings.get('itemFieldAssociations', null);
  if (deepFiltering) {
    const objectFieldId = deepFiltering.keySeq().first();
    const newRule = {
      _id: `${fieldTypes.DEEP_FILTER_ITEM}-${pcdFilterOperatorTypes.pcdFilterOperator.EQ}`,
      operator: pcdFilterOperatorTypes.pcdFilterOperator.EQ,
      type: 'value',
      value: objectFieldId || 'all',
      kind: 'options',
      fieldId: fieldTypes.DEEP_FILTER_ITEM,
    };

    rules.unshift(newRule);
  }

  const sideFilters = sideSettings.reduce((accByField, setting, fieldId) => {
    ['whiteList', 'blackList'].forEach((filterType) => {
      if (Map.isMap(setting) && setting.has(filterType)) {
        accByField.push(buildFilter(fieldId, setting, filterType));
      }
    });
    return accByField;
  }, []);

  // Sort rules by the order they appear in the form
  // Otherwise the rules will be sorted by the order they appear in the link settings which might not the same as the form
  // This causes weird UI flickering on auto save on drafts
  const updated = rules.concat(sideFilters).sort((a, b) => {
    let indexA = currentFormSideFilters.findIndex((filter) => filter._id === a._id);
    let indexB = currentFormSideFilters.findIndex((filter) => filter._id === b._id);

    // If _id doesn't exist, try to find by fieldId
    // This might happen when the user updates the operator only
    // But only do this if there is only one occurrence of the fieldId in sideFilters
    if (indexA === -1 && sideFilters.filter((filter) => filter.fieldId === a.fieldId && !a.isSetDefault).length === 1) {
      indexA = currentFormSideFilters.findIndex((filter) => filter.fieldId === a.fieldId);
    }
    if (indexB === -1 && sideFilters.filter((filter) => filter.fieldId === b.fieldId && !b.isSetDefault).length === 1) {
      indexB = currentFormSideFilters.findIndex((filter) => filter.fieldId === b.fieldId);
    }

    // If id doesn't exist, assign a high index value
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
  return updated;
}

export function getActionsBySide(link, containerSide) {
  const sideSettings = link.getIn(['syncSettings', containerSide], Map());

  // TODO: Deprecate this code after deploying the new operators UI - https://app.asana.com/0/1187555619063353/1201760599227534
  const defaultTypes = sideSettings.filter(
    (setting, index) => setting && index.includes('default') && index !== 'defaultValues',
  );
  return defaultTypes
    .map((defaultType, defaultFieldId) => ({
      _id: `default-${transformDefaultFieldIdIntoFieldId(defaultFieldId)}`,
      fieldId: transformDefaultFieldIdIntoFieldId(defaultFieldId),
      isSetDefault: true,
      operator: pcdFilterOperatorTypes.pcdFilterOperator.EQ,
      value: defaultType,
      kind: fieldTypes.KINDS.PCD_TYPED_FIELD,
      type: fieldTypes.TYPES.SELECT,
    }))
    .toArray();
}

export function getSyncDirection(syncSettings) {
  if (syncSettings === undefined) {
    return undefined;
  }

  const readOnlyA = syncSettings.getIn(['A', 'readOnly'], null);
  const readOnlyB = syncSettings.getIn(['B', 'readOnly'], null);

  if (readOnlyA === null || readOnlyB === null) {
    return undefined;
  }

  if (readOnlyA) {
    return fieldTypes.TARGET.B;
  }

  if (readOnlyB) {
    return fieldTypes.TARGET.A;
  }

  return fieldTypes.TARGET.BOTH;
}

function buildAssociations(fieldAssociations) {
  return fieldAssociations
    .map((fieldAssociation) =>
      /**
       * FROM:
       * {
       *   A: {
       *     mapping: [['a value']],
       *   },
       *   B: {
       *     mapping: [['another value']]
       *   }
       * }
       *
       * TO:
       * {
       *   A: {
       *     mapping: {
       *       values: [{value: 'a value'}]
       *     }
       *   },
       *   B:{
       *     mapping: {
       *       values: [{value: 'another value'}]
       *     }
       *   }
       * }
       */
      ['A', 'B'].reduce((acc, side) => {
        if (!acc.getIn([side, 'mapping'])) {
          return acc.setIn([side, 'hasMapping'], false);
        }

        const fieldMapping = acc
          .getIn([side, 'mapping'], List())
          .map((mapping) => Map({ values: mapping.map((mappingValue) => Map({ value: mappingValue })) }));

        return acc.setIn([side, 'mapping'], fieldMapping).setIn([side, 'hasMapping'], true);
      }, fieldAssociation),
    )
    .toJS();
}

export function linkPayloadToFlowBuilderFormData(link, currentFormValues = {}) {
  if (link.isEmpty()) {
    return {};
  }

  const configBySide = ['A', 'B'].reduce((acc, side) => {
    const prefix = link.getIn(['syncSettings', side, 'taskNumberPrefix']) || false;
    const mergeField = link.getIn(['syncSettings', side, 'merge', 'fields'], List())?.first();
    return {
      ...acc,
      [side]: {
        containerType: link.getIn([side, 'containerType'], null),
        itemType: link.getIn([side, 'itemType'], null),
        parentContainer: link.getIn([side, 'parentContainer'], null)?.toJS(),
        nodes: link.getIn([side, 'nodes'], undefined)?.toJS(),
        streamAttachments: link.getIn(['syncSettings', side, 'streamAttachments'], null),
        filtersInitializedAt: link.getIn(['syncSettings', side, 'filtersInitializedAt'], null),
        includePublicComments: link.getIn(['syncSettings', side, 'includePublicComments'], null),
        providerContainerId: link.getIn([side, 'providerContainerId'], null),
        containerId: link.getIn([side, 'container', 'id'], null),
        providerName: link.getIn([side, 'providerName'], null),
        providerIdentityId:
          link.getIn([side, 'providerIdentity', '_id'], null) || link.getIn([side, 'providerIdentity'], null),
        itemFieldAssociations: link.getIn(['syncSettings', side, 'itemFieldAssociations'], null)?.toJS(),
        actions: getActionsBySide(link, side),
        filters: getRulesBySide(link, side, currentFormValues[side]?.filters),
        truthSide: link.getIn(['syncSettings', side, 'truthSide']),
        // temporary to make merge fields handling more palatable in ManageDuplicates since the real merge field (fields)
        // is an array but we only support one value, we use this intermediary field to make the implementation a bit simpler
        // in the modal.
        mergeField,
        onFilterOut: link.getIn(['syncSettings', side, 'onFilterOut'], linkTypes.ON_FILTER_OUT_VALUES.IGNORE),
        merge: {
          fields: link.getIn(['syncSettings', side, 'merge', 'fields']),
        },
        deepFilters: getDeepRulesBySide(link, side),
        prefix: !!prefix,
        prefixes: typeof prefix === 'boolean' ? [] : prefix.map((value, key) => ({ key, value })).toArray(),
        // TODO see if we need to do any pre-crunching of settings here, removed for now
        // the default behaviour for settings will be used when the payload is processed
        // in formDatatoLinkPayload.
      },
    };
  }, {});

  const manualOptions = link.getIn(['syncSettings', 'manualOptions'], Map());

  const isTruthSideSetToA = link.getIn(['syncSettings', 'A', 'truthSide'], false);
  const isTruthSideSetToB = link.getIn(['syncSettings', 'B', 'truthSide'], false);

  // set truthSide only if a side was explicitly set to true, keep null otherwise.
  let truthSide = null;
  if (isTruthSideSetToA) {
    truthSide = 'A';
  }
  if (isTruthSideSetToB) {
    truthSide = 'B';
  }

  return {
    _id: link.get('_id'),
    name: link.get('name'),
    ...configBySide,
    // truthSide is extracted at the top level to make handling the value more easy in the ManageDuplicates modal
    truthSide,
    syncDirection: getSyncDirection(link.get('syncSettings', Map())),
    // This small change just makes it easier for the tests, we used to send the immutable list directly
    // which worked but made it impractical to test payloads in tests.
    associations: buildAssociations(link.getIn(['syncSettings', 'fieldAssociations'], List())),
    // isMultiSync: link.get('isMultisync'),
    // kind: link.get('kind'),
    isSuspended: link.get('isSuspended', false),
    isAutoSync: link.get('isAutoSync', true),
    state: link.get('state'),
    duplicateLinkId: link.get('duplicateLinkId') || '',
    workflowId: link.get('originatorWorkflow'),
    manualOptions: !manualOptions.isEmpty() ? JSON.stringify(manualOptions.toJS(), null, 2) : '', // prettify JSON
    lazyResync: link.get('lazyResync', true),
    rules: link.get('rules', Map()).toJS(),
    linkSettingsLastModifiedOn: link.get('linkSettingsLastModifiedOn')
      ? new Date(link.get('linkSettingsLastModifiedOn'))
      : null,
    lastSyncRequest: link.get('lastSyncRequest') ? new Date(link.get('lastSyncRequest')) : null,
    earliestCreatedAt: link.getIn(['syncSettings', 'earliestCreatedAt']),
    createdAt: link.get('createdAt'),
  };
}

function transformDefaultFieldIdIntoFieldId(defaultFieldId) {
  // e.g. Transforms "defaultColumn" into "column"
  return defaultFieldId
    .split('default')
    .splice(1)
    .join()
    .charAt(0)
    .toLowerCase()
    .concat(defaultFieldId.split('default').splice(1).join().slice(1));
}

/**
 * Receives an error object like this (react-hook-form format):
 * {
 *   RULES: {
 *     mandatoryDeepFiltersMissingSideA: {
 *       type: 'manual'
 *     },
 *     incompleteItemFieldAssociationsA: {
 *       type: 'manual'
 *     },
 *   },
 *   MAPPINGS: {
 *     missingMandatoryFieldsB: {
 *       type: 'manual'
 *     },
 *   },
 * }
 *
 * and returns an object like this:
 * {
 *   RULES: 'mandatoryDeepFiltersMissingSideA, incompleteItemFieldAssociationsA',
 *   MAPPINGS: 'missingMandatoryFieldsB',
 * }
 */
export function formatFormErrors(errors) {
  const pageNames = Object.keys(errors || {});

  return pageNames.reduce((acc, pageName) => {
    const pageErrors = Object.keys(errors[pageName] || {});

    acc[pageName] = pageErrors.join(', ');

    return acc;
  }, {});
}

export function hasFieldValues(field) {
  if (!field) {
    return false;
  }

  return (
    field.get('type') === fieldTypes.TYPES.ENUM || field.get('semantic', '')?.startsWith(fieldTypes.SEMANTIC.WORKFLOW)
  );
}

export function getIsWorkItemsPageCompleted(link) {
  const requiredWorkItemKeys = ['providerName', 'providerIdentity', 'container', 'containerType', 'itemType'];
  return requiredWorkItemKeys.every(
    (workItemKey) => !!link.getIn(['A', workItemKey]) && link.getIn(['B', workItemKey]),
  );
}

export const getSearchContainerPlaceholdertext = (capabilitiesV3, containerType) => {
  const searchablePlaceholder = capitalize(
    capabilitiesV3.getIn(['containers', containerType, 'searchable', 'placeholder']),
  );
  const searchableTerm = capabilitiesV3.getIn(['containers', containerType, 'names', 'native']);
  return searchablePlaceholder || `Search by ${searchableTerm}`;
};

export const hasVowelAsFirstLetter = (word) => {
  const [firstLetter] = typeof word === 'string' ? word.toLowerCase() : '';
  return ['a', 'e', 'i', 'o', 'u'].includes(firstLetter);
};
