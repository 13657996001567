import React from 'react';
import PropTypes from 'prop-types';

import { IntegrationPathFinder } from '@unitoio/mosaic';
import { TrackingFunnel } from '@unitoio/sherlock';

import { trackingTypes } from 'consts';
import { useSelector } from 'react-redux';
import { getProviderIdentityProviderName } from 'reducers';

import { useConsoleTrackingFunnelContext } from 'hooks';

export function PathFinderConnectTools({
  providerIdentityId,
  onNodeChange,
  onItemTypeChange,
  selectedNodes = null,
  selectedItemType = null,
  readOnly,
  isSaving,
}) {
  // Bring Logic around flowbuilder here so the IntegrationPathFinder component can live in different context (workflow designer, flow builder, standalone).
  // return <PathFinder />;
  const onGraphStateChange = (nodes = [], itemType, newContainerType) => {
    onNodeChange(nodes, newContainerType, itemType);
  };

  const providerName = useSelector((state) => getProviderIdentityProviderName(state, providerIdentityId));

  const controlledGraphState =
    selectedNodes !== null
      ? {
          controlledGraphState: {
            pathSegments: selectedNodes,
            itemType: selectedItemType,
          },
        }
      : {};

  const consoleTrackingFunnelContext = useConsoleTrackingFunnelContext();

  return (
    <TrackingFunnel
      contextName={trackingTypes.MODULE.TOOL_SELECTION}
      sharedProperties={{
        ...consoleTrackingFunnelContext,
        selected_tool_name: providerName,
      }}
    >
      <IntegrationPathFinder
        credentialId={providerIdentityId}
        rootPath="/"
        onGraphStateChange={onGraphStateChange}
        onItemTypeChange={onItemTypeChange}
        readOnly={readOnly}
        isSaving={isSaving}
        {...controlledGraphState}
      />
    </TrackingFunnel>
  );
}

PathFinderConnectTools.propTypes = {
  providerIdentityId: PropTypes.string.isRequired,
  onNodeChange: PropTypes.func.isRequired,
  onItemTypeChange: PropTypes.func.isRequired,
  selectedNodes: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({ label: PropTypes.string, path: PropTypes.string }),
      relation: PropTypes.shape({ label: PropTypes.string, path: PropTypes.string }),
    }).isRequired,
  ),
  selectedItemType: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};
