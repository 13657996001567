import { useSelector } from 'react-redux';

import { getLinkById } from 'reducers';

export function useGetContainerTypes(linkId) {
  const link = useSelector((state) => getLinkById(state, linkId));
  const containerTypeA = link.getIn(['A', 'containerType']);
  const containerTypeB = link.getIn(['B', 'containerType']);
  return [containerTypeA, containerTypeB];
}
