import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { tokens, Box, Icon } from '@unitoio/mosaic';

import { useWatchFieldArray } from '../hooks';
import { FieldValuesSelect } from './FieldValuesSelect';
import TwoWayArrow from '../images/two-way-arrow.svg';
import OneWayArrow from '../images/one-way-arrow.svg';

const IconContainer = styled.div`
  height: ${tokens.spacing.s5};
  width: ${tokens.spacing.s5};
`;

const CircleButton = styled(Box)`
  border-radius: ${tokens.spacing.s4};
  border: 2px solid ${tokens.colors.content.info.default};
  color: ${tokens.colors.content.info.default};
  height: ${tokens.spacing.s5};
  width: ${tokens.spacing.s5};
  cursor: pointer;
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
  opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : '1')};
`;

const MappingItemTrashContainer = styled(Box)`
  height: ${tokens.spacing.s5};
  min-width: ${tokens.spacing.s5};
`;

const ClickableIcon = styled(Icon)`
  cursor: pointer;
`;

const FullWidthContainer = styled(Box)`
  width: 100%;
  min-width: 0;

  &:not(:hover) ${ClickableIcon} {
    visibility: hidden;
  }
`;

const FullWidthFlexContainer = styled(FullWidthContainer)`
  display: flex;

  &:hover {
    border-color: ${tokens.colors.content.secondary.default};
  }
  &:not(:hover) ${MappingItemTrashContainer} {
    visibility: hidden;
  }
`;

const ArrowContainer = styled(Box)`
  width: 3.75rem;
  img {
    ${({ $fieldDirection, $side }) => {
      if ($fieldDirection !== 'both') {
        return `transform: ${$fieldDirection === 'A' ? 'rotate(180deg)' : 'none'}`;
      }
      return `transform ${$side === 'B' ? 'rotate(180deg)' : 'none'}`;
    }}
  }
`;

export const FieldMappingGroupItem = ({
  fieldIndex,
  mappingIndex,
  onRemoveGroup,
  containerIdA,
  containerIdB,
  containerTypeA,
  containerTypeB,
  providerIdentityIdA,
  providerIdentityIdB,
  fieldAssociation,
  itemTypeA,
  itemTypeB,
}) => {
  const { setValue } = useFormContext();
  const {
    fields: fieldsAGroup,
    append: appendA,
    remove: removeA,
  } = useWatchFieldArray(`associations.${fieldIndex}.A.mapping.${mappingIndex}.values`);
  const {
    fields: fieldsBGroup,
    append: appendB,
    remove: removeB,
  } = useWatchFieldArray(`associations.${fieldIndex}.B.mapping.${mappingIndex}.values`);
  const fieldDirection = fieldAssociation.target;
  const sides = {
    A: { containerId: containerIdA, providerIdentityId: providerIdentityIdA, values: fieldsAGroup },
    B: { containerId: containerIdB, providerIdentityId: providerIdentityIdB, values: fieldsBGroup },
  };

  useEffect(() => {
    if (fieldsAGroup?.length && !fieldsBGroup?.length) {
      appendB({ value: null });
      return;
    }
    if (fieldsBGroup?.length && !fieldsAGroup?.length) {
      appendB({ value: null });
      return;
    }
    if (!fieldsAGroup?.length) {
      appendA({ value: null });
      appendB({ value: null });
    }
  }, [fieldsAGroup, fieldsBGroup, appendA, appendB]);

  const getAddButtonDisabledState = (side) => {
    if (side === 'A') {
      return !fieldsAGroup?.at(-1)?.value;
    }
    return !fieldsBGroup?.at(-1)?.value;
  };

  const handleFieldOnChange = (fieldId, side, groupIndex) => {
    setValue(`associations.${fieldIndex}.${side}.mapping.${mappingIndex}.values.${groupIndex}.value`, fieldId, {
      shouldDirty: true,
    });
  };

  const handleDeleteFromSide = (side, groupIndex) => {
    const removeFromSide = side === 'A' ? removeA : removeB;
    removeFromSide(groupIndex);
  };

  const renderSide = (side) => {
    const fieldsSide = side === 'A' ? fieldsAGroup : fieldsBGroup;
    const appendToSide = side === 'A' ? appendA : appendB;
    const watchedSideValue = fieldAssociation[side];
    const fieldName = watchedSideValue.field;

    const isOptionDisabled = (fieldValue) => {
      const isAlreadyMapped = watchedSideValue.mapping.some((mapping) =>
        mapping.values?.some((mappingField) => mappingField.value === fieldValue.id),
      );
      if (isAlreadyMapped) {
        return {
          disabled: true,
          disabledText: 'Mapped',
        };
      }
      return {};
    };

    return fieldsSide.map((groupItem, groupIndex) => (
      <FullWidthContainer key={groupItem.value ?? 'newFieldMappingGroupItem'}>
        <Box alignItems="center" p={[groupIndex !== 0 ? tokens.spacing.s3 : 0, 0, 0]}>
          <FullWidthContainer p={[0, tokens.spacing.s4, 0, 0]}>
            <FieldValuesSelect
              containerSide={side}
              name={`associations.${fieldIndex}.${side}.mapping.${mappingIndex}.values.${groupIndex}.value`}
              providerIdentityId={sides[side].providerIdentityId}
              containerId={sides[side].containerId}
              kind={watchedSideValue.kind}
              value={sides[side].values?.[groupIndex]?.value}
              fieldId={watchedSideValue.field}
              isOptionDisabled={isOptionDisabled}
              onChange={(fieldId) => handleFieldOnChange(fieldId, side, groupIndex)}
              itemType={side === 'A' ? itemTypeA : itemTypeB}
              containerType={side === 'A' ? containerTypeA : containerTypeB}
            />
          </FullWidthContainer>
          <IconContainer>
            {groupIndex === 0 ? (
              <CircleButton
                $isDisabled={getAddButtonDisabledState(side)}
                onClick={() => appendToSide({ value: null })}
                alignItems="center"
                justifyContent="center"
              >
                <Icon name="plus" />
              </CircleButton>
            ) : (
              <ClickableIcon
                onClick={() => handleDeleteFromSide(side, groupIndex)}
                name="trash"
                kind={Icon.KINDS.SOLID}
                data-testid={`${fieldName}-${groupItem.value}-trash`}
              />
            )}
          </IconContainer>
          <MappingItemTrashContainer m={[0, tokens.spacing.s3]} alignItems="center" justifyContent="center">
            {side === 'B' && groupIndex === 0 && (
              <ClickableIcon onClick={onRemoveGroup} name="trash" kind={Icon.KINDS.SOLID} />
            )}
          </MappingItemTrashContainer>
        </Box>
      </FullWidthContainer>
    ));
  };

  return (
    <FullWidthFlexContainer
      p={[tokens.spacing.s3, 0, tokens.spacing.s3, 2.5]}
      borderSize={1}
      borderColor="transparent"
      borderRadius={tokens.spacing.s3}
    >
      <FullWidthContainer>{renderSide('A')}</FullWidthContainer>
      <ArrowContainer $fieldDirection={fieldDirection} m={[0, 2.5, 0, 0]}>
        <img
          alt={fieldDirection !== 'both' ? 'One way' : 'Two way'}
          src={fieldDirection !== 'both' ? OneWayArrow : TwoWayArrow}
        />
      </ArrowContainer>
      <FullWidthContainer>{renderSide('B')}</FullWidthContainer>
    </FullWidthFlexContainer>
  );
};

FieldMappingGroupItem.propTypes = {
  mappingIndex: PropTypes.number.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  onRemoveGroup: PropTypes.func.isRequired,
  containerIdA: PropTypes.string.isRequired,
  containerIdB: PropTypes.string.isRequired,
  containerTypeA: PropTypes.string.isRequired,
  containerTypeB: PropTypes.string.isRequired,
  providerIdentityIdA: PropTypes.string.isRequired,
  providerIdentityIdB: PropTypes.string.isRequired,
  itemTypeA: PropTypes.string.isRequired,
  itemTypeB: PropTypes.string.isRequired,
  fieldAssociation: PropTypes.shape({
    A: PropTypes.shape({
      field: PropTypes.string,
      kind: PropTypes.string,
      mapping: PropTypes.arrayOf(
        PropTypes.shape({ values: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })) }),
      ),
    }),
    B: PropTypes.shape({
      field: PropTypes.string,
      kind: PropTypes.string,
      mapping: PropTypes.arrayOf(
        PropTypes.shape({ values: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })) }),
      ),
    }),
    target: PropTypes.oneOf(['A', 'B', 'both']),
  }).isRequired,
};
