import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Map } from 'immutable';
import { isMobile } from 'react-device-detect';

import { formTypes } from 'consts';
import { SelectProviderField } from 'containers';
import { fontSize } from 'theme';
import { Divider } from 'components';
import { LoginEmailPasswordButton } from './LoginEmailPasswordButton';

const SectionTitle = styled.h5`
  font-size: ${fontSize.subheading};
  margin-bottom: 32px;
  text-align: center;
`;

export const LoginFields = ({ disableSubmit = false, provider, providers, trackLoginAction, onSubmit }) => (
  <Fragment>
    {!isMobile && (
      <Fragment>
        <SectionTitle>Log in with</SectionTitle>

        <SelectProviderField
          {...provider}
          id="loginProvider"
          providers={providers}
          track={trackLoginAction}
          onSubmit={onSubmit}
          disableSubmit={disableSubmit}
        />
        <Divider>OR</Divider>
        <SectionTitle>
          <LoginEmailPasswordButton onSubmit={onSubmit} />
        </SectionTitle>
      </Fragment>
    )}
  </Fragment>
);

LoginFields.propTypes = {
  disableSubmit: PropTypes.bool,
  provider: PropTypes.shape(formTypes.fieldPropTypes).isRequired,
  providers: PropTypes.instanceOf(Map).isRequired,
  trackLoginAction: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
