import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { IconHoverTooltip, Href } from 'components';
import { getEmbedName, getSelectedOrganizationId } from 'reducers';
import { routes } from 'consts';
import { makeLimitMessageTemplate } from 'utils';
import './FeatureLimitTooltip.scss';

export const FeatureLimitTooltip = ({ feature, messageVariables, className }) => {
  const embedName = useSelector((state) => getEmbedName(state));
  const isEmbed = !!embedName;
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const classNames = classnames(className);

  const getPricingUrl = () => {
    const billingUrl = `${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/pricing`;
    // Open in new tab inside embed mode, redirect in standalone
    return isEmbed ? { href: `/#${billingUrl}` } : { to: billingUrl };
  };

  return (
    <span className={classNames}>
      <IconHoverTooltip placement="top">
        {`${makeLimitMessageTemplate(feature, messageVariables)}\n`}
        <Href {...getPricingUrl()}>See the features</Href> included with your plan.
      </IconHoverTooltip>
    </span>
  );
};

FeatureLimitTooltip.propTypes = {
  feature: PropTypes.string.isRequired,
  className: PropTypes.string,
  messageVariables: PropTypes.shape({}),
};
