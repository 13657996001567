import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { tokens, Typography } from '@unitoio/mosaic';

import { featureTypes } from 'consts';
import { getOrganizationPlanProfile, getUsageByOrganizationId } from 'reducers';

function getUsageFontColor(usage, limit) {
  // To keep consistency and the same font color as non warning or error numbers
  if (limit === featureTypes.UNLIMITED || typeof limit === 'string' || limit instanceof String) {
    return tokens.colors.content.neutral.n40;
  }

  if (usage > limit) {
    return tokens.colors.content.destructive.default;
  }

  if (usage > limit * 0.75) {
    return tokens.colors.content.warning.default;
  }

  return tokens.colors.content.neutral.n40;
}

export function FeatureUsage({ featureName, colored = false }) {
  const usage = useSelector((state) => getUsageByOrganizationId(state)).getIn([featureName, 'usage']);
  const limit = useSelector((state) => getOrganizationPlanProfile(state)).getIn(['features', featureName, 'limit']);

  const usageFontColor = colored ? getUsageFontColor(usage, limit) : tokens.colors.content.neutral.n40;

  return (
    <Typography variant={Typography.variants.H2} color={usageFontColor}>
      {usage ?? 'Usage unavailable'}
    </Typography>
  );
}

FeatureUsage.propTypes = {
  featureName: PropTypes.oneOf(Object.values(featureTypes.FEATURES)).isRequired,
  colored: PropTypes.bool,
};
