import React from 'react';
import PropTypes from 'prop-types';

import { Box, ProviderIcon, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';

import { ProviderTermsByName } from 'components';
import { capitalize } from 'utils';

import { RulesRow } from '../../../../components/RulesRow';

// TODO: To be moved in its own files as they are shared with other components

export const IfStatementRuleRow = ({ providerName, providerDisplayName, containerName, itemType, containerType }) => (
  <RulesRow hoverable={false} itemType={itemType} containerType={containerType}>
    <Box m={[0, tokens.spacing.s2, 0, 0]}>
      <Typography variant={TypographyVariants.H4}>IF</Typography>
    </Box>
    <Typography variant={TypographyVariants.BODY2}>
      <Box
        backgroundColor={tokens.colors.background.neutral.grey}
        p={[tokens.spacing.s2, tokens.spacing.s3]}
        borderRadius={0.4}
      >
        a{' '}
        <ProviderTermsByName
          providerNameA={providerName}
          plurality="singular"
          termKey="task"
          pcdv3
          itemTypeA={itemType}
        />{' '}
        is in
      </Box>
    </Typography>
    <Typography variant={TypographyVariants.BODY2}>
      <Box
        backgroundColor={tokens.colors.background.neutral.grey}
        p={[tokens.spacing.s2, tokens.spacing.s3]}
        borderRadius={0.4}
        flexDirection={Box.flexDirection.ROW}
      >
        <Box m={[0, tokens.spacing.s3, 0, 0]}>
          <ProviderIcon name={providerName} size="small" />
        </Box>
        {capitalize(providerDisplayName)}
      </Box>
    </Typography>
    <Typography variant={TypographyVariants.BODY2}>
      <Box
        backgroundColor={tokens.colors.background.neutral.grey}
        p={[tokens.spacing.s2, tokens.spacing.s3]}
        borderRadius={0.4}
      >
        {containerName}
      </Box>
    </Typography>
  </RulesRow>
);

IfStatementRuleRow.propTypes = {
  providerName: PropTypes.string.isRequired,
  providerDisplayName: PropTypes.string.isRequired,
  containerName: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
};
